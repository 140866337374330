import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";
const userNameLuSearch = (user_id) => {
    return clientApi.get(ApiControllers.master + `AuditLogs/Users/${user_id}`);
}
const getFeatureLuSearch = () => {
    return clientApi.get(ApiControllers.master + `AuditLogs`);
}
const getCustomerBankDetails = (customerId,currency) => {
    return clientApi.get(ApiControllers.commissions + `CustomerBanks/${customerId}/${currency}`);
}

export {userNameLuSearch, getFeatureLuSearch,getCustomerBankDetails}