import React, { Component } from "react";
import List from "../grid.component";
import {
	Modal,
	Tooltip,
	Button,
	Typography,
	Alert,
	Spin,
	Empty,
} from "antd";
import apiCalls from "../../api/apiCalls";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";

const { Text } = Typography;

class ReconcileView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			check: false,
			selection: [],
			selectedObj: {},
			cryptoModal: false,
			infoData: [],
			errorMsg: null,
			loader: false,
			fiatDecimal:null
		};
	}

	getInfoCall = async (data) => {
		let response = await apiCalls.getReconcileInfo(data.id);
		if (response.ok) {
			this.setState({
				...this.state,
				
				loader: false,
				infoData: response.data.data,
				errorMsg:null
			});
		} else {
			this.setState({ ...this.state, loader: false, errorMsg:apiCalls.isErrorDispaly(response) });
		}
	};

	gridColumns = [
		{
			field: "walletCode",
			title: "Wallet Code",
			filter: true,
			width: 180,
			customCell: (props) => (
				<td>
					<div className="gridLink" onClick={() => this.reconcileView(props)}>
						{props.dataItem.walletCode}
					</div>
				</td>
			),
		},
		{
			field: "type",
			title: "Type",
			filter: true,
			width: 180,
		},
		{
			field: "providerBeforeValue",
			title: "Provider Before Value",
			filter: true,
			dataType: "number",
			filterType: "numeric",
			width: 200,
		},
		{
			field: "providerValue",
			title: "Provider Value",
			width: 200,
			filter: true,
			dataType: "number",
			filterType: "numeric",
			customCell: (props) => (
				<td>
					{props.dataItem.providerValue ? props.dataItem.providerValue : 0}
					<Tooltip title="View More">
						<span
							className="icon md info c-pointer k-float-right ml-36"
							style={{ float: "right" }}
							onClick={() => {
								this.setState({
									...this.state,
									cryptoModal: true,
									loader: true,
								});
								this.getInfoCall(props.dataItem);
							}}
						/>
					</Tooltip>
				</td>
			),
		},
		
		{
			field: "transactiosBeforeValue",
			title: "Transactions Before Value",
			width: 230,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "transactiosValue",
			title: "Transactions Value",
			width: 200,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerBeforeFeeValue",
			title: "Provider Before Fee Value",
			width: 230,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerFeeValue",
			title: "Provider Fee Value",
			width: 200,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "suissebaseProfitBeforeValue",
			title: "Suissebase Profit Before Value",
			width: 250,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "suissebaseProfitValue",
			title: "Suissebase Profit Value",
			width: 230,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepingFeeBeforeValue",
			title: "Sweeping Fee Before Value",
			width: 230,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepingFeeValue",
			title: "Sweeping Fee Value",
			width: 200,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "krakenProviderValue",
			title: "Kraken Provider Value",
			width: 200,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "fireblocksProviderValue",
			title: "Fireblocks Provider Value",
			width: 220,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "suissebaseComissionValue",
			title: "Suissebase Commission Value",
			width: 250,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "suissebaseComissionBeforeValue",
			title: "Suissebase Commission Before Value",
			width: 300,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "transitValue",
			title: "Transit Value",
			width: 200,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		
		{
			field: "transitBeforeValue",
			title: "Transit Before Value",
			width: 200,
			filter: true,
			dataType: "number",
			filterType: "numeric",
		},
		
	];
	reconcileView = (e) => {
		const items = e.dataItem;
		const val = items.id;
		this.props.history.push(
			`/reconcile/view/${this.props.match.params.id}/detail/${val}`
		);
		this.props.dispath(
			setBreadcrumb({
				key: `/reconcile/view/${this.props.match.params.id}/detail/${val}`,
				val: "Reconcile details",
			})
		);
	};

	render() {
		return (
			<>
				<div>
					<List
						ignoreSaved={true}
						pKey={"reconcile"}
						ref={this.gridRef}
						url={
							process.env.REACT_APP_RECONCILE_GRID_API +
							`Reconcile/GetReconcileDetails/${this.props.match.params.id}`
						}
						columns={this.gridColumns}
						columnsIsNotReorderable={true}
					/>
				</div>

				<Modal
					title="Provider Balance"
					visible={this.state.cryptoModal}
					className="crypto-list"
					closeIcon={
						<Tooltip title="Close">
							<span
								className="icon md x c-pointer"
								onClick={() =>
									this.setState({ ...this.state, cryptoModal: false })
								}
							/>
						</Tooltip>
					}
					footer={
						<Button
						    type="primary"
							className="primary-btn cancel-btn"
							onClick={() =>
								this.setState({ ...this.state, cryptoModal: false })
							}>
							Close
						</Button>
					}>
					<>
						<Spin spinning={this.state.loader}>
							{this.state.errorMsg && (
								<Alert
									closable={false}
									type={"error"}
									description={"Some error occur"}
									onClose={() =>
										this.setState({ ...this.state, errorMsg: null })
									}
									showIcon
								/>
							)}
							{this.state.infoData.length !== 0 ? (
								this.state.infoData.map((item) => (
									<div className="coin-info" key={item.type}>
										<Text className="hisstate">{item.type}</Text>
										<Text className="hisstate">{item.providerValue}</Text>
									</div>
								))
							) : (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description="No Data Found"></Empty>
							)}
						</Spin>
					</>
				</Modal>
			</>
		);
	}
}
const connectDispatchToProps = (dispath) => {
	return {
		dispath,
	};
};
const connectStateToProps = ({ breadCrumb }) => {
	return {
		breadCrumb,
	};
};
export default connect(
	connectStateToProps,
	connectDispatchToProps
)(ReconcileView);
