import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getBank = (id) => {
    return clientApi.get(ApiControllers.banks + `${id}`)
}
const saveBank = (obj, id) => {
    return id === "00000000-0000-0000-0000-000000000000" ? clientApi.post(ApiControllers.banks + "Banks", obj) : clientApi.put(ApiControllers.banks + `${id}`, obj);
}
const getCurrency = () => {
    return clientApi.get(ApiControllers.banks + "Currency");
}
const getCurrencyLU = () => {
    return clientApi.get(ApiControllers.banks + "Currency");
}
const activeInactive = (obj) => {
	return clientApi.put(ApiControllers.master + `banks/Status`, obj);
};
const getIBANData = (ibannumber) => {
	return clientApi.get(
		ApiControllers.master + `GetIBANAccountDetails?ibanNumber=` + ibannumber
	);
};

const getCode = ( isResendOTP) => {
	return clientApi.get(
		ApiControllers.master + `SendOTP/${isResendOTP}`
	);
};
const getVerification = ( code) => {
	return clientApi.get(
		ApiControllers.master + `OTPVerification/${code}`
	);
};
const sendEmail = ( isResendOTP) => {
	return clientApi.get(
		ApiControllers.master + `SendEmailOTP/${isResendOTP}`
	);
};

const verifyEmailCode = ( code) => {
	return clientApi.get(
		ApiControllers.master + `EmailOTPVerification/${code}`
	);
};
const getAuthenticator = (Code) => {
	return clientApi.get(
		ApiControllers.master + `VerifyAuthenticator/${Code}`
	);
};
const getVerificationFields = () => {
	return clientApi.get(
		ApiControllers.master + `Verificationfields`
	);
};
const adminVerifications=()=>{
	return clientApi.get(
		ApiControllers.customers + `AdminVerifications`
	);
}
const getReceiveOptions = () => {
    return clientApi.get(ApiControllers.common + "ControlCodes?codeCategory=receivingoptions");
}
export { getBank, saveBank, getCurrency, getCurrencyLU, activeInactive,getIBANData, getCode,getVerification,sendEmail,verifyEmailCode,getAuthenticator, getVerificationFields,adminVerifications,getReceiveOptions }