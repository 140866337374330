import React, { Component } from "react";
import List from "../grid.component";
import {  Tooltip, Modal, message, Button, Alert,Form,Row,Col,Select,Input } from "antd";
import apiCalls from "../../api/apiCalls";
import { ApiControllers } from "../../api/config";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import {approveStateUpdate,getStates} from "./api"
import Moment from "react-moment";
import Loader from "../loader.component";
import { validateContentRule } from "../../utils/custom.validator";
import config from "../../config";
const { Option } = Select;
const { TextArea } = Input;

class Transfer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isBtnApprove: false,
      check: false,
      selectedObj: {},
      selection: [],
      stateChange: {},
      modal: false,
      statuserrorMsg: null,
      gridUrl: process.env.REACT_APP_GRID_API + ApiControllers.common + "CompanyWalletTransfer",
      obj: {
        id: "",
        status: "",
        modifiedBy: "",
        modifiedDate: "",
        remarks: "",
        info: ""
      },
      alert: false,
      errorMessage: "",
      warningMsg: null,
      isTransferLoading: false,
      statesLst:[]
    };
    this.addTransfer= this.addTransfer.bind(this);
    this.gridRef = React.createRef();
    this.formref = React.createRef();
  }

  gridColumns = [
    {
      field: "",
      title: "",
      locked: true,
      width: 50,
      customCell: (props) => (
        <td>
          {" "}
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.checkChange(props, e)}
            />
            <span></span>{" "}
          </label>
        </td>
      )
    },
    {
        field: "createdDate",
        title: "Date",
        filter: true,
        width: 240,
        isShowTime: true,
        locked: true,
        filterType: "date",
        customCell: (props) => (
            <td>
                <div className="gridLink" onClick={() => this.updateTransferView(props)}>

                    {props.dataItem?.createdDate ? <Moment format={config?.dateFormates?.dateTimeFormate} >{props.dataItem?.createdDate && apiCalls.convertUTCToLocalTime(props.dataItem?.createdDate) || props.dataItem?.createdDate}</Moment> : props.dataItem?.createdDate}
                </div>
            </td>
        ),
    },
   
    { field: "fromWallet", title: "From Wallet", filter: true, width: 250 },
    { field: "toWallet",  title: "To Wallet",  filter: true,  width: 180  },
    { field: "netWork", title: "Network", filter: true, width: 190 },
    { field: "coin", title: "Coin", filter: true, width: 160 },
    { field: "amount", title: "Amount", filter: true, dataType: "number", filterType: "numeric", width: 220 },
    { field: "transactionId", title: "Transaction ID", filter: true, width: 180 },
    { field: "state", title: "Status", filter: true, width: 150 }
  ];



  stateChange = (val, prop) => {
		let { stateChange } = this.state;
		stateChange.status = val;
		this.setState({ ...this.state, stateChange });
	
		if(val ==="Reject" || val ==="Approve"){
			this.formref.current.setFieldsValue({stateRemarks:""})
		  }
	};

  updateTransferView = (e) => {
    const items = e.dataItem;
		this.props.history.push(`/internalWalletTransfer/view/${items.id}`);
		this.props.dispatch(setBreadcrumb({ key: `/internalWalletTransfer/view/${items.id}`, val: items.state }));
   
  };
  
  checkChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.currentTarget.type === "checkbox"
        ? e.currentTarget.checked
        : e.currentTarget.value;
    const name = e.currentTarget.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      warningMsg: null
    });
  };
  
  handleCancel = (e) => {
    this.props?.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      check: false,
      error: null,
      statuserrorMsg: null
    });
      this.formref.current?.resetFields();
      this.gridRef.current?.refreshGrid();
  };

  addTransfer = () => {
    this.props?.history.push('/internalWalletTransfer/00000000-0000-0000-0000-000000000000');
		this.props?.dispatch(setBreadcrumb({ key: `/internalWalletTransfer/00000000-0000-0000-0000-000000000000`, val: "Add Transfer" }));

  };

  onActionClick = (key) => {
    const actions = {
      add: "addTransfer",
      "State Change": "statusChange"
    };
    this[actions[key]]();
  };

  statusChange = () => {
    const { check, selectedObj, stateChange } = this.state;
  
    const setInitialState = () => {
      this.setState({
        warningMsg: 'Please select the one record',
        isSubmitting: stateChange.status !== "Submitted"
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          isSubmitting: stateChange.status !== "Submitted"
        });
      }, 1000);
    };
  
    const fetchData = () => {
      getStates(selectedObj.state).then(response => {
        if (response.ok) {
          this.setState({ statesLst: response.data, stateLoading: false });
        }
      });
    };
  
    const showModal = () => {
      this.setState({
        modal: true,
        stateChange: {
          status: selectedObj.state,
          type: selectedObj.type,
          stateRemarks: selectedObj.stateRemarks,
          isSubmitting: stateChange.status !== "Submitted"
        }
      }, () => {
        this.setState({ stateLoading: true, isSubmitting: stateChange.status !== "Submitted" });
  
        setTimeout(() => {
          this.setState({ stateLoading: false, isSubmitting: stateChange.status !== "Submitted" });
        }, 1000);
  
        setTimeout(() => {
          this.formref.current.setFieldsValue({
            status: selectedObj.state,
            stateRemarks: selectedObj.stateRemarks,
          });
        }, 1000);
      });
    };
  
    if (!check) {
      setInitialState();
    } else {
      fetchData();
      showModal();
    }
  };
  

  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Approve":
        status = "Approved";
        break;
      case "Rejected":
        status = "Rejected";
        break;
      case "Reject":
        status = "Rejected";
        break;
        default:
        return null;
    }
    message.success({
      content: "Transaction " + status + " successfully",
      className: "custom-msg",
      duration: 1
    });
  };
  error = () => {
    message.error("Please select the one record");
  };

  approveSave = async (values) => {
    this.setState({ ...this.state, isBtnApprove: true })
    if (!this.isLoading) {
      this.isLoading = true;
      let statusObj = this.state.obj;
      statusObj.id = this.state.selectedObj.id;
      statusObj.status = values.status || this.state.selectedObj.state;
      statusObj.remarks = values.stateRemarks;
      statusObj.modifiedBy = this.props.userConfig.userName;
      statusObj.info = JSON.stringify(this.props.trackAuditLogData);
      statusObj.customerId = this.props.userConfig?.id;
          let response = await approveStateUpdate(statusObj);
          if (response.ok) {
            this.props?.dispatch(setCurrentAction(null));
            this.success(statusObj.status);
            this.setState({
              ...this.state,
              modal: false,
              selection: [],
              check: false,
              stateLoading: true,
              isBtnApprove: false
            });
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
           
              this.gridRef.current?.refreshGrid();
          
          } else {
            this.setState({
              ...this.state,
              isLoading: false,
              statuserrorMsg: response.data || response.originalError?.message || 'Something went wrong please try again!',
              error: response.status === 401 ? response.data.message : response.data,
              isBtnApprove: false
            });
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          }
    }

  };

  render() {
    const { gridUrl, warningMsg, statuserrorMsg,isBtnApprove } = this.state;
    return (
      <>
        {warningMsg !== undefined && warningMsg !== null && (
          <Alert
            className="w-100 mb-16"
            type="warning"
            description={warningMsg}
            showIcon
          />
        )}

        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"Transfer"}
          url={gridUrl}
          ref={this.gridRef}
          columns={this.gridColumns}
          className="custom-transfer-grid table-grid-change"
        />
         <Modal
          title="Confirm Approve/Reject?"
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {this.state.stateLoading && <Loader />}
            {statuserrorMsg !== undefined && statuserrorMsg !== null && (
              <Alert
                className="mb-12"
                type="error"
                description={statuserrorMsg}
                showIcon
              />
            )}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.approveSave}
            >
             
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="status"
                    label="State"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      }
                    ]}
                  >
                    <Select
                      className="cust-input mb-0"
                      onChange={(e) => this.stateChange(e)}
                      disabled={this.state.selectedObj.state === "Approved" || this.state.selectedObj.state === "Rejected"}
                      placeholder="Select State"
                    >
                      {this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24}>
                {this.state.stateChange.status !== "Submitted" &&
                    <Form.Item
                      name="stateRemarks"
                      label="Remarks"
                      className="input-label"
                      rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: "Is required"
                        },
                        {
                            validator: validateContentRule
                        }
                    ]}
                    >
                      <TextArea
                        placeholder="Remarks"
                        maxLength={100}
                        rows={4}
                        disabled={
                          this.state.selectedObj.state === "Rejected" ||
                          this.state.selectedObj.state === "Approved"
                            ? true
                            : false
                        }
                        showCount
                      />
                    </Form.Item>
                    }
                </Col>
              </Row>
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  {(this.state.selectedObj.state !== "Approved" && this.state.selectedObj.state !== "Rejected") && <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    disabled={this.state.stateChange?.status === "Submitted"}
                    loading={isBtnApprove}
                  >
                    Save
                  </Button>}
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return { breadCrumb, oidc, userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData };
};

const connectDispatchToProps = dispatch => {
  return {
    setAction: (val) => {
      dispatch(setCurrentAction(val))
    },
    dispatch
  }
}

export default connect(connectStateToProps, connectDispatchToProps)(Transfer);
