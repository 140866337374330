import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";

const getRolesData = () => {
    return clientApi.get(ApiControllers.security + `Roles/Permissions`);
}
const getAppLp = () => {
    return clientApi.get(ApiControllers.security + `GetApps`);
}

const getAppDetails = (AppId) => {
    return clientApi.get(ApiControllers.security + `FeatureActions/${AppId}`);
}
const getEditData = (RoleId) => {
    return clientApi.get(ApiControllers.security +'Role/'+RoleId);
}
const saveRole = (saveobj) => {
    return clientApi.post(ApiControllers.security+"Role", saveobj)
}
const activeInactive = (obj) => {
	return clientApi.put(ApiControllers.master + `Role/Status`, obj);
};

export  {getRolesData,getEditData,getAppLp, saveRole,getAppDetails, activeInactive}