import React, { Component } from 'react';
import {Button ,Col, Row,Alert } from 'antd';
import { connect } from "react-redux";
import { getCasesTemplate} from './api';
import apiCalls from '../../api/apiCalls';
import Loader from "../loader.component";
import Info from '../info/Info';
class CaseTemplateView extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
      btnLoader:false,
      deletebtn:false,
      getCaseTemplateObj:{},
      errorMsg:null,
      loading:false
      
    }
    this.formref = React.createRef();
    this.gridRef = React.createRef();
  }
  componentDidMount() {
    this.getCaseTemplate();
  }
  getCaseTemplate = async () => {
    this.setState({ ...this.state, errorMsg:null,loading:true });
    let response = await getCasesTemplate(this.props.match.params?.id )
    if (response.ok) {
      this.setState({ ...this.state, getCaseTemplateObj: response.data,loading:false });
      this.formref.current?.setFieldsValue({remarks:response?.data?.remarks,templateName:response?.data?.templateName})
    }
    else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response),loading:false });
    }
  }

  handleCancel = () => {
    this.props.history.push("/cases/3")
    this.setState({ ...this.state,  errorMsg:null, })
    this.formref.current?.resetFields();
  }
  render() {
    const {getCaseTemplateObj,errorMsg,loading}=this.state;
    return (
      <>
      {loading && <Loader />}
            {errorMsg !== undefined && errorMsg !== null && (
              <Alert type="error" className="mb-16" showIcon description={errorMsg} />
            )}
            <Row gutter={8}>
              <Col xl={24} xxl={24} className="bank-view">
                <Row className="kpi-List">
                  <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                    <span className="icon md description"/>
                    <div>
                      <label className="kpi-label">Template Name</label>
                      <div className="kpi-val">
                        {getCaseTemplateObj?.templateName ||"-"}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                    <span className="icon md file" />
                    <div>
                      <label className="kpi-label">Remarks</label>
                      <div className=" kpi-val">{getCaseTemplateObj?.remarks ||"-"}</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                    <span className="icon md userby" />
                    <div>
                      <label className="kpi-label">Created By</label>
                      <div className=" kpi-val">{getCaseTemplateObj?.createdBy ||"-"}</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                    <span className="icon md userby" />
                    <div>
                      <label className="kpi-label">Modified By</label>
                      <div className="kpi-val">{`${getCaseTemplateObj?.modifiedBy ||"-"}`}</div>
                    </div>
                  </Col>
                 
                </Row>
              </Col>
            </Row>
            <Info Id={getCaseTemplateObj?.id} Status={getCaseTemplateObj?.status} Createdby={getCaseTemplateObj?.createdBy} CreatedDate={getCaseTemplateObj?.createdDate} ModifiedDate={getCaseTemplateObj?.modifiedDate} Modifiedby={getCaseTemplateObj?.modifiedBy} infoDetails={true} screenName={"cases"} />
            <div className="text-right mt-24">
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                style={{ margin: "0 8px" }}
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
            </div>
      </>
    )
  }
}
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData,
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(CaseTemplateView);

