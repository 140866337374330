import React, { Component } from 'react';
import { Radio, Button, Tooltip, message, Modal, Form, Row, Col, Input, Alert } from "antd";
import List from "../grid.component";
import { connect } from "react-redux";
import { setCurrentAction } from '../../reducers/actionsReducer';
import NumberFormat from "react-number-format";
import Loader from '../loader.component';
import { publishShowActions } from "../grid.component/subscribir";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import { getFiatDetails, getCryptoDetails, saveFiat, saveCrypto } from "./api";
import apiCalls from '../../api/apiCalls';
class CustomerCommissions extends Component {
    constructor (props) {
        super(props);
        this.state = {
            check: false,
            selection: [],
            selectedObj: {},
            commission: true,
            modal: false,
            stateLoading: true,
            btnDisabled: false,
            comissionObj: {},
            fiatObj: {},
            CryptoObj: {},
            setLoading: false,
            gridUrl: process.env.REACT_APP_GRID_API + `Commissions/CustomerComissionFiat/${this.props.match.params.id}/Fiat`,
            gridUrlC: process.env.REACT_APP_GRID_API + `Commissions/CustomerComissionCrypto/${this.props.match.params.id}/Crypto`,
          
        }
        this.gridRef = React.createRef();
        this.formRef = React.createRef();
    }

    componentDidMount = () => {
        publishShowActions(false);
         this.props.dispatch(updateCurrentScreen("commission"));
    }

    setLoadingTime = () => { setTimeout(() => this.setState({ ...this.state, stateLoading: false }), 1000); }

    success = (msg) => {
        message.destroy();
        message.success({
            content: msg,
            className: "custom-msg",
            duration: 4
        });
    };

    getFiat = async () => {
        let response = await getFiatDetails(this.state.selectedObj.id);
        if (response.ok) {
            this.setState({ fiatObj: response.data,warningMsg:null })
            this.formRef.current.setFieldsValue(this.state.fiatObj)
        }else{
            this.setState({...this.state,warningMsg:apiCalls.isErrorDispaly(response)})
        }
    }
    getCrypto = async () => {
        let response = await getCryptoDetails(this.state.selectedObj.id);
        if (response.ok) {
            this.setState({ CryptoObj: response.data,warningMsg:null })
            this.formRef.current.setFieldsValue(this.state.CryptoObj)
        }else{
            this.setState({...this.state,warningMsg:apiCalls.isErrorDispaly(response)})  
        }
    }

    handleInputChange = (prop, e) => {
        const rowChecked = prop.dataItem;
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let { selection } = this.state;
        let idx = selection.indexOf(rowChecked.id);
        if (selection) {
            selection = [];
        }
        if (idx > -1) {
            selection.splice(idx, 1)
        }
        else {
            selection.push(rowChecked.id)
        }
        this.setState({ ...this.state, [name]: value, selectedObj: rowChecked, selection, warningMsg: null });
    }

    handleCheckBox = (props) => (
        <td>
            <label className="text-center custom-checkbox">
                <input
                    id={props.dataItem.id}
                    name="check"
                    type="checkbox"
                    checked={this.state.selection.indexOf(props.dataItem.id) > -1}
                    onChange={(e) => this.handleInputChange(props, e)}
                />
                <span></span>
            </label>
        </td>
    )
    columnCommFiat = [
        {
            field: "",
            title: "",
            width: 50,
            customCell: this.handleCheckBox
        },
        { field: "currency", title: "Currency", filter: true, width: 150 },
        { field: "bankname", title: "Bank", filter: true, width: 200 },
        { field: "depositFiatMinValue", title: "Deposit Min Fee ($)", filter: true, width: 220, filterType: "numeric", dataType: "number" },
        { field: "depositFiatMaxValue", title: "Deposit Max Fee (%)", filter: true, width: 220, filterType: "numeric", dataType: "number" },
        { field: "depositFiatMultiplier", title: "Deposit Multiplier", filter: true, width: 200, filterType: "numeric", dataType: "number" },
        { field: "withdrawFiatMinValue", title: "Withdraw Min Fee ($)", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "withdrawFiatMaxValue", title: "Withdraw Max Fee (%)", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "withdrawFiatMultiplier", title: "Withdraw Multiplier", filter: true, width: 200, filterType: "numeric", dataType: "number" },

        { field: "partnerDMinFee", title: "Partner Deposit Min Fee ($)", filter: true, width: 240, filterType: "numeric", dataType: "number" }, 
        { field: "partnerDMaxFee", title: "Partner Deposit Max Fee (%)", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "pDepositFiatMultiplier", title: "Partner Deposit Multiplier", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "partnerWMinFee", title: "Partner Withdraw Min Fee ($)", filter: true, width: 240, filterType: "numeric", dataType: "number" }, 
        { field: "partnerWMaxFee", title: "Partner Withdraw Max Fee (%)", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "pWithdrawFiatMultiplier", title: "Partner Withdraw Multiplier", filter: true, width: 240, filterType: "numeric", dataType: "number" },


        { field: "partnerDMinFee", title: "Sub Partner Deposit Min Fee ($)", filter: true, width: 300, filterType: "numeric", dataType: "number" }, 
        { field: "depositMaxSubPartnerFee", title: "Sub Partner Deposit Max Fee (%)", filter: true, width: 300, filterType: "numeric", dataType: "number" },
        { field: "dSubPartnerFeeFiatMultiplier", title: "Sub Partner Deposit Multiplier", filter: true, width: 300, filterType: "numeric", dataType: "number" },
        { field: "partnerWMinFee", title: "Sub Partner Withdraw Min Fee ($)", filter: true, width: 300, filterType: "numeric", dataType: "number" }, 
        { field: "withdrawMaxSubPartnerFee", title: "Sub Partner Withdraw Max Fee (%)", filter: true, width: 300, filterType: "numeric", dataType: "number" },
        { field: "wSubPartnerFeeFiatMultiplier", title: "Sub Partner Withdraw Multiplier", filter: true, width: 300, filterType: "numeric", dataType: "number" },
        { field: "status", title: "Status", filter: true, width: 150,}
    ];

    columnCommCrypto = [
        {
            field: "",
            title: "",
            width: 50,
            customCell: this.handleCheckBox
        },
        { field: "depositCryptoMaxValue", title: "Deposit Fee (%)", filter: true, width: 200, filterType: "numeric", dataType: "number" },
        { field: "depositCryptomultiplier", title: "Deposit Multiplier", filter: true, width: 200, filterType: "numeric", dataType: "number" },
        { field: "withdrawCryptoMaxValue", title: "Withdraw Fee (%)", filter: true, width: 200, filterType: "numeric", dataType: "number" },
        { field: "withdrawCryptomultiplier", title: "Withdraw Multiplier", filter: true, width: 200, filterType: "numeric", dataType: "number" },
        { field: "buyMaxFee", title: "Buy Fee (%)", filter: true, width: 160, filterType: "numeric", dataType: "number" },
        { field: "buymultiplier", title: "Buy Multiplier", filter: true, width: 160, filterType: "numeric", dataType: "number" },
        { field: "sellMaxFee", title: "Sell Fee (%)", filter: true, width: 160, filterType: "numeric", dataType: "number" },
        { field: "sellmultiplier", title: "Sell Multiplier", filter: true, width: 160, filterType: "numeric", dataType: "number" },


        { field: "partnerDMaxFee", title: "Partner Deposit Fee (%)", filter: true, width: 200, filterType: "numeric", dataType: "number" },
        { field: "pDepositCryptoMultiplier", title: "Partner Deposit Multiplier", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "partnerWMaxFee", title: "Partner Withdraw Fee (%)", filter: true, width: 220, filterType: "numeric", dataType: "number" },
        { field: "pWithdrawCryptoMultiplier", title: "Partner Withdraw Multiplier", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "partnerBuyMaxFee", title: "Partner Buy Fee (%)", filter: true, width: 200, filterType: "numeric", dataType: "number" },
        {field: "pBuyMultiplier", title: "Partner Buy Multiplier", filter: true, width: 240, filterType: "numeric", dataType: "number" },
        { field: "partnerSellMaxFee", title: "Partner Sell Fee (%)", filter: true, width: 170, filterType: "numeric", dataType: "number" },
        { field: "pSellMultiplier", title: "Partner Sell Multiplier", filter: true, width: 200, filterType: "numeric", dataType: "number" },

        { field: "depositMaxSubPartnerFee", title: "Sub Partner Deposit Fee (%)", filter: true, width: 290, filterType: "numeric", dataType: "number" },
        { field: "dSubPartnerFeeCryptoMultiplier", title: "Sub Partner Deposit Multiplier", filter: true, width: 280, filterType: "numeric", dataType: "number" },
        { field: "withdrawMaxSubPartnerFee", title: "Sub Partner Withdraw Fee (%)", filter: true, width: 290, filterType: "numeric", dataType: "number" },
        { field: "wSubPartnerFeeCryptoMultiplier", title: "Sub Partner Withdraw Multiplier", filter: true, width: 280, filterType: "numeric", dataType: "number" },
        { field: "buyMaxSubPartnerFee", title: "Sub Partner Buy Fee (%)", filter: true, width: 290, filterType: "numeric", dataType: "number" },
        {field: "buySubPartnerFeeMultiplier", title: "Sub Partner Buy Multiplier", filter: true, width: 280, filterType: "numeric", dataType: "number" },
        { field: "sellMaxSubPartnerFee", title: "Sub Partner Sell Fee (%)", filter: true, width: 290, filterType: "numeric", dataType: "number" },
        { field: "sellSubPartnerFeeMultiplier", title: "Sub Partner Sell Multiplier", filter: true, width: 280, filterType: "numeric", dataType: "number" },
        { field: "status", title: "Status", filter: true, width: 150,}
    ];

    backToBanks = () => {
        setCurrentAction(null);
        this.props.history.push({
            pathname: '/customers'
        });
    }
    handleWithdraw = (e) => {
        this.setState(
            {
                ...this.state,
                commission: e.target.value === 1,
                selection: [],
                warningMsg: null
            },

            () => {
                this.gridRef.current.refreshGrid();
            }
        );

    };

    commissionEdit = () => {
        const { selection } = this.state;
        if (selection.length !== 1) {
            this.setState({ ...this.state, warningMsg: 'Please select the one record' }
            );
        } else {
            this.setLoadingTime()
            if (this.state.selectedObj.feeType === "Fiat") {
                this.getFiat()
            } else {
                this.getCrypto()
            }
            this.setState({ ...this.state, modal: true });
        }
    }
    handleModalCancel = () => {
        this.props.dispatch(setCurrentAction(null));
        this.formRef.current.resetFields();
        this.setState({ ...this.state, modal: false, selection: [], check: false, stateLoading: true });   
    }
    saveFiatCommission = async (values) => {
        if (this.state.selectedObj.feeType === "Fiat") {
            let obj = {
                "id": this.state.selectedObj.id,
                "customerId": this.state.selectedObj.customerId,
                "comissionId": this.state.selectedObj.comissionId,
                "depositFiatMaxValue": null,
                "depositFiatMinValue": parseFloat(this.state.fiatObj.depositFiatMinValue),
                "depositFiatMultiplier": values.depositFiatMultiplier,
                "withdrawFiatMaxValue": null,
                "withdrawFiatMinValue": parseFloat(this.state.fiatObj.withdrawFiatMinValue),
                "withdrawFiatMultiplier": values.withdrawFiatMultiplier,
                "currency": this.state.selectedObj.currency,
                "bankname": this.state.selectedObj.bankname,
                "feeType": "Fiat",
                "status": this.state.selectedObj.status == 'Active' ? true : false,
                "createdBy": this.props.userConfig.userName,
                "createddate": new Date(),
                "modifiedBy": this.props.userConfig.userName,
                "modifiedDate": new Date(),
                "pWithdrawFiatMultiplier": values.pWithdrawFiatMultiplier,
                "pDepositFiatMultiplier": values.pDepositFiatMultiplier,
                "dSubPartnerFeeFiatMultiplier" : values.dSubPartnerFeeFiatMultiplier,
                "wSubPartnerFeeFiatMultiplier" : values.wSubPartnerFeeFiatMultiplier,
                
            }
            obj.info = JSON.stringify(this.props.trackAuditLogData);
            this.setState({ ...this.state, btnDisabled: true });
            let response = await saveFiat(obj)
            if (response.ok) {
                this.props.dispatch(setCurrentAction(null));
                this.formRef.current.resetFields();
                this.setState({ ...this.state, modal: false, selection: [], check: false, stateLoading: true, btnDisabled: false });
                this.gridRef.current.refreshGrid();             
                this.success("Commission saved successfully")
                setTimeout(() => {
                    this.isLoading = false;
                }, 2000);
            }
        } else {
            let cryobj = {
                "id": this.state.selectedObj.id,
                "customerId": this.state.selectedObj.customerId,
                "comissionId": this.state.selectedObj.comissionId,
                "depositCryptoMaxValue": parseFloat(this.state.CryptoObj.depositCryptoMaxValue),
                "depositCryptomultiplier": (values.depositCryptomultiplier),
                "withdrawCryptoMaxValue": parseFloat(this.state.CryptoObj.withdrawCryptoMaxValue),
                "withdrawCryptomultiplier": (values.withdrawCryptomultiplier),
                "buyMaxFee": parseFloat(this.state.CryptoObj.buyMaxFee),
                "buymultiplier": (values.buymultiplier),
                "sellMaxFee": parseFloat(this.state.CryptoObj.sellMaxFee),
                "sellmultiplier": (values.sellmultiplier),
                "swapMaxFee": parseFloat(this.state.CryptoObj.swapMaxFee),
                "swapmultiplier": (values.swapmultiplier),
                "feeType": this.state.selectedObj.feeType,
                "status": this.state.selectedObj.status == 'Active' ? true : false,
                "createdBy": this.props.userConfig.userName,
                "createddate": new Date(),
                "modifiedBy": this.props.userConfig.userName,
                "modifiedDate": new Date(),
                "pDepositCryptoMultiplier": values.pDepositCryptoMultiplier,
                "pWithdrawCryptoMultiplier": values.pWithdrawCryptoMultiplier,
                "pBuyMultiplier": values.pBuyMultiplier,
                "pSellMultiplier": values.pSellMultiplier,
                "dSubPartnerFeeCryptoMultiplier" : values.dSubPartnerFeeCryptoMultiplier,
                "wSubPartnerFeeCryptoMultiplier" : values.wSubPartnerFeeCryptoMultiplier,
                "buySubPartnerFeeMultiplier" : values.buySubPartnerFeeMultiplier,
                "sellSubPartnerFeeMultiplier" : values.sellSubPartnerFeeMultiplier,
                "partnerBuyMaxFee" : values.partnerBuyMaxFee,
            }
            cryobj.info = JSON.stringify(this.props.trackAuditLogData);
            this.setState({ ...this.state, btnDisabled: true });
            let response = await saveCrypto(cryobj)
            setTimeout(() => ({ ...this.state, setLoading: false }), 1000)
            if (response.ok) {
                this.props.dispatch(setCurrentAction(null));
                this.formRef.current.resetFields();
                this.setState({ ...this.state, modal: false, selection: [], check: false, stateLoading: true, btnDisabled: false,warningMsg:null });
                this.gridRef.current.refreshGrid();               
                this.success("Commission saved successfully")
                setTimeout(() => {
                    this.isLoading = false;
                }, 2000);
            }else{
                this.setState({...this.state,warningMsg:apiCalls.isErrorDispaly(response)})   
            }
        }
    }
    getAlertMsg=()=>(
        this.state.warningMsg && (
            <Alert
                className="w-100 mb-16"
                type="warning"
                description={this.state.warningMsg}
                showIcon
            />
        )
    )
    renderCommissionvalue=(commissionValue)=>{
           return commissionValue || 0
        }
    render() {
        const { commission, gridUrl, gridUrlC, fiatObj, CryptoObj } = this.state;
        publishShowActions(false);
        const isDecimal=commission ? 2 : 8;
        const isCommissionMaxlength=commission ? 13 : 20;
        const isMaxlengthWithoutCommission =!commission && 12;
        return (
            <>
                {this.getAlertMsg()}
                <div
                    style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}
                    className="mb-16"
                >
                    <div>
                        <Radio.Group
                            defaultValue={1}
                            onChange={this.handleWithdraw}
                            className="buysell-toggle"
                        >
                            <Radio.Button value={1}> Fiat </Radio.Button>
                            <Radio.Button value={2}> Crypto </Radio.Button>
                        </Radio.Group>
                    </div>
                    <ul class="admin-actions mb-0">
                        <Tooltip title="Edit"><li onClick={this.commissionEdit}>
                            <span class="icon c-pointer md edit mr-0" />
                        </li>
                        </Tooltip>
                    </ul>
                </div>
                {commission && <List
                    showActionBar={true}
                    pKey={"CustomerComissionFiat"}
                    onActionClick={(key) => this.onActionClick(key)}
                    url={gridUrl}
                    ref={this.gridRef}
                    columns={this.columnCommFiat}
                />}
                {!commission && <List
                        showActionBar={true}
                        pKey={"CustomerComissionCrypto"}
                        onActionClick={(key) => this.onActionClick(key)}
                        url={gridUrlC}
                        ref={this.gridRef}
                        columns={this.columnCommCrypto}
                    />}
                <div className="text-right mt-24">
                    <Button
                        type="primary"
                        className="primary-btn cancel-btn"
                        style={{ margin: '0 8px' }}
                        onClick={this.backToBanks}
                    >
                        Cancel
                    </Button>
                </div>
                <Modal
                    title={commission ? 'Fiat Commission' : 'Crypto Commission'}
                    visible={this.state.modal}
                    closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={this.handleModalCancel} /></Tooltip>}
                    footer=""
                    width={1000}
                >
                    {this.state.stateLoading && <Loader />}
                    {this.state.modal && 
                    <Form
                        name="basic"
                        initialValues={this.state.selectedObj.feeType === "Fiat" ? fiatObj : CryptoObj}
                        autoComplete="off"
                        onFinish={this.saveFiatCommission}
                        ref={this.formRef}
                    >

                        <Row gutter={16}>
                            {commission ? <>

                                <Col xs={24} sm={24} md={12} lg={9} xxl={8}>
                                    <Form.Item
                                        label={`Deposit Multiplier (${this.renderCommissionvalue(fiatObj.depositFiatMinValue)} USD / ${this.renderCommissionvalue(fiatObj.depositFiatMaxValue)}%)`}
                                        name="depositFiatMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Deposit Multiplier"
                                            allowNegative={false}
                                            maxlength={isCommissionMaxlength}
                                        />
                                    </Form.Item>
                                </Col>&emsp;&emsp;&emsp;&emsp;
                                <Col xs={24} sm={24} md={12} lg={9} xxl={8}>
                                    <Form.Item
                                       
                                       label={`Withdraw Multiplier (${this.renderCommissionvalue(fiatObj.withdrawFiatMinValue)} USD / ${this.renderCommissionvalue(fiatObj.withdrawFiatMaxValue)}%)`}
                                       name="withdrawFiatMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Withdraw Multiplier "
                                            allowNegative={false}
                                            maxlength={isCommissionMaxlength}
                                        />  
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={9} xxl={8}>
                                    <Form.Item
                                       label={`Partner Deposit Multiplier
                                       (${this.renderCommissionvalue(fiatObj.partnerDMaxFee)}%)`
                                       }
                                        name="pDepositFiatMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Partner Deposit Multiplier "
                                            allowNegative={false}
                                            maxlength={isCommissionMaxlength}
                                        />
                                    </Form.Item>
                                </Col>&emsp;&emsp;&emsp;&emsp;
                                <Col xs={24} sm={24} md={12} lg={9} xxl={8}>
                                    <Form.Item
                                        label={`Partner Withdraw Multiplier
                                        (${this.renderCommissionvalue(fiatObj.partnerWMaxFee)}%)`
                                       }
                                        name="pWithdrawFiatMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Partner Withdraw Multiplier"
                                            allowNegative={false}
                                            maxlength={isCommissionMaxlength}
                                        />
                                    </Form.Item>
                                </Col>&emsp;&emsp;&emsp;

                                <Col xs={24} sm={24} md={12} lg={9} xxl={8}>
                                    <Form.Item
                                       label={`Sub Partner Deposit Multiplier
                                       (${this.renderCommissionvalue(fiatObj.depositMaxSubPartnerFee)}%)`
                                       }
                                        name="dSubPartnerFeeFiatMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Sub Partner Deposit Multiplier"
                                            allowNegative={false}
                                            maxlength={isCommissionMaxlength}
                                        />
                                    </Form.Item>
                                </Col>&emsp;&emsp;&emsp;&emsp;
                                <Col xs={24} sm={24} md={12} lg={9} xxl={8}>
                                    <Form.Item
                                       label={`Sub Partner Withdraw Multiplier
                                       (${this.renderCommissionvalue(fiatObj.withdrawMaxSubPartnerFee)}%)`
                                       }
                                        name="wSubPartnerFeeFiatMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Sub Partner Withdraw Multiplier"
                                            allowNegative={false}
                                            maxlength={isCommissionMaxlength}
                                        />
                                    </Form.Item>
                                </Col>
                                </>
                                : <><Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item
                                        label={`Deposit Multiplier (${this.renderCommissionvalue(CryptoObj.depositCryptoMaxValue)}%)`}
                                        name="depositCryptomultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Deposit Multiplier "
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                        <Form.Item
                                            label={`Withdraw Multiplier (${this.renderCommissionvalue(CryptoObj.withdrawCryptoMaxValue)}%)`}
                                            name="withdrawCryptomultiplier"
                                            className="input-label"
                                            rules={[{ required: true, message: "Is required" }]}
                                        >
                                            <NumberFormat
                                                decimalScale={isDecimal}
                                                className="cust-input"
                                                customInput={Input}
                                                thousandSeparator={true}
                                                prefix={""}
                                                placeholder="Enter Withdraw Multiplier"
                                                allowNegative={false}
                                                maxlength={isMaxlengthWithoutCommission}
                                            />
                                        </Form.Item>
                                    </Col></>}
                            {!commission && <><Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <Form.Item

                                    label={`Buy Multiplier (${CryptoObj.buyMaxFee}%)`}
                                    name="buymultiplier"
                                    className="input-label"
                                    rules={[{ required: true, message: "Is required" }]}
                                >
                                    <NumberFormat
                                        decimalScale={isDecimal}
                                        className="cust-input"
                                        customInput={Input}
                                        thousandSeparator={true}
                                        prefix={""}
                                        placeholder="Enter Buy Multiplier"
                                        allowNegative={false}
                                        maxlength={isMaxlengthWithoutCommission}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <Form.Item

                                    label={`Sell Multiplier (${CryptoObj.sellMaxFee}%)`}
                                    name="sellmultiplier"
                                    className="input-label"
                                    rules={[{ required: true, message: "Is required" }]}
                                >
                                    <NumberFormat
                                        decimalScale={isDecimal}
                                        className="cust-input"
                                        customInput={Input}
                                        thousandSeparator={true}
                                        prefix={""}
                                        placeholder="Enter Sell Multiplier"
                                        allowNegative={false}
                                        maxlength={isMaxlengthWithoutCommission}
                                    />
                                </Form.Item>
                            </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item
                                        label={`Partner Deposit Multiplier
                                        (${this.renderCommissionvalue(CryptoObj.partnerDMaxFee)}%)`
                                       }
                                        name="pDepositCryptoMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Partner Deposit Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item

                                        label={`Partner Withdraw Multiplier
                                          (${this.renderCommissionvalue(CryptoObj.partnerWMaxFee)}%)`
                                        }
                                        name="pWithdrawCryptoMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Partner Withdraw Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item

                                           label={`Partner Buy Multiplier
                                               (${this.renderCommissionvalue(CryptoObj.partnerBuyMaxFee)}%)`
                                    }
                                    name="pBuyMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Partner Buy Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item

                                        label={`Partner Sell Multiplier
                                        (${this.renderCommissionvalue(CryptoObj.partnerSellMaxFee)}%)`
                                    }
                                        name="pSellMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Partner Sell Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>


                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item

                                            label={`Sub Partner Deposit Multiplier
                                                 (${this.renderCommissionvalue(CryptoObj.depositMaxSubPartnerFee)}%)`
                                       }
                                        name="dSubPartnerFeeCryptoMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Sub Partner Deposit Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item

                                               label={`Sub Partner Withdraw Multiplier
                                                   (${this.renderCommissionvalue(CryptoObj.withdrawMaxSubPartnerFee)}%)`
                                                  }
                                        name="wSubPartnerFeeCryptoMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Sub Partner Withdraw Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item

                                        label={`Sub Partner Buy Multiplier
                                          (${this.renderCommissionvalue(CryptoObj.buyMaxSubPartnerFee)}%)`
                                    }
                                        name="buySubPartnerFeeMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Sub Partner Buy Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <Form.Item

                                         label={`Sub Partner Sell Multiplier
                                       (${this.renderCommissionvalue(CryptoObj.sellMaxSubPartnerFee)}%)`
                                    }
                                        name="sellSubPartnerFeeMultiplier"
                                        className="input-label"
                                        rules={[{ required: true, message: "Is required" }]}
                                    >
                                        <NumberFormat
                                            decimalScale={isDecimal}
                                            className="cust-input"
                                            customInput={Input}
                                            thousandSeparator={true}
                                            prefix={""}
                                            placeholder="Enter Sub Partner Sell Multiplier"
                                            allowNegative={false}
                                            maxlength={isMaxlengthWithoutCommission}
                                        />
                                    </Form.Item>
                                </Col>
                            </>}
                        </Row>
                        <div className="text-right mt-24">
                            <Button type="primary" disabled={this.state.btnDisabled} className="primary-btn" htmlType="submit">
                                Save
                            </Button>
                            <Button
                                type="primary"
                                className="primary-btn cancel-btn"
                                style={{ margin: "0 8px" }}
                                onClick={this.handleModalCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>}
                </Modal>
            </>
        )
    }
}

const connectStateToProps = ({ oidc, userConfig }) => {
    return { userConfig: userConfig.userProfileInfo, oidc, trackAuditLogData: userConfig.trackAuditLogData };
};
export default connect(connectStateToProps, (dispatch) => {
    return { dispatch };

})
    (CustomerCommissions);