import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Button, Form, Alert } from "antd";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import { getBankTransactionSearch, pyrrosUserNameLuSearch, evolveUserNameLuSearch,openpaydUserNameLuSearch, GridColorsType, getBankStatusLookup, getBankCurrencyLookup } from "./api";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import Moment from "react-moment";
import config from "../../config";
const { Option } = Select;

class BankTransaction extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading: false,
			customerData: [],
			typeData: [],
			bankTypeData: [],
			value: "",
			errorMsg: null,
			searchObj: {
				type:'All',
				currencyType:"All",
                statusType:'All',
				memberName: "00000000-0000-0000-0000-000000000000",
			},
			searchObjs: {
				type:'All',
				memberName: "00000000-0000-0000-0000-000000000000",
			},
			cryptoModal: false,
			selectedModal: "",
			tranObj: {},
			gridUrl: null,
			pkeyValue:null,
			bankCurrencyTypesData: [],
			bankStatusTypeData: [],
			colorsCode:[],
		};
		this.gridRef = React.createRef();
	}
	
	openpaydGridColumns = [
		{
			field: "date",
			title: "Date",
			filter: true,
			isShowTime: true,
			filterType: "date",
			locked: true,
			width: 210,
			customCell: (props) => (
				<div className="gridLink" onClick={() => this.bankTransUpdate(props)}>
				{props.dataItem?.date ? (
					<Moment format={config?.dateFormates?.dateTimeFormate}>
					  {apiCalls.convertUTCToLocalTime(props.dataItem?.date)}
					</Moment>
				  ) : (
					props.dataItem?.date
				  )}
				</div>
			)
		},
		{ field: "transactionId", title: "Transaction ID", filter: true, width: 350 },
		{ field: "referenceId", title: "Customer/Contract ID", filter: true, width: 250 },
		{
			field: "name",
			title: "Business Name/Personal Name",
			filter: true,
			width: 320,
			customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width text-nowrap">{props.dataItem.name}</div>
				  {(props?.dataItem?.accountType !== null) && (
					<div className="file-labels ml-8 fs-12 address-label address-label-width text-nowrap">
					  {!props?.dataItem?.accountType  && "Digital Transfer" || props?.dataItem?.accountType && "Personal"}
					</div>
				  )}</div>
				</td>
			  ),
		},
		{ field: "userName", title: "User Name", filter: true, width: 200 },
		{ field: "email", title: "Email", filter: true, width: 220 },
		{ field: "transaction", title: "Transaction Type", filter: true, width: 220 },
		{
			field: "currency",
			title: "Currency",
			filter: true,
			width: 120,
			customCell: (props) => {
			  const currencyColorConfig = this.state?.colorsCode?.find(colorConfig => colorConfig.currency === props.dataItem.currency);
			  const currencyColor = currencyColorConfig?.color.toLowerCase() || 'black';
			  const textColor = (currencyColor === 'blue' || currencyColor === 'darkgreen'||currencyColor === 'green') ? 'white' : 'black';
		  
			  return (
				<td className="whitelist-name">
				  <div className={`white-address ibancolor ${currencyColor}`} style={{ color: textColor }}>
					{props.dataItem.currency}
				  </div>
				</td>
			  );
			},
			
		  },
		{ field: "grossAmount", title: "Gross Amount", filter: true, width: 220,dataType: "number",filterType: "numeric" },
		{ field: "commission", title: "Commission", filter: true, width: 220,dataType: "number",filterType: "numeric" },
		{ field: "providerCommission", title: "Provider Commission", filter: true, width: 220, dataType: "number", filterType: "numeric" },
		{ field: "sweepSourceComission", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
		{ field: "sweepDestinationComission", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
		{ field: "netAmount", title: "Net Amount", filter: true, width: 220,dataType: "number",filterType: "numeric" },
		{ field: "profit", title: "Profit", filter: true, width: 120, dataType: "number", filterType: "numeric" },
		{ field: "profitInBase", title: "Profit In Base", filter: true, width: 140, dataType: "number", filterType: "numeric" },
		{ field: "state", title: "Status", filter: true, width: 160, },
	];
	gridColumns = [
		{
			field: "date",
			title: "Date",
			filter: true,
			isShowTime: true,
			filterType: "date",
			locked: true,
			width: 210,
			customCell: (props) => (
				<div className="gridLink" onClick={() => this.bankTransUpdate(props)}>
				{props.dataItem?.date ? (
					<Moment format={config?.dateFormates?.dateTimeFormate}>
					  {apiCalls.convertUTCToLocalTime(props.dataItem?.date)}
					</Moment>
				  ) : (
					props.dataItem?.date
				  )}
				</div>
			)
		},
		{ field: "transactionId", title: "Transaction ID", filter: true, width: 350 },
		{ field: "referenceId", title: "Customer/Contract ID", filter: true, width: 250 },
		{
			field: "name",
			title: "Business Name/Personal Name",
			filter: true,
			width: 320,
			customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width text-nowrap">{props.dataItem.name}</div>
				  {(props?.dataItem?.accountType !== null) && (
					<div className="file-labels ml-8 fs-12 address-label address-label-width text-nowrap">
					  {!props?.dataItem?.accountType  && "Digital Transfer" || props?.dataItem?.accountType && "Personal"}
					</div>
				  )}</div>
				</td>
			  ),
		},
		{ field: "userName", title: "User Name", filter: true, width: 200 },
		{ field: "email", title: "Email", filter: true, width: 220 },
		{ field: "transaction", title: "Transaction Type", filter: true, width: 220 },
		{ field: "grossAmount", title: "Gross Amount", filter: true, width: 220,dataType: "number",filterType: "numeric" },
		{ field: "commission", title: "Commission", filter: true, width: 220,dataType: "number",filterType: "numeric" },
		{ field: "providerCommission", title: "Provider Commission", filter: true, width: 220, dataType: "number", filterType: "numeric" },
		{ field: "sweepSourceComission", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
		{ field: "sweepDestinationComission", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
		{ field: "netAmount", title: "Net Amount", filter: true, width: 220,dataType: "number",filterType: "numeric" },
		{ field: "profit", title: "Profit", filter: true, width: 120, dataType: "number", filterType: "numeric" },
		{ field: "state", title: "Status", filter: true, width: 160, },
	];
	componentDidMount = () => {
		this.BankTransactionSearch();
		this.BankTransactionUserSearch(null, null);
		let gridUrlValue;
		let pKeyVal;
		const { pathname } = this.props?.location || {};
		if (pathname === '/pyrrostransaction') {
			gridUrlValue = process.env.REACT_APP_GRID_API + `Bank/GetPyrrosBankTransactions`;
			pKeyVal = "Pyrros Transactions";
		} else if (pathname === '/openpaydtransaction') {
			gridUrlValue = process.env.REACT_APP_GRID_API + `Bank/GetOpenpaydBankTransactions`;
			pKeyVal = "OpenPayd Transactions";
		} else {
			gridUrlValue = process.env.REACT_APP_GRID_API + `Bank/GetEvolveBankTransactions`;
			pKeyVal = "Evolve Transaction";
		}
		this.setState(prevState => ({ ...prevState, gridUrl: gridUrlValue,pkeyValue:pKeyVal }));
		this.BankStatusSearch();
		this.BankCurrencySearch();
		this.gridColorTypes();
	};

	bankTransUpdate = (e) => {
		const items = e.dataItem;
		const val = items.id;
		let pathName;
		const { pathname } = this.props?.location || {};
		if (pathname === '/pyrrostransaction') {
			pathName = "pyrrostransaction";
		} else if (pathname === '/openpaydtransaction') {
			pathName = "openpaydtransaction";
		} else {
			pathName = "evolvetransaction";
		}
		if(this.props?.location?.pathname === "/openpaydtransaction"){
			this.props.dispatch(
				setBreadcrumb({
					key: "/openpaydtransaction" + "/details/" + val + "/view",
					val: `${e.dataItem.userName+ " " + "/" + " " + e.dataItem.state}`
				})
			);
		}else{
			this.props.dispatch(
				setBreadcrumb({
					key: (this.props?.location?.pathname === "/pyrrostransaction"
						? "/pyrrostransaction" : "/evolvetransaction") + "/details/" + val + "/view",
					val: `${e.dataItem.userName+ " " + "/" + " " + e.dataItem.state}`
				})
			);
		}
		this.props.history.push(`/${pathName}/details/` + val + "/view");
	};

	BankTransactionUserSearch = async (email, memberName) => {
		this.setState({ isLoading: true, errorMsg: null });
		let response;	
		const { pathname } = this.props?.location || {};
		if (pathname === `/pyrrostransaction`) {
			response = await pyrrosUserNameLuSearch(email, memberName);
		} else if (pathname === `/openpaydtransaction`) {
			response = await openpaydUserNameLuSearch(email, memberName);
		} else {
			response = await evolveUserNameLuSearch(email, memberName);
		}
		if (response.ok) {
			const newState = { isLoading: false, errorMsg: null };
			if (memberName) {
				newState.customerData = response.data;
			} else {
				newState.searchObj = {...this.state.searchObj , type: "All", memberName: response.data[0].customerId };
				newState.searchObjs = {...this.state.searchObjs , type: "All", memberName: response.data[0].customerId };
				newState.customerData = response.data;
			}
			this.setState(newState);
		} else {
			this.setState({ errorMsg: apiCalls.isErrorDispaly(response) });
		}
	};
	

	handleUserChange = (event, type) => {
		let userVal = event.target.value;
		if (userVal != null && userVal.length > 2) {
			if (type === "memberName") {
				this.BankTransactionUserSearch(null, userVal);
			}
		}
	}
	BankTransactionSearch = async () => {
		let response = await getBankTransactionSearch();
		if (response.ok) {
			this.setState({
				bankTypeData: response.data,
				errorMsg:null
			});
		}else{
			this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
		}
	};

	handleChange = (value, prop) => {
		var val = "";
		let { customerData, bankTypeData, searchObj,bankCurrencyTypesData,bankStatusTypeData,searchObjs } = this.state;
		if (prop === "type") {
			let index = bankTypeData.findIndex(function (o) {
				return o.transactionType === value;
			});
			val = bankTypeData[index].transactionType;
			searchObj[prop] = prop === "type" ? val : value;
			this.setState({ ...this.state, searchObj });
			searchObjs[prop] = prop === "type" ? val : value;
			this.setState({ ...this.state, searchObjs });
		}
		else if (prop === "currencyType") {
     
			let index = bankCurrencyTypesData.findIndex(function (o) {
			  return o.currency === value;
			});
			val = bankCurrencyTypesData[index].currency;
			searchObj[prop] = prop === "currencyType" ? val : value;
			this.setState({ ...this.state, searchObj });
		}
		else if (prop === "statusType") {
		 
		  let index = bankStatusTypeData.findIndex(function (o) {
			return o.status === value;
		  });
		  val = bankStatusTypeData[index].status;
		  searchObj[prop] = prop === "statusType" ? val : value;
		  this.setState({ ...this.state, searchObj });
	  }
		else {
			const obj = customerData?.find((item) => item?.name === value);
			searchObj[prop] =  obj?.customerId  ? obj?.customerId : null;
			this.setState({ ...this.state, searchObj });
		}

	};
	handleSearch = (values) => {
		const { pathname } = this.props?.location || {};
		if(pathname === 'openpaydtransaction'){
			let { searchObj } = this.state;
			this.setState({ ...this.state, searchObj }, () => {
				this.gridRef.current.refreshGrid();
			});
		}
		else{
			let { searchObjs } = this.state;
			this.setState({ ...this.state, searchObjs }, () => {
				this.gridRef.current.refreshGrid();
			});
		}			
	};
	
	onActionClick = (key) => {
		const action = {
		};
		this[action[key]]();
	};

	BankCurrencySearch = async () => {
		let response = await getBankCurrencyLookup();
		if (response.ok) {
		  this.setState({
			bankCurrencyTypesData: response.data,
			errorMsg:null
		  });
		}else{
		  this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
		}
	  };
	  
	  BankStatusSearch = async () => {
		let response = await getBankStatusLookup();
		if (response.ok) {
		  this.setState({
			bankStatusTypeData: response.data,
			errorMsg:null
		  });
		}else{
		  this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
		}
	  };

	  gridColorTypes=async()=>{
		const res= await GridColorsType();
		if(res.ok){
		  this.setState({ ...this.state, colorsCode:res?.data?.result });
		}
	  }
	render() {
		const { customerData, bankTypeData, gridUrl, searchObj, errorMsg, pkeyValue,bankCurrencyTypesData,bankStatusTypeData,searchObjs} = this.state;
		const options = customerData.map((d) => (
			<Option key={d.id} value={d.name}>
				{d.name}
			</Option>
		));
		const options2 = bankTypeData.map((d) => (
			<Option key={d.id} value={d.transactionType}>
				{d.transactionType}
			</Option>
		));
		const options3 = bankCurrencyTypesData.map((item) => (
			<Option key={item.id} value={item.currency}>
				{item.currency}
			</Option>
		));
    const options4 = bankStatusTypeData.map((item) => (
			<Option key={item.id} value={item.status}>
				{item.status}
			</Option>
		));
		const pathName = window.location.pathname.split('/')[1]
		return (
			<>
				{errorMsg !== null && errorMsg !== '' &&
					<Alert
						className="w-100 mb-16"
						type="error"
						description={errorMsg}
						showIcon
					/>}
				<div>
					<Form
						initialValues={this.state.bankTypeData}
						className="ant-advanced-search-form form form-bg search-bg pt-8"
						autoComplete="off">
						<Row style={{ alignItems: "flex-end" }}>
							<Col sm={24} xs={24} md={5} lg={5} className="px-8">
								<Form.Item
									name="customerId"
									className="input-label mb-0"
									label="User Name">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onKeyUp={(event) =>
											this.handleUserChange(event, "memberName")
										}
										onChange={(e) => this.handleChange(e, "memberName")}
										placeholder="Select Members">
										{options}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} xs={24} md={5} lg={5} className="px-8">
								<Form.Item
									name="transactionType"
									className="input-label mb-0"
									label="Transaction ">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "type")}
										placeholder="Select Transaction">
										{options2}
									</Select>
								</Form.Item>
							</Col>
							{pathName === 'openpaydtransaction' && <Col sm={24} xs={24} md={5} lg={5} className="px-8">
								<Form.Item
									name="currency"
									className="input-label mb-0"
									label="Currency ">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "currencyType")}
										placeholder="Select Currency">
										{options3}
									</Select>
								</Form.Item>
							</Col> }
							{pathName === 'openpaydtransaction' &&  <Col sm={24} xs={24} md={5} lg={5} className="px-8">
								<Form.Item
									name="status"
									className="input-label mb-0"
									label="Status">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "statusType")}
										placeholder="Select Status">
										{options4}
									</Select>
								</Form.Item>
							</Col>}
							<Col sm={24} xs={24} md={4} lg={4} className="px-8">
								<Button
									type="primary"
									className="primary-btn px-24 search-btn mt-20"
									htmlType="submit"
									onClick={this.handleSearch}>
									Search
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
				<List
					showActionBar={true}
					onActionClick={(key) => this.onActionClick(key)}
					url={gridUrl}
					pKey={pkeyValue}
					additionalParams={pathName === 'openpaydtransaction'?searchObj:searchObjs}
					ref={this.gridRef}
					key={gridUrl}
					columns={pathName === 'openpaydtransaction' ? this.openpaydGridColumns : this.gridColumns}
					showExcelExport={true}
					className="transaction-table-header"
				/>
			</>
		);
	}
}

const connectStateToProps = ({ oidc, userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(BankTransaction);
