
import React, { Component } from 'react';
import { Row, Col, Typography, Alert } from 'antd';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Loader from '../loader.component';
import { handleTransaction } from '../../reducers/transactionReducer'
import { setCurrentAction } from '../../reducers/actionsReducer';
import Moment from 'react-moment';
import apiCalls from '../../api/apiCalls';
import { getPyrrosTransactionDetails, getEvolveTransactionDetails,getOpenTransactionDetails } from '../bankTransaction.component/api'
import { publishShowActions } from '../grid.component/subscribir';
import { setBreadcrumb } from '../../reducers/breadcrumbReducer';
import config from "../../config";
const { Title } = Typography
class BankTransactionSummary extends Component {
    state = {
        transactionData: {},
        bankTransDetailsData: [],
        loading: false,
        errorMsg: null,
    }

    componentDidMount() {
        this.loadBankTransData();
        this.props.setAction(null)
        publishShowActions(false);
    }

    loadBankTransData = async () => {
        this.setState({ ...this.state, loading: true, errorMsg: null });
        let response
        if (this.props?.location?.pathname === `/pyrrostransaction/details/${this.props.match.params.id}/view`) {
            response = await getPyrrosTransactionDetails(this.props.match.params.id);
        }else if(this.props?.location?.pathname?.includes('openpaydtransaction')){
            response = await getOpenTransactionDetails(this.props.match.params.id)
        }
        else {
            response = await getEvolveTransactionDetails(this.props.match.params.id);
        }
        if (response.ok) {
            this.setState({ ...this.state, transactionData: response.data, loading: false, errorMsg: null })
        } else {
            this.setState({ ...this.state, loading: false, errorMsg: apiCalls.isErrorDispaly(response) });
        }
    }
    redirectCustomerView = (items) => {
        const val = items.customerId;
        if(this.props?.location?.pathname.includes("openpaydtransaction")){
            this.props.dispatch(
                setBreadcrumb({
                    key: "/openpaydcustomer/details/" + val,
                    val: items?.name
                })
            );
            window.open("/openpaydcustomer/details/" + val);
        }else{
            this.props.dispatch(
                setBreadcrumb({
                    key: "/pyrroscustomer/details/" + val,
                    val: items?.name
                })
            );
            window.open("/pyrroscustomer/details/" + val);
        }
    }
    getErrorMsg=()=>{
        const {errorMsg} = this.state;
        return errorMsg !== null && errorMsg !== '' &&
        <Alert
            className="w-100 mb-16"
            type="error"
            description={errorMsg}
            showIcon
        />
    }
    transactionDetailsData = (lable,value,transactionClass) => (
        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <span className={`icon md ${transactionClass}`} />
            <div>
                <label className="kpi-label">{lable}</label>
                <div className=" kpi-val">
                    {value || "-"}
                </div>
            </div>
        </Col>
    )
    transactionDetailsCommission=(commissionlable,commissionVal,commissionClass)=>(
        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <span className={`icon md ${commissionClass}`} />
            <div>
                <label className="kpi-label">{commissionlable}</label>
                <div className=" kpi-val">
                    {commissionVal ? (
                        <NumberFormat
                            value={commissionVal}
                            decimalSeparator="."
                            displayType={"text"}
                            thousandSeparator={true}
                        />
                    ) : (
                        "0"
                    )}
                </div>
            </div>
        </Col>
    )
    renderCommissionData = () => {
        const {transactionData} = this.state;
        return (<>
            {this.transactionDetailsCommission("Gross Amount", transactionData?.grossAmount, "commission")}
            {this.transactionDetailsCommission("Commission", transactionData?.commission, "commission")}
            {this.transactionDetailsCommission("Commission in Base", transactionData?.commissionInBase, "commission")}
            {this.transactionDetailsCommission("Fees", transactionData?.originalCommission, "commission")}
            {this.transactionDetailsCommission("Tier Discount", transactionData?.tierDiscount, "commission")}
            {this.transactionDetailsCommission("Provider Commission", transactionData?.providerCommission, "commission")}
            {this.transactionDetailsCommission("Provider Commission in Base", transactionData?.providerCommissionInBase, "commission")}
            {this.transactionDetailsCommission("Sweep Source Commission", transactionData?.sweepSourceComission, "commission")}
            {this.transactionDetailsCommission("Sweep Source in Base", transactionData?.sweepSourceComissionInBase, "commission")}
            {this.transactionDetailsCommission("Sweep Destination Commission", transactionData?.sweepDestinationComission, "commission")}
            {this.transactionDetailsCommission("Sweep Destination in Base", transactionData?.sweepDestinationComissionInBase, "commission")}
            {this.transactionDetailsCommission("Net Amount", transactionData?.netAmount, "cash")}
            {this.transactionDetailsCommission("Profit", transactionData?.profit, "commission")}
            {this.transactionDetailsCommission("Profit in Base", transactionData?.profitInBase, "commission")}</>)
    }
    render() {
        const { transactionData, loading } = this.state;
        return (
            <>
                {this.getErrorMsg()}
                {loading && <Loader />}
                <Title className="page-title">Transaction Detail View</Title>
                {transactionData && (
                    <Row gutter={8}>
                        <Col xl={24} xxl={24} className="bank-view">
                            <Row className="kpi-List">
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <span className="icon md date" />
                                    <div>
                                        <label className="kpi-label">Date</label>
                                        <div className="kpi-val">
                                            <Moment format={config?.dateFormates?.dateTimeFormate}>
                                                {transactionData?.date
                                                    ? apiCalls.convertUTCToLocalTime(
                                                        transactionData?.date
                                                    )
                                                    : transactionData?.date}
                                            </Moment>
                                        </div>
                                    </div>
                                </Col>
                                {this.transactionDetailsData("Transaction ID",transactionData?.transactionId,"user")}
                                {this.transactionDetailsData("Customer/Contract ID",transactionData?.referenceId,"user")}
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <span className="icon md user" />
                                    <div>
                                        <label className="kpi-labels">{transactionData?.isBusiness === true ? <><b>Business Name</b>/<>Personal Name</> </> : <><>Business Name</>/<b>Personal Name</b></>}</label>
                                        <br />
                                        <span className="kpi-val c-pointer">
                                            <a href="#/" onClick={() => this.redirectCustomerView(transactionData)}>
                                            {transactionData?.name || "-"}
                                            </a>
                                            </span>
                                    </div>
                                </Col>
                                {this.transactionDetailsData("User Name",transactionData?.userName,"user")}
                                {this.transactionDetailsData("Email",transactionData?.email,"email")}
                                {this.transactionDetailsData("Transaction Type",transactionData?.transaction,"addresstype")}
                                {this.transactionDetailsData("Currency",transactionData?.currency,"wallet")}
                               {this.renderCommissionData()}
                                {this.transactionDetailsData("Status",transactionData?.status,"status")}
                            </Row>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}

const connectStateToProps = ({ transactionStore }) => {
    return { transactionStore }
}
const connectDispatchToProps = dispatch => {
    return {
        fetchtransactionData: (transId) => {
            dispatch(handleTransaction(transId))
        },
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}

export default connect(connectStateToProps, connectDispatchToProps)(BankTransactionSummary);
