import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getCaseLu = (name) => {
  return clientApi.get(ApiControllers.customers + `AdminUsers/${name}`)
}
const getAlertNumLu = (number) => {
  return clientApi.get(ApiControllers.customers + `cases/Alerts/${number}`)
}

const getAlertTitleLu = () => {
  return clientApi.get(ApiControllers.customers + `cases/Alerts`)
}
const getAlert = (id) => {
  return clientApi.get(ApiControllers.customers + `Alert/${id}`);
};
const getNameandEmail = (email, name) => {
  return clientApi.get(ApiControllers.customers + `Customers/${email}/${name}`)
}
const getCase = (id) => {
  return clientApi.get(ApiControllers.customers + `Admin/Cases/${id}`)
}
const saveCase = (obj, id) => {
  return id === "00000000-0000-0000-0000-000000000000" ? clientApi.post(ApiControllers.customers + "SaveCases", obj) : clientApi.put(ApiControllers.customers + `UpdateCases/${id}`, obj);
}

const saveCasePopObj=(obj)=>{
  return clientApi.put(ApiControllers.customers + `Cases/Case`,obj)

}

const statusCases = (obj) => {
  return clientApi.put(ApiControllers.customers + `Cases/Status`,obj);
};
const getCasesViewData = (id) => {
  return clientApi.get(
    ApiControllers.customers + `Admin/Cases/${id}`
  );
}

const SaveCase = (obj) => {
  return clientApi.post(ApiControllers.customers + `Cases/Case`, obj);
};
const updateCase = (obj) => {
  return clientApi.put(ApiControllers.customers + `Cases/Case`, obj);
};

const getCreateCaseObj=(ScreenId,ScreenName)=>{
  return clientApi.get(ApiControllers.customers + `Cases/${ScreenId}/${ScreenName}`)
}
const getCaseObj=(Id)=>{
  return clientApi.get(ApiControllers.customers + `Admin/Case/${Id}`)
}
const deleteCaseAssigners=(id)=>{
  return clientApi.delete(ApiControllers.customers + `CaseAssigners/${id}`)

}
const getcaseDoc=(id,isCrypto)=>{
  return clientApi.get(ApiControllers.customers + `Admin/Cases/${id}/${isCrypto}`)
  }
  const getStates = (status) => {
    return clientApi.get(
      ApiControllers.common + `statechange/${"Cases"}/${status}`
    );
  };
  const deleteCaseTemplate=(id)=>{
    return clientApi.delete(ApiControllers.customers + `DeleteTemplate/${id}`)
  
  }
  const getCasesTemplate = (id) => {
    return clientApi.get(
      ApiControllers.customers + `CasesTemplate/${id}`
    );
  };
  const saveCasesTemplate = (obj) => {
      return clientApi.post(ApiControllers.customers + `SaveCasesTemplate`, obj);
  };
  
export { getCaseLu,getCreateCaseObj,saveCasePopObj,getCaseObj, getCase, getAlertTitleLu, getAlert, saveCase, SaveCase, getNameandEmail, updateCase, getAlertNumLu, statusCases, getCasesViewData,deleteCaseAssigners,getcaseDoc,getStates,deleteCaseTemplate,getCasesTemplate,saveCasesTemplate }

