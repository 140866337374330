import React from 'react'
import { Row, Col, Typography } from "antd";
import Moment from "react-moment";
import apiCalls from '../../api/apiCalls';
import config from "../../config";
const IdCard = ({details,type}) => {
    const { Title, Paragraph } = Typography;
  return (
    <div className='text-captz'><Title level={5} style={{ margin: "0" }}>
    {details?.idDocType?.includes('_') ? details?.idDocType?.split('_').join(' ').toLowerCase():details?.idDocType?.toLowerCase() }
  </Title>
  <div className="applicant-content">
    <Row gutter={[16, 24]}>
      <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">First Name</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.firstName}
          </Paragraph>
        </div>
      </Col>
      <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Last Name</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.lastName}
          </Paragraph>
        </div>
      </Col>
      {type==='idCard' && <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Middle Name</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.middleName}
          </Paragraph>
        </div>
      </Col>}
      {type==='drivers' && <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">License Number</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.licenseNumber}
          </Paragraph>
        </div>
      </Col>}
      {type==='passport' && <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Passport Number</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.number}
          </Paragraph>
        </div>
      </Col>}
      {type!=='drivers' && type!=='passport' && <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Number</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.number}
          </Paragraph>
        </div>
      </Col>}
      <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Date of Birth</label>
          <Paragraph className="kpi-val fw-700 mb-0">
          {details?.dob ? (
               <Moment format={config?.dateFormates?.dateFormate}>
                 {details?.dob
                   && apiCalls.convertUTCToLocalTime(
                       details?.dob
                     )
                  || details?.dob}
               </Moment>
             ) : (
               details?.dob
             )}
          </Paragraph>
        </div>
      </Col>
      <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Valid Until</label>
          <Paragraph className="kpi-val fw-700 mb-0">
          {details?.validUntil ? (
               <Moment format={config?.dateFormates?.dateFormate}>
                 {details?.validUntil
                   && apiCalls.convertUTCToLocalTime(
                       details?.validUntil
                     )
                   || details?.validUntil}
               </Moment>
             ) : (
               details?.validUntil
             )}
          </Paragraph>
        </div>
      </Col>
      {type==='residence' && <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Additional Number</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.additionalNumber}
          </Paragraph>
        </div>
      </Col>}
      {type!=='drivers' && <>
      <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Mrz1</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.mrzLine1}
          </Paragraph>
        </div>
      </Col>
      <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Mrz2</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.mrzLine2}
            
          </Paragraph>
        </div>
      </Col>
      {type!=='passport' && <Col
        className="gutter-row"
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xxl={6}
      >
        <div>
          <label className="kpi-label">Mrz3</label>
          <Paragraph className="kpi-val fw-700 mb-0">
            {details?.mrzLine3}
            
          </Paragraph>
        </div>
      </Col>}
      </>}
    </Row>
  </div></div>
  )
}

export default IdCard