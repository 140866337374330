import { getWithdraw, getWithdrawFait } from '../components/withdraw.component/api'

//Actions
const FETCH_WITHDRAW = 'fetchWithdraw';
const FETCH_WITHDRAW_SUCCESS = 'fetchWithdrawSuccess';
const FETCH_WITHDRAW_REJECT = 'fetchWithdrawReject';

//Action Type
const fetchWithdraw = () => {
    return {
        type: FETCH_WITHDRAW,

    }
}
const fetchWithdrawSuccess = (payload) => {
    return {
        type: FETCH_WITHDRAW_SUCCESS,
        payload
    }
}
const fetchWithdrawReject = (payload) => {
    return {
        type: FETCH_WITHDRAW_REJECT,
        payload
    }
}

//InitialState
let initialState = {
    withdrawData: { isLoading: false, data: [], error: null },
    withdrawFait: { isLoading: false, data: [], error: null },
    withdrawRowData: {}
}

const handleWithdrawCrypto = (crypto_id) => {
    return async (dispatch) => {
        dispatch(fetchWithdrawSuccess({ key: "withdrawData", isLoading: true, data: [] }));
        const response = await getWithdraw(crypto_id);
        if (response.ok) {
            dispatch(fetchWithdrawSuccess({ key: "withdrawData", isLoading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchWithdrawReject({ key: "withdrawData", isLoading: false, data: [], error: response.originalError }))
        }
    }
}
const handleWithdrawFait = (fiat_id) => {
    return async (dispatch) => {
        dispatch(fetchWithdrawSuccess({ key: "withdrawFait", isLoading: true, data: [] }));
        const response = await getWithdrawFait(fiat_id);
        if (response.ok) {
            dispatch(fetchWithdrawSuccess({ key: "withdrawFait", isLoading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchWithdrawReject({ key: "withdrawFait", isLoading: false, data: [], error: response.originalError }))
        }
    }
}

//Reducer
const withdrawReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case FETCH_WITHDRAW:
            return { ...state, isLoading: true }
        case FETCH_WITHDRAW_SUCCESS:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, isLoading: action.payload.isLoading } }
        case FETCH_WITHDRAW_REJECT:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, isLoading: action.payload.isLoading } }
        default:
            return state;
    }

}

//Export
export default (withdrawReducer);
export { handleWithdrawCrypto, fetchWithdraw, handleWithdrawFait };