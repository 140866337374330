import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getAddressBookData = (id) => {
    return clientApi.get(ApiControllers.addressbook + `Withdraw/Favourite/${id}`);
}
const getCoinList = (type) => {
    return clientApi.get(ApiControllers.markets + `Coins/${type}`);
}
const favouriteNameCheck = (customerId, name, type,favaddrId) => {
    return clientApi.get(ApiControllers.addressbook + `Favourite/${favaddrId}/${customerId}/${name}/${type}`);
}
const saveAddress = (obj) => {
    return clientApi.post(ApiControllers.addressbook + `Admin/Favourite`, obj);
}
const getCurrency = () => {
    return clientApi.get(ApiControllers.banks + "Currency");
}
const stateSave = (obj) => {
    return clientApi.put(ApiControllers.addressbook + `Address/Status`, obj);
}
const getFileURL = (obj) => {
    return clientApi.post(ApiControllers.customers + `FetchFile`, obj)
}
const downloadDeclForm=(addr_id)=>{
	return clientApi.get(ApiControllers.addressbook + `DownloadDocument/${addr_id}`);
}
const getPayeeData = (id,payeeId) => {
    return clientApi.get(ApiControllers.addressbook + `PayeeAccountsDetail/${id}/${payeeId}`);
}
const savePayeeAccounts = (obj) => {
    return clientApi.put(ApiControllers.addressbook + `SavePayeeAccounts`, obj);
}
const getStates = (tabName,status) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${tabName}/${status}`
	);
};
export { getAddressBookData,getPayeeData, getCoinList, favouriteNameCheck, saveAddress, getCurrency, stateSave, getFileURL,downloadDeclForm,savePayeeAccounts,getStates }