import React from 'react'
import { Row, Col, Typography } from "antd";
import Moment from "react-moment";
import apiCalls from '../../api/apiCalls';
import config from "../../config";
const ProfileData = ({data,type}) => {
const { Title, Paragraph } = Typography;
  return (
    <div> <Title level={5} className="m-0 mt-5">
    Profile Data
   </Title>
   <div className="applicant-content">
     <Row gutter={[16, 24]}>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">ID</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.id}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Created For</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.createdFor}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Inspection ID</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.inspectionId}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">External User ID</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.externalUserId}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Date of Creation</label>
           <Paragraph className="kpi-val fw-700 mb-0">
           {data?.createdAt ? (
               <Moment format={config?.dateFormates?.dateFormate}>
                 {data?.createdAt
                   && apiCalls.convertUTCToLocalTime(
                       data?.createdAt
                     )
                   || data?.createdAt}
               </Moment>
             ) : (
               data?.createdAt
             )}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Last Screened</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.lastScreened}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Entity Type</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {type}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Source Key From Client</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.key}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Email</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.email}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Phone</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.phone}
           </Paragraph>
         </div>
       </Col>
       <Col
         className="gutter-row"
         xs={24}
         sm={24}
         md={12}
         lg={6}
         xxl={6}
       >
         <div>
           <label className="kpi-label">Language</label>
           <Paragraph className="kpi-val fw-700 mb-0">
             {data?.lang?.toUpperCase()}
           </Paragraph>
         </div>
       </Col>
     </Row>
   </div></div>
  )
}

export default ProfileData