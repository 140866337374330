import { Breadcrumb } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ActionsToolbar from '../components/toolbar.component/actions.toolbar'
const BreadcrumbComponent = withRouter(props => {
  const { location } = props;
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      let url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      url=url==='/addressbookview'?'/addressbook':url
      url=url==='/balanceview'?'/balances':url
      return (
        <Breadcrumb.Item key={url}>
         {props.breadCrumb.values[url] && <Link to={url}>{props.breadCrumb.values[url]}</Link>}
        </Breadcrumb.Item>
      );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home"></Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (<>
    <div className="d-flex actions-line justify-content align-center tlv-header head-navsec ">
      <Breadcrumb className="my-10">{breadcrumbItems}</Breadcrumb>
      <div style={{padding:5}}>
      <ActionsToolbar />
      </div>
    </div>
  </>)
})
const connectStateToProps = ({ breadCrumb }) => {
  return { breadCrumb }
}
const connectDispatchToProps = dispatch => {
  return { dispatch }
}
export default connect(connectStateToProps, connectDispatchToProps)(BreadcrumbComponent);