import { bankApi,clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";
const getPyrrosWithdrawalDetails=(screen,id) => {
    return bankApi.get(ApiControllers.bank + `${screen}/${id}`);
}
const getEvolveWithdrawalDetails=(id) => {
    return bankApi.get(ApiControllers.bank + `GetEvolveWithdrawalDetails/${id}`);
}
const getFileURL = (obj) => {
    return clientApi.post(ApiControllers.customers + `FetchFile`, obj)
}
const BankReceiveUserNameLuSearch = (email, name) => {
    return bankApi.get(
        ApiControllers.bank + `OpenpaydUserNames/${email}/${name}`);
}
const getBankCurrencyLookup = () => {
    return bankApi.get(
        ApiControllers.bank + `OpenpaydCurrencyLu`);
}
const getBankStatusLookup = () => {
    return bankApi.get(
        ApiControllers.bank + `statusLu`);
}
const GridColorsType=()=>{
    return bankApi.get(
        ApiControllers.bank + `OpenpaydCurrencyColor`);
}
export {getPyrrosWithdrawalDetails,getEvolveWithdrawalDetails,getFileURL,BankReceiveUserNameLuSearch,getBankCurrencyLookup,getBankStatusLookup,GridColorsType}