import React, { useEffect, useState, useReducer } from "react";
import { Form, Row, Col, Select, Alert, Button, Pagination, Empty } from "antd";
import apiCalls from "../../api/apiCalls";
import Loader from "../loader.component";
import config from "../../config";
import Moment from "react-moment";
const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const BankBalance = () => {
  const initialState = {
    bankdetails: [],
    bankTransactionDetails: [],
    invocationDetails: [],
    bankStatus: [],
    uniqueBankcurrency: [],
    loading: false,
    errorMsg: null
  };
  const [form] = Form.useForm();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectTransactionData, setIsSelectTransactionData] = useState({ accountnumber: "", bankName: "", status: "", pageNo: "", pageSize: "", selectedCurrency: "" });
  const { Option } = Select;

  useEffect(() => {
    getBankLu()
  }, [])
  const getBankLu = async () => {
    dispatch({ type: 'update', payload: { loading: true } });
    let res = await apiCalls.getBankLus();
    if (res.ok) {
      dispatch({ type: 'update', payload: { bankdetails: res.data, loading: false } });
    } else {
      dispatch({ type: 'update', payload: { errorMsg: apiCalls.isErrorDispaly(res), loading: false } });
    }
  }
  const getTransactionData = async (accountnumber, status, page, pageSize) => {
    dispatch({ type: 'update', payload: { loading: true, errorMsg: null } });
    let res = await apiCalls.getBankTransactions(selectTransactionData.accountnumber || accountnumber, selectTransactionData.bankName, selectTransactionData.status || status, page || selectTransactionData.pageNo || 1, pageSize || selectTransactionData.pageSize || 10);
    if (res.ok) {
      dispatch({ type: 'update', payload: { loading: false, bankTransactionDetails: res.data } });
    } else {
      dispatch({ type: 'update', payload: { loading: false, errorMsg: apiCalls.isErrorDispaly(res) } });
    }
  }
  const handlePageChange = (page, pageSize) => {
    getTransactionData(selectTransactionData.accountnumber, selectTransactionData.status, page, pageSize);
    setIsSelectTransactionData(prevState => ({ ...prevState, pageNo: page, pageSize: pageSize }));
  }

  const handleBankChange = (bankName) => {
    form.setFieldsValue({
      currency: null, accountholdername: null, status: null,
    });
    dispatch({ type: 'update', payload: { bankTransactionDetails: [] } });
    setIsSelectTransactionData(prevState => ({ ...prevState, selectedCurrency: "" }));

    getBankStatus(bankName)
    setIsSelectTransactionData(prevState => ({ ...prevState, bankName: bankName }));
    const innovativeCon = state.bankdetails.filter((item) => item.bankName == bankName);
    const uniqueBankcurrencyName = [...new Set(innovativeCon.map(item => item.currency))];
    dispatch({ type: 'update', payload: { uniqueBankcurrency: uniqueBankcurrencyName } });
  }
  const getBankStatus = async (bankName) => {
    let res = await apiCalls.getBankLuStatus(bankName);
    if (res.ok) {
      dispatch({ type: 'update', payload: { bankStatus: res.data } });
    } else {
      dispatch({ type: 'update', payload: { errorMsg: apiCalls.isErrorDispaly(res) } });
    }
  }
  const handleCurrencyChange = (currency) => {
    const innovativeCon = state.bankdetails.filter((item) => item.currency == currency);
    dispatch({ type: 'update', payload: { invocationDetails: innovativeCon } });
    setIsSelectTransactionData(prevState => ({ ...prevState, selectedCurrency: currency }));
  }
  const handleInnovativeConcept = (val) => {
    const [innovativeAccountHolder] = state.bankdetails.filter((item) => item.accountHolderName == val);
    setIsSelectTransactionData(prevState => ({ ...prevState, accountnumber: innovativeAccountHolder.accountNumber }));
  }
  const handleStatusChange = (status) => {
    setIsSelectTransactionData(prevState => ({ ...prevState, status: status }));
  }
  const SearchBankTransaction = () => {
    getTransactionData(selectTransactionData.accountnumber, 1);
  }
  const uniqueBankNames = [...new Set(state.bankdetails.map(item => item.bankName))];

  return (<>
    {state.errorMsg && (
      <Alert
        closable
        type="error"
        description={state.errorMsg}
        onClose={() => dispatch({ type: 'update', payload: { errorMsg: null } })}
        showIcon
      />
    )}
    <div>
      <Form onFinish={SearchBankTransaction} form={form}
        autoComplete="off">
        <Row>
          <Col sm={24} xs={24} md={4} lg={4} className="px-8">
            <Form.Item
              name="bankname"
              className="input-label mb-0"
              label="Banks"
              rules={[
                {
                  required: true,
                  message: "Is required",
                },
              ]}
            >
              <Select
                className="cust-input w-100 mb-8"
                showSearch
                placeholder="Select Bank"
                onChange={(item) => handleBankChange(item)}
              >
                {uniqueBankNames.map((bankName, index) => (
                  <Option key={index} value={bankName}>
                    {bankName}
                  </Option>
                ))}
              </Select>
            </Form.Item></Col>
          <Col sm={24} xs={24} md={5} lg={5} className="px-8"><Form.Item
            name="currency"
            className="input-label mb-0"
            label="Currency"
            rules={[
              {
                required: true,
                message: "Is required",
              },
            ]}
          >
            <Select
              className="cust-input w-100 mb-8"
              showSearch
              placeholder="Select Currency"
              onChange={(item) => handleCurrencyChange(item)}
            >
              {state.uniqueBankcurrency?.map((currency, index) => (
                <Option key={index} value={currency}>
                  {currency}
                </Option>
              ))}
            </Select>
          </Form.Item></Col>
          <Col sm={24} xs={24} md={12} lg={6} className="px-8"><Form.Item
            name="accountholdername"
            className="input-label mb-0"
            label="Account Holder"
            rules={[
              {
                required: true,
                message: "Is required",
              },
            ]}
          >
            <Select
              className="cust-input w-100 mb-8"
              showSearch
              placeholder="Select Account Holder"
              onChange={(item) => handleInnovativeConcept(item)}
            >
              {state.invocationDetails.map((item) => (
                <Option key={item.accountHolderName} value={item.accountHolderName}>
                  {item.accountHolderName}
                </Option>
              ))}

            </Select>
          </Form.Item></Col>
          <Col sm={24} xs={24} md={12} lg={6} className="px-8"><Form.Item
            name="status"
            className="input-label mb-0"
            label="Status"
            rules={[
              {
                required: true,
                message: "Is required",
              },
            ]}
          >
            <Select
              className="cust-input w-100 mb-8"
              showSearch
              placeholder="Select Status"
              onChange={(item) => handleStatusChange(item)}
            >
              {state.bankStatus[0]?.status?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          </Col>
          <Col sm={12} xs={12} md={3} lg={3} className="px-8 text-right mt-16">
            <Button
              type="primary"
              className="primary-btn px-24 search-btn mt-16"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
          <Col sm={12} xs={12} md={2} lg={12} className="px-8 mt-16 d-flex align-center">
            <div className="d-flex">
              <label className="kpi-label">Balance : </label>
              <p className="kpi-val fw-700 mb-0 ml-4">
                {state.bankTransactionDetails?.walletBalance?.toLocaleString()}  {selectTransactionData.selectedCurrency}</p>
            </div>
          </Col>

        </Row></Form>
    </div>
    <div className="companybanks-table">
      {state.loading && <Loader /> || <table className="commision-table table-border edit-commition-table table-responsive mt-12" border="2">
        <thead>
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>From</th>
            <th>To</th>
            <th>Currency</th>
            <th>Gross Amount</th>
            <th>Fees</th>
            <th>Net Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {state.bankTransactionDetails?.transactionDetails?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.type}</td>
                <td><Moment format={config?.dateFormates?.dateTimeFormate}>{item?.date ?
                  apiCalls.convertUTCToLocalTime(item?.date) : item?.date}</Moment></td>
                <td>{item.from} / {item.fromAccountHolderName}</td>
                <td>{item.to} / {item.toAccountHolderName}</td>
                <td>{item.currency}</td>
                <td>{item.gross?.toLocaleString()}</td>
                <td>{item.fees?.toLocaleString()}</td>
                <td>{item.net?.toLocaleString()}</td>
                <td>{item.status}</td>
              </tr>
            );
          })}

          {!state.bankTransactionDetails?.transactionDetails?.length && <tr className="hover-none"><td colSpan={9}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Data Found"
            ></Empty> </td></tr>}

        </tbody>
      </table>}
    </div>

    <Pagination defaultCurrent={1} total={500} onChange={handlePageChange} />
  </>)
}
export default BankBalance;