import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getBuySell = (buysellId) => {
    return clientApi.get(ApiControllers.buysell + `${buysellId}`);
}

const getRefIdFullName = (ReferenceId, name) => {
    return clientApi.get(ApiControllers.buysell + `UserReference/${ReferenceId}/${name}`);
}
const getCustomerfiat = (customer_id) => {
    return clientApi.get(ApiControllers.wallets + 'Fiat/' + customer_id);
}
const getCryptos = () => {
    return clientApi.get(ApiControllers.markets + 'Coins/All');
}
const fetchCurrencyConvertionValue = ({ from, to, value, isCrypto, customer_id, screenName }) => {
    return clientApi.get(ApiControllers.master + `CryptoFiatConverter/${customer_id}/${from}/${to}/${value}/${isCrypto}/${screenName || null}`);
}
const saveBuySell = (obj) => {
    return clientApi.post(ApiControllers.buysell + `AdminBuy`, obj);
}
const savesellData = (obj) => {
    return clientApi.post(ApiControllers.buysell + 'AdminSell', obj);
}
const sellCustomerCrypto = (customer_id) => {
    return clientApi.get(ApiControllers.wallets + customer_id);
}
const getSelectedCoinDetails = (coin_code, customer_id) => {
    return clientApi.get(ApiControllers.buysell + `${customer_id}/Coins/${coin_code}`)
}
export {
    saveBuySell, getBuySell, getRefIdFullName, getCryptos, sellCustomerCrypto, getCustomerfiat, fetchCurrencyConvertionValue,
    savesellData, getSelectedCoinDetails
}
