import React, { Component } from 'react';
import { Collapse, Button, Typography, Modal, Tooltip, message, Input, Upload, Spin, Popconfirm, Empty } from 'antd';
import { approveDoc, getDocDetails, getDocumentReplies, saveDocReply, uuidv4, getFileURL } from './api';
import Loader from '../loader.component'
import Moment from 'react-moment';
import Mome from 'moment';
import { connect } from 'react-redux';
import FilePreviewer from 'react-file-previewer';
import { validateContent } from "../../utils/custom.validator";
import config from "../../config";
const { Panel } = Collapse;
const { Text } = Typography;
const EllipsisMiddle = ({ suffixCount, children }) => {
    const start = children?.slice(0, children.length - suffixCount).trim();
    const suffix = children?.slice(-suffixCount).trim();
    return (
        <Text className="mb-0 fs-14 docname c-pointer d-block" style={{ maxWidth: '100%' }} ellipsis={{ suffix }}>
            {start}
        </Text>
    );
};

class RequestedDocs extends Component {
    state = {
        modal: false,
        previewModal: false,
        docDetails: {},
        loading: true,
        error: null,
        errorMessage: null,
        documentReplies: {},
        docReplyObjs: [],
        previewPath: null,
        isSubmitting: false,
        uploadLoader: false,
        isMessageError: null,
        isValidFile: true,
        docErrorMessage: null,
        PreviewFilePath: null,
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getDocument(this.props?.match?.params?.id);

    }
    getDocument = async (id) => {
        this.setState({ ...this.state, loading: true, error: null });
        const response = await getDocDetails(id);
        if (response.ok) {
            this.setState({ ...this.state, docDetails: response.data, loading: false });
            this.loadDocReplies(response.data?.details[0]?.id)
     
        } else {
            this.setState({ ...this.state, loading: false, error: response.data });
        }

    }
    loadDocReplies = async (id) => {
        let docReObj = this.state.docReplyObjs.filter(item => item.docunetDetailId !== id);
        this.setState({ ...this.state, documentReplies: { ...this.state.documentReplies, [id]: { loading: true, data: [], error: null } }, docReplyObjs: docReObj, docErrorMessage: null });
        const response = await getDocumentReplies(id);
        if (response.ok) {
            this.setState({
                ...this.state, documentReplies: {
                    ...this.state.documentReplies, [id]: {
                        loading: false, data: response.data.map(item => {
                            return { ...item, path: item.path && item?.path !== "string" ? JSON.parse(item.path) : [] }
                        }), error: null
                    }
                }
            });
        } else {
            this.setState({ ...this.state, documentReplies: { ...this.state.documentReplies, [id]: { loading: false, data: [], error: response.data } } });
        }
    }
    docPreview = async (file) => {
        let res = await getFileURL({ url: file.path });
        if (res.ok) {
            this.state.PreviewFilePath = file.path;
            this.setState({ ...this.state, previewModal: true, previewPath: res.data });
        }
    }
    DownloadUpdatedFile = async () => {
        let res = await getFileURL({ url: this.state.PreviewFilePath });
        if (res.ok) {
            this.setState({ ...this.state, previewModal: true, previewPath: res.data });
            window.open(res.data, "_blank")
        }
    }
    docPreviewClose = () => {
        this.setState({ ...this.state, previewModal: false, previewPath: null })
    }
    updateDocRepliesStatus = (doc, Status) => {
        let docDetails = [...this.state.docDetails.details];
        for (let item of docDetails) {
            if (item.id === doc.id) {
                item.status = Status;
            }
        }
        this.setState({ ...this.state, docDetails: { ...this.state.docDetails, details: docDetails } });
    }
    docApprove = async (doc) => {
        const response = await approveDoc({
            "id": this.state.docDetails.id,
            "documentDetailId": doc.id,
            "status": "Approved",
            "note": this.state.docDetails.note,
            customerId: this.state.docDetails.customerId,
            "documentName": doc.documentName
        });

        this.setState({ ...this.state, isMessageError: null });
        message.destroy()
        if (response.ok) {
            message.success({
                content: 'Document has been approved',
                className: 'custom-msg',
            });

            this.updateDocRepliesStatus(doc, "Approved");
            this.loadDocReplies(doc.id);
        } else {
            message.warning({
                content: response.data,
                className: 'custom-msg',
            });
        }
     
    }
    docReject = async (doc) => {
        this.setState({ ...this.state, isMessageError: null, docErrorMessage: null })
        let item = this.isDocExist(this.state.docReplyObjs, doc.id);
        if (!item || !item.reply || !validateContent(item?.reply)) {
            let _message;
            if (!validateContent(item?.reply)) {
                _message = "Please enter valid content";
            } else {
                _message = "Please enter message";
            }
            this.setState({ ...this.state, isMessageError: doc.id.replace(/-/g, ""), docErrorMessage: _message });
            return;
        }
        const itemPath = function () {
            if (item.path) {
                return typeof (item.path) === "object" ? JSON.stringify(item.path) : item.path;
            } else {
                return item.path;
            }
        }();
        item.path = itemPath;
        item.status = "Rejected";
        item.repliedDate = Mome().format("YYYY-MM-DDTHH:mm:ss");
        item.note = this.state.docDetails.note;
        item.customerId = this.state.docDetails.customerId;
        item.documentName = doc.documentName;
        this.setState({ ...this.state, isSubmitting: true });
        const response = await saveDocReply(item);
        message.destroy()
        if (response.ok) {
            message.warning({
                content: 'Document has been rejected',
                className: 'custom-msg',
            });
            this.updateDocRepliesStatus(doc, "Rejected");
            this.loadDocReplies(doc.id)
        } else {
            message.warning({
                content: response.data,
                className: 'custom-msg',
            });
        }
        let objs = [...this.state.docReplyObjs];
        objs = objs.filter(ditem => ditem.docunetDetailId !== doc.id);
        this.setState({ ...this.state, docReplyObjs: objs, isSubmitting: false, isMessageError: null });
        document.getElementsByClassName(`${doc.id.replace(/-/g, "")}`).value = "";
    }
    deleteDocument = async (doc, idx, isAdd) => {
        let item = { ...doc };
        item.path.splice(idx, 1);
        item.path = JSON.stringify(item.path);
        item.status = "Rejected";
        if (isAdd) {
            let objs = [...this.state.docReplyObjs];
            objs = objs.filter(ditem => ditem.docunetDetailId !== doc.id);
            this.setState({ ...this.state, docReplyObjs: objs });
            return;
        }
        const response = await saveDocReply(item);
        message.destroy()
        if (response.ok) {
            message.warning({
                content: 'Document has been deleted',
                className: 'custom-msg',
            });
            this.loadDocReplies(doc.id)
        } else {
            message.warning({
                content: response.data,
                className: 'custom-msg',
            });
        }
    }
    isDocExist(lstObj, id) {
        const lst = lstObj.filter(obj => {
            return obj.docunetDetailId === id
        });
        return lst[0]
    }
    messageObject = (id) => {
        return {
            "id": uuidv4(),
            "docunetDetailId": id,
            "path": [],
            "reply": "",
            "repliedBy": "",
            "repliedDate": null,
            "isCustomer": false
        }
    }
    handleUpload = ({ file }, doc) => {
        const { docReplyObjs } = this.state;
    
        this.setState({ uploadLoader: true, errorMessage: null });
    
        if (file.status === "done" && this.state.isValidFile) {
            const updatedReplyObjs = this.updateReplyObjects(file, doc, docReplyObjs);
    
            this.setState({ docReplyObjs: updatedReplyObjs, uploadLoader: false });
        } else if (file.status === "error") {
            message.error({ content: `${file.response}`, className: 'custom-msg' });
            this.setState({ uploadLoader: false });
        } else if (!this.state.isValidFile) {
            this.setState({ uploadLoader: false, isSubmitting: false });
        }
    };
    
    updateReplyObjects = (file, doc, replyObjs) => {
        let updatedReplyObjs = [...replyObjs];
        const item = this.isDocExist(updatedReplyObjs, doc.id);
        let obj;
    
        if (item) {
            obj = item;
            obj.path = this.parseObjectPath(obj.path);
        } else {
            obj = this.messageObject(doc.id);
            obj.path = [];
            updatedReplyObjs.push(obj);
        }
    
        obj.repliedDate = new Date();
        obj.path.push({ filename: file.name, path: file.response[0], size: file.size });
        obj.repliedBy = this.props.userProfileInfo?.firstName;
    
        return updatedReplyObjs;
    };
    
    parseObjectPath = (path) => {
        if (typeof path === "string") {
            return JSON.parse(path);
        } else {
            return path ? path : [];
        }
    };
    
    beforeUpload = (file) => {
        let fileType = { "image/png": true, 'image/jpg': true, 'image/jpeg': true, 'image/PNG': true, 'image/JPG': true, 'image/JPEG': true, 'application/pdf': true, 'application/PDF': true };
      
        const isValidFileType = fileType[file.type];
        this.setState({ isValidFile: isValidFileType });
      
        if (!isValidFileType) {
          message.error({ content: `File is not allowed. You can upload jpg, png, jpeg, and PDF files`, className: 'custom-msg' });
        }
      
        return isValidFileType;
      }
      
    uopdateReplyObj = (item, list) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
                list[i] = item;
                break;
            }
        }
        return list;
    };
    
    handleReplymessage = (_message, doc) => {
        let replyObjs = [...this.state.docReplyObjs];
        let item = this.isDocExist(replyObjs, doc.id);
        let obj;
        if (item) {
            obj = item;
            obj.reply = _message;
            obj.repliedBy = this.props.userProfileInfo?.firstName;
            replyObjs = this.uopdateReplyObj(obj, replyObjs);

        } else {
            obj = this.messageObject(doc.id);
            obj.reply = _message;
            obj.repliedDate = new Date();
            obj.repliedBy = this.props.userProfileInfo?.firstName;
            replyObjs.push(obj);
        }
        this.setState({ ...this.state, docReplyObjs: replyObjs });
    }
    getUploadedFiles = (id) => {
        let data = this.state.docReplyObjs.filter(item => item.docunetDetailId === id)[0];
        if (data && data.path) {
            data.path = (typeof (data.path) == "string" ? JSON.parse(data.path) : data.path) || [];
            return data
        } else {
            return { path: [] }
        }
    }
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    filePreviewPath() {
        return this.state.previewPath;
    }
    fileDownload = async () => {
        let res = await getFileURL({ url: this.state.previewPath });
        if (res.ok) {
            this.DownloadUpdatedFile();
        }
    }
    renderClassName=(fiel)=>(
        fiel.filename?.slice(-3) === "zip" ? "file" : "") || (fiel.filename?.slice(-3) === "pdf" ? "file" : "image"
    )
    renderEmptyDoc=(doc)=>(
        (!this.state?.documentReplies[doc.id]?.data || this.state?.documentReplies[doc.id]?.data?.length === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No documents submitted" />
    )
    renderDocPannel=(doc,idx)=>{
        return <Panel header={doc?.documentName} key={idx + 1} extra={doc.status ? <span className={`${doc.status && doc.status.toLowerCase() + "-lbl staus-lbl" || ""}`}>{doc.status}</span> : ""}>
                        {this.state.documentReplies[doc.id]?.loading && <div className="text-center"><Spin size="large" /></div>}
                        {this.state?.documentReplies[doc.id]?.data?.map((reply, index) => <div key={index} className="reply-container">
                            <div className="user-shortname">{reply.repliedBy?.slice(0, 2)}</div>
                            <div className="reply-body">
                                <Text className="reply-username">{reply.repliedBy}</Text><Text className="reply-date"><Moment format={config?.dateFormates?.dateTimeFormate}>{reply.repliedDate}</Moment></Text>
                                <p className="reply-txt">{reply.reply}</p>
                                <div className="docfile-container">
                                    {reply?.path?.map((file, _idx) => <div key={_idx} className="docfile">
                                        <span className={`icon xl ${this.renderClassName(file)} mr-16`} />
                                        <div className="docdetails c-pointer" onClick={() => this.docPreview(file)}>
                                            <EllipsisMiddle suffixCount={6}>{file.filename}</EllipsisMiddle>
                                            <span className="fs-12 text-secondary">{this.formatBytes(file?.size)}</span>
                                        </div>
                                        {doc.status !== "Approved" && <Popconfirm title="Are you sure to delete this document?"
                                            onConfirm={() => this.deleteDocument(reply, idx)}
                                            okText="Yes"
                                            cancelText="No">
                                            <span className="icon md close c-pointer" />
                                        </Popconfirm>}
                                    </div>)}
                                </div>

                            </div>
                        </div>)}
                        {this.state?.documentReplies[doc.id]?.data && this.state?.documentReplies[doc.id]?.data?.length !== 0 && <>
                            <div className="reply-container">
                                <div className="user-shortname">{this.props?.userProfileInfo?.firstName?.slice(0, 2)}</div>
                                <div className="reply-body">
                                    <div className="chat-send mb-0">
                                        <Input maxLength={200} autoFocus type="text" className={doc.id.replace(/-/g, "")} id={doc.id.replace(/-/g, "")} onChange={({ currentTarget: { value } }) => this.handleReplymessage(value, doc)} placeholder="Write your message..." size="large" bordered={false} multiple={true} validator={validateContent} />

                                        <div className="d-flex align-center">
                                            <Tooltip title="Attachments">
                                                <Upload accept=".pdf,.jpg,.jpeg,.png, .PDF, .JPG, .JPEG, .PNG" onChange={(props) => this.handleUpload(props, doc)} beforeUpload={(props) => { this.beforeUpload(props) }} showUploadList={false} action={process.env.REACT_APP_API_END_POINT + "/UploadFile"}
                                                  headers={{Authorization : `Bearer ${this.props?.user.deviceToken}`}}>
                                                    <span className="icon md attach mr-16 c-pointer" />
                                                </Upload> </Tooltip>
                                            {this.state.uploadLoader && <Loader />}
                                        </div>
                                    </div>
                                    {this.state.errorMessage != null && <div className="text-red">{this.state.errorMessage}</div>}
                                    {this.state.isMessageError === doc.id.replace(/-/g, "") && <div style={{ color: "red" }}>{this.state.docErrorMessage}</div>}
                                    <div className="docfile-container">
                                        {this.getUploadedFiles(doc.id)?.path?.map((file, idxx) => <div key={idxx} className="docfile">
                                            <span className={`icon xl ${this.renderClassName(file)} mr-16`} />
                                            <div className="docdetails c-pointer" onClick={() => this.docPreview(file)}>
                                                <EllipsisMiddle suffixCount={6}>{file.filename}</EllipsisMiddle>
                                                <span className="fs-12 text-secondary">{this.formatBytes(file?.size)}</span>
                                            </div>
                                            <span onClick={() => this.deleteDocument(this.getUploadedFiles(doc.id), idxx, true)} className="icon md close c-pointer" />
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <Button type="primary" disabled={this.state.isSubmitting} onClick={() => this.docReject(doc)} className="primary-btn cancel-btn" style={{ margin: '0 8px' }}>
                                    Reject
                                </Button>
                                <Button type="primary" disabled={doc.status === "Approved"} className="primary-btn" onClick={() => this.docApprove(doc)}>Approve</Button>
                            </div></>}
                        {this.renderEmptyDoc(doc)}
                    </Panel>
    }
    render() {
        if (this.state.loading) {
            return <Loader />
        }
        if (!this.state.docDetails?.details || this.state.docDetails?.details.length === 0) {
            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
        }
        return <>
            <div className="bank-view">
                {this.state?.docDetails?.details?.map((doc, idx) => <Collapse onChange={(key) => {
                    if (key) { this.loadDocReplies(doc.id) }
                }}
                    accordion className="accordian mb-24" defaultActiveKey={['1']}
                    expandIcon={() => <span className="icon md downangle" />}>
                    {this.renderDocPannel(doc,idx)}
                </Collapse>)}

                <Modal
                    className="documentmodal-width"
                    destroyOnClose={true}
                    title="Preview"
                    width={1000}
                    visible={this.state.previewModal}
                    closeIcon={<Tooltip title="Close"><span className="icon md x" onClick={this.docPreviewClose} /></Tooltip>}
                    footer={<>
                        <Button type="primary" onClick={this.docPreviewClose} className="primary-btn cancel-btn">Close</Button>
                        <Button type="primary" className="primary-btn" onClick={() => window.open(this.state.previewPath, "_blank")}>Download</Button>
                    </>}
                >
                    <FilePreviewer hideControls={true} file={{ url: this.state.previewPath ? this.filePreviewPath() : null, mimeType: this.state?.previewPath?.includes(".pdf") ? 'application/pdf' : '' }} />
                </Modal>
            </div></>;
    }
}
const connectStateToProps = ({ userConfig, oidc }) => {
    return { userProfileInfo: userConfig.userProfileInfo, user: oidc }
}
export default connect(connectStateToProps)(RequestedDocs);