import { clientApi} from "../api/index";
import { ApiControllers } from"../api/config"



const getCasePopObj=(FromScreen,ScreenId,ScreenName)=>{
    return clientApi.get(ApiControllers.customers + `Cases/${FromScreen}/${ScreenId}/${ScreenName}`)
   
}
const saveCasePopObj=(obj,FromScreen)=>{
    return clientApi.post(ApiControllers.customers + `Cases/${FromScreen}/Case`,obj)
  
}

const caseCreate=(Id)=>{
    return clientApi.get(ApiControllers.customers + `Admin/Cases/${Id}`)
}
const getcaseDoc=(id,isCrypto)=>{
    return clientApi.get(ApiControllers.customers + `Admin/Cases/${id}/${isCrypto}`)
    }
    const caseTemplateLu=()=>{
        return clientApi.get(ApiControllers.customers + `TemplateLu`)
        }
export{getCasePopObj,saveCasePopObj,caseCreate,getcaseDoc,caseTemplateLu}