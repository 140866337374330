import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";



const introducerSave = (obj) => {
    return clientApi.post(ApiControllers.customer + `Introducer`, obj);
  };
  const introducergridData = (customerId) => {
    return clientApi.get(ApiControllers.customer + `Introducer/${customerId}`);
  };
  const introducerLookup = (searchVal) => {
    return clientApi.get(ApiControllers.customer + `IntroducersLookUp/${searchVal?searchVal:null}`);
  };
  const getFeeData = (introducerId) => {
    return clientApi.get(ApiControllers.customer + `Report/${introducerId}`);
  };
  const generateSave = (obj) => {
    return clientApi.post(ApiControllers.customer + `GenerateReport `, obj);
  };
  const deleteDetails = (id) => {
    return clientApi.delete(ApiControllers.customer + `IntroducerReport/${id}`);
  };
  const getSendDetails = (introducerId) => {
    return clientApi.get(ApiControllers.customer + `SendReport/${introducerId}`);
  };
  const getEmailDetails = (introducerId) => {
    return clientApi.get(ApiControllers.customer + `IntroducerEmail/${introducerId}`);
  };

  export{introducergridData,introducerSave,introducerLookup,getFeeData,generateSave,deleteDetails,getSendDetails,getEmailDetails}