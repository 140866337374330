import React, { Component } from 'react';
import { Radio, Form, Input, Row, Col, Select, Button, Alert, message} from 'antd';
import LocalCryptoSwapperCmp from './localCryptoSwapperCmp';
import { fetchSelectedCoinDetails } from '../../reducers/buysellReducer';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { getRefIdFullName, getCryptos, getCustomerfiat, saveBuySell, sellCustomerCrypto, savesellData } from './api';
import { convertCurrency, convertCurrencyDuplicate, validatePreview } from './buySellService';
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import apiCalls from "../../api/apiCalls";
import {numberWithCommas} from '../../utils/service';
import Loader from "../loader.component";
import { publishShowActions } from '../grid.component/subscribir';

const { Option } = Select;

class BuySellDetails extends Component {
    formRef = React.createRef();
    state = {
        buyMin: null,
        buyMax: null,
        isArrow: true,
        selectedvalue: null,
        selectedWallet: null,
        symbols: {
            "EUR": "€",
            "USD": "$",
            "GBP": "£",
            "undefined": "$"
        }
    }
    constructor (props) {
        super(props);
        this.state = {
            clickedYes: false,
            buysellObj: {
                ReferenceId: null,
                FullName: null,
                Crypto: null,
                Wallet: null,
                Amount: null,
                customerId: null,
                maxFiatValue: null,
                isbusiness: null,
                screenName:null,
            },
            swapValues: {
                localValue: '',
                cryptoValue: '',
                isSwaped: false,
                isConvertionLoading: false
            },
            screen: "",
           
            RefrenceData: {},
            RefIdData: [],
            coins: [],
            sellCoins: [],
            wallets: [],
            fullNames: [],
            btnDisabled: false,
            error: null,
            alert: false,
            message: "Buy Crypto",
            coinData: {},
            loading: false,
            isLoding: false,
            initialLoader: false,
            tabLoading: false,
            errorMsg: null,
            isLoading: false,
        }
        this.useDivRef = React.createRef();
    }

    componentDidMount() {
        this.cryptoLu();
        publishShowActions(false);
        this.useDivRef.current.scrollIntoView();
    }
    
    fetchConvertionValue = async () => {
        const { coin } = this.props.buysellStore?.selectedCoin?.data;
        const { isSwaped, cryptoValue, localValue } = this.state.swapValues;
        const value = await convertCurrency({
            from: coin,
            to: "USD",
            value: isSwaped ? cryptoValue : localValue,
            isCrypto: !isSwaped,
            customer_id: this.state.buysellObj.customerId,
            screenName: "buy"
        });

        this.setState({
            ...this.state,
            disableConfirm: false,
            swapValues: {
                ...this.state.swapValues,
                [isSwaped ? "localValue" : "cryptoValue"]: value
            }
        });
    };
    onValueChange = (value) => {
        const { isSwaped, localValue, cryptoValue } = this.state.swapValues;
        let _nativeValue = localValue,
            _cryptoValue = cryptoValue;
        if (isSwaped) {
            _cryptoValue = value;
        } else {
            _nativeValue = value;
        }
        this.setState(
            {
                ...this.state,
                swapValues: {
                    localValue: _nativeValue,
                    cryptoValue: _cryptoValue,
                    isSwaped
                }
            },
            () => {
                this.handleConvertion();
            }
        );
    };

    renderCurrencyObj=(coin,isSwaped, localValue, cryptoValue)=>{
        return {
            from: coin,
            to: this.state.buysellObj.Wallet || "USD",
            value: (isSwaped ? cryptoValue : localValue) || 0,
            isCrypto: !isSwaped,
            customer_id: this.state.buysellObj.customerId,
            screenName: this.state.clickedYes ? 'sell' : 'buy'
        }
    }
    handleConvertion = async () => {
        const coin = this.state.buysellObj.Crypto;
        this.setState({
            ...this.state,
            swapValues: { ...this.state.swapValues, isConvertionLoading: true }
        });
        let currencyOBJ = this.renderCurrencyObj(coin,this.state.swapValues?.isSwaped, this.state.swapValues?.localValue, this.state.swapValues?.cryptoValue)
        const response = await convertCurrencyDuplicate(currencyOBJ);
        if (response.ok) {
            const { isSwaped, localValue, cryptoValue } = this.state.swapValues;
            let _nativeValue = localValue,
                _cryptoValue = cryptoValue;
            const {
                data: value,
                config: { url }
            } = response;
            const _obj = url.split("CryptoFiatConverter")[1].split("/");
            const _val = isSwaped ? cryptoValue : localValue;
            if (_obj[4] == _val || _obj[4] == 0) {
                if (!isSwaped) {
                    _cryptoValue = value || 0;
                } else {
                    _nativeValue = value || 0;
                }
                this.setState({
                    ...this.state,
                    swapValues: {
                        localValue: _nativeValue,
                        cryptoValue: _cryptoValue,
                        isSwaped,
                    }
                },()=>{
                    this.setState({...this.state,swapValues:{...this.state.swapValues,isConvertionLoading: false}});
                });
            }

        } else {
            this.setState({
                ...this.state,
                swapValues: { ...this.state.swapValues, isConvertionLoading: false }
            });
        }
    };
    walletLu = async () => {
        let response = await getCustomerfiat(this.state.buysellObj.customerId);
        if (response.ok) {
            this.setState({
                wallets: response.data,
                errorMsg:null
            });
        }else{
            this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
        }
    };

    cryptoLu = async () => {
        this.setState({ loading: true });
        let response = await getCryptos();
        if (response.ok) {
            this.setState({
                coins: response.data, loading: false,
                errorMsg:null
            })
        }else{
        this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
        }
    }

    loadData = async (refId, fullName) => {
        let response = await getRefIdFullName(
            (refId === null ? null : refId),
            (fullName === null ? null : fullName)
        );
        if (response.ok) {
            if (refId) {
                this.setState({
                    RefIdData: response.data,
                    errorMsg:null
                });
            } else {
                this.setState({
                    fullNames: response.data,
                    errorMsg:null
                });

            }
        }else{
          this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
        }
    };

    handleSearch = (e, type) => {
        if(e.keyCode >= 48 ){
        if (e.target.value != null && e.target.value.length > 2) {
            if (type === "RefId") {
                const refId = e.target.value;
                this.loadData(refId, null);
            } else {
                const fullName = e.target.value;
                this.loadData(null, fullName);
            }
        }
    }
}

    fetchSellCustomerCrpto = async () => {
        const { buysellObj } = this.state;
        this.setState({ loading: true });
        let response = await sellCustomerCrypto(buysellObj.customerId);
        if (response.ok) {
            this.setState({ sellCoins: response.data, loading: false,errorMsg:null });
        }else{
            this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
        }
    };

    handleChange = (val, type) => {
        const { buysellObj, fullNames, RefIdData } = this.state
        if (type === 'RefId') {    
            const obj = RefIdData?.find((item) => item.referenceId === val);
            buysellObj.ReferenceId = val;
            buysellObj.customerId = obj.customerId;
            buysellObj.isbusiness = obj?.isbusiness;
            this.setState({ ...this.state, RefrenceData: obj })
            this.setState({ ...this.state, buysellObj })
            this.formRef.current.setFieldsValue({ FullName: obj.fullname, userName: obj?.userName, email: obj?.email })
            this.walletLu();
            this.fetchSellCustomerCrpto();
        } else if (type === 'Crypto') {
            this.props.getCoinDetails(val, buysellObj.customerId);
            buysellObj.Crypto = val;
            this.setState({ ...this.state, buysellObj });
            this.fetchSellCustomerCrpto();
            this.setState({ ...this.state, coinData: this.props.buysellStore?.selectedCoin?.data });
            this.handleConvertion();
        } else if (type === 'Wallet') {
            buysellObj.Wallet = val;
            const walletObj = this.state.wallets?.find((item) => item.currencyCode === this.state.buysellObj.Wallet);
            buysellObj.maxFiatValue = walletObj.avilable;
            this.setState({ ...this.state, buysellObj });
            this.handleConvertion();
        } else {
            const obj = fullNames?.find((item) => item.fullname === val);
            buysellObj.FullName = val;
            buysellObj.customerId = obj?.customerId;
            buysellObj.isbusiness = obj?.isbusiness;
            this.setState({ ...this.state, buysellObj })
            this.formRef.current.setFieldsValue({ ReferenceId: obj?.referenceId, userName: obj?.userName, email: obj?.email })
            this.walletLu();
            this.fetchSellCustomerCrpto();
        }
    }
    backToBuySell = () => {
        setCurrentAction(null);
        this.props.history.push({
            pathname: "/buysell"
        });
    };

    savebuysell = async () => {
        this.setState({ ...this.state, isLoading: true });
        const { data: coinData } = this.props.buysellStore?.selectedCoin;
        const { isSwaped, localValue, cryptoValue } = this.state.swapValues;
        const walletObj = this.state.wallets?.find((item) => item.currencyCode === this.state.buysellObj.Wallet);
        const res = validatePreview({ localValue: localValue, cryptValue: cryptoValue, wallet: walletObj, minPurchase: coinData?.buyMin, maxPurchase: coinData?.buyMax, eurInUsd: coinData?.eurInUsd, gbpInUsd: coinData?.gbpInUsd, sellMinValue: coinData?.sellMinValue,coinData:coinData });
        if (!res.valid) {
            this.setState({ alert: true, message: "Buy Crypto", errorMsg: res.message, error: res.message, isLoading: false });
            return false;
        } else {
            const coinsObj = this.state.coins?.find((item) => item.walletCode === this.state.buysellObj.Crypto);
            const obj = {

                "id": "40f5bbb4-291b-4d4d-be16-5fb116b036c7",
                "customerId": this.state.buysellObj.customerId,
                "createdby": this.props.userProfileInfo?.userName,
                "createdDate": new Date(),
                "modifiedby": this.props.userProfileInfo?.userName,
                "modifiedDate": new Date(),
                "fromWalletId": walletObj.id,
                "fromWalletCode": walletObj.currencyCode,
                "fromWalletName": walletObj.bankName,
                "fromValue": localValue,
                "toWalletId": coinsObj.id,
                "toWalletCode": coinsObj.walletCode,
                "toWalletName": coinsObj.walletName,
                "toValue": cryptoValue,
                "description": "Buy Crypto",
                "comission": '',
                "executedPrice": 0.00,
                "info": JSON.stringify(this.props.trackAuditLogData),
                "totalAmount": cryptoValue,
                "isCrypto": !isSwaped
            }
            const response = await saveBuySell(obj);
            if (response.ok) {
                this.setState({ ...this.state, btnDisabled: false, isLoading: false,errorMsg:null })
                message.destroy()
                message.success({
                    content: 'Buy saved successfully',
                    className: 'custom-msg',
                    duration: 4
                });
              
                this.props.history.push({
                    pathname: '/buysell'
                })
            } else {
                this.setState({
                    alert: true, btnDisabled: false, message: "Buy Crypto",
                    errorMsg: apiCalls.isErrorDispaly(response),
                    isLoading: false,
                })
                this.useDivRef.current.scrollIntoView();
            }
        }

    };
    

    savesellData = async () => {
        this.setState({ ...this.state, isLoading: true });
        const { isSwaped, localValue, cryptoValue } = this.state.swapValues;
        const { data: coinData } = this.props.buysellStore?.selectedCoin;
        if(localValue === "0" || cryptoValue === "0"){
            this.setState({
                ...this.state, alert: true, message: "Sell Crypto",
                errorMsg: 'Amount must be greater than zero.', isLoading: false
            })
        }
        else if  ((!localValue && !cryptoValue) || (parseFloat(localValue) === 0 || parseFloat(cryptoValue) === 0)) {
            this.setState({
                ...this.state, alert: true, message: "Sell Crypto",
                errorMsg: 'Please enter amount', isLoading: false
            })
        }
        else if (!this.state.buysellObj.Wallet) {
            this.setState({
                ...this.state, alert: true, message: "Sell Crypto",
                errorMsg: 'Please select wallet', isLoading: false
            })
            return;
        }
        else if (cryptoValue > coinData.coinBalance) {
            this.setState({
                ...this.state, alert: true, message: "Sell Crypto",
                errorMsg: 'Insufficient funds', isLoading: false
            })
            return;
        } else if (parseFloat(cryptoValue) < coinData.sellMinValue) {
            this.setState({
                ...this.state, alert: true, message: "Sell Crypto",
                errorMsg: 'You have entered an amount below the minimum sale. The minimum amount is ' + coinData.sellMinValue+" " + coinData.coin, isLoading: false
            })
            return;
        }
        else if (parseFloat(cryptoValue) > coinData.buyMax) {
            this.setState({
                ...this.state, alert: true, message: "Sell Crypto",
                errorMsg: 'You have to sell maximum of ' +`${numberWithCommas(coinData.buyMax)}`+ " " + coinData.coin  +". Please contact support for higher amounts.", isLoading: false
            })
            return;
        } else {
            this.setState({ ...this.state, isLoading: true });
            const coinsObj = this.state.coins?.find((item) => item.walletCode === this.state.buysellObj.Crypto);
            const walletObj = this.state.wallets?.find((item) => item.currencyCode === this.state.buysellObj.Wallet);
            const obj = {
                "id": "40f5bbb4-291b-4d4d-be16-5fb116b036c7",
                "customerId": this.state.buysellObj.customerId,
                "fromWalletId": coinsObj.id,
                "createdby": this.props.userProfileInfo?.userName,
                "createdDate": new Date(),
                "modifiedby": this.props.userProfileInfo?.userName,
                "modifiedDate": new Date(),
                "fromWalletCode": coinsObj.walletCode,
                "fromWalletName": coinsObj.walletName,
                "fromValue": cryptoValue,
                "toWalletId": walletObj.id,
                "toWalletCode": walletObj.currencyCode,
                "toWalletName": walletObj.bankName,
                "toValue": localValue,
                "description": "Sell Crypto",
                "comission": '',
                "executedPrice": 0.00,
                "info": JSON.stringify(this.props.trackAuditLogData),
                "totalAmount": localValue,
                "isCrypto": !isSwaped
            }
            const response = await savesellData(obj);
            if (response.ok) {
                this.setState({ ...this.state, isLoading: false })
                message.destroy()
                message.success({
                    content: 'Sell saved successfully',
                    className: 'custom-msg',
                    duration: 4
                });
               
                this.props.history.push({
                    pathname: '/buysell'
                })
            } else {

                this.setState({
                    alert: true, btnDisabled: false, message: "Sell Crypto",
                    errorMsg: apiCalls.isErrorDispaly(response),
                    isLoading: false,
                })
                this.useDivRef.current.scrollIntoView();
            }
        }

    }



    onActionClick = (key) => {
        const actions = {
            add: "add"
        };
        this[actions[key]]();
    };

    radioControl = (e) => {
        const { buysellObj } = this.state;
        this.setState({ ...this.state, swapValues:{localValue: "", cryptoValue: "" }})
        if (e.target.value == 2) {
            buysellObj.screenName= "Sell"
            buysellObj.FullName= null
            buysellObj.ReferenceId= null
            this.setState({ ...this.state, errorMsg: null,buysellObj, isLoading: false, error: null,  swapValues: { localValue: "", cryptoValue: "" }, clickedYes: true, wallets: [], fullNames: [], sellCoins: [], RefIdData: [], tabLoading: true })
            setTimeout(() => this.setState({ ...this.state, tabLoading: false }), 500);
            this.formRef.current.resetFields();
            this.formRef.current.setFieldsValue({ FullName: null, Crypto: null, Wallet: null, ReferenceId: null })
        } else {
            buysellObj.screenName= "Buy"
            buysellObj.FullName= null
            buysellObj.ReferenceId= null
            this.setState({ error: null, buysellObj, errorMsg: null, isLoading: false, swapValues: { localValue: "", cryptoValue: "" }, clickedYes: false, wallets: [], fullNames: [], RefIdData: [], tabLoading: true })
            setTimeout(() => this.setState({ ...this.state, tabLoading: false }), 500);
            this.formRef.current.resetFields();
            this.formRef.current.setFieldsValue({ FullName: null, Crypto: null, Wallet: null, ReferenceId: null })
        }
    }
    renderLabelContent = () => {
        const { buysellObj } = this.state;
        const isScreenNameEqual = !buysellObj.screenName === buysellObj.screenName;
        const hasFullNameOrReferenceId = buysellObj?.FullName || buysellObj.ReferenceId;
        const isBusiness = buysellObj?.isbusiness;
    
        let labelContent;
    
        if (isScreenNameEqual) {
            labelContent = <div>Business Name/Personal Name</div>;
        } else {
            if (hasFullNameOrReferenceId) {
                if (isBusiness) {
                    labelContent = (
                        <div>
                            <b>Business Name</b>/Personal Name
                        </div>
                    );
                } else {
                    labelContent = (
                        <div>
                            Business Name/<b>Personal Name</b>
                        </div>
                    );
                }
            } else {
                labelContent = <div>Business Name/Personal Name</div>;
            }
        }
        
    
        return labelContent;
    };
    
    
    render() {
        const {
            buysellObj,
            RefIdData,
            coins,
            wallets,
            fullNames,
            sellCoins,
            clickedYes,
            loading,
            tabLoading,
            errorMsg
        } = this.state;
        const { localValue, cryptoValue, isSwaped, isConvertionLoading } =
            this.state.swapValues;
        return (
            <>
                <div ref={this.useDivRef}></div>
                {this.state.coins.length == 0 && <Loader />}

                <Radio.Group
                    defaultValue={1}
                    onChange={(e) => this.radioControl(e)}
                    className="buysell-toggle"
                >
                    <Radio.Button value={1} >Buy</Radio.Button>
                    <Radio.Button value={2}>Sell</Radio.Button>
                </Radio.Group>
                {errorMsg != null && errorMsg != '' &&
                    <Alert
                        className="w-100 mb-16"
                        type="error"
                        description={errorMsg}
                        showIcon
                    />}

                {tabLoading && <Loader />}
                <Form
                    ref={this.formRef}
                    initialValues={buysellObj}
                    onFinish={clickedYes ? this.savesellData : this.savebuysell}
                    autoComplete="off"
                >
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                            <Form.Item
                                label="Customer/Contract ID"
                                name="ReferenceId"
                                className="input-label"
                                rules={[{ required: true, message: 'Is required', },]}
                            >
                                <Select
                                    showSearch
                                    className="cust-input"
                                    placeholder="Select Customer/Contract ID"
                                    onKeyUp={(e) => this.handleSearch(e, "RefId")}
                                    onChange={(e) => this.handleChange(e, "RefId")}
                                >
                                    {RefIdData?.map((item, idx) => (
                                        <Option key={idx} value={item.referenceId}>
                                            {item.referenceId}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                            <Form.Item
                                label={this.renderLabelContent()}
                                name="FullName"
                                className="input-label"
                                rules={[{ required: true, message: 'Is required', },]}
                            >
                                <Select
                                    showSearch
                                    className="cust-input"
                                    placeholder="Business Name/Personal Name"
                                    onKeyUp={(e) => this.handleSearch(e, "FullName")}
                                    onChange={(e) => this.handleChange(e, "FullName")}
                                >
                                    {fullNames?.map((name) => (
                                        <Option key={name.customerId} value={name.fullname}>
                                            {name.fullname}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                            <Form.Item
                                name="userName"
                                label="User Name"
                                className="input-label"
                                rules={[
                                    {
                                        whitespace: true
                                    },
                                    
                                ]}
                            >
                                <Input
                                    disabled={true}
                                    placeholder="User Name"
                                    className="cust-input"
                                    maxLength={50}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                            <Form.Item
                                name="email"
                                label="Email"
                                className="input-label"
                                rules={[
                                    {
                                        whitespace: true
                                    },
                                    
                                ]}
                            >
                                <Input
                                    disabled={true}
                                    placeholder="Email"
                                    className="cust-input"
                                    maxLength={50}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                            <Form.Item
                                label="Crypto"
                                name="Crypto"
                                className="input-label"
                                rules={[{ required: true, message: 'Is required', },]}
                            >
                                <Select
                                    showSearch
                                    className="cust-input"
                                    placeholder="Select Crypto"
                                    optionFilterProp="children"
                                    onChange={(e) => this.handleChange(e, "Crypto")}
                                    loading={loading}
                                >
                                    {clickedYes ? sellCoins?.map(c => <Option key={c.id} value={c.coin}>{c.coin} Balance: {c.coinBalance?.toLocaleString()}</Option>) :
                                        coins?.map(coin => <Option key={coin.customerId} value={coin.walletCode}>{coin.walletCode}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                            <Form.Item
                                label="Fiat Wallet"
                                name="Wallet"
                                className="input-label"
                                rules={[{ required: true, message: 'Is required', },]}
                            >
                                <Select
                                    showSearch
                                    className='cust-input'
                                    placeholder="Select Fiat Wallet"
                                    onChange={(e) => this.handleChange(e, 'Wallet')}
                                >

                                    {wallets?.map((wallet, idx) => <Option key={idx} value={wallet['currencyCode' || 'id']}>{wallet.currencyCode}
                                        <NumberFormat value={wallet.avilable} displayType={'text'} thousandSeparator={true} renderText={(value, props) => <span {...props}> Balance: {value}</span>} ></NumberFormat>
                                    </Option>)}

                                </Select>
                            </Form.Item>
                        </Col>
                        <div className='newswap-align'>
                            <LocalCryptoSwapperCmp
                                localAmt={localValue}
                                cryptoAmt={cryptoValue}
                                localCurrency={this.state.buysellObj.Wallet || "USD"}
                                cryptoCurrency={this.state.buysellObj.Crypto}
                                onChange={(obj) => this.onValueChange(obj)} 
                                screenName='sell'
                                isSwaped={isSwaped}
                                maxValue={this.state.buysellObj.maxFiatValue}
                                onCurrencySwap={() => {
                                    this.setState({ ...this.state, swapValues: { ...this.state.swapValues, isSwaped: !this.state.swapValues.isSwaped } })
                                }}
                                isConvertionLoad={isConvertionLoading} />
                        </div>
                    </Row>
                    <div className="text-right mt-24">
                        <Button type="primary" className="primary-btn" htmlType="submit"
                            loading={this.state.isLoading}
                            disabled={isConvertionLoading}
                        >
                            Save
                        </Button>
                        <Button
                            type="primary"
                            className="primary-btn cancel-btn"
                            style={{ margin: '0 8px' }}
                            onClick={this.backToBuySell}
                        >
                            Cancel
                        </Button>
                    </div>
                </Form >
            </>
        )
    }
}

const connectStateToProps = ({ buySell, buysellStore, userConfig }) => {
    return {
        buySell,
        buysellStore,
        userProfileInfo: userConfig?.userProfileInfo,
        trackAuditLogData: userConfig.trackAuditLogData
    };
};
const connectDispatchToProps = (dispatch) => {
    return {
        getCoinDetails: (coin, customer_id) => {
            dispatch(fetchSelectedCoinDetails(coin, customer_id));
        },
        dispatch
    };
};

export default connect(
    connectStateToProps,
    connectDispatchToProps
)(BuySellDetails);
