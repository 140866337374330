import { clientApi } from '../../api'
import { ApiControllers } from '../../api/config'
export const getComplianceLU = () => {
    return clientApi.get(ApiControllers.compliance + `Actions`)
}
export const complianceStateChange=(obj)=> {
    return clientApi.put(ApiControllers.compliance + `Dismiss`,obj)
}
export const countryLucup=()=> {
    return clientApi.get(ApiControllers.compliance + `ComplienceLookup`)
}
export const updateCountryRisk=(obj)=> {
    return clientApi.put(ApiControllers.compliance + `UpdateCountryRisk`,obj)
}