
import React, { Component } from "react";
import { Form, Select, message, Button, Spin, Popover, Typography, Tooltip, Input, Alert } from "antd";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { connect } from "react-redux";
import { getPaymentDetails, savePaymentDetails, getBankData,getStates } from "./api";
import { validateContentRule } from "../../utils/custom.validator";
import NumberFormat from "react-number-format";
import { publishShowActions } from "../grid.component/subscribir";
import Loader from "../loader.component";
import { Link } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
import DocumentPreview from "../shared/documentPreview";

const { Text,Title } = Typography;
const { TextArea } = Input;
const EllipsisMiddle = ({ suffixCount, children }) => {
  const start = children.slice(0, children.length - suffixCount).trim();
  const suffix = children.slice(-suffixCount).trim();
  return (
    <Text
      className="mb-0 fs-14 docnames c-pointer d-block"
      style={{ maxWidth: "100% !important" }}
      ellipsis={{ suffix }}>
      {start}
    </Text>
  );
};
class PaymentDetails extends Component {
  constructor (props) {
    super(props);
    this.state = {
      paymentsData: [],
      paymentSavedata: [],
      btnDisabled: false,
      showButton: false,
      disabled: false,
      moreBankInfo: {},
      loading: false,
      visible: false,
      previewModal: false,
      previewPath: null,
      PreviewFilePath: null,
      errorMsg: null,
      isLoading: false,
      errorBillMsg: null,
      statesLst:[]
    };
    this.useDivRef = React.createRef();
  }
  componentDidMount() {
    setCurrentAction(null);
    this.getPayments();
    publishShowActions(false);

  }
  backToPayments = () => {
    setCurrentAction(null);
    this.props.history.push({
      pathname: "/payments"
    });
  };

  getPayments = async () => {
    this.setState({ ...this.state, loading: true });
    let response = await getPaymentDetails(this.props.match.params.id);

    if (response.ok) {
      const a = response.data;
      let showButton = false;

      for (let i in a) {
        if (a[i].state === 'Submitted' || a[i].state === 'Pending') {
          showButton = true;
          a[i].modifiedBy = this.props.userConfig?.userName
        }
        a[i].dupState= a[i].state;
        this.getStatus(a[i].state);
      }
      this.setState({
        ...this.state,
        paymentsData: a,
        loading: false,
        showButton
      });
    } else {
      message.destroy();
      message.error({
        content: apiCalls.isErrorDispaly(response),
        className: "custom-msg",
        duration: 0.5
      });
    }
  };
  getStatus=async(status)=>{
   let response=await getStates(status)
      if (response.ok) {
        if(status==="Submitted"){
          this.setState({ ...this.state, statesLst: response.data,errorBillMsg:null });
        }else if(status==="Approved"){
        
          this.setState({ ...this.state, statesApprovedLst: response.data,errorBillMsg:null });
        }else{
          this.setState({ ...this.state, statesCanceledLst: response.data,errorBillMsg:null });
        }
         
      }else{
        this.setState({ ...this.state, errorBillMsg: apiCalls.isErrorDispaly(response), });
      }
  }

  addressTypeNames = (type) => {
    const stepcodes = {
      "ownbusiness": "My Company",
      "individuals": "Individuals",
      "otherbusiness": "Other Business",
      "myself": "My Self"
    };
    return stepcodes[type];
  };
  handleChange = (e, val) => {
    const { paymentsData, paymentSavedata } = this.state;
    const payrecord = paymentsData.find((item) => item.id === val.id);
    payrecord.state = e;
    payrecord.modifiedBy = this.props.userConfig?.userName;
    paymentSavedata.push(payrecord);
    this.setState({ ...this.state})
  };
  isHtmlallowed=(data)=>{
    const reg = /<(.|\n)*?>/g;
    for(let i in data ){
      if(reg.test(data[i].remarks)){
      return true
    }
    }
  }
  savePayments = async () => {
    this.setState({ ...this.state, errorMsg: "", isLoading: true, errorBillMsg: null })
    const { paymentsData } = this.state;
    if (this.isHtmlallowed(paymentsData)) {
      this.setState({ ...this.state, errorMsg: "Please enter valid content", isLoading: false, })
      this.useDivRef?.current?.scrollIntoView(0,0);
    }else{
    if(this.props.match.params.id !=="00000000-0000-0000-0000-000000000000"){
      paymentsData[0].modifiedBy=this.props.userConfig?.userName
      }
      paymentsData?.forEach((item) => {
        if (item) {
          item.info = JSON.stringify(this.props.trackAuditLogData);
      }
      return item;
      })
    let response = await savePaymentDetails(paymentsData);
    if (response.ok) {
      this.setState({ ...this.state, errorBillMsg: null, loading: false, isLoading: false, });
      message.destroy();
      message.success({
        content: "Payment details saved successfully",
        className: "custom-msg",
        duration: 4
      });
      this.props.history.push("/payments");
    } else {
      this.setState({ ...this.state, errorBillMsg:apiCalls.isErrorDispaly(response), loading: false, isLoading: false });
      this.useDivRef?.current?.scrollIntoView(0,0);
    }
  }
  };
    
  moreInfoPopover = async (id) => {
    this.setState({...this.state,isloading:true});
    let response = await getBankData(id);
    if (response.ok) {
      this.setState({
        ...this.state,
        moreBankInfo: response.data,
        visible: true,
        isloading:true,
        errorBillMsg:null
      });
    } else {
      this.setState({ ...this.state, visible: false, isloading: false,errorBillMsg:apiCalls.isErrorDispaly(response) });
    }
  };
  handleVisibleChange = () => {
    this.setState({ ...this.state, visible: false });
    if(this.state.visible=== false){
      this.setState({ ...this.state, isloading: false });
    }
  };
  popOverContent = () => {
    const { moreBankInfo, isloading } = this.state;
    if (!isloading) {
      return <Spin />;
    } else {
      return (
        <div className="more-popover">
          <Text className="lbl">BIC/SWIFT/ABA Routing Code</Text>
           <Text className="val">{moreBankInfo?.routingNumber}</Text>
          {(moreBankInfo?.transferType!=="internationalIBAN" && moreBankInfo?.transferType!=="sepa")&& 
          <div>
           <Title className="lbl">Bank Address</Title>
           <Text className="lbl">Address Line 1</Text>
           <Text className="val ">{moreBankInfo?.bankAddress1}</Text>
           {moreBankInfo?.bankAddress2!==null &&<>
          <Text className="lbl">Address Line 2</Text>
          <Text className="val">{moreBankInfo?.bankAddress2}</Text>
          </>}
          </div>}
          {(moreBankInfo?.transferType==="sepa" || moreBankInfo?.transferType==="internationalIBAN" ) && <div><Text className="lbl">Bank Address</Text>
          <Text className="val">
          {moreBankInfo?.bankBranch}{moreBankInfo?.bankBranch && ", "}
          {moreBankInfo?.country}{ moreBankInfo?.country && ", "}{moreBankInfo?.state}{moreBankInfo?.state && ", "}{moreBankInfo?.city}{moreBankInfo?.city && ", "}{moreBankInfo?.postalCode}
          </Text></div>}
        </div>
      );
    }
  };
 
  filePreviewPath() {
    return this.state.previewPath;
  }
  docPreviewClose = () => {
    this.setState({ ...this.state, previewModal: false });
  }

  handleRemarks = (e, id) => {
    let paymentData = this.state.paymentsData;
    const recRemarks = paymentData.find(r => r.id === id);
    recRemarks.remarks = e.target.value;
    recRemarks.modifiedBy=this.props.userConfig?.userName;
    this.setState({ ...this.state, paymentData: recRemarks, btnDisabled: true, errorMsg: "" })
    const reg = /<(.|\n)*?>/g;
    if (reg.test(e.target.value)) {
      this.setState({ ...this.state, errorMsg: "Please enter valid content",btnDisabled: true, })
      this.useDivRef?.current?.scrollIntoView(0,0);
      return false;
    }
    else {
      this.setState({ ...this.state, errorMsg: "",btnDisabled: false, })
      e.preventDefault();
      return true;
    }
  }

    
  docPreviewClose = () => {
    this.setState({ ...this.state, previewModal: false, docPreviewDetails: null });
  };
  docPreviewOpen = (data) => {
    this.setState({ ...this.state, previewModal: true, docPreviewDetails: { id: data.id, fileName: data.fileName } });
  };

  render() {
    const { paymentsData, loading, errorBillMsg, btnDisabled, isLoading } = this.state;
    return (
      <>
      <div ref={this.useDivRef}>
        <div>
          {loading && <Loader />}
          {errorBillMsg !== undefined && errorBillMsg !== null && (
            <Alert className="w-100 mb-16" type="error" showIcon description={errorBillMsg} />
          )}
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={this.savePayments}
            autoComplete="off"
          >
            {this.state.errorMsg && (
              <Alert type="error" closable={false} className="mb-16" showIcon description={this.state.errorMsg} />
            )}
            <div className="responsive_table">
              <table className="pay-grid">
                <thead>
                  <tr>
                    <th style={{ width: 250 }}>Whitelist Name</th>
                    <th style={{ width: 300 }}>Bank Name</th>
                    <th style={{ width: 300 }}>Bank Account Number/IBAN</th>
                    <th style={{ width: 100 }}>Currency</th>
                    <th style={{ width: 150 }}>Amount</th>
                    <th style={{ width: 250 }}>Documents</th>
                    <th style={{ width: 300 }}>Remarks</th>
                    <th style={{ width: 100 }}>State</th>
                  </tr>
                </thead>
                <tbody>

                  {paymentsData?.map((item, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td style={{ width: 250 }}> <span>{item.beneficiaryAccountName}</span></td>
                          <td style={{ width: 310}}>
                            <div className="d-flex align-center ">
                              <span>
                             
                                <span className="pay-docs"> <Tooltip title={item.bankname}>{item.bankname}</Tooltip></span>
                              </span>
                              <Text
                                size="small"
                                className="files-labels ml-8"
                              >

                                {this.addressTypeNames(item.addressType)}{" "}

                              </Text>
                              <Popover
                                className="more-popover"
                                content={this.popOverContent}
                                trigger="click"
                                visible={item.visible}
                                placement="top"
                                onVisibleChange={() =>
                                  this.handleVisibleChange()
                                }
                              >
                                <span
                                  className="icon md info c-pointer ml-4"
                                  onClick={() =>
                                    this.moreInfoPopover(item.addressId)
                                  }
                                />
                              </Popover>
                            </div>
                          </td>

                          <td style={{ width: 180 }}>
                            <Tooltip title={item.accountnumber}>
                              <span className="pay-doc">{item.accountnumber}</span>
                            </Tooltip>
                          </td>
                          <td style={{ width: 100 }}>{item.currency}</td>
                          <td style={{ width: 150 }}>
                            <NumberFormat
                              value={item.amount}
                              decimalSeparator="."
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td style={{ width: 250 }}>
                            <div className="docdetails fs-12 fw-400 c-pointer"  style={{width:"100px"}}>
                              {item.docrepoistries?.map((file, idx) => <Link key={idx} 
                              onClick={() => this.docPreviewOpen(file)}
                              className="text-blue">
                              <Tooltip title={file.fileName}>
                                                      {file.fileName?.split(".")[0].length>4&&<EllipsisMiddle>
                                                        {file.fileName.slice(0,4) + "..." +file.fileName.split(".")[1]}
                                                      </EllipsisMiddle>}
                                                      {file.fileName?.split(".")[0].length<=4&&<EllipsisMiddle>
                                                        {file.fileName}
                                                      </EllipsisMiddle>}
                                                    </Tooltip>
                              </Link>)}
                            </div>
                          </td>
                          <td style={{ width: 300 }}>
                            <TextArea placeholder="Remarks" rows={1} className='cust-input' style={{ overflow: "hidden" }}
                             rules={[
                              {
                                validator: validateContentRule
                              }
                            ]}
                              value={item.remarks}
                              maxLength={100}
                              onChange={(e) => this.handleRemarks(e, item.id)}
                              disabled={
                              item?.dupState==item.state||
                                item.dupState==="Approved" ||
                                this.props.match.params.type === 'disabled'}
                            />
                          </td>
                          <td style={{ width: 100 }}>
                            <Select
                              name="state"
                              className="cust-input"
                              onChange={(e) => this.handleChange(e, item)}
                              placeholder="Select state"
                              style={{ width: '100%' }}
                              defaultValue={item.state}
                              disabled={this.props.match.params.type === 'disabled'||
                              item.dupState==="Approved" ||
                              item.dupState==="Cancelled"
                               
                              }

                            >
                               {this.state.statesLst?.map(items => <Select.Option value={items?.code}>{items?.name}</Select.Option>)}
                            </Select>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table></div>
            <div className="text-right mt-24">
              {this.props.match.params.type !== 'disabled' && this.state.showButton && (
                <Button
                  type="primary"
                  disabled={btnDisabled}
                  loading={isLoading}
                  className="primary-btn"
                  htmlType="submit"
                >
                  Save
                </Button>
              )}
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                style={{ margin: "0 8px" }}
                onClick={this.backToPayments}
              >
                Cancel
              </Button>
            </div>
          </Form>
       
        </div>
        {this.state.previewModal && (
          <DocumentPreview
            previewModal={this.state.previewModal}
            handleCancle={this.docPreviewClose}
            upLoadResponse={this.state.docPreviewDetails}
          />
        )}
        </div>
      </>
    );
  }
}

const connectDispatchToProps = (dispath) => {
  return {
    dispath
  };
};
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return {
    breadCrumb,
    oidc,
    userConfig: userConfig?.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(PaymentDetails);
