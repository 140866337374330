const SET_BREADCRUMB = 'setBreadcrumb';
const CLEAR_BREADCRUMB = 'clearBreadcrumb';
const setBreadcrumb = (payload) => {

    return {
        type: SET_BREADCRUMB,
        payload
    }
}
const clearBreadcrumb = (payload) => {
    return {
        type: CLEAR_BREADCRUMB,
        payload
    }
}

const initialState = {
    values: {
        '': 'Dashboard',
        '/': 'Dashboard',
        '/dashboard': 'Dashboard',
        '/customers': 'Customers',
        '/adduser': 'Add User',
        '/banks': 'Banks',
        '/transaction': 'Transactions',
        '/buysell': 'Trade',
        '/swap': 'Swap',
        '/deposit': 'Receive',
        '/deposits': 'Receive',
        '/fiatDetails': 'Receive',
        '/cryptoDetailView': 'Receive',
        '/withdraw': 'Send',
        '/notices': 'Notices',
        '/controlcodes': 'Control Codes',
        '/controlCodeDetails': 'Control Codes',
        '/withdrawfait': 'Withdraw Fiat',
        '/auditlogs': 'Audit Logs',
        "/users": 'Users',
        "/document": 'Documents',
        "/documents": 'Documents',
        '/roles': 'Roles',
        '/commissions': 'Commissions',
        '/compliance': 'Compliance',
        '/reports': 'Reports',
        '/Reports': 'Reports',
        '/memberdepositcrypto': 'Member Deposit Crypto',
        '/uploadmasspayments': 'Upload MassPayments',
        '/masspayments': 'MassPayments',
        '/payments': 'Bill Payments',
        '/alerts': 'Alerts',
        '/cases': 'Cases',
        '/addressbook': 'Address Book',
        //'/addressbookview': 'Address Book',
        '/reconcile': 'Reconciliation',
        '/balances':'Balances',
        '/companywallets':'Company Wallets',
        '/userprofile':"Manage Your Account",
        '/transactionscompliance':"Transactions Compliance",
        '/referral':"Referral",
        '/bonus':"Bonus",
         '/internalWalletTransfer': "Internal Wallet Transfer",
        //'/internalWalletTransfer':"Internal Wallet Transfer",
        '/transferDetails': "internalWalletTransfer",
        '/pyrroscustomer': "Customers",
        '/evolvecustomer': "Customer",
        '/pyrrosdeposit': "Receive",
        '/evolvedeposit': "Deposit",
        '/pyrrostransaction': "Transactions",
        '/evolvetransaction': "Transactions",
        '/pyrroswithdrawal': "Send",
        '/evolvewithdrawal': "Withdrawal",
        '/batchPayments':"Batch Payments",
        '/personalbanks':"Personal Banks",
        '/providerfees': "Provider Fees",
        '/pendingdepostis':'Pending Deposit',
        '/openpaydcustomer':"Customers",
        '/openpayddeposit':"Receive",
        '/openpaydwithdrawal':"Send",
        '/openpaydtransaction':"Transactions",
        '/companybanks':"Company Banks",
        '/deductions':"Deductions",
        '/card':"Cards",
        '/openpaydReports':"Reports",
        '/Reports':"Report View"
    }
}

const breadCrumbReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case SET_BREADCRUMB:
            state = { values: { ...state.values, [action.payload.key]: action.payload.val } };
            return state;
        case CLEAR_BREADCRUMB:
            state = { values: initialState.values };
            return state;
        default:
            return state;
    }
}
export { setBreadcrumb, clearBreadcrumb };
export default breadCrumbReducer;