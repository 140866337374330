import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getCompanyWalletLU = () => {
    return clientApi.get(ApiControllers.common + `CompanyWallentLU`)
}

const getCoinData = () => {
    return clientApi.get(ApiControllers.common + `Transfer/Coin`)
}
const getTransfer = (Id) => {
    return clientApi.get(ApiControllers.common + `Transfer/${Id}`)
}

const saveTransfer=(obj)=>{
    return clientApi.post(ApiControllers.common + `CompanyWalletTransfer`,obj)
}

const approveStateUpdate = (obj) => {
    return clientApi.put(ApiControllers.common + "UpdateTransferStatus", obj)
}

const getAmount = (walletType, coin) => {
    return clientApi.get(ApiControllers.dashboard + `/${walletType}/${coin}`)
}
const getStates = (status) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${"Internal Wallet Transfer"}/${status}`
	);
};
export { getCompanyWalletLU, getCoinData, getTransfer, saveTransfer,approveStateUpdate, getAmount,getStates }