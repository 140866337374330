import React, { Component } from "react";
import List from "../grid.component";
import { Form, Select, Modal, Tooltip, Alert, Input, Button, message, Row, Col, Radio } from "antd";
import { getComplianceLU, complianceStateChange,countryLucup,updateCountryRisk } from "./api";
import DocumentsList from "../documents.component/documentsList";
import { sendRequest, uuidv4 } from "../documents.component/api";
import { connect } from "react-redux";
import { validateContentRule } from "../../utils/custom.validator";
import apiCalls from "../../api/apiCalls";
const { Option } = Select;
class Compliance extends Component {
  docReqForm;
  constructor(props) {
    super(props);
    this.state = {
      Status: "All",
      selection: [],
      selectedObj: {},
      complianceLu: [],
      check: false,
      docModal: false,
      sendDisable: false,
      documentRequest: {
        id: uuidv4(),
        transactionId: null,
        adminId: this.props?.userConfig?.id,
        date: new Date(),
        type: "Compliance",
        CustomerId: null,
        note: "",
        remarks: "",
        details: []
      },
      stateLoading: false,
      modal: false,
      error: null,
      errorMsg: null,
      isBtnLoading:false,
      selectedTab:1,
      countryData:[],
      selectedCountrysdata:[]
    };
    this.gridRef = React.createRef();
    this.formref = React.createRef();
    this.countryRiskgridRef = React.createRef();
  }

  componentDidMount() {
    if(this.state.selectedTab==1){
      this.getComplianceLu();
    }
  }

  getComplianceLu = async () => {
    let response = await getComplianceLU();
    if (response.ok) {
      this.setState({ ...this.state, complianceLu: response.data,errorMsg:null });
    }else{
      this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
    }
  };
  getCountryData=async()=>{
    let res = await countryLucup();
    if(res.ok){
      this.setState({...this.state,countryData:res.data});
    }else{
      this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(res)});
    }
  }
 
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      customCell: (props) => (
        <td>
          {" "}
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
            />
            <span></span>{" "}
          </label>
        </td>
      )
    },
    { field: "memberName", title: "Name", filter: true, width: 200 },
    { field: "userName", title: "User Name", filter: true, width: 200 },
    { field: "memberEmail", title: "Email", filter: true },
    { field: "description", title: "Reason", filter: true },
    {
      field: "createdDate",
      title: "Reg Date",
      filter: true,
      filterType: "date"
    },
    { field: "state", title: "Status", filter: true },
  ];
  handleSaveCountryRisk =async()=>{
    const {selectedCountrysdata} = this.state;
    if(selectedCountrysdata.length===0){
   this.setState({...this.state,errorMsg:"Apply a color code to any one country before saving."});
   window.scrollTo(0,0)
    }else{
      this.setState({ isBtnLoading: true });
      let res = await updateCountryRisk(selectedCountrysdata);      
      if(res.ok){  
        this.countryRiskgridRef.current.refreshGrid();
        message.success({
          content: "Colour codes saved successfully",
          className: "custom-msg",
          duration: 3
        });
        setTimeout(()=>{
          this.setState({...this.state,selectedCountrysdata:[],errorMsg:null,isBtnLoading:false});
          return
        },1200)
      }
    }
  }
   countryRiskColumns = [
    { field: "countryName", title: "Country", filter: true, width: 200 },
    { field: "countryCode", title: "IBAN Code", filter: true, width: 200 },
    {
        field: "",
        title: "Color Code",
        filter: false,
        sortable:false,
        width: 200,
        customCell: (props) => (<td>
                <div className="d-flex mb-16" style={{ justifyContent: "flex-end" }}>
                      <Select                  
                        value={this.state.selectedCountrysdata.find(item => item.countryCode === props.dataItem.countryCode)?.countryColour || props.dataItem.countryColour}
                        disabled={this.state.isBtnLoading}
                        className="cust-input w-100"
                        showSearch
                        onChange={(item) => this.handleCountryColor(item, props.dataItem)}
                        placeholder="Select Color Code"
                       >
                        {this.state.countryData?.map((item, idx) => (
                            <Option key={idx} value={item.name}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </div>
            </td>)
    },
];

  onActionClick = (key) => {
    const actions = {
      Dismiss: "dismiss",
      "Doc Request": "showReqDocModal"
    };
    this[actions[key]]();
  };
  handleSearch = (val) => {
    const searchVal = `${val ? val : "All"}`;
    this.setState(
      {
        ...this.state,
        Status: val.target ? val.target.value : searchVal,selection:[],check: false,selectedObj:{}
      },
      () => {
        this.gridRef.current.refreshGrid();
      }
    );
  };
   handleCountryColor = (selctedColourValue, selectedObj) => {
    this.setState(previousState=>({...previousState,errorMsg:null}))
    let updatedObj = { ...selectedObj };
    const {selectedCountrysdata} = this.state;
    updatedObj.countryColour = selctedColourValue;
    const index = selectedCountrysdata.findIndex(obj => obj.id === updatedObj.id);
    if(index !== -1){
      selectedCountrysdata[index] = updatedObj;
    }else{
      selectedCountrysdata.push(updatedObj);
    }
  }
  
  handleInputChange = (prop, e) => {
    const rowChecked = prop.dataItem;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection,  errorMsg:null,
    });
  };
  closeDocModal = () => {
    this.setState({ ...this.state, sendDisable: false });
    this.setState({
      docModal: false,
      selection: [],
      check: false,
      documentRequest: this.createDocReq(),
      errorMsg: null
    });
  };
  handleRequestedDoc = (docs) => {
    let _docDetails = [];
    for (let obj of docs) {
      let detail = {
        id: obj.id,
        documentName: obj.name,
        documentId: this.state.documentRequest.id,
        remarks: ""
      };
      _docDetails.push(detail);
    }
    this.setState({
      ...this.state,
      documentRequest: { ...this.state.documentRequest, details: _docDetails }
    });
  };
  showReqDocModal = () => {
    this.setState({ docModal: true });
  };
  handleReqSend = async (values) => {
    let { documentRequest } = this.state;
    if (documentRequest.details.length === 0) {
      message.destroy();
      this.setState({...this.state,errorMsg:"Please select the one record"});
      return;
    }
    documentRequest.note = values.note;
    documentRequest.CustomerId = this.state.selection[0];
    documentRequest.Requestedby = this.props?.userConfig?.userName;
    this.setState({ ...this.state, sendDisable: true });
    const response = await sendRequest(documentRequest);
    if (response.ok) {
      this.setState({ ...this.state, sendDisable: false,errorMsg:null });
      this.closeDocModal();
      this.setState({ ...this.state, selection: [], check: false });
      message.destroy();
      message.success({
        content: "Documents requested successfully",
        className: "custom-msg",
        duration: 0.5
      });
      this.gridRef.current.refreshGrid();
    } else {
      this.setState({
        ...this.state,
        isLoading: false,
        selection: [],
        isCheck: false,
        sendDisable: false,
        errorMsg:apiCalls.isErrorDispaly(response)
      });
    }

  };
  createDocReq() {
    return {
      id: uuidv4(),
      transactionId: null,
      adminId: this.props?.userConfig?.id,
      date: new Date(),
      type: "Compliance",
      CustomerId: null,
      note: "",
      remarks: "",
      Requestedby: this.props?.userConfig?.userName,
      details: []
    };
  }

  handleCancel = (e) => {
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      check: false,
      error: null
    });
    this.formref.current.resetFields();
  };
  handleOk = async (values) => {
    this.setState({ ...this.state, error: null, stateLoading: true });
    let obj = {
      CustomerId: this.state.selection[0],
      Status: "Dismiss",
      Remarks: "",
      Changedby: this.props?.userConfig?.userName
    };
    obj.Remarks = values.statusRemarks;
    let response = await complianceStateChange(obj);
    if (response.ok) {
      this.setState({
        ...this.state,
        modal: false,
        selection: [],
        check: false,
        stateLoading: false,
        error:null
      });
      message.destroy();
      message.success({
        content: "Record dismissed successfully",
        className: "custom-msg",
        duration: 3
      });
      this.formref.current.resetFields();
      this.gridRef.current.refreshGrid();
    } else {
      message.destroy();
      this.setState({
        ...this.state,
        error: apiCalls.isErrorDispaly(response),
        stateLoading: false,
      });
     
    }

  };

  dismiss = () => {
    if (!this.state.check) {
      this.setState({...this.state,errorMsg:"Please select the one record"});

    } else {
      this.setState({ ...this.state, errorMsg: null, modal: true });
    }

  };
  handleTabsChange=(e)=>{
  this.setState({...this.state,selectedTab:e.target.value,errorMsg:null,selectedCountrysdata:[]});
  if(e.target.value === 2){
     this.getCountryData();
  }
  }
  render() {
    const {selectedTab,isBtnLoading,errorMsg} = this.state;

    return (<>
      {errorMsg && <div style={{ width: '100%' }}>
            <Alert className="mb-16 w-100" type="warning" description={errorMsg} showIcon/></div> }
            <div className="d-flex justify-content align-center">
          <Radio.Group
            value={selectedTab}
            onChange={this.handleTabsChange}
            className="buysell-toggle"
          >
            <Radio.Button value={1}> Compliance </Radio.Button>
            <Radio.Button value={2}> Country Risk </Radio.Button>
          </Radio.Group>
        </div>
       {selectedTab ==1 &&<> <div className="d-flex mb-16" style={{ justifyContent: "flex-end" }}>
          <Form
            className="form form-bg mb-0 d-flex align-center "
            autoComplete="off"
          >
            <div>
              <Form.Item name="gridSearch" className="">
                <Select
                  style={{ width: 250 }}
                  className="cust-input mb-0"
                  onChange={(e) => this.handleSearch(e)}
                  onSearch={(event) => this.handleSearch(event)}
                  defaultValue="All"
                >
                  {this.state.complianceLu?.map((item, idx) => (
                    <Option value={item.code} key={idx}>{item.code}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"compliance"}
          ref={this.gridRef}
          url={process.env.REACT_APP_GRID_API + "Compliances"}
          columns={this.gridColumns}
          additionalParams={{ status: this.state.Status }}
        /></>}
        {selectedTab == 2 &&  <>
          <List
          showActionBar={false}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"compliance"}
          ref={this.countryRiskgridRef}
          url={process.env.REACT_APP_GRID_API + "Compliances/CountryRisk"}
          columns={this.countryRiskColumns}
        />
        <div className="text-right mb-8">
          <Button className="primary-btn text-right" 
          style={{ margin: "8px 8px" }} onClick={()=>this.handleSaveCountryRisk()}
          type="primary"
          htmlType="submit"         
           loading={isBtnLoading}
          disabled={isBtnLoading}
          >Save</Button></div>
        </>}
        <Modal
          className="documentmodal-width"
          title="Documents Requests"
          visible={this.state.docModal}
          destroyOnClose={true}
          width={1000}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.closeDocModal} />
            </Tooltip>
          }
          footer={null}
        >
          {this.state.error !== undefined && this.state.error!== null && <div style={{ width: '100%' }}>(
            <Alert type="error" className="mb-16 w-100" showIcon message={this.state.error} />
          )</div>}
          <Form
            name={this.docReqForm}
            initialValues={this.state.documentRequest}
            onFinish={(values) => this.handleReqSend(values)}
          >
            <div className="mb-24">
              <Form.Item
                rules={[
                  { required: true, message: "Is required" },
                  {
                    whitespace: true,
                    message: "Is required"
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
                name="note"
                label="Title"
                className="input-label"
              >
                <Input
                  maxLength="150"
                  id="note"
                  className="cust-input"
                  placeholder="Enter Title"
                />
              </Form.Item>
            </div>
            <DocumentsList
              onDocSelect={(docs) => {
                this.handleRequestedDoc(docs);
              }}
            />
            <Form.Item className="text-right mb-0 docmodal-footer">
              <Button
                type="primary"
                onClick={this.closeDocModal}
                className="primary-btn cancel-btn mr-16"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="primary-btn"
                disabled={this.state.sendDisable === true}
                loading={this.state.sendDisable}
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Confirm Dismiss?"
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div className="">
          
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.handleOk}
            >
              {this.state.error !== undefined && this.state.error !== null && (
                <Alert type="error" showIcon description={this.state.error} />
              )}
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24}>
                  <Form.Item
                    name="statusRemarks"
                    label="Reason"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required",
                        whitespace: true
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Input
                      placeholder="Reason"
                      className="cust-input"
                      maxLength={30}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    loading={this.state.stateLoading}
                  >
                  Save
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps)(Compliance);
