import React, { Component } from "react";
import {setBreadcrumb,} from "../../reducers/breadcrumbReducer"; 
import List from "../grid.component";
import { connect } from "react-redux";
import {Tooltip } from 'antd';
class BatchPayments extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selection:[],
      gridUrl: process.env.REACT_APP_GRID_API + `MassPayments/Transactions`,
    };
  }
  gridColumns = [
    {field:"batchID",title:"Batch ID",width:250, filter: true,locked: true, 
    customCell: (props) => (
        <div className="gridLink table-data-align" onClick={() => this.batchPaymentsDetailgrid(props)}>
          {props.dataItem.batchID}
        </div>
    )},
    {
      field: "date",
      title: "Date",
      width: 200,
      filter: true,
      filterType: "date",
      isShowTime: true,
    },
    {
      field: "fileName", title: "File Name", width: 250, filter: true,
      customCell: (props) => (
        <td>
          <div className="gridLink table-data-align" onClick={() => this.batchPaymentsDetailgrid(props)}>
            {props.dataItem.fileName}
          </div>
        </td>
      )
    },
    {
      field: "name",
      title: "Name",
      width: 150,
      filter: true,
    },
    { field: "currency", title: "Currency", width: 150, filter: true },
    { field: "status", title: "Status", width: 140, filter: true },
    { field: "totalTransactionAmount", title: "Total Transaction Amount", width: 250, filter: true, dataType: "number",
    filterType: "numeric", },
    { field: "approvedAmount", title: "Approved Amount", width: 180, filter: true , dataType: "number",
    filterType: "numeric",},
    { field: "rejectedAmount", title: "Rejected Amount", width: 180, filter: true , dataType: "number",
    filterType: "numeric",},
    { field: "pendingAmount", title: "Pending Amount", width: 165, filter: true, dataType: "number",
    filterType: "numeric", },
    { field: "netAmount", title: "Net Amount", width: 150, filter: true , dataType: "number",
    filterType: "numeric",},
    { field: "uploadedExcel", title: "Uploaded Excel", width:160,
    customCell: (props) => (
      <td>
        <div className="d-flex">
       <div className="batch-excel"><span> <Tooltip title={props?.dataItem?.fileName}>{props?.dataItem?.fileName}</Tooltip></span></div>
         <div><span className="icon md download c-pointer" onClick={()=>this.downloadExcel(props.dataItem.uploadedExcel)}></span></div></div>
          </td>
    )},
  ];
   batchPaymentsDetailgrid = (e) => {
     const items = e.dataItem;
     const vall = items.currency;
     const batchID = items.batchID;
     const id = items.id;
     this.props.history.push(`/batchPayments/${items.id}/${vall}`);
     this.props.dispatch(
      setBreadcrumb({
        key: `/batchPayments/` + id,
        val: batchID +" "+ "/"+" " + vall
      })
    );
  };

  downloadExcel =(fileName)=>{
    window.open(fileName, '_self')
  }
  render() {
    return (
      <>
        <List
          showActionBar={true}
          url={this.state.gridUrl}
          columns={this.gridColumns}
        />
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(BatchPayments);
