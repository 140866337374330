export const ApiControllers = {
	reports: "Report/",
	withdrawal: "Withdrawal/",
	deposit: "Deposit/",
	crypto: "Crypto/",
	fiat: "Fiat/",
	auditLogs: "AuditLogs/",
	transaction: "Transactions/",
	banks: "Banks/",
	Admin: "Admin/",
	member: "Member/",
	master: "Master/",
	swap: "Swap/",
	customers: "Customer/",
	roles: "Roles/",
	users: "Users/",
	buysell: "BuySell/",
	wallets: "Wallets/",
	notification: "Notifications/",
	security: "Security/",
	compliance: "Compliance/",
	dashboard: "DashBoard/",
	documents: "Documents/",
	commissions: "Commissions/",
	depositeWithdraw: "DepositeWithdraw/",
	addressbook: "addressbook/",
	uploadmasspayments: "Masspayments/",
	importexcel: "ImportExcel/",
	masspayment: "MassPayment/",
	markets: "Markets/",
	memberDeposits: "MemberAppDeposits/",
	alerts: "Alerts/",
	cases: "Cases/",
	kompany: "Kompany/",
	address: "Address/",
	reskscreen: "RiskScreen/",
	pyrros: "Pyrros",
	reconcile: "Reconcile/",
	withdraw:"Withdraw/",
	common:"Common/",
	partner:"Partner/",
	bank:"Bank/",
	batchPayments:"BatchPayments/",
	massPayment:"MassPayment/",
	sumSub:"Sumsub/",
	customer:"Customer/"
};
