

import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Alert } from 'antd';
import Loader from '../loader.component';
import { publishShowActions } from '../grid.component/subscribir';
import { getPayeeData } from "./api";
import { bytesToSize } from "../../utils/service";
import { redirectToCaseView } from '../../utils/caseRediractions';
import { connect } from 'react-redux';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { Link } from "react-router-dom";
import DocumentPreview from "../shared/documentPreview";
import apiCalls from '../../api/apiCalls';
import Info from "../info/Info";
import DocumentsUploade from '../../utils/documents';
import { PlusCircleOutlined } from '@ant-design/icons';
import Notes from '../../utils/notes';
import Moment from 'react-moment';
import config from "../../config";
const { Title, Text } = Typography
const EllipsisMiddle = ({ suffixCount, children }) => {
  const start = children?.slice(0, children.length - suffixCount).trim();
  const suffix = children?.slice(-suffixCount).trim();
  return (
    <Text
      className="mb-0 fs-14 docnames c-pointer d-block"
      style={{ maxWidth: "100% !important" }}
      ellipsis={{ suffix }}>
      {start}
    </Text>
  );
};
const AddressCryptoView = (props) => {
  const [loading, setLoading] = useState(false);
  const [cryptoAddress, setCryptoAddress] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);
  const [files, setFiles] = useState([]);
  const [docPreviewModal, setDocPreviewModal] = useState(false)
  const [docPreviewDetails, setDocPreviewDetails] = useState(null)
  const [isModalVissible, setIsVissible] = useState(false);
  const [docReplyObjs, setIsdocReplyObjs] = useState([]);
  useEffect(() => {
    publishShowActions(false);
    getLoadData();
  }, []);//eslint-disable-line react-hooks/exhaustive-deps
  const getLoadData = async () => {
    setLoading(true);
    let response = await getPayeeData(props.match.params.payeeId, props.match.params.id);
    if (response.ok) {
      setLoading(false)
      setErrorMsg(null);
      setCryptoAddress(response.data);
      setIsdocReplyObjs(response.data.adminDocuments)
      let fileDetails = response.data?.docRepoistryLists;
      if (fileDetails) {
        if (fileDetails.length !== 0) {
          fileDetails.forEach((item) => {
            let obj = {};
            obj.id = item.id;
            obj.name = item.fileName;
            obj.size = item.fileSize;
            obj.response = [item.path];
            setFiles((preState) => [...preState, obj]);
          });
        }
      }
    } else {
      setLoading(false);
      setErrorMsg(apiCalls.isErrorDispaly(response))
    }
  };

  const docPreviewOpen = (data) => {
    setDocPreviewModal(true)
    setDocPreviewDetails({ id: data.id, fileName: data.name })
  }
  const docPreviewClose = () => {
    setDocPreviewModal(false)
    setDocPreviewDetails(null)
  }

  const backToAddressBook = () => {
    props.history.push("/addressbook/type");
  };

  const redirectCaseView = (caseData) => {
    redirectToCaseView(caseData, props)
  };
  const docOpen = () => {
    setIsVissible(true);
  }
  const CloseModal = () => {
    setIsVissible(false);
  }
  const updateAttachemnts = (attachemnts) => {
    setIsdocReplyObjs(attachemnts)
  }

  return (<>
    {loading && <Loader />}
    {errorMsg && (
      <Alert
        closable
        type="error"
        description={errorMsg}
        onClose={() => setErrorMsg(null)}
        showIcon
      />
    )}
    <Title className="page-title ">BENEFICIARY DETAILS VIEW</Title>
    {cryptoAddress && <Row gutter={8}>
      <Col xl={24} xxl={24} className="bank-view">
        <Row className="kpi-List">
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>

            <div>
              <label className="kpi-label">Created Date</label>
              <div className=" kpi-val">
                {cryptoAddress?.createddate && (
                  <Moment format={config?.dateFormates?.dateTimeFormate}>
                    {apiCalls.convertUTCToLocalTime(cryptoAddress?.createddate)}
                  </Moment>
                )}
                 {!cryptoAddress?.createddate && ("--")}
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">Whitelist Name</label>
              <div className=" kpi-val">{cryptoAddress?.whiteListName || "-"}</div>

            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">Network</label>
              <div className="kpi-val">{cryptoAddress?.network || "-"}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">Wallet Address</label>
              <div className="kpi-val">
                {cryptoAddress?.walletAddress || "-"}
              </div>
            </div>
          </Col>
          {process.env.REACT_APP_ISTR == "true" && <> <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">Wallet Source</label>
              <div className="kpi-val">

                {cryptoAddress?.walletSource === "Others" ? `${cryptoAddress?.walletSource} (${cryptoAddress?.otherWallet})` : cryptoAddress?.walletSource || "-"}
              </div>
            </div>
          </Col>
          </>}
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">I'm The Owner Of This Wallet Address</label>
              <div className="kpi-val">

                <input
                  type="checkbox"
                  id="agree-check"
                  checked={cryptoAddress?.isProofofOwnership}
                  disabled={!cryptoAddress?.isProofofOwnership}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">Signature Hash</label>
              <div className=" kpi-val">{cryptoAddress?.signatureHash || "-"}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">Whitelisting Status</label>
              <div className=" kpi-val">{cryptoAddress?.addressState || "-"}</div>
            </div>
          </Col>
          {cryptoAddress?.addressState === "Rejected" && (<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
              <label className="kpi-label">Reason For Rejection </label>
              <div className=" kpi-val">{cryptoAddress?.rejectReason || "-"}</div>
            </div>
          </Col>)}
          {cryptoAddress?.caseIds !== null && (
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
              <div>
                <label className="kpi-label d-block">Case Number</label>
                <div
                  className="fw-600 fs-14">
                  {cryptoAddress?.caseIds?.map(item => <Link className="c-pointer" onClick={() => redirectCaseView(item)}>{item.caseNumber}<br /></Link>)}

                </div>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {files?.map((file) =>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <div className="docfile mr-8 mt-24 d-flex align-center" key={file.id} >
                <span
                  className={`icon xl ${(file.name?.slice(-3) === "zip" &&
                    "file") ||
                    (file.name?.slice(-3) !== "zip" &&
                      "") ||
                    ((file.name?.slice(-3) === "mp4" ||
                      file.name?.slice(-3) === "wmv" ||
                      file.name?.slice(-3) === "avi" ||
                      file.name?.slice(-3) === "mov") &&
                      "video") ||
                    ((file.name?.slice(-3) === "pdf" ||
                      file.name?.slice(-3) === "PDF") &&
                      "file") ||
                    (file.name?.slice(-3) !== "pdf" &&
                      file.name?.slice(-3) !== "PDF" &&
                      "image")
                    } mr-16`}
                />
                <div
                  className="docdetails c-pointer"
                  onClick={() => docPreviewOpen(file)}

                >
                  {file.name !== null ? (
                    <EllipsisMiddle suffixCount={4}>
                      {file.name}
                    </EllipsisMiddle>
                  ) : (
                    <EllipsisMiddle suffixCount={4}>Name</EllipsisMiddle>
                  )}
                  <span className="fs-12 text-secondary">
                    {bytesToSize(file.size)}
                  </span>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>}

    <p className="mt-16"><b> Admin Upload</b> <PlusCircleOutlined onClick={() => docOpen()} /></p>
    <Row gutter={24} className="mb-24 pb-24 border-bottom">
      <>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          {<DocumentsUploade
            pop_up_cancel={() => CloseModal()}
            docID={cryptoAddress?.id}
            previewModal={isModalVissible}
            attachmentUpdate={(attachemnts) =>
              updateAttachemnts(attachemnts)
            }
            docViewObj={docReplyObjs}
            screenTitle={"Address Book Crypto"}
            ModifiedBy={props.userConfig?.userName}
          />}
        </Col>
      </>
    </Row>
    <Notes screenId={props.match.params.payeeId} screenName={"AddressbookCrypto"} createdNote={cryptoAddress.notes}></Notes>

    <Info Id={cryptoAddress.id} Status={cryptoAddress.status} Createdby={cryptoAddress?.userCreated}
      CreatedDate={cryptoAddress.createddate} ModifiedDate={cryptoAddress?.modifiedDate}
      Modifiedby={cryptoAddress?.modifiedBy} isShowInfo={false} screenName={"Addressbook Crypto"} />
    <div className="text-right mt-24">
      <Button
        type="primary"
        className="primary-btn cancel-btn"
        style={{ margin: "0 8px" }}
        onClick={backToAddressBook}
      >
        Cancel
      </Button>
    </div>
    {docPreviewModal &&
      <DocumentPreview
        previewModal={docPreviewModal}
        handleCancle={docPreviewClose}
        upLoadResponse={docPreviewDetails}
      />
    }
  </>)
}
const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo }
}
const connectDispatchToProps = dispatch => {
  return {
    setAction: (val) => {
      dispatch(setCurrentAction(val))
    },
    dispatch
  }
}

export default connect(connectStateToProps, connectDispatchToProps)(AddressCryptoView);
