

//Actions
const SET_WITHDRAWFIAT = "setWithdrawfiat";
const SET_STEP="setStep";
const SET_SAVEWITHDRAW_OBJECT = 'setsavewithdrawobject';
const SET_SAVEWDCRYPTO_OBJECT = 'setsaveWDCryptoObject';
const SET_SUB_TITLE = "setSubTitle";
    
//Action Type
const setStep = (payload) => {
    return {
      type: SET_STEP,
      payload
    };
  };
  const setWithdrawfiat = (payload) => {
    return {
      type: SET_WITHDRAWFIAT,
      payload
    };
  };
  const setSubTitle = (payload) => {
    return {
      type: SET_SUB_TITLE,
      payload
    };
  };
const setsavewithdrawobject = (payload) => {
  return {
      type: SET_SAVEWITHDRAW_OBJECT,
      payload
  }
}
const setsaveWDCryptoObject = (payload) => {
  return {
      type: SET_SAVEWDCRYPTO_OBJECT,
      payload
  }
}
//InitialState
let initialState = {
    stepcode: "",
    stepTitles: {
      withdraw_transaction_selected:'WITHDRAW',
      withdraw_transaction_summary:"WITHDRAW FIAT SUMMARY",
      withdraw_transaction_crypto_selected:"WITHDRAW",
      withdraw_transaction_crypto_summary:"WITHDRAW CRYPTO SUMMARY",
    },
   
    transactionData: { loading: false, data: [], error: null },
    transactionRowData: {},withdrawFinalObj:{},withdrawCryptoObj:{}
}




//Reducer
const transactionComplianceReducer = (state, action) => {
  if (!state) {
    state = {
      ...initialState,
      ...state
    }
  }
    switch (action.type) {
        case SET_STEP:
            state = { ...state, stepcode: action.payload };
            return state;
            case SET_WITHDRAWFIAT:
                state = { ...state, withdrawFiatObj: action.payload };
                return state;
                case SET_SUB_TITLE:
                  state = { ...state, subTitle: action.payload };
                  return state;
        case SET_SAVEWITHDRAW_OBJECT:
            return { ...state, withdrawFinalObj: action.payload }
            case SET_SAVEWDCRYPTO_OBJECT:
              return { ...state, withdrawCryptoObj: action.payload }
        default:
            return state;
    }

}

//Export
export default (transactionComplianceReducer);
export {  setStep,setWithdrawfiat,setsavewithdrawobject ,setsaveWDCryptoObject,setSubTitle,};