import { getBank, getCurrency } from "../components/banks.component/api";

//Actions
const FETCH_BANKS = 'fetchBanks';
const FETCH_BANKS_SUCCESS = 'fetchBanksSuccess';
const FETCH_BANKS_REJECT = 'fetchBanksReject';
const FETCH_CURRENCY = 'fetchCurrency';

//Action Type
const fetchBanks = (payload) => {
    return {
        type: FETCH_BANKS,
        payload
    }
}
const fetchBanksSuccess = (payload) => {
    return {
        type: FETCH_BANKS_SUCCESS,
        payload
    }
}
const fetchBanksReject = (payload) => {
    return {
        type: FETCH_BANKS_REJECT,
        payload
    }
}
const fetchCurrency = (payload) => {
    return {
        type: FETCH_CURRENCY,
        payload
    }
}

//InitialState
let initialState = {
    banks: { loading: false, data: [], error: null },
    currency: [],
    saveBank: {}
}

const handleBank = (bankId) => {
    return async (dispatch) => {
        dispatch(fetchBanks({ key: "banks", loading: true, data: [] }));
        const response = await getBank(bankId);
        if (response.ok) {
            dispatch(fetchBanksSuccess({ key: "banks", loading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchBanksReject({ key: "banks", loading: false, data: [], error: response.originalError }))
        }
    }
}

const handleCurrency = () => {
    return async (dispatch) => {
        dispatch(fetchCurrency({ key: "currency", loading: true, data: [] }));
        const response = await getCurrency();
        if (response.ok) {
            dispatch(fetchCurrency({ key: "currency", loading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchCurrency({ key: "currency", loading: false, data: [], error: response.originalError }))
        }
    }
}

//Reducer
const bankReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case FETCH_BANKS:
            return { ...state, isLoading: true }
        case FETCH_BANKS_SUCCESS:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        case FETCH_BANKS_REJECT:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        case FETCH_CURRENCY:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        default:
            return state;
    }

}

//Export
export default (bankReducer);
export { handleBank, handleCurrency };