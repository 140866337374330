import React, { Component } from "react";
import { Row, Col, Typography,  Alert, } from "antd";
import { connect } from "react-redux";
import { handleTransaction } from "../../reducers/transactionReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { getPyrrosDeposit, getEvolveDeposit,getOpenPayd } from "./api";
import NumberFormat from 'react-number-format';
import Moment from "react-moment";
import apiCalls from "../../api/apiCalls";
import Loader from "../loader.component";
import BankInfo from "../info/BankInfo";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import config from "../../config";
const { Title } = Typography;

class DepositPyrrosDetailView extends Component {

  state = {
    bankDetailsData: [],
    loading: false,
    error: null
  };
  componentDidMount() {
    this.loadData();
  }
  loadData = async () => {
    this.setState({ ...this.state, loading: true, error: null });
    let response = await (this.props?.location?.pathname === `/pyrrosdeposit/details/${this.props.match.params.id}/view` && getPyrrosDeposit(this.props.match.params.id) || this.props?.location?.pathname === `/openpayddeposit/details/${this.props.match.params.id}/view` && getOpenPayd(this.props.match.params.id) || getEvolveDeposit(this.props.match.params.id))
    if (response.ok) {
      this.setState({ ...this.state, bankDetailsData: response.data, loading: false });
    } else {

      this.setState({ ...this.state, error: apiCalls.isErrorDispaly(response), loading: false });

    }
  };
  backToDeposit = () => {
    if (this.props?.location?.pathname === `/pyrrosdeposit/details/${this.props.match.params.id}/view`) {
      this.props.history.push("/pyrrosdeposit")
    } else if(this.props?.location?.pathname === `/openpayddeposit/details/${this.props.match.params.id}/view`) {
      this.props.history.push("/openpayddeposit")
    }else{
      this.props.history.push("/evolvedeposit")
    }
  }
  redirectCustomerView = (items) => {
    const val = items.customerId;
    if(this.props?.location?.pathname === `/openpayddeposit/details/${this.props.match.params.id}/view`){
      this.props.dispatch(
        setBreadcrumb({
            key: "/openpaydcustomer/details/" + val,
            val: items?.name
        })
    );
    window.open("/openpaydcustomer/details/" + val);
    }else{
    this.props.dispatch(
      setBreadcrumb({
          key: "/pyrroscustomer/details/" + val,
          val: items?.name
      })
  );
  window.open("/pyrroscustomer/details/" + val);
    }

}
getPyrrousDetails=(lable,value,pyrrousdetailsClass)=>(
  <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
  <span className={`icon md ${pyrrousdetailsClass}`} />
  <div>
    <label className="kpi-label">{lable}</label>
    <div className=" kpi-val">
      {value || "--"}
    </div>
  </div>
</Col>
)
  getPyrrousDetailsCommissions = (commissionLable, commissionValue, commissionclass) => (
    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
      <span className={`icon md ${commissionclass}`} />
      <div>
        <label label className="kpi-label">{commissionLable}</label>
        <div className=" kpi-val">
          {commissionValue ? (
            <NumberFormat
              value={commissionValue}
              decimalSeparator="."
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            "0"
          )}
        </div>
      </div>
    </Col>
  )
  renderWithdrawCommonData=()=>{
    const {bankDetailsData} = this.state;
    return(<>
              {this.getPyrrousDetails("User Name",bankDetailsData?.userName,"user")}
              {this.getPyrrousDetails("Email",bankDetailsData?.email,"email")}
              {this.getPyrrousDetails("Currency",bankDetailsData?.currency,"wallet")}
              {this.getPyrrousDetails("Bank Name",bankDetailsData?.bank,"bank")}
              {this.getPyrrousDetails("IBAN Number",bankDetailsData?.bankAccountNumber,"file")}
              {this.getPyrrousDetails("Sender's Name",bankDetailsData?.sendersName,"user")}
              {this.getPyrrousDetails("Sender's Bank Name",bankDetailsData?.sendersBankName,"bank")}
              {this.getPyrrousDetails("Sender's Bank Account Number/IBAN",bankDetailsData?.sendersAccount,"bank")}
              {this.getPyrrousDetails("Sender's Bank Address",bankDetailsData?.sendersBankAddress,"addresstype")}
              {this.getPyrrousDetails("Sender's Country",bankDetailsData?.sendersCountry,"file")}
              {this.getPyrrousDetailsCommissions("Gross Amount",bankDetailsData?.grossAmount,"file")}
              {this.getPyrrousDetailsCommissions("Commission",bankDetailsData?.commission,"commission")}
              {this.getPyrrousDetailsCommissions("Commission in Base",bankDetailsData?.commissionInBase,"commission")}
              {this.getPyrrousDetailsCommissions("Fees",bankDetailsData?.originalCommission,"commission")}
              {this.getPyrrousDetailsCommissions("Tier Discount",bankDetailsData?.tierDiscount,"commission")}
              {this.getPyrrousDetailsCommissions("Provider Commission",bankDetailsData?.providerCommission,"commission")}
              {this.getPyrrousDetailsCommissions("Provider Commission in Base",bankDetailsData?.providerCommissionInBase,"commission")}
              {this.getPyrrousDetailsCommissions("Sweep Source Commission",bankDetailsData?.sweepSourceComission,"commission")}
              {this.getPyrrousDetailsCommissions("Sweep Source in Base",bankDetailsData?.sweepSourceComissionInBase,"commission")}
              {this.getPyrrousDetailsCommissions("Sweep Destination Commission",bankDetailsData?.sweepDestinationComission,"commission")}
              {this.getPyrrousDetailsCommissions("Sweep Destination in Base",bankDetailsData?.sweepDestinationComissionInBase,"commission")}
              {this.getPyrrousDetailsCommissions("Net Amount",bankDetailsData?.netAmount,"cash")}
              {this.getPyrrousDetailsCommissions("Profit",bankDetailsData?.profit,"cash")}
              {this.getPyrrousDetailsCommissions("Profit in Base",bankDetailsData?.profitInBase,"cash")}
    </>)
  }
  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        <Title className="page-title">Receive Detail View</Title>

        {this.state.error !== undefined && this.state.error !== null && (
          <Alert type="error" className="mb-16" showIcon message={this.state.error} />
        )}

        <Row gutter={8}>
          <Col xl={24} xxl={24} className="bank-view">
            <Row className="kpi-List">
              <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md date" />
                <div>
                  <label className="kpi-label">Date</label>
                  <div className="kpi-val">
                    <Moment format={config?.dateFormates?.dateTimeFormate}>
                      {this.state.bankDetailsData?.date
                        ? apiCalls.convertUTCToLocalTime(
                          this.state.bankDetailsData?.date
                        )
                        : this.state.bankDetailsData?.date}
                    </Moment>
                  </div>
                </div>
              </Col>
              {this.getPyrrousDetails("Transaction ID",this.state.bankDetailsData?.transactionId,"user")}
              {this.getPyrrousDetails("Customer/Contract ID",this.state.bankDetailsData?.referenceId,"user")}
              <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md user" />
                <div>
                  <label className="kpi-labels">{this.state.bankDetailsData?.isBusiness === true ? <><b>Business Name</b>/<>Personal Name</> </> : <><>Business Name</>/<b>Personal Name</b></>}</label>
                  <br />
                  <span className="kpi-val c-pointer">
                  <a href="#/" onClick={() => this.redirectCustomerView(this.state.bankDetailsData)}>
                   {this.state.bankDetailsData?.name || "-"}
                  </a>
                   </span>
                </div>
              </Col>
              {this.renderWithdrawCommonData()}
              {this.getPyrrousDetails("Status",this.state.bankDetailsData?.state,"status")}
            </Row>
          </Col>
        </Row>
        <BankInfo
          Id={this.state.bankDetailsData?.id}
          Status={this.state.bankDetailsData?.state}
          Createdby={this.state.bankDetailsData?.createdBy}
          CreatedDate={this.state.bankDetailsData?.createdDate}
          ModifiedDate={this.state.bankDetailsData?.modifiedDate}
          Modifiedby={this.state.bankDetailsData?.modifiedBy}
        />
      </>
    );
  }
}

const connectStateToProps = ({ transactionStore }) => {
  return { transactionStore };
};
const connectDispatchToProps = (dispatch) => {
  return {
    fetchtransactionData: (transId) => {
      dispatch(handleTransaction(transId));
    },
    setAction: (val) => {
      dispatch(setCurrentAction(val));
    },
    dispatch
  };
};

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(DepositPyrrosDetailView);
