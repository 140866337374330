import React, { Component } from 'react'
import App from '../App';
import { Modal, Button} from 'antd';
import { userManager } from './index';
import IdleTimer from 'react-idle-timer';
import Wraplogout from './wraplogout';
class IdleCmp extends Component {
    _count = 15;
    timeInterval;
    state = {
        counter: 15,
        showIdleModal: false,
        logoutuser:false
    }
    handleOnIdle = () => {
        this.setState({ ...this.state, logoutuser: true });
    }


    handleCancel = () => {
        clearInterval(this.timeInterval); this._count = 15;
        this.setState({ ...this.state, counter: this._count, showIdleModal: false });
    }

    render() {
        const { showIdleModal, counter } = this.state;
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 20}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <Wraplogout isLogout={this.state.logoutuser}/>
                <App />
                <Modal title="Session timedout alert"
                    visible={showIdleModal}
                    closable={false}
                    closeIcon={false}
                    footer={<>
                        <Button
                            type="primary"
                            className="primary-btn"
                            onClick={() => this.handleCancel()}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" className="primary-btn" onClick={() => {userManager.signoutRedirect() }}>
                            Ok
                        </Button>
                    </>}>
                    <h4 className="text-white-30 fs-16 fw-400">You're session will be logged out in {counter}</h4>
                </Modal>
            </div >
        )
    }
}

export default IdleCmp