import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk'
import authReducer from '../reducers/authReducer';
import customerReducer from '../reducers/customerReducer';
import banksReducer from '../reducers/bankReducer';
import transactionReducer from '../reducers/transactionReducer';
import swapReducer from '../reducers/swapReducer';
import depositReducer from '../reducers/depositReducer';
import buysellReducer from '../reducers/buysellReducer';
import breadCrumbReducer from '../reducers/breadcrumbReducer';
import withdrawReducer from '../reducers/withdrawReducer';
import auditlogReducer from '../reducers/auditlogReducer'
import permissionsReducer from '../reducers/permissionsReducer';
import UserConfig from '../reducers/configReduser';
import currentActionReducer from '../reducers/actionsReducer';
import UserprofileReducer from '../reducers/UserprofileReducer';
import transactionComplianceReducer from '../reducers/transactionComplianceReducer';
import serviceWorkerReducer from '../reducers/serviceWorker';
import gridLayoutReducer from '../reducers/gridLayoutReducer';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["oidc","breadCrumb","currentAction","permissions"]
}
const rootReducer = combineReducers({
    oidc: authReducer,
    customerStore: customerReducer,
    bankStore: banksReducer,
    transactionStore: transactionReducer,
    swapStore:swapReducer,
    depositStore:depositReducer,
    buysellStore: buysellReducer,
    withdrawStore:withdrawReducer,
    breadCrumb:breadCrumbReducer,
    auditlogStore:auditlogReducer,
    permissions:permissionsReducer,
    userConfig:UserConfig,
    userProfile: UserprofileReducer,
    currentAction:currentActionReducer,
    transactionCompliance:transactionComplianceReducer,
    serviceWReducer:serviceWorkerReducer,
    saveGridModal: gridLayoutReducer

})
const reducer = persistReducer(persistConfig, rootReducer)
let store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk)),
);
const persistor = persistStore(store);

export { store, persistor }