import React, { Component } from 'react';
import { Result } from "antd";
import {Redirect} from 'react-router-dom'
import {updateAccessDenied} from '../../reducers/permissionsReducer'
import {setCurrentAction} from '../../reducers/actionsReducer'
import { store } from '../../store';
class AccessDenied extends Component {
    state = {
        redirect: false
    }
    componentDidMount (){
      store.dispatch(updateAccessDenied(false));
      store.dispatch(setCurrentAction(null))
    }
    redirectHandler = () => {
        this.setState({ redirect: true })
        this.renderRedirect();
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/dashboard' />
        }
    }
    
  render() {
    return (
      <>
        <Result
          status="403"
          title="Access Denied"
          subTitle="Please contact administrator"
        />
      </>
    );
  }
}
export default AccessDenied;