import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getNotices = (noticeId) => {
    return clientApi.get(ApiControllers.common + `Notices/${noticeId}`)
}
const saveNotices = (obj, id) => {
    return id === "00000000-0000-0000-0000-000000000000" ? clientApi.post(ApiControllers.common + "Notices", obj) : clientApi.put(ApiControllers.common + `Notices/${id}`, obj);
}
const editNotices = (id, obj) => {
    return clientApi.put(ApiControllers.common + `Notices/${id}`, obj )
}
const activeInactive = (obj) => {
	return clientApi.put(ApiControllers.master + `notice/Status`, obj);
};
export { getNotices, saveNotices, editNotices, activeInactive }