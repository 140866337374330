import { withRouter } from 'react-router-dom'
import { Provider } from "react-redux";
import { Spin } from 'antd';
import { store } from "./store";
import { useEffect } from "react";
import LayoutComponent from './layout';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./layout/appinsights";
import { useAuth0 } from "@auth0/auth0-react";
import { userLogout } from './reducers/authReducer';
function App(props) {

  const { isLoading,isAuthenticated,loginWithRedirect } = useAuth0();

  const connectToHub = () => {
    setTimeout(() => {
        connectToHub();
      
    }, 2000)
  }

  useEffect(() => {
    localStorage.setItem("__url", window.location.pathname);
    let pathName = window.location.pathname
    if(pathName !=="/callback"){
      localStorage.setItem("myurl",pathName)
    }
    if (!isAuthenticated && !isLoading) {
      props.dispatch(userLogout());
      loginWithRedirect();
    }
    connectToHub(); 
  }, []);//eslint-disable-line react-hooks/exhaustive-deps
  return (
    // <Provider store={store}>
        <AppInsightsContext.Provider value={reactPlugin}>
          {isLoading ? <div className="loader"><Spin size="large" tip="Loading..." /></div> : <LayoutComponent />
          }
        </AppInsightsContext.Provider>
    // </Provider>
  );
}

export default withRouter(App);
