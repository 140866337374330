import React from "react";
import List from "../grid.component"
import Moment from "react-moment";
import apiCalls from "../../api/apiCalls";
import config from "../../config";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const Transactions = (props) => {
  
    const customerId=window.location.pathname.split("/")[3]
    const gridRef = React.createRef();
   const gridColumns = [
        {
          field: "transactionId",
          title: "Transaction ID",
          filter: true,
          locked: true,
          width: 210
        },
        {
          field: "date",
          title: "Submission Date",
          filter: true,
          isShowTime: true,
          filterType: "date",
          locked: false,
          width: 210,
          customCell: (props) => (
            <td>
              <Moment format={config?.dateFormates?.dateTimeFormate}>
                {props.dataItem?.date
                  ? apiCalls.convertUTCToLocalTime(props.dataItem?.date)
                  : props.dataItem?.date}
              </Moment>
            </td>
          )
        },
        {
          field: "approvedDate",
          title: "Approval Date",
          filter: true,
          isShowTime: true,
          filterType: "date",
          locked: false,
          width: 210,
        },
        { field: "status", title: "Status", filter: true, width: 180,
          customCell: (data) => {
            debugger
            const statusColorConfig = props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status == data.dataItem.status);
            const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
            const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';
    
            return (
              <td className="whitelist-name">
                <div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
                  {data.dataItem.status}
                </div>
              </td>
            );
          }
         },    { field: "docType", title: "Transaction", filter: true, width: 120 },
        {
          field: "fromWalletCode",
          title: "From Wallet Code",
          filter: true,
          width: 180
        },
        {
          field: "fromValue",
          title: "From Value",
          width: 150,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "toWalletCode",
          title: "To Wallet Code",
          filter: true,
          width: 150
        },
        {
          field: "toValue",
          title: "To Value",
          width: 150,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "effectiveFee",
          title: "Fees",
          filter: true,
          width: 220,
          dataType: "number",
          filterType: "numeric",
        },
        {
          field: "comission",
          title: "Commission",
          width: 150,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "comissionInBase",
          title: "Commission in Base",
          width: 200,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "providerComission",
          title: "Provider Commission",
          width: 200,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "providerComissionInBase",
          title: "Provider Commission in Base",
          width: 240,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "sweepsourceComissionValue",
          title: "Sweep Source Commission",
          filter: true,
          width: 250,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "sweepSourceValueInBase",
          title: "Sweep Source in Base",
          filter: true,
          width: 250,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "sweepDestinationComissionValue",
          title: "Sweep Destination Commission",
          filter: true,
          width: 260,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "sweepDestinationValueInBase",
          title: "Sweep Destination in Base",
          filter: true,
          width: 260,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "totalAmount",
          title: "Total Cost",
          filter: true,
          width: 130,
          dataType: "number", filterType: "numeric"
        },
        {
          field: "profit",
          title: "Profit",
          width: 130,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "profitInBase",
          title: "Profit in Base",
          width: 160,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "provider",
          title: "Provider",
          filter: true,
          width: 160
        },
        {
          field: "fromValueBefore",
          title: "From Before Value",
          width: 180,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "fromValueAfter",
          title: "From After Value",
          width: 180,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "toValueBefore",
          title: "To Before Value",
          width: 180,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "toValueAfter",
          title: "To After Value",
          width: 150,
          filter: true,
          footerCell: true,
          dataType: "number",
          filterType: "numeric"
        },
      ];
    return (
        
            <List
                ignoreSaved={true}
                style={{ margin: "-22px 0 0" }}
                url={process.env.REACT_APP_GRID_API + "Customer/Transactions"}
                ref={gridRef}
                additionalParams={{ customerid: customerId }}
                columns={gridColumns}
            />
        
    )
}
const connectStateToProps = ({
    customerStore,
    userConfig,
    permissions
  }) => {
    return {
      permissions,
      customerStore,
      userConfig: userConfig.userProfileInfo,
      trackAuditLogData: userConfig.trackAuditLogData
    };
  };

  Transactions.propTypes = {
    date: PropTypes.string
};

export default connect(connectStateToProps)(Transactions);