import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip,Form,Input,Radio,Typography,message, Space,Row,Alert} from "antd";
import NumberFormat from "react-number-format";
import apiCalls from "../api/apiCalls";
import {getCustomerLogs,saveCredits} from '../components/customers.component/api'
import Loader from "../components/loader.component";
import Col from "antd/lib/col";
import { connect } from "react-redux";
const { Title } = Typography;
const SuisseBaseCredits=(props)=>{
  const [type,setType]=useState()
  const [loader,setloader]=useState(false)
  const [logsData,setLogsData]=useState([])
  const [errorMsg,setErrorMsg]=useState()
  const [amount,setAmount]=useState()
  const [btnloader,setbtnLoader]=useState(false)
  useEffect(() => {
    if (props.isShowLogs) {
      getLogs()
    }
  }, [])
  const getLogs=async()=>{setloader(true)
    let response=await getCustomerLogs(props.customerId); 
    if (response.ok) {
      setloader(false);setLogsData(response.data)
    }
    else{
      setloader(false)
      setErrorMsg(apiCalls.isErrorDispaly(response))
    }
  }
    const  numberValidator = async function (rule, value, callback) {
        if (value) {
          if (typeof value === "number") {
            value = value.toString();
          }
          if (
            (value.indexOf(".") > -1 && value.split(".")[0].length >= 11) ||
            (value.indexOf(".") < 0 && value.length >= 11)
          ) {
            throw new Error("Amount exceeded");
          } else {
            callback();
          }
        }
      };

  const confirmCredits = async() => {
    if(!type){
      setErrorMsg("Please select Overwrite or Top up (+) / Deduct (-)")
    }
    else{
      let obj = { "CustomerId": [], "isTopUp": false, "CreditValue": "5000", "ModifiedBy": "Finance","info":"" }
    if(typeof props.customerId=='object'){obj.CustomerId=props.customerId}else{obj.CustomerId.push(props.customerId)}
    obj.isTopUp=type==2?true:false;
    obj.CreditValue=amount;
    obj.ModifiedBy=props.userConfig.userName;setbtnLoader(true);
    obj.info = JSON.stringify(props.trackAuditLogData);
    let response=await saveCredits(obj); 
    if (response.ok) {
      setbtnLoader(false);	message.success({
        content: "SuisseBase Credits saved successfully",
        className: "custom-msg",
        duration: 3
      });props.handleModalCloseCreidit(amount)
    }
    else{
      setbtnLoader(false)
      setErrorMsg(apiCalls.isErrorDispaly(response))
    }}
  }
  
    return (
        <>
        <Modal
          title="SuisseBase Credits"
          visible={props.showModal}
          className="crypto-list"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close " onClick={()=>props.handleModalCloseCreidit('')}>
              <span
                className="icon md x c-pointer"
              />
            </Tooltip>
          }
          footer={null}
        > {errorMsg !== undefined && errorMsg !== null && (
          <Alert className="mb-12 send-poperror" type="error" showIcon description={errorMsg} />)}
          <Form
          
          className="risk-popup cust-popalign"
          onFinish={()=>confirmCredits()}>
          <div>
          
            <Radio.Group onChange={(e)=>setType(e.target.value)}>
            <Space direction="vertical">
            <Radio name="Overwrite" value={1}>Overwrite</Radio>
            <Radio name="Topup" value={2}>
             Top up (+) / Deduct (-) <Tooltip placement="top"  title="To Deduct please use (-)"><span className="icon md info"></span></Tooltip>
            </Radio>
            </Space>
            </Radio.Group>
       
        {loader&&<Loader></Loader>}
					
        <Row gutter={24} className="mb-24 pb-24 border-bottom log-row" style={{marginTop:10,marginLeft:3}}>
								<Col xs={24} sm={24} md={12}>
                {(props.customerId?.length==1||typeof props.customerId=='string') &&<p className="mt-14">Available Balance : 
                 {" "}<strong><NumberFormat value={props.availableBlc}
                  decimalSeparator="." displayType={'text'} thousandSeparator={true} /></strong>
                 </p>}
          <Form.Item
              name="accumulatedDeposit"
              className="input-label cust-inputlabel-style"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Is required"
                },
                {
                  type: "number",
                  validator: numberValidator
                }
              ]}
            >
              <NumberFormat
                className="cust-input"
                placeholder="Amount"
                customInput={Input}
                thousandSeparator={true}
                decimalSeparator={false}
                prefix={""}
                allowNegative={true}
                style={{ width: "100%" }}
                value={amount}
                onValueChange={(value)=>
                  setAmount(value.value)
                } maxLength={8}/>
            </Form.Item>
            </Col>
							
            {props.isShowLogs&&<div><Title level={5}>Logs</Title>
            <div className="cust-log">
              {logsData?.map((log)=>(<p className="mb-0">{log.remarks}</p>))}
              </div>
              {logsData?.length==0&&<p className="text-center">No data found</p>}
              </div>}
								</Row>
          <footer className="cust-log-footer">
            <Form.Item className="text-right mb-0">
								<div className="text-right">
                <Button
                type="primary"
                className="primary-btn mr-8"
                htmlType="submit"
              style={{marginLeft:10}} loading={btnloader}>
                Confirm
              </Button>
              <Button
                type="primary"
                className="primary-btn cancel-btn reject-btn"
                onClick={()=>props.handleModalCloseCreidit('')}
              >
                Cancel
              </Button>
             </div>
            </Form.Item>
            </footer>
          </div>
          </Form>
        </Modal> 
        </>
    )
}
const connectStateToProps = ({ userConfig }) => {
	return {
		userConfig: userConfig.userProfileInfo,
    trackAuditLogData:userConfig.trackAuditLogData,
	};
};
export default connect(
	connectStateToProps)(SuisseBaseCredits);