import React from 'react';
import { Layout, } from 'antd';
const { Footer: AntFooter } = Layout

function Footer() {

    return <AntFooter style={{ backgroundColor: 'transparent', padding: 0 }}>
        <div className="footer-links p-16 cust-footer">
                <p className="text-white-30 mb-0 fs-14">SuisseBase<sup className="fs-10">TM</sup> {new Date().getFullYear()}</p>
                <p className="mb-0 fs-14"><a href={window.location.origin} target="_blank">{window.location.host} </a></p>
            </div>
      
    </AntFooter>


}

export default Footer