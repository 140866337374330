import React, { Component } from "react";
import List from "../grid.component";
import { Alert } from "antd";
import { setBreadcrumb } from '../../reducers/breadcrumbReducer';
import { connect } from "react-redux";
import Moment from "react-moment";
import config from "../../config";
class Balances extends Component {
	constructor (props) {
		super(props);
		this.state = {
			check: false,
			selection: [],
			selectedObj: {},
			errorMsg : "",
		};
	}

	columns = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: (props) => (
				<td className="text-center">
					<label className="text-center custom-checkbox">
						<input
							id={props.dataItem.id}
							name="check"
							type="checkbox"
							checked={this.state.selection.indexOf(props.dataItem.id) > -1}
							onChange={(e) => this.handleInputChange(props, e)}
							className="grid_check_box"
						/>
						<span></span>
					</label>
				</td>
			),
		},
		{
			field: "date", title: "Date", filter: true, filterType: "date",width:200,
			customCell: (props) => (
				<td>
					<div className="gridLink" onClick={() => this.balanceView(props)}>
					<Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem.date}</Moment>
					</div>
				</td>
			),

		},
		{ field: "provider", title: "Provider", filter: true, width:160 },
		{ field: "type", title: "Wallet Type", filter: true , width:160},
		{ field: "walletCode", title: "Wallet Code", filter: true ,width:160},
		{ field: "value", title: "Value", filter: true,width:150, dataType: "number", filterType: "numeric" },
		{ field: "isConsider", title: "Is Consider", filter: true ,width:155},

	]
	handleInputChange = (prop, e) => {
		const rowChecked = prop.dataItem;
		const target = e.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		let { selection } = this.state;
		let idx = selection.indexOf(rowChecked.id);
		if (selection) {
			selection = [];
		}
		if (idx > -1) {
			selection.splice(idx, 1);
		} else {
			selection.push(rowChecked.id);
		}
		this.setState({
			...this.state,
			[name]: value,
			selectedObj: rowChecked,
			selection,errorMsg:null
		});
	};
	onActionClick = (key) => {
		const actions = {
			add: "add",
			edit: "edit",
		};
		this[actions[key]]();
	};
	add = () => {
		this.props?.history.push('/balances/00000000-0000-0000-0000-000000000000');
		this.props.dispath(setBreadcrumb({ key: `/balances/00000000-0000-0000-0000-000000000000`, val: "Add Balance" }));
	}
	edit = () => {
		if (this.state.selection.length === 0 || this.state.selection.length > 1) {
			this.setState({...this.state,errorMsg:"Please select the one record"});
		  return;
		}
		if(this.state.selectedObj.isConsider==="True"){
			this.setState({...this.state,errorMsg:"This record is already considered."});
			return;
		}
		if (!this.state.check) {
			this.setState({ alert: true })
			setTimeout(() => this.setState({ alert: false }), 1000)
		} else {
			const obj = this.state.selectedObj;
			const val = obj.id;
			this.props.history.push('/balances/' + val);
			this.props.dispath(setBreadcrumb({ key: `/balances/${obj.id}`, val: obj.provider }));
		}

	  }
	  balanceView = (e) =>{
		const items = e.dataItem;
		this.props.history.push(`/balances/view/${items.id}`);
		this.props.dispath(setBreadcrumb({ key: `/balances/view/${items.id}`, val: items.provider }));
	}
	render() {
		return (
			<>
			
		{this.state.errorMsg && <div style={{ width: '100%' }}><Alert className="mb-16 w-100" type="warning" description={this.state.errorMsg} showIcon/></div> }
				<div>
					<List
						showActionBar={true}
						onActionClick={(key) => this.onActionClick(key)}
						pKey={"balances"}
						ref={this.gridRef}
						url={process.env.REACT_APP_RECONCILE_GRID_API + `Reconcile/GetBalancesK`}
						columns={this.columns}
						className="maintable balances-table"
					/>
				</div>
			</>
		);
	}
}

const connectDispatchToProps = (dispath) => {
	return {
		dispath
	};
};
const connectStateToProps = ({ breadCrumb, }) => {
	return {
		breadCrumb,
	};
};

export default connect(connectStateToProps, connectDispatchToProps)(Balances);

