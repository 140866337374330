import React, { Component } from 'react';
import List from "../grid.component";
import {  Alert, Radio } from 'antd';
import { connect } from 'react-redux';
import { setBreadcrumb } from '../../reducers/breadcrumbReducer';
import { updateCurrentScreen } from "../../reducers/permissionsReducer";

class Documents extends Component {
    constructor (props) {
        super(props);
        this.state = {
            gridUrl: process.env.REACT_APP_GRID_API + "Documents",
            alert: false,
            errorMessage: "",
            allDocs: false,
            selection: []
        }
        this.gridRef = React.createRef();
    }
    details = ({ dataItem }) => {
        this.props.dispath(setBreadcrumb({ key: "/documents/" + dataItem.id, val: dataItem.fullName }))
        this.props.history.push({
            pathname: "/documents/" + dataItem.id,
            state: {
                pKey: "documents",
                action: 'edit'
            }
        })
    }
    gridColumns = [
        { field: "fullName", title: "Name", filter: true, customCell: (props) => <td><div className="gridLink" onClick={() => this.details(props)}>{props.dataItem?.fullName}</div></td> },
        { field: "userName", title: "User Name", filter: true, width: 200, },
        { field: "email", title: "Email", filter: true },
        { field: "date", title: "Requested Date", filter: true, filterType: "date" },
        { field: "title", title: "Title", filter: true },
        { field: "status", title: "Status", filter: true },
    ]
    componentDidMount() {
        this.props.dispath(updateCurrentScreen('documents'));
 
    }
   
    handleSelection = (dataItem) => {
        let _selection = [...this.state.selection];
        let _idx = this.state.selection.indexOf(dataItem.id);
        if (_idx > -1) {
            _selection.splice(_idx, 1);
        } else {
            _selection.push(dataItem.id);
        }
        this.setState({ ...this.state, selection: _selection });
    }
    handleAllDocuments = e => {
        this.setState({
            allDocs: e.target.value === 1
        }, () => this.gridRef.current.refreshGrid());


    }

    render() {
        const { gridUrl } = this.state;
        return (<>
            {this.state.alert &&
                <div className="custom-alert" ><Alert
                    message="Please select the one record"
                    type="warning"
                    showIcon
                    closable={false}
                />
                </div>}
            <div className="d-flex justify-content align-center">
                <Radio.Group
                    value={this.state.allDocs ? 1 : 2}
                    onChange={this.handleAllDocuments}
                    className="buysell-toggle">
                    <Radio.Button value={2}>My Documents</Radio.Button>
                    <Radio.Button value={1}>All</Radio.Button>

                </Radio.Group>
            </div>
            {this.state.allDocs && <List url={gridUrl} ref={this.gridRef} columns={this.gridColumns} />} 
            {!this.state.allDocs && <List url={gridUrl} ref={this.gridRef} columns={this.gridColumns} />}
                

        </>
        );
    }
}
const connectDispatchToProps = dispath => {
    return { dispath }
}
const connectStateToProps = ({ breadCrumb, userConfig }) => {
    return { breadCrumb, userProfileInfo: userConfig.userProfileInfo }
}
export default connect(connectStateToProps, connectDispatchToProps)(Documents);
