import React, { Component } from "react";
import List from "../grid.component";
import { Form,Col,Select,Button, Row,Alert} from "antd";
import { connect } from "react-redux";
import {getNameLu} from "../customers.component/api"
import apiCalls from "../../api/apiCalls";
const { Option } = Select;

class Referral extends Component {
	constructor(props) {
		super(props);
		this.state = {
			check: false,
			selection: [],
			selectedObj: {},
      errorMsg:null,
            searchObj: {
                memberName: "00000000-0000-0000-0000-000000000000"
              },
		};
        this.gridRef = React.createRef();
	}
	componentDidMount() {
		this.getNameLookup(null, null);
	}
	

    gridColumns = [
      {field: "joiningDate", title: "Joining Date", filter: true, filterType: "date",isShowTime: true,},
      { field: "name", title: "Business Name/Personal Name", filter: true},
      { field: "bonusEarned", title: "Bonus Earned", filter: false,dataType: "number", filterType: "numeric"},
      { field: "tradeIntiated", title: "Trade Initiated", filter: false, dataType: "boolean", filterType: "boolean" },
     ];
    getNameLookup = async (emails, memberName) => {
        let response = await getNameLu(
          emails === null ? null : emails,
          memberName === null ? null : memberName
        );
        if (response.ok) {
          if (memberName) {
            this.setState({ ...this.state, customerNames: response.data });
          } else {
            this.setState({ ...this.state, searchObj: { memberName: response.data[0].id }, customerNames: response.data,errorMsg:null });
          }
        }else{
          this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
        }
      };
    
      handleChange = (value, prop) => {
        var val = "";
        let { accounttypeData, searchObj } = this.state;
        if (prop === "customerType") {
          let index = accounttypeData.findIndex(function (o) {
            return o.name === value;
          });
          val = accounttypeData[index].name;
          searchObj[prop] = prop === "customerType" ? val : value;
          this.setState({ ...this.state, searchObj });
        } else {
          const obj = this.state.customerNames?.find((item) => item.name === value);
          searchObj[prop] = prop === "memberName" && obj.id 
          this.setState({ ...this.state, searchObj });
        }
      };
      handleSearch = (e, type) => {
        let sval = e.target.value;
        if (sval !== null && sval.length > 2) {
          if (type === "memberName") {
            this.getNameLookup(null, sval);
          }
        }
      };
      handlebtnSearch = () => {
        let { searchObj } = this.state;
        this.setState({ ...this.state, searchObj }, () => {
          this.gridRef.current.refreshGrid();
        });
      };
	
	render() {
		return (
			<>
      {this.state.errorMsg && <div style={{ width: '100%' }}>
					<Alert className="mb-16 w-100" type="warning"
						description={this.state.errorMsg} showIcon />
				</div>}
            <Row  style={{ alignItems: "flex-end" }}>
             <Col sm={24} md={7} className="px-8">
                <Form.Item
                  name="names"
                  className="input-label mb-0"
                  label="Business Name/Personal Name"
                >
                  <Select
                    className="cust-input w-80"
                    showSearch
                    onKeyUp={(e) => this.handleSearch(e, "memberName")}
                    onChange={(e) => this.handleChange(e, "memberName")}
                    placeholder="Select Business Name/Personal Name"
                  >
                    {this.state.customerNames?.map((item, idx) => {
                        if(item.name!== "All"){
                            return  <Option key={idx} value={item.name}>
                            {item.name} 
                          </Option>
                        }
                     
                     } )}
                  </Select>
                </Form.Item>
                
              </Col>
              <Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-right">
             
             <Button
               type="primary"
               className="primary-btn px-24 search-btn mt-20"
               htmlType="submit"
               onClick={this.handlebtnSearch}
             >Search
             </Button>
            
           </Col>
              </Row>
             
              <br/>
				<div>
					<List
						showActionBar={true}
						pKey={"Referral"}
						ref={this.gridRef}
                        columns={this.gridColumns}
                         additionalParams={this.state.searchObj}
						url={
							process.env.REACT_APP_GRID_API +
							`Partner/GetRefferalCodessK`
						}
                        showExcelExport={true}
                        

					/>
				</div>
				
			</>
		);
	}
}
const connectDispatchToProps = (dispath) => {
	return {
		dispath,
	};
};
const connectStateToProps = ({ breadCrumb }) => {
	return {
		breadCrumb,
	};
};

export default connect(connectStateToProps, connectDispatchToProps)(Referral);
