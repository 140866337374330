import React, { useEffect, useState } from 'react'
import { Result } from 'antd';
import { useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import apicalls from "../../api/apiCalls"
import Loader from '../loader.component';

const BankReportView = () => {
    let val = useParams();
    const [reportdata, setReportdata] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);
    useEffect(() => {
        if (!["contractagreement", "initialform"].includes(val.reportname)) {
            loadReport();
            setErrorMsg(false);
        }
        else {
            setIsLoading(false);
            setTimeout(() => {
                if(document.getElementById("jotform-async")){
                    document.getElementById("jotform-async").parentElement.removeChild(document.getElementById("jotform-async"));
                }
                loadIframe();
            }, 1000);
        }
    }, []);//eslint-disable-line react-hooks/exhaustive-deps
    const loadReport = () => {
        setIsLoading(true);
        apicalls.getEmbidedReport('EmbedReportAPI?reportname=' + (val?.reportname)).then((res) => {
            if (res.ok) {
                setIsLoading(false)
                setReportdata(res.data)
            } else {
                setErrorMsg(true)
            }
        })
    }
    function loadIframe(doc = document, id = "jotform-async") {
        var scripts = doc.getElementsByTagName("script")[0];
        if (!doc.getElementById(id)) {
            var script = doc.createElement("script");
            script.async = 1;
            script.id = id;
            script.src = "https://cdn.jotfor.ms/s/umd/latest/for-sheets-embed.js";
            scripts.parentNode.insertBefore(script, scripts);
        }
    }
    if (["contractagreement", "initialform"].includes(val.reportname)) {
        if (val.reportname === "contractagreement") {
            return <div className="jotform-table-embed" style={{width:"100%",height:600}}  data-id="231483298198065" data-iframesource="www.jotform.com" data-type="interactive"></div>
        } else {
            return <div className="jotform-table-embed" style={{width:"100%",height:600}} data-id="231443151170443" data-iframesource="www.jotform.com" data-type="interactive"></div>
        }
    }
    return (<>
        {isLoading && <Loader />}

        {reportdata &&
            <div className="report_container">
                <div className='powerbi-placeholder'></div>
                <PowerBIEmbed
                    embedConfig={{
                        type: reportdata.type,   // Supported types: report, dashboard, tile, visual and qna
                        id: reportdata.id,
                        embedUrl: reportdata.embedUrl,
                        accessToken: reportdata.accessToken.token,
                        tokenType: 1,
                        settings: {
                            filterPaneEnabled: false,
                        }
                    }}
                    cssClassName={val?.reportname?.toLowerCase().indexOf('dashboard') > -1 ? "dashboard-styles" : "report-styles"}
                />
            </div>}
        {errorMsg && <Result
            status="500"
            title="Report Failed"
            subTitle="Sorry, something went wrong."
        />}
    </>)

}

export default BankReportView
