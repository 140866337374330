import React, { Component } from 'react';
import List from "../grid.component";
import { Form, Row, Col, Select, Button, Alert, message, Modal, Tooltip, Input } from 'antd';
import { setCurrentAction } from "../../reducers/actionsReducer";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import { getAccountLu, getNameLu, evolveSaveStatusChange,pyrrosSaveStatusChange,oPenPaydStatus,saveOpenpydStatus} from './api';
import Loader from '../loader.component';
import { validateContentRule } from '../../utils/custom.validator';
import apiCalls from '../../api/apiCalls';
const { Option } = Select;
const { TextArea } = Input;

class BankCustomers extends Component {
  constructor (props) {
    super(props);
    this.state = {
      errorMsg: null,
      isLoading: false,
      selection: [],
      selectedObj: {},
      gridUrl: '',
      accounttypeData: [],
      searchObj: {
        customerType: "All",
        memberName: "00000000-0000-0000-0000-000000000000"
      },
      customerNames: [],
      stateChange: {},
      stateLoading: false,
      stateBtnLoading: false,
      stateErrorMsg: null,
      stateLu:[],
      selectedState:null,
      isAccountOpenModal:false,
      selectedkey:null,
      isAccountError:null,
      isAccountbtn:false,
      pKey:null,
      selectedAction:null
    }
    this.gridRef = React.createRef();
    this.formref = React.createRef();
  }
  openpaydGridColumns = [
    {
      field: "firstName",
      title: "First Name",
      filter: true,
      locked: true,
      width: 200,
      customCell: (props) => (
        <>
          {props?.dataItem?.isBusiness === false ?
            <div className="gridLink" onClick={() => this.update(props)}>
              {props?.dataItem?.firstName}
            </div> : <div>{" "} </div>}
        </>
      )
    },
    {
      field: "lastName", title: "Last Name", filter: true, width: 200, customCell: (props) => (
        <td>
          {props?.dataItem?.isBusiness === false ?
            <div className="gridLink" onClick={() => this.update(props)}>
              {props?.dataItem?.lastName}
            </div> : <div>{""} </div>}
        </td>
      )
    },
    {
      field: "businessName", title: "Business Name", filter: true, width: 200,
      customCell: (props) => (
        <td>
          {props?.dataItem?.isBusiness === true ?
            <div className="gridLink" onClick={() => this.update(props)}>
              {props?.dataItem?.businessName}
            </div> : <div>{""} </div>}
        </td>
      )
    },
    { field: "refernceId", title: "Customer/Contract ID", filter: true, width: 250 },
    { field: "email", title: "Email", filter: true, width: 200 },
    { field: "eurBankBalance", title: "EUR Balance", filter: true, width: 180,dataType: "number",
			filterType: "numeric", },
    { field: "gbpBankBalance", title: "GBP Balance", filter: true, width: 180 ,dataType: "number",
			filterType: "numeric",},
      { field: "pendingActions", title: "Pending Action", filter: true, width: 200 },

  ];
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <td>
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
            />
            <span></span>
          </label>
        </td>
      )
    },
    {
      field: "firstName",
      title: "First Name",
      filter: true,
    },
    { field: "refernceId", title: "Customer/Contract ID", filter: true, width: 250 },
    { field: "accounNumber", title: "IBAN Number", filter: true, width: 250 },
    { field: "email", title: "Email", filter: true, width: 200 },
    { field: "displayBalance", title: "Available Balance", filter: true, width: 170, dataType: "number", filterType: "numeric" },
    { field: "bankStatus", title: "Bank Status", filter: true, width: 200 },
    { field: "status", title: "Status", filter: true, width: 100 },
  ];
  componentDidMount = () => {
    const { pathname } = this.props?.location || {};
    let gridurl;
    let pKeyValue;
    if (pathname === '/pyrroscustomer') {
        gridurl = process.env.REACT_APP_GRID_API + "Bank/GetPyrossCustomerDetails";
        pKeyValue = "Pyrros Customers";
    } else if (pathname === '/openpaydcustomer') {
        gridurl = process.env.REACT_APP_GRID_API + "Bank/GetOpenpaydCustomerDetails";
        pKeyValue = "OpenPayd Customers";
    } else {
        gridurl = process.env.REACT_APP_GRID_API + "Bank/GetEvolveCustomerDetails";
        pKeyValue = "Evolve Customer";
    }
    this.setState(prevState => ({ ...prevState, gridUrl:gridurl, pKey:pKeyValue }));
    this.accountTypeSearch();
    this.getNameLookup(null, null);
  };
  handleCancel = (e) => {
    this.setState({
      ...this.state,
      isModal: false,
      selection: [],
      stateErrorMsg: null,
      errorMsg: null,
      selectedState:null
    });
  };
   getStatesLu=async()=>{
    let res = await apiCalls.getStates("customer",this.state.selectedObj.status)
    if(res.ok){
      this.setState({...this.state,stateLu:res.data})
    }else{
      this.setState({ ...this.state,stateErrorMsg: apiCalls.isErrorDispaly(res) });
    }
  }

  handleInputChange = (event, i) => {
    const rowChecked = event.dataItem;
    const target = i.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection: selection,
      errorMsg: null,
      stateErrorMsg: null,
    });
  };
  update = (e) => {
    this.props.dispath(setCurrentAction(null));
    const items = e.dataItem;
    const val = items.customerId;
    let pathName;
    const { pathname } = this.props?.location || {};
    if (pathname === '/pyrroscustomer') {
      pathName = "pyrroscustomer";
    } else if (pathname === '/openpaydcustomer') {
      pathName = "openpaydcustomer";
    } else {
      pathName = "evolvecustomer";
    }
    this.props.history.push(`/${pathName}/details/` + val + "/view");
    this.props.dispath(
      setBreadcrumb({
        key: `/${pathName}/details/` + val + "/view",
        val: items.isBusiness !== true ? (items.firstName + " " + items.lastName) : items.businessName
      })
    );
  }
  handleCheckbox = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 0);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      werningMsg: null
    });
  };

  onActionClick = (key) => {
    const actions = {
      "State Change": "statusChange",
      "Create Case": "createCase",
      "Freeze":this.isAccountModalOpen(key)
    };
    this[actions[key]]();
  };

  isAccountModalOpen=(key)=>{
    if (this.state.selection.length <= 0) {
      this.setState({
        ...this.state,
        errorMsg: "Please select the one record",
      });}else if(this.state?.selectedObj?.status !== "Approved" && this.state?.selectedObj?.status !== "Freezed"){
        this.setState({
          ...this.state,selection:[],
          errorMsg: "Only approved accounts can freeze/close",
        });
        window.scrollTo(0,0)
      }else{
        let action;
        if (key?.toLowerCase() === "close") {
            action = "Close";
        } else if (this.state.selectedObj?.bankStatus?.toLowerCase() === "freezed") {
            action = "Unfreeze";
        } else {
            action = "Freeze";
        }
        this.setState(prevState => ({ ...prevState, selectedAction:action,selectedkey:key,isAccountOpenModal:true,isAccountError:null }));
      }
  }
  customerStateChange = async (values) => {
    let stateObj = {customerId: "", id: "", state: "",ModifiedBy:''}
    this.setState({ ...this.state, stateBtnLoading: true });
    stateObj.id = this.state.selectedObj.id;
    stateObj.customerId = this.state.selectedObj.customerId;
    stateObj.state = values.status || this.state.selectedObj.status;
    stateObj.ModifiedBy = this.props?.userConfig.userName;
    if (values?.status === "Rejected") {
      stateObj.ReasonofCancellation = this.formref.current.getFieldValue("ReasonofCancellation");
      stateObj.remarks = ""
    }
    else if (values?.status === "Approved") {
      stateObj.remarks = this.formref.current.getFieldValue("Remarks");
      stateObj.ReasonofCancellation = ""
    }
    let response;
    if (this.props?.location?.pathname === '/evolvecustomer') {
      response = await evolveSaveStatusChange(stateObj);
    } else if (this.props?.location?.pathname === '/pyrroscustomer') {
      response = await pyrrosSaveStatusChange(stateObj)
    } else {
      response = await oPenPaydStatus(stateObj)
    }
    this.renderSuccessData(response,stateObj);
  };
  renderSuccessData=(response,stateObj)=>{
    if (response.ok) {
      this.success(stateObj.state);
      this.gridRef.current?.refreshGrid();
      this.setState({ ...this.state, stateBtnLoading: false, stateErrorMsg: null, selection: [], isModal: false, selectedState: null, isAccountOpenModal: false });
    } else {
      this.setState({ ...this.state, stateBtnLoading: false, stateErrorMsg: apiCalls.isErrorDispaly(response) });
    }
  }
  statusChange = () => {
    if (this.state.selection.length <= 0) {
      this.setState({
        ...this.state,
        errorMsg: "Please select the one record",
      });
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            alert: false,
          }),
        1000
      );
    } else {
      this.getStatesLu();
      this.setState(
        {
          ...this.state,
          isModal: true,
          stateChange: {
            status: this.state.selectedObj.status,
            ReasonofCancellation: this.state.selectedObj.status === "Approved" ? this.state.selectedObj?.remarks :  this.state.selectedObj?.reasonOfCancellation  ,
          }
        },
        () => {
          this.setState({
            ...this.state,
            stateLoading: true,
          });
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                stateLoading: false,
              }),
            1000
          );

          setTimeout(
            () =>
              this.formref.current.setFieldsValue({
                ...this.state,
                status: this.state.selectedObj.status,
                ReasonofCancellation: this.state.selectedObj.status === "Approved" ? this.state.selectedObj?.remarks :  this.state.selectedObj?.reasonOfCancellation  ,
              }),
            1000
          );
        }
      );
    }

  };
  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Reject":
        status = "Rejected";
        break;
        case "Rejected":
          status = "Rejected";
          break;
      case "Pending":
        status = "Pending";
        break;
        default:  return null;
    }
    message.success({
      content:
        "Record " +
        status +
        " successfully",
      className: "custom-msg",
      duration: 4
    });
  };
  stateChange = (val, prop) => {
    let { stateChange } = this.state;
    stateChange.status = val;
    this.setState({ ...this.state, stateChange,selectedState:val, stateErrorMsg: null });
    this.formref?.current?.setFieldsValue({
      ...this.state,
      ReasonofCancellation: null,
      Remarks:null
    })
  };

  accountTypeSearch = async (value, prop) => {
    let response = await getAccountLu();
    if (response.ok) {
      this.setState({
        accounttypeData: response.data
      });
    }
    else{
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response)});
    }
  };
  handleChange = (value, prop) => {
    var val = "";
    let { accounttypeData, searchObj } = this.state;
    if (prop === "customerType") {
      let index = accounttypeData.findIndex(function (o) {
        return o.accountType === value;
      });
      val = accounttypeData[index].accountType;
      searchObj[prop] = prop === "customerType" ? val : value;
      this.setState({ ...this.state, searchObj });
    } else {
      const obj = this.state.customerNames?.find((item) => item.name === value);
      searchObj[prop] =  obj?.customerId  ? obj?.customerId : null;
      this.setState({ ...this.state, searchObj });
    }
  };
  getNameLookup = async (emails, memberName) => {
    let bankType;
    const { pathname } = this.props?.location || {};
    if (pathname === '/pyrroscustomer') {
      bankType = 'PyrrosUserNames';
    } else if (pathname === '/openpaydcustomer') {
      bankType = 'OpenpaydUserNames';
    } else {
      bankType = 'EvolveUserNames';
    }
    let response = await getNameLu(
      emails === null ? null : emails,
      memberName === null ? null : memberName,
      bankType
    );
    if (response.ok) {
      if (memberName) {
        this.setState({ ...this.state, customerNames: response.data });
      } else {
        this.setState({ ...this.state, searchObj: { customerType: "All", memberName: response.data[0].customerId }, customerNames: response.data });
      }
    }
    else{
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response)});
    }
  };
  handleSearch = (e, type) => {
    let sval = e.target.value;
    if (sval !== null && sval.length > 2) {
      if (type === "memberName") {
        this.getNameLookup(null, sval);
      }
    }
  };

  handlebtnSearch = () => {
    let { searchObj } = this.state;
    this.setState({ ...this.state, searchObj, selection: []}, () => {
      this.gridRef.current.refreshGrid();
    });
  };
  handleCloseAccModal=()=>{
    this.setState({...this.state,isAccountOpenModal:false,selection: [],selectedAction:null});
  }
  handleSaveAccount=async()=>{
    this.setState({...this.state,isAccountbtn:true});
    let obj;
    if(this.state?.selectedkey=="disable"){
       obj={
        id:this.state.selectedObj?.id,
        state:this.state.selectedObj.bankStatus?.toLowerCase()=="freezed" ? "UNFREEZE": "FREEZE" ,//: this.state?.selectedkey
        ModifiedBy : this.props?.userConfig.userName,
      }
    }else{
       obj={
        id:this.state.selectedObj?.id,
        state:"CLOSE",
        ModifiedBy : this.props?.userConfig.userName,
      }
    }
   
    let res= await saveOpenpydStatus(obj);
    if(res.ok){
      message.success({
          content: `Account ${this.state.selectedAction}d successfully`,
          className: "custom-msg",
          duration: 3
      });
      this.setState({...this.state,isAccountOpenModal:false,isAccountbtn:false,selection: []})
      setTimeout(()=>{
        this.gridRef.current.refreshGrid()
      },2000)
    }else{
      this.setState({...this.state,isAccountError:apiCalls.isErrorDispaly(res),isAccountbtn:false});
    }
  }
  renderAlertMsgData = (errorMsgData) => {
   return errorMsgData !== undefined && errorMsgData !== null && (
      <div style={{ width: '100%' }}>
        <Alert
          className="w-100 mb-16 align-center"
          type="warning"
          description={errorMsgData}
          showIcon
        />
      </div>
    )
  }
  isField1Disabled(disabledStatuses) {
    const { selectedObj } = this.state;
    return disabledStatuses.includes(selectedObj?.status?.toLowerCase());
}
  render() {
    const { gridUrl, accounttypeData, searchObj, isLoading, errorMsg, stateChange, stateErrorMsg,isAccountError,selectedAction } = this.state;
    const options = accounttypeData.map((d) => (
      <Option key={d.accountType} value={d.accountType}>
        {d.accountType}
      </Option>))
     const pathName=window.location.pathname.split('/')[1]
    return (
      <>
      {this.renderAlertMsgData(errorMsg)}
        <div>
          <Form
            initialValues={this.state.accounttypeData}
            className="ant-advanced-search-form form form-bg search-bg pt-8"
            autoComplete="off"
          >
            <Row style={{ alignItems: "flex-end" }}>
              <Col sm={24} xs={24} md={7} className="px-8">
                <Form.Item
                  name="customerType"
                  className="input-label mb-0"
                  label="Account Type"
                >
                  <Select
                    defaultValue="All"
                    className="cust-input w-100"
                    showSearch
                    onChange={(e) => this.handleChange(e, "customerType")}
                    placeholder="Select Account Type"
                  >
                    {options}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={7} className="px-8">
                <Form.Item
                  name="names"
                  className="input-label mb-0"
                  label="Name"
                >
                  <Select
                    className="cust-input w-80"
                    showSearch
                    defaultValue="All"
                    onKeyUp={(e) => this.handleSearch(e, "memberName")}
                    onChange={(e) => this.handleChange(e, "memberName")}
                    placeholder="Select Name"
                  >
                    {this.state.customerNames?.map((item, idx) => (
                      <Option key={idx} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={3} className="px-8 text-right">
                <Button
                  type="primary"
                  className="primary-btn px-24 search-btn mt-20"
                  htmlType="submit"
                  onClick={this.handlebtnSearch}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <List
            showActionBar={true}
            onActionClick={pathName !=='openpaydcustomer' && ((key) => this.onActionClick(key))}
            pKey={this.state.pKey}
            additionalParams={searchObj}
            key={gridUrl}
            url={gridUrl}
            ref={this.gridRef}
            columns={ pathName ==='openpaydcustomer' ? this.openpaydGridColumns : this.gridColumns}
            showExcelExport={true}
            pSize={50}
            className="customer-table-header table-checkalign"
          />
        )}
         <Modal
          title={`Confirm ${selectedAction}?`}
          className="custom-width text-break"
          visible={this.state.isAccountOpenModal}
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={()=>this.handleCloseAccModal()}
              />
            </Tooltip>
          }
          footer={<div className="text-right">
          <Button
            type="primary"
            className="primary-btn cancel-btn mr-8"
            onClick={()=>this.handleCloseAccModal()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            key="submit"
            className="primary-btn"
            htmlType="submit"
            onClick={()=>this.handleSaveAccount("Freeze")}
            loading={this.state.isAccountbtn}
          >
            Save
          </Button>
  </div>}
  
        >
          {this.renderAlertMsgData(isAccountError)}
          <p>Do you really want to {selectedAction?.toLowerCase()} the account  ?</p>
        </Modal>
        <Modal
          title={"Confirm Pending/Approve/Reject?"}
          className="custom-width text-break"
          visible={this.state.isModal}
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={this.handleCancel}
              />
            </Tooltip>
          }
          footer={null}
        >
          {this.renderAlertMsgData(stateErrorMsg)}
          <Form ref={this.formref}
            onFinish={this.customerStateChange}>
            <Row gutter={24} className="mb-24 pb-24 border-bottom">
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  className="input-label"
                  rules={[
                    {
                      required: true,
                      message: "Is required"
                    }
                  ]}
                >

                  <Select
                    className="cust-input mb-0"
                    onChange={(e) => this.stateChange(e)}
                    disabled={this.isField1Disabled(["approved","rejected","closed","Closed","freezed"])}
                    placeholder="Select State"
                  >
                    {this.state?.stateLu?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
              {(this.state.selectedObj.status === "Rejected" || stateChange.status === "Rejected") &&(
              <Col xs={24}>
                  <Form.Item
                    name="ReasonofCancellation"
                    className="input-label"
                    label= "Reason For Rejection"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      },
                      {
                        validator:validateContentRule
                      }
                    ]}
                    >
                    <TextArea
                      placeholder= "Reason For Rejection"
                      maxLength={100}
                      rows={4}
                      showCount
                      disabled={this.isField1Disabled(["approved","rejected"])}
                    />
                  </Form.Item>
              </Col>)}
            </Row>
            <div>
              {this.state.stateLoading && <Loader />}
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  {(this.state.selectedObj.status !== "Rejected" && this.state.selectedObj.status !== "Approved" && this.state.selectedObj.status !== "Closed" && this.state.selectedObj.status !== "Freezed") &&
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    disabled={!this.state?.selectedState}
                    loading={this.state.stateBtnLoading}
                  >
                    Save
                  </Button>}
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </>
    )
  }
}
const connectStateToProps = ({ oidc, userConfig }) => {
  return {
    oidc, userConfig: userConfig?.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData
  };
};
const connectDispatchToProps = (dispath) => {
  return {
    dispath
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(BankCustomers)