import React, { Component } from "react";
import List from "../../grid.component";
import { Alert, Modal, message, Form, Button,Tooltip } from "antd";
import { setBreadcrumb } from "../../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import { deleteCommission } from "../api";
import apiCalls from '../../../api/apiCalls';
import Moment from "react-moment";
import config from "../../../config";
class NewCommission extends Component {
	constructor (props) {
		super(props);
		this.state = {
			check: false,
			selection: [],
			selectedObj: {},
			warningMsg: null,
			openModal: false,
			deleteShowTemplate: false,
			loadingBtn: false,
			deleteErrorMessage: null
		};
		this.gridRef = React.createRef();
	}
	columns = [
		{
			field: "",
			title: "",
			width: 30,
			locked: true,
			customCell: (props) => (
				<td className="text-center">
					<label className="text-center custom-checkbox">
						<input
							id={props.dataItem.id}
							name="check"
							type="checkbox"
							checked={this.state.selection.indexOf(props.dataItem.id) > -1}
							onChange={(e) => this.handleInputChange(props, e)}
							className="grid_check_box"
						/>
						<span></span>
					</label>
				</td>
			),
		},
		{
			field: "templateName", title: "Template Name", filter: true, width: 150,
			customCell: (props) => (
				<td>
					<div className="gridLink" onClick={() => this.redirectToAddCommission(props)}>
						{props.dataItem.templateName}
					</div></td>
			)
		},
		{
			field: "userCreated", title: "Creator", filter: true, width: 150,
			customCell: (props) => (
				<td>
					{props.dataItem.userCreated}
				</td>
			)
		},
		{
			field: "createdDate",
			title: "Date",
			filter: true,
			width: 240,
			isShowTime: true,
			filterType: "date",
			customCell: (props) => (
				<td>
					{props.dataItem?.createdDate && <Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem?.createdDate && apiCalls.convertUTCToLocalTime(props.dataItem?.createdDate) || props.dataItem?.createdDate}</Moment> || props.dataItem?.createdDate}
				</td>
			),
		},
	]
	handleInputChange = (prop, e) => {
		const rowChecked = prop.dataItem;
		const target = e.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		let { selection } = this.state;
		let idx = selection.indexOf(rowChecked.id);
		if (selection) {
			selection = [];
		}
		if (idx > -1) {
			selection.splice(idx, 1);
		} else {
			selection.push(rowChecked.id);
		}
		this.setState({
			...this.state,
			[name]: value,
			selectedObj: rowChecked,
			selection,
			warningMsg: null
		});
	};
	redirectToAddCommission = (e) => {
		const items = e.dataItem;
		const val = (items.id);
		this.props.history.push('/commissions/addCommissionsView/' + val);
		this.props.dispath(setBreadcrumb({ key: `/commissions/addCommissionsView/${val}`, val: `${e.dataItem.templateName}` }));
	}

	addCommission = () => {
		this.props.history.push(`commissions/addCommission/00000000-0000-0000-0000-000000000000/add/addcommisions`)
		this.props.dispath(setBreadcrumb({ key: `commissions/addCommission/00000000-0000-0000-0000-000000000000/add/addcommisions`, val: `Add Commissions` }));
	}

	editCommission = () => {
		if (!this.state.check) {
			this.setState({ errorMessage: null, warningMsg: "Please select the one record", visibleModel: false, error: null, });
		}
		else {
			this.props.history.push(`commissions/editCommissions/${this.state.selectedObj.id}`)
			this.props.dispath(setBreadcrumb({ key: `/commissions/editCommissions/${this.state.selectedObj.id}`, val: `${this.state.selectedObj.templateName}` }));
		}
	}
	commissionActionsNew = (key) => {
		const action = {
			add: "addCommission",
			edit: "editCommission",
			disable: "statusUpdate",
			Delete: "deleteCommission",
			Tier: "tierCommissions"
		};
		this[action[key]]();
	};
	tierCommissions = () => {
		if (!this.state.check) {
			this.setState({ errorMessage: null, warningMsg: "Please select the one record", visibleModel: false, error: null, });
		}
		else {
			this.props.history.push(`commissions/commissionsTier/${this.state.selectedObj.id}/Tier`)
			this.props.dispath(setBreadcrumb({ key: `/commissions/commissionsTier/${this.state.selectedObj.id}`, val: `${this.state.selectedObj.templateName} / Tier` }));
		}
	}
	addCommission = () => {
		this.props.history.push(`commissions/addCommission/00000000-0000-0000-0000-000000000000/add`)
		this.props.dispath(setBreadcrumb({ key: "/commissions/addCommission/" + "00000000-0000-0000-0000-000000000000/add", val: "Add Commissions" }));
	}

	deleteCommission = async () => {

		const { selectedObj } = this.state;
		if (!this.state.check) {
			this.setState({ errorMessage: null, warningMsg: "Please select the one record", visibleModel: false, error: null, });
		}
		else {
			if (selectedObj.templateName !== "Default") {
				this.setState({ ...this.state, deleteShowTemplate: true })
			} else {
				this.setState({ ...this.state, openModal: true });
			}
		}

	}
	deleteSelectedCommission = async () => {
		const { selectedObj } = this.state;
		if (selectedObj.templateName !== "Default") {
			this.setState({ ...this.state, loadingBtn: true, deleteErrorMessage: null })
			let res = await deleteCommission(selectedObj?.id);
			if (res.ok) {
				this.gridRef?.current.refreshGrid();
				this.setState({ ...this.state, deleteShowTemplate: false, loadingBtn: false, check: false, selection: [], selectedObj: {} })
				message.success({
					content: 'Template deleted successfully',
					className: "custom-msg",
					duration: 3
				})
			} else {
				this.setState({ ...this.state, deleteErrorMessage: apiCalls.isErrorDispaly(res), deleteShowTemplate: true, loadingBtn: false, check: false, selection: [] })
			}
		}

	}
	handleCancel = () => {
		this.setState({ openModal: false, check: false, deleteErrorMessage: null, selection: [], selectedObj: {} })
	};
	handleModalClose = () => {
		this.setState({ deleteShowTemplate: false, warningMsg: null, deleteErrorMessage: null, check: false, selection: [], selectedObj: {} })
	}
	onClose = () => {
		this.setState({ ...this.state, warningMsg: null })
	}
	handleModalCloseed=()=>{
		this.setState({...this.state,openModal:false})
	}
	render() {
		const { warningMsg, deleteErrorMessage } = this.state;
		return (<>
			<div>
				{warningMsg !== undefined && warningMsg !== null && (
					<Alert
						className="w-100 mb-16"
						type="warning"
						description={warningMsg}
						showIcon
					/>
				)}
				<List
					showActionBar={true}
					url={process.env.REACT_APP_GRID_API + `Commissions/Commission`}
					columns={this.columns}
					ref={this.gridRef}
					className="maintable balances-table"
					onActionClick={(key) => this.commissionActionsNew(key)}
				/>
				<Modal
				    title="Info"
					className="crypto-list"
					visible={this.state.openModal}
					closeIcon={
						<Tooltip title="Close">
						  <span className="icon md x c-pointer" onClick={this.handleCancel} />
						</Tooltip>
					  }
					footer={<Button
						type="primary"
						className="primary-btn cancel-btn"
						onClick={this.handleCancel}
					>
						Cancel
					</Button>}
				>
					    <div>
							<p className="mb-16">The Default Template cannot be deleted</p>
						</div>
				</Modal>
			</div>
			<Modal
				title="Confirm Delete"
				visible={this.state.deleteShowTemplate}
				className="crypto-list"
				closeIcon={
					<span onClick={this.handleModalClose}
						className="icon md x c-pointer"
					/>
				}
				destroyOnClose={true}

				footer={ <Form.Item className="text-right mb-0">
				<Button
					type="primary"
					className="primary-btn cancel-btn reject-btn"
					onClick={this.handleModalClose}
				>
					No
				</Button>
				<Button
					type="primary"
					className="primary-btn ml-8"
					htmlType="submit"
					loading={this.state.loadingBtn}
					onClick={this.deleteSelectedCommission}
				>
					Yes
				</Button>
			</Form.Item>}
			>
				<div>
					{deleteErrorMessage !== undefined && deleteErrorMessage !== null && (
						<Alert
							className="w-100 mb-16"
							type="error"
							description={deleteErrorMessage}
							onClose={this.handleModalClose}
							showIcon
						/>
					)}
					<Form
						className="risk-popup"
					>
						<div>
							<p className="mb-16">Are you sure, do you really want to delete ?</p>
						</div>
						
					</Form>
				</div>
			</Modal>
		</>)
	}
}
const connectDispatchToProps = (dispath) => {
	return {
		dispath
	};
};
const connectStateToProps = ({ breadCrumb, }) => {
	return {
		breadCrumb,
	};
};

export default connect(connectStateToProps, connectDispatchToProps)(NewCommission);