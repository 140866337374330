

import React, { Component } from 'react';
import { Row, Col,message } from 'antd';
import {getAlert} from "./api";
import Loader from '../loader.component';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { setCurrentAction } from '../../reducers/actionsReducer';
import {redirectToCaseView} from '../../utils/caseRediractions';
import { Link } from "react-router-dom";
import apiCalls from '../../api/apiCalls';
import config from '../../config';
class AlertDetails extends Component {
    constructor() {
        super();
        this.state = {
          alertObject:{},
          loading: false,
        };
        this.formRef = React.createRef();
      }
    componentDidMount = () => {
     this.loadData();
      };
      loadData = async () => {
        this.setState({...this.state, loading:true})
        let response = await getAlert(this.props.match.params.id);
        if (response.ok) {
          this.setState({...this.state,alertObject:response.data, loading: false})
        }else {
            message.destroy()
            message.error({
                content:apiCalls.isErrorDispaly(response),
                className: 'custom-msg',
                duration: 0.5
            });
        }
    }

   
    redirectCaseView = (caseData) => {
        redirectToCaseView(caseData,this.props)
	  };

    render() {
  const {alertObject,loading}=this.state;
        return (
            <div className='bank-view'>
            {alertObject && (
    <Row className="kpi-List">
        {loading && <Loader />}
        {[
            { label: "Alert Number", value: alertObject.alertNumber,className:"acnum" },
            { label: "Alert Title", value: alertObject.alert,className:"Alert Title" },
            { label: "Feature", value: alertObject.feature,className:"Features" },
            { label: "Customer Name", value: alertObject.name,className:"user" },
            { label: "Customer Email", value: alertObject.email,className:"email" },
            { label: "Type", value: alertObject.type,className:"Type" },
            { label: "Transaction ID", value: alertObject.transactionId,className:"Transaction Id" },
            { label: "Date", value: <Moment format={config?.dateFormates?.dateFormate}>{alertObject.createdDate}</Moment>,className:"date" },
            { label: "Created By", value: alertObject.createdBy,className:"userby" },
            { label: "Modified By", value: alertObject.modifiedBy,className:"userby" },
            { label: "Alert Description", value: alertObject.description,className:"description" },
            { label: "Remarks", value: alertObject.remarks,className:"file" },
        ]?.map((item, index) => (
            <Col key={index} xs={24} sm={24} md={12} lg={8} xxl={6}>
                <span className={`icon md ${item.className}`} />
                <div>
                    <label className="kpi-label">{item.label}</label>
                    <div className="kpi-val">{item.value || "-"}</div>
                </div>
            </Col>
        ))}
        {alertObject.caseIds !== null && (
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                <span className="icon md case" />
                <div>
                    <label className="kpi-label d-block">Case Number</label>
                    <div className="fw-600 fs-14">
                        {alertObject?.caseIds?.map(item => (
                            <Link key={item.caseNumber} className="c-pointer" onClick={() => this.redirectCaseView(item)}>
                                {item.caseNumber}<br />
                            </Link>
                        ))}
                    </div>
                </div>
            </Col>
        )}
    </Row>
)}

                       
                {!alertObject && <div>No bank details available</div>}

            </div>
        )
    }
}
const connectStateToProps = ({ userConfig }) => {
    return {userConfig: userConfig.userProfileInfo}
}
const connectDispatchToProps = dispatch => {
    return {
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}

export default connect(connectStateToProps, connectDispatchToProps)  (AlertDetails);

