import React from "react";
import { Tooltip, message, Modal, Button, Alert } from "antd";
import { connect } from "react-redux";
import List from "../grid.component";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import {activeInactive} from "./api"

class UsersComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      gridUrl: process.env.REACT_APP_GRID_API + "Security/Users",
      selection: [],
      alert: false,
      selectedObj: {},
      isCheck: false,
      obj: {
        id: [],
        tableName: "Security.UserRole",
       // modifiedBy: "",
        status: []
      },
      btnDisabled: false,
      warningMsg: null,
      isuserLoading: false
    };
    this.gridRef = React.createRef();
  }

  handleSelectionChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      warningMsg: null
    });
  };

  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <td className="text-center">
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleSelectionChange(props, e)}
            />
            <span></span>
          </label>
        </td>
      )
    },
    {
      field: "firstName",
      title: "First Name",
      locked: true,
      width:'250px',
      filter: true,
      customCell: (props) => (
        <td>
          <div className="gridLink" onClick={() => this.update(props)}>
            {" "}
            {props.dataItem.firstName}
          </div>
        </td>
      )
    },
    { field: "lastName", title: "Last Name", filter: true, width:'300px' },
    { field: "userName", title: "User Name", filter: true, width:'300px'},
    { field: "email", title: "Email", filter: true, width:'300px' },
    { field: "roleName", title: "Role", filter: true, width:'250px'},
    { field: "status", title: "Status", filter: true, width:'250px' }
  ];


  addUser = () => {
    this.props.history.push("/users/00000000-0000-0000-0000-000000000000");
    this.props.dispatch(
      setBreadcrumb({
        key: "/users/" + "00000000-0000-0000-0000-000000000000",
        val: "Add User"
      })
    );
    
  };
  update = (e) => {
    const items = e.dataItem;
    const val = items.id;
    this.props.dispatch(
      setBreadcrumb({ key: "/users/" + val, val: items.userName })
    );
    this.props.history.push("/users/" + val + "/view");
  
  };
  editUser = () => {
    if (!this.state.check) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record' })
    } else {
      const obj = this.state.selectedObj;
      const val = obj.id;
      if (obj.status === "Inactive") {
        this.props.history.push("/users/" + val + "/view");
        this.props.dispatch(
          setBreadcrumb({ key: "/users/" + val, val: obj.userName })
        );
      } else {
        this.props.history.push("/users/" + val);
        this.props.dispatch(
          setBreadcrumb({ key: "/users/" + val, val: obj.userName })
        );
      }
    }
    
  };
  statusUpdate = () => {
    if (!this.state.check) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record' })
    } else {
      this.setState({ ...this.state, modal: true });
    }
  };
  success = () => {
    message.success({
      content:
        "Record " +
        (this.state.selectedObj.status === "Active"
          ? "deactivated"
          : "activated") +
        " successfully",
      className: "custom-msg",
      duration: 4
    });
  };
  handleSatatuSave = async () => {
    this.setState({ ...this.state, isLoading: true, btnDisabled: true, isuserLoading: true });
    let statusObj = this.state.obj;
    statusObj.id.push(this.state.selectedObj.id);
    statusObj.modifiedBy = this.props.userConfig?.userName;
    statusObj.status.push(this.state.selectedObj.status);
    statusObj.info = JSON.stringify(this.props.trackAuditLogData);
    statusObj.customerId = this.props.userConfig?.id;
    let response = await activeInactive(statusObj);
    if (response.ok) {
      this.props.dispatch(setCurrentAction(null));
      this.success();
      this.setState({
        ...this.state,
        modal: false,
        selection: [],
        check: false,
        isLoading: false,
        btnDisabled: false,
        isuserLoading: false
      });
      this.gridRef.current.refreshGrid();
    }
  };
  handleCancel = (e) => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({ ...this.state, modal: false, selection: [], check: false });
  };
  onActionClick = (key) => {
    const actions = {
      add: "addUser",
      edit: "editUser",
      disable: "statusUpdate"
    };
    this[actions[key]]();
  };
  render() {
    const { gridUrl, warningMsg } = this.state;
    return (
      <>
        {warningMsg !== undefined && warningMsg !== null && (
          <Alert
            className="w-100 mb-16"
            type="warning"
            description={warningMsg}
            showIcon
          />
        )}
        <div>
          <List
            showActionBar={true}
            onActionClick={(key) => this.onActionClick(key)}
            pKey={"users"}
            url={gridUrl}
            ref={this.gridRef}
            columns={this.gridColumns}
          />

        </div>
        <Modal
          title={
            this.state.selectedObj.status === "Active"
              ? "Confirm Deactivate?"
              : "Confirm Activate?"
          }
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.handleCancel}
              >
                No
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                onClick={this.handleSatatuSave}
                loading={this.state.isuserLoading}
              >
                Yes
              </Button>
            </>
          }
        >
          <p className="fs-16 mb-0">
            Do you really want to{" "}
            {this.state.selectedObj.status === "Active"
              ? "deactivate?"
              : "activate?"}
          </p>
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return {
    breadCrumb,
    oidc,
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(UsersComponent);
