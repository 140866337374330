import React from "react";
import { Modal, Tooltip, Button} from "antd";

const ComissionCheckBoxModal=(props)=>{
   
    return (
        <>
        <Modal
          title="Error Message"
          visible={props?.showModal}
          className="crypto-list"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close " onClick={props?.handleCloseModal}>
              <span
                className="icon md x c-pointer"
              />
            </Tooltip>
          }
          footer={<>
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() => props?.handleCloseModal()}
            >
              Cancel
            </Button>
          </>}>
          <p className="fs-16 mb-0">{props?.errorMessage}</p>
        </Modal> 
        </>
    )
}
export default ComissionCheckBoxModal;