
import { Alert, Button, Modal, Tooltip, Typography } from "antd";
import React ,{ useState } from "react";
import List from "../grid.component";
import { getWalletDetails } from "./api";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import Loader from "../loader.component";
import PropTypes from "prop-types";

const WalletsDetials = () => {
    const [isModal, setIsModal] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [isError, setIsError] = useState(null);
    const [getWallets, setGetWallets] = useState([]);
    const customerId=window.location.pathname.split("/")[3]
    const gridRef = React.createRef();
    const { Paragraph } = Typography;
    
    const getWalletView = async (props) => {
        setIsModal(true);
        setIsLoader(true);
        setIsError(null);
      try{
        const res = await getWalletDetails(props?.dataItem?.id);
        if (res.ok) {
          setGetWallets(res.data);
          setIsLoader(false);
          setIsError(null);
        } else {
          setIsError(apiCalls.isErrorDispaly(res));
          setIsLoader(false);
        }
      }
      catch(error){
        setIsError(error)
        setIsLoader(false);
      }
      };
    const gridWallets = [
        {
          field: "",
          title: "",
          width: 220,
          customCell: (props) => (
            <td>
              <img
                src={props.dataItem.impagePath}
                alt="coins"
                width="36"
                height="36"
                style={{ marginRight: 16 }}
              />
              {props.dataItem.walletCode}
            </td>
          )
        },
        { field: "walletName", title: "Wallet Name", filter: true, width: 240 },
        { field: "type", title: "Type", filter: true, width: 100 },
        { field: "walletaddress", title: "Wallet Address", filter: true, width: 480, 
          customCell: (props) => (
            <td>
              <div className="d-flex">
                <div className="batch-excel"><span>
                  {props?.dataItem?.walletaddress && <Paragraph className="" >{props?.dataItem?.walletaddress}</Paragraph>}
                </span></div>
           {props.dataItem?.type!=="Fiat" &&  <div><Tooltip title="Wallet Details">
            <button className="icon md info c-pointer ml-8 btn-transparent" onClick={()=>getWalletView(props)}></button></Tooltip></div>}</div>
            </td>
        ) },
        {
          field: "availableAmount",
          title: "Available Amount",
          filter: true,
          width: 240,
          dataType: "number",
          filterType: "numeric"
        },
        {
          field: "coinValueinNativeCurrency",
          title: "Amount in USD",
          filter: true,
          width: 240,
          dataType: "number",
          filterType: "numeric"
        }
      ];

      const ModalClose=()=>{
        setIsModal(false)
      }
    return (
        <>
			{isError && <div style={{ width: '100%' }}>
				<Alert
					className="w-100 mb-16 align-center mt-0"
					type="warning"
					description={isError}
					showIcon
				/>
			</div>}
            <List
                url={process.env.REACT_APP_GRID_API + "Customer/Wallets"}
                ref={gridRef}
                ignoreSaved={true}
                 additionalParams={{ customerid:customerId }}
                columns={gridWallets}
                className="customerwallets-grid"
            />

            <Modal
            title={<p> Wallet Details</p>}
            visible={isModal}
            className="crypto-list"
            destroyOnClose={true}
            width={600}
            closeIcon={
              <Tooltip title="Close ">
                <button className="icon md x c-pointer btn-transparent" onClick={ModalClose}></button>
              </Tooltip>
            }
            footer={
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={ModalClose}>
                Close
              </Button>
            }
          >
            
             {isError && <div style={{ width: '100%' }}>
				<Alert
					className="w-100 mb-16 align-center mt-0"
					type="warning"
					description={isError}
					showIcon
				/>
			</div>}
            {isLoader ? <Loader/> : <table className="commision-table table-border edit-commition-table table-responsive" border="2">
              <tr>
                <th>Network</th>
                <th>Wallet Address</th>
              </tr>
              {getWallets.map((item) => {
                return (
                  <tr key={item?.id}>
                    <td>{item.network}</td>
                    <td><Paragraph className="mb-0" copyable>{item.walletAddress}</Paragraph></td>
                  </tr>
                );
              })}
            </table>}
          </Modal>
          </>
    )
}

const connectStateToProps = ({
    customerStore,
    userConfig,
    permissions
  }) => {
    return {
      permissions,
      customerStore,
      userConfig: userConfig.userProfileInfo,
      trackAuditLogData: userConfig.trackAuditLogData
    };
  };

  WalletsDetials.propTypes = {
    dataItem: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        impagePath: PropTypes.string,
        walletCode: PropTypes.string,
        walletaddress: PropTypes.string,
        type: PropTypes.string,
    }),
};
export default connect(
    connectStateToProps
  )(WalletsDetials);