import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";

const excelExport = (obj) => {
    return clientApi.put(ApiControllers.massPayment + `ExcelExportTransaction`,obj);
}
const stateSave = (obj) => {
    return clientApi.put(ApiControllers.massPayment + "BatchPayments/Status", obj);
}
const getStates = (status) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${"Batch payments"}/${status}`
	);
};
export {excelExport,stateSave,getStates}