
import React, { Component } from "react";
import { connect } from "react-redux";
import List from "../../grid.component";

class DeductionTransaction extends Component {
  constructor (props) {
    super(props);
    this.state = {
      searchObj: {
        type: "All",
        docType: "All",
        customerId: this.props?.searchId,
      },
      gridUrl: process.env.REACT_APP_GRID_API + "Transaction",

    };
    this.gridRef = React.createRef();
  }
  gridColumns = [
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      locked: true,
      width: 210,

    },

    {
      field: "date",
      title: "Submission Date",
      filter: true,
      width: 210,
      isShowTime: true,
      filterType: "date",
    },
    {
      field: "approvedDate",
      title: "Approval Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: false,
      width: 210,
    },
    { field: "status", title: "Status", filter: true, width: 160 },
    { field: "docType", title: "Transaction", filter: true, width: 120 },
    {
      field: "fromWalletCode",
      title: "From Wallet Code",
      filter: true,
      width: 180
    },
    {
      field: "fromValue",
      title: "From Value",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "toWalletCode",
      title: "To Wallet Code",
      filter: true,
      width: 150
    },
    {
      field: "toValue",
      title: "To Value",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "effectiveFee",
      title: "Fees",
      filter: true,
      width: 220,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "comission",
      title: "Commission",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "comissionInBase",
      title: "Commission in Base",
      width: 200,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "providerComission",
      title: "Provider Commission",
      width: 200,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "providerComissionInBase",
      title: "Provider Commission in Base",
      width: 240,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "sweepsourceComissionValue",
      title: "Sweep Source Commission",
      filter: true,
      width: 250,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "sweepSourceValueInBase",
      title: "Sweep Source in Base",
      filter: true,
      width: 250,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "sweepDestinationComissionValue",
      title: "Sweep Destination Commission",
      filter: true,
      width: 260,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "sweepDestinationValueInBase",
      title: "Sweep Destination in Base",
      filter: true,
      width: 260,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "totalAmount",
      title: "Total Cost",
      filter: true,
      width: 130,
      dataType: "number", filterType: "numeric"
    },
    {
      field: "profit",
      title: "Profit",
      width: 130,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "profitInBase",
      title: "Profit in Base",
      width: 160,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "provider",
      title: "Provider",
      filter: true,
      width: 160
    },
    {
      field: "fromValueBefore",
      title: "From Before Value",
      width: 180,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "fromValueAfter",
      title: "From After Value",
      width: 180,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "toValueBefore",
      title: "To Before Value",
      width: 180,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "toValueAfter",
      title: "To After Value",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
  ];

  render() {
    const { gridUrl, searchObj } =
      this.state;

    return (
      <>
        <List
          showActionBar={true}
          url={gridUrl}
          pKey={"Transactions"}
          additionalParams={searchObj}
          ref={this.gridRef}
          key={gridUrl}
          columns={this.gridColumns}
          showExcelExport={true}
          className="transaction-table-header"
        />

      </>
    );

  }
}

const connectStateToProps = ({ oidc, userConfig }) => {
  return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(DeductionTransaction);
