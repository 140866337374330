import React, { Component } from "react";
import {
	Typography,
	Radio, Form, Row, Col, Button,
	Alert, Input
} from "antd";
//import * as XLSX from 'xlsx';
import { ApiControllers } from "../../api/config";
import List from "../grid.component";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import apiCalls from "../../api/apiCalls";
import Moment from 'react-moment';
import config from "../../config";
import CopyToClipboard from "react-copy-to-clipboard";
import { validateContentRule } from "../../utils/custom.validator";

const { Text } = Typography;
class BankSathoshiTest extends Component {
	state = {
		stateObj: {},
		selection: [],
		caseModal: false,
		selectedObj: {},
		reasonReject: "",
		activeTab: 1,
		selectedObjs: [],
		error: null,
		searchQuery: null,
		selectedState: null,
		gridSatoshiUrl: process.env.REACT_APP_GRID_API + ApiControllers.address + "SelfSigned/Satoshi Test",
		gridAllUrl: process.env.REACT_APP_GRID_API + ApiControllers.address + "SelfSigned/all",
		gridDigitalsignedUrl: process.env.REACT_APP_GRID_API + ApiControllers.address + "SelfSigned/Self",
		errorMsg: null,
	};
	formref = React.createRef();
	gridRef = React.createRef();
	useDivRef = React.createRef();
	componentDidMount = () => {
		this.setState({
			...this.state,
			selection: [],
			activeTab: 1,
			selectedObj: {},
			check: false,
			errorMsg: null,
			error: null,
			selectedObjs: []
		}, () => this.gridRef.current.refreshGrid());
	};
	handleCheck = (props) => {
		return <div className="text-center">

			<label className="text-center custom-checkbox">
				<input
					id={props.dataItem.id}
					name="check"
					type="checkbox"
					checked={this.state.selection.indexOf(props.dataItem.id) > -1}
					onChange={(e) => this.handleCheckbox(props, e)}
				/>
				<span></span>{" "}
			</label>
		</div>
	}

	gridColumns = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: (props) => (
				<label className="text-center custom-checkbox">
					<input
						id={props.dataItem.id}
						name="check"
						type="checkbox"
						checked={this.state.selection.indexOf(props.dataItem.id) > -1}
						onChange={(e) => this.handleCheckbox(props, e)}
					/>
					<span></span>
				</label>
			)
		},
		{
			field: "transactionDate", title: "Created Date", filter: true, filterType: "date", width: 250, locked: true,
			customCell: (props) => (
				<div className="gridLink" onClick={() => this.addressView(props)}>
					{props.dataItem?.transactionDate && (
						<Moment format={config?.dateFormates?.dateTimeFormate}>
							{apiCalls.convertUTCToLocalTime(props.dataItem?.transactionDate)}
						</Moment>
					)}
					{!props.dataItem?.transactionDate && ("--")}

				</div>
			),
		},
		{
			field: "address",
			title: "Wallet Address/ID",
			width: 560,
			customCell: (props) => (
				<td>
					<CopyToClipboard>
						<Text
							copyable
							className="fs-22 text-black-30 text-center custom-display"
						>
							{props?.dataItem?.address}
						</Text>
					</CopyToClipboard>
				</td>
			)
		},
		{
			field: "state",
			title: "Status/Risk Score",
			filter: true,
			width: 200,

		},
		{
			field: "hash",
			title: "Virtual Assert",
			filter: true,
			width: 400,

		},

		{ field: "type", title: "Proof Type", filter: true, width: 250 },

	];
	handleCheckbox = (prop, e) => {
		this.formref.current?.resetFields();
		const rowObj = prop.dataItem;
		const value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const name = e.target.name;

		let { selection, selectedObjs } = this.state;
		let idx = selection.indexOf(rowObj.id);
		if (selection) {
			selection = [];
		}
		if (idx > -1) {
			selection.splice(idx, 1);
		} else {
			selection.push(rowObj.id);
		}
		this.setState({
			...this.state,
			[name]: value,
			selectedObj: rowObj,
			stateObj: rowObj,
			selectedObjs,
			selection,
			werningMsg: null
		});
		this.formref.current?.resetFields();
	};

	handleTabs = async (e) => {
		this.formref.current?.resetFields();
		this.setState({
			...this.state,
			selection: [],
			activeTab: e.target.value,
			searchQuery: null,
			selectedObj: {},
			check: false,
			errorMsg: null,
			error: null,
			selectedObjs: []
		}, () => this.gridRef.current.refreshGrid());
	};

	onActionClick = (key) => {
		const actions = {
			//"State Change": "statusChange"
		};
		this[actions[key]]();
	};
	handleStateChange = (val, value) => {
		this.formref.current.setFieldsValue({ rejectReason: "" })
		this.setState({ ...this.state, error: null, stateObj: { addressState: val, rejectReason: value }, selectedState: val });
	};
	handleSearchData = (e) => {
		e.target?.value.trim();
		if(e.target.value)
		this.setState({ ...this.state, searchQuery: e.target.value });
	}
	addressView = (e) => {
		const items = e.dataItem;
		const val = items.id;
		this.props.history.push(`/selfhostedwallets/selfhostedwalletsView/${val}`);
		this.props.dispatch(
			setBreadcrumb({
				key: `/selfhostedwallets/selfhostedwalletsView/${items.id}`,
				val: items.address,
			})
		);
	};
	handleSearch = async () => {
		try {
			await this.formref.current.validateFields();
			const searchQuery = this.state.searchQuery
			this.setState({ searchQuery }, () => {
				this.gridRef.current.refreshGrid(); // Refresh grid with new URL
			});
		} catch (error) {
			this.setState({ ...this.state, errorMsg: '' });
		}

	};
	render() {
		const { activeTab } = this.state;
		return (
			<>	<div ref={this.useDivRef}>
				{this.state.errorMsg && <div style={{ width: '100%' }}>
					<Alert className="mb-16 w-100" type="warning"
						description={this.state.errorMsg} showIcon />
				</div>}
				<div className="d-flex align-center justify-content">
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center"
						}}
						className="mb-16"
					>
						<Radio.Group
							defaultValue={1}
							onChange={this.handleTabs}
							className="buysell-toggle mb-0 custcase-toggle"
						>
							<Radio.Button value={1}>All</Radio.Button>
							<Radio.Button value={2}>Digital Signed</Radio.Button>
							<Radio.Button value={3}>Satoshi Test</Radio.Button>
						</Radio.Group>
					</div>
				</div>
				<div>
					<Form
						className="ant-advanced-search-form form form-bg search-bg pt-8"
						autoComplete="off"
						ref={this.formref}
						initialValues
					>
						<Row>
							<Col sm={24} md={7} lg={7} xl={7} xxl={7} className="px-8">
								<Form.Item
									name="searchType"
									className="input-label mb-0"
									rules={[{
										validator: validateContentRule,
									},]}
								>
									<Input
										className="cust-input"
										maxLength={50}
										placeholder="Search..."
										disabled={false}
										onChange={this.handleSearchData}
									/>
								</Form.Item>
							</Col>

							<Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-left">
								<Button
									type="primary"
									className="primary-btn px-24 search-btn "
									htmlType="submit"
									onClick={this.handleSearch}
								>Search
								</Button>

							</Col>
						</Row>
					</Form>
				</div>
				{(activeTab === 1 || activeTab == '1') && <List
					showActionBar={true}
					pKey={"selfhostedwallets"}
					onActionClick={(key) => this.onActionClick(key)}
					url={this.state.gridAllUrl}
					ref={this.gridRef}
					key={this.state.gridAllUrl}
					additionalParams={{ searchObj: this.state.searchQuery }}
					columns={this.gridColumns}
					showExcelExport={true}
				/>}
				{(activeTab === 2 || activeTab == '2') && <List
					showActionBar={true}
					pKey={"selfhostedwallets"}
					onActionClick={(key) => this.onActionClick(key)}
					url={this.state.gridDigitalsignedUrl}
					ref={this.gridRef}
					key={this.state.gridDigitalsignedUrl}
					additionalParams={{ searchObj: this.state.searchQuery }}
					columns={this.gridColumns}
					showExcelExport={true}
				/>}
				{(activeTab === 3 || activeTab == '3') && <List
					showActionBar={true}
					pKey={"selfhostedwallets"}
					onActionClick={(key) => this.onActionClick(key)}
					url={this.state.gridSatoshiUrl}
					ref={this.gridRef}
					key={this.state.gridSatoshiUrl}
					additionalParams={{ searchObj: this.state.searchQuery }}
					columns={this.gridColumns}
					showExcelExport={true}
				/>}
			</div>
			</>
		);
	}
}

const connectStateToProps = ({ oidc, userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, oidc, trackAuditLogData: userConfig.trackAuditLogData };
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(BankSathoshiTest);
