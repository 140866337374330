import { clientApi } from "../../api"
import { ApiControllers } from "../../api/config"


const fetchScreenPermissions = ({ customerId, perKey }) => {
    return clientApi.get(ApiControllers.security + `Toolbar/${customerId}/${perKey}`);
}
const fetchMenuItems = (AccounId,appid) => {
    return clientApi.get(ApiControllers.security + `Features/${process.env.REACT_APP_APP_ID}/${AccounId}`);
}

export { fetchScreenPermissions, fetchMenuItems }