
import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Select, Typography, Modal,Alert,Spin} from "antd";
import Loader from "../loader.component";
import { useHistory } from "react-router";
import { publishShowActions } from "../grid.component/subscribir";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { validateContentRule } from "../../utils/custom.validator";
import { connect } from "react-redux";
import { getCase, getCaseLu, saveCasePopObj, deleteCaseAssigners,getcaseDoc,getStates } from "./api";
import {caseTemplateLu} from '../../utils/caseApi';
import { success, error } from "../../utils/messages";
import NumberFormat from "react-number-format";
import { LoadingOutlined } from "@ant-design/icons";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { Link } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
import Info from "../info/Info";
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { confirm } = Modal;

const CaseDetails = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [caseObject, setCaseObject] = useState({});
  const [assignedTo, setAssignedTo] = useState([]);
  const [docDetails, setDocDetails] = useState([]);
  const [caseStatusLu, setCaseStatusLu] = useState([]);
  const [caseSubStatusLu, setCaseSubStatusLu] = useState([]);
  const [commonModel, setCommonModel] = useState({});
  const [isCustomerDocument, setIsCustomerDocument] = useState(false);
  const [isCustomerDetails,setIsCustomerDetails]=useState(false);
  const [caseTemplateLuObj,setCaseTemplate]=useState([]);
  const[errorMsg,setErrorMsg] = useState();
  const[isDeleted,setIsDeleted]=useState(false);
  const [isSaveDisable, setIsSaveDisable]=useState(true);
  const [caseCryptoDoc,setCaseCryptoDco]=useState([]);
  const [caseStateChange,setCaseStateChange]=useState({});
  const [caseActiveKey,setCaseActiveKey]=useState(props.match.params.key);
  const [isDeleteRecord,setIsDeleteRecord]=useState(false);
  useEffect(() => {
    publishShowActions(false);
    caseTemplate();
    if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
      props.setAction("edit");
      setCaseActiveKey(props.match.params.key);
      loadData();
    } else {
      loadData();
    }
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const getcaseDocs=async()=>{
  let res = await getcaseDoc(caseObject.id,caseObject.isCrypto)
 if(res.ok){
  setCaseCryptoDco(res.data.details);
  setErrorMsg(null)
  form.setFieldsValue({customerCaseTitle:caseObject.customerCaseTitle ,remarks:caseObject.remarks})
 } else{
  setErrorMsg(apiCalls.isErrorDispaly(res));
 }  
}

  const loadData = async () => {
    setLoading(true);
    fetchAssignedToLu(null);
    let response = await getCase(props.match.params.id);
    if (response.ok) {
      setErrorMsg(null)
      let obj = response.data;
      obj.assignedTo = response.data?.assignedTo?.map((item) => ({
        ...item,
        assignedToIds: item.assignedToIds?.split(",")
      }));
      setCaseObject(response.data);
      setDocDetails(response.data?.caseDetails);
      setCaseSubStatusLu(response.data?.caseSubStateLu);
      setCaseStateChange(response.data?.state)
      setCommonModel(response.data?.commonModel);
      setIsCustomerDocument(response.data?.isCustomerDocument);
      obj.customerCaseTitle = response.data?.customerCaseTitle;
      form.setFieldsValue(obj);
      setLoading(false);
      setIsCustomerDetails(response.data?.isCrypto)
      setCaseCryptoDco(response.data?.caseDetails)
      getStatesLu(response.data.state);
    }else{
      setErrorMsg(apiCalls.isErrorDispaly(response));
    }
  
  };
  const caseTemplate=async()=>{
    let res= await caseTemplateLu();
    if(res.ok)
    {
      setCaseTemplate(res.data);
    }
  }
  const handleTemplateChange=(val)=>{
    form.setFieldsValue({remarks: val })
  }
const getStatesLu=(state)=>{
  getStates(state).then(response => {
    if (response.ok) {
      setCaseStatusLu(response.data);
    }else{
      setErrorMsg(apiCalls.isErrorDispaly(response));
    }
})
}

  const fetchAssignedToLu = async (names) => {
    let response = await getCaseLu(names);
    if (response.ok) {
      setAssignedTo(response.data);
      setErrorMsg(null)
    }else{
      setErrorMsg(apiCalls.isErrorDispaly(response));
    }

  };

  const handleSearch = (e, type) => {
    let sval = e.target.value;
    if (type === "AssignedTo") {
      fetchAssignedToLu(sval);
    }
  };

  const backToCases = () => {
    const items = caseObject;
    const val = items.id;
    if (props.match.params.alertid === "null") {
      setCurrentAction(null);
      history.push({
        pathname:`/cases/${caseActiveKey}`
      });
    } else {
      props.history.push(`/cases/${caseActiveKey}/` + val + "/view");
    }
  };

  const saveCaseInfo = async (values) => {
    setBtnDisabled(true);
    let obj = {
      id: props.match.params.id,
      caseNumber: caseObject.caseNumber,
      caseTitle: values.caseTitle,
      assignedTo: values.assignedTo.map((item) => ({
        assignedToIds: item.assignedToIds?.join(","),
        id: item.id || uuidv4(),
        assignedRemarks: item.assignedRemarks?.trim(),
        recordStatus:
          Object.keys(item).indexOf("recordStatus") > -1
            ? item.recordStatus
            : "Added",
        caseSubState: item.caseSubState
      })),
      commonModel: commonModel,
      remindDate: null,
      closedDate: null,
      customerId: caseObject.customerId,
      remarks: values.remarks,
      state: values.state,
      createdDate: new Date(),
      createdBy: props.userConfig?.userName,
      modifiedDate: null,
      modifiedBy: props.userConfig?.userName,
      status: false,
      caseDetails: isCustomerDetails  ? caseCryptoDoc :  docDetails,
      documentNames: null,
      isCrypto:isCustomerDetails,
      isCustomerDocument: isCustomerDocument,
      typeId: caseObject.typeId,
      templateName:values.templateName,
      reason:(caseStateChange=="Reopened" ||caseObject.state==="Reopened")? (values.reason):null,
      type: caseObject.type,
      caseStateLu: ["Submitted", "Approved", "Rejected"],
      caseSubStateLu: ["Submitted", "Re-assigned"],
      documentDetails: null,
      customerCaseTitle: values?.customerCaseTitle || caseObject?.customerCaseTitle
    };
    obj.info = JSON.stringify(props.trackAuditLogData);
    let response = await saveCasePopObj(obj);
    setBtnDisabled(false);
    if (response.ok) {
      success("Case details saved successfully");
      setTimeout(() => { setLoading(false) }, 1000)

      const items = caseObject;
      const val = items.id;
      if (props.match.params.alertid === "null") {
        setCurrentAction(null);
        history.push({
          pathname: `/cases/${caseActiveKey}`
        });
      } else {
        props.history.push(`/cases/${caseActiveKey}/` + val + "/view");
      }
    } else {
      setBtnDisabled(false);
      setErrorMsg(apiCalls.isErrorDispaly(response));
    }
  };
 
  const handleInputChange = (e, d) => {
    let docData = Object.assign([], docDetails);
    for (let i in docDetails) {
      if (docDetails[i].id === d.id) {
        docData[i].isChecked = docData[i].isChecked ? false : true;
      }
    }
    setDocDetails(docData);
  };
  const handleCustomerChange = (e) => {
    if(!caseObject.isCrypto){
      if (e.target.checked === false) {
        let val = [];
        for (let i of docDetails) {
          i.isChecked = false;
          val.push(i);
        }
        setDocDetails(val);
        setIsCustomerDocument(false);
      } else {
        setIsCustomerDetails(false);
        setIsCustomerDocument(e.target.checked);      
        form.setFieldsValue({ customerCaseTitle: null, remarks: null })
      }
    }    
  };
  const handleCustomerDetailsChange = (e) => {
    if(!caseObject.isCrypto){
      getcaseDocs();
      if (e.target.checked === false) {
        let val = [];
        for (let i of caseCryptoDoc) {
          i.isChecked = false;
          val.push(i);
        }
        setCaseCryptoDco(val);
        setIsCustomerDetails(false);
      } else {
        setIsCustomerDocument(false);
        setIsCustomerDetails(e.target.checked);
        form.setFieldsValue({ customerCaseTitle: null, remarks: null })
      }
    }  
  };
  const crypto = require("crypto");
  function uuidv4() {
    let randumnumberval;
    let buf = crypto.randomBytes(16);
    randumnumberval = buf.toString("hex");
    return (
      randumnumberval.substr(0, 8) +
      "-" +
      randumnumberval.substr(8, 4) +
      "-" +
      randumnumberval.substr(12, 4) +
      "-" +
      randumnumberval.substr(16, 4) +
      "-" +
      randumnumberval.substr(20, 12)
    );
  }


  const deleteAssignedToRow = async (id, remove) => { 
    setIsDeleted(true)
    fetchAssignedToLu(null)
    let deleteId = caseObject?.assignedTo[id]?.id;
     caseObject.assignedTo.splice(id, 1)
    if (deleteId) {
      setIsDeleted(true)
      let response = await deleteCaseAssigners(deleteId)
      if (response.ok) {
        setIsDeleted(false)
        if(isDeleteRecord){
          remove(id);
        }       
        success("Assigned details deleted successfully");
      }
      else {
        error(response.data)
        setIsDeleted(false)
      }
    } else {
      remove(id);
       setIsDeleted(false)
    }

  }
 const addRow=(add)=>{
  if(isDeleteRecord){
    setIsDeleteRecord(false)
  }else{
    setIsDeleteRecord(true)
  } 
   fetchAssignedToLu(null)
   add();
 }

 const redirectCustomerView=()=>{
  const items = caseObject;
  const val = items.customerId;
 props.dispatch(
    setBreadcrumb({ key: "/customers/details/" + val, val: caseObject.commonModel?.Name })
  );
  localStorage.setItem("MyURL","/customers/details/" + val)
  window.open("/customers/details/" + val);
}

const stateChange = (val) => {
    setCaseStateChange(val);
  if((val !== caseObject.state) && (caseObject.state === "Approved" || caseObject.state === "Rejected")) {
    setIsSaveDisable(false);
  }
  
};

 const antIcon = () => {
  (
    <LoadingOutlined
      style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
      spin
    />

  );
}

let checkBoxClass = "c-pointer";

if (
    props.match.params.type === "disabled" ||
    (caseObject.state !== "Submitted" && caseObject.state !== "Reopened") ||
    caseObject.isCrypto === true
) {
    checkBoxClass = "c-notallowed";
}

  return (
     <>
  
      {errorMsg && <div style={{ width: '100%' }}>
            <Alert className="mb-16 w-100" type="error" description={errorMsg} showIcon/></div> }
            {loading?<Loader />:
      <Form
        advanced_search
        initialValues={caseObject}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={saveCaseInfo}
        autoComplete="off"
      >
        <>
          <Row gutter={24} className="mb-8">
            <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
              <Form.Item
                name="caseNumber"
                label="Case Number"
                className="input-label"
                rules={[
                  {
                    required: true,
                    message: "Is required"
                  },
                  {
                    whitespace: true,
                    message: "Is required"
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  placeholder="Case Number"
                  className="cust-input"
                  disabled={true}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={14}>
              <Form.Item
                name="caseTitle"
                label="Case Title"
                className="input-label"
                rules={[
                  {
                    required: true,
                    message: "Is required"
                  },
                  {
                    whitespace: true,
                    message: "Is required"
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  placeholder="Case Title"
                  className="cust-input"
                  maxLength={50}
                  disabled={
                    (props.match.params.type === "disabled" || caseObject.state !== "Submitted" || caseObject.state === "Submitted")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={4}>
              <Form.Item
                name="state"
                label="Case State"
                className="input-label"
                rules={[
                  {
                    required: true,
                    message: "Is required"
                  }
                ]}
              >
                <Select className="cust-input" placeholder="Select Sub-State"
                  onChange={(e) => stateChange(e)}
                >
                   {caseStatusLu?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
           {(caseStateChange=="Reopened") && 
            <Col xs={24} sm={24} md={12} lg={24} xxl={24}>
              <Form.Item
                name="reason"
                label="Reason"
                className="input-label"
                rules={[
                  {
                    required: true,
                    whitespace:true,
                    message: "Is required"
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                 <TextArea
                     disabled={
                      ((props.match.params.type === "disabled" && caseObject.state==="Reopened") ||isSaveDisable)
                    }
                      placeholder="Reason"
                      maxLength={100}
                      showCount
                      rows={2}>
                    </TextArea>
              </Form.Item>
            </Col>
            }
          </Row>
          <div className="case-ribbon">
              <Row gutter={[16, 16]}>
                {commonModel && (
                  Object.entries(commonModel).map(([key, value], idx) => {
                    let colSize;
                    if (key === "Decription") {
                      colSize = 24;
                    } else if (key === null) {
                      colSize = "Decription";
                    } else if (key === "Currency" && value === "EUR") {
                      colSize = "EUR";
                    } 
                    else if(key==="Customer/Contract ID"){
                      colSize =  "Customer ID"
                      } 
                    else {
                      colSize = key;
                    }

                    const renderValue = () => {
                      if (value === null || value === " " || value === "") {
                        return '-';
                      } else if (
                        isNaN(value) ||
                        key === 'Transaction Id' ||
                        key === 'Bank Account number/IBAN' ||
                        key === "Bank Account Number/IBAN" ||
                        key === 'Bank Name' ||
                        key === "Wallet Address"
                      ) {
                        if (key === 'Name') {
                          return <Link onClick={() => redirectCustomerView()}>{value}</Link>;
                        } else {
                          return value;
                        }
                      } else {
                        return <NumberFormat value={value} decimalSeparator="." displayType={'text'} thousandSeparator={true} />;
                      }
                    };

                    return (
                      <Col
                        key={idx}
                        xs={colSize}
                        md={colSize}
                        lg={colSize === 24 ? 24 : 12}
                        xl={colSize === 24 ? 24 : 8}
                        xxl={colSize === 24 ? 24 : 6}
                      >
                        <div className="ribbon-item">
                          <span className={`icon md ${colSize}`} />
                          <div className="ml-16" style={{ flex: 1 }}>
                            <Text className="fw-300 text-white-50 fs-12">{key || '-'}</Text>
                            <div className='fw-600 text-white-30 fs-16 l-height-normal' style={{ width: "30", wordBreak: "break-all" }}>
                              {renderValue()}
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) || (
                  <Loader />
                )}
              </Row>

          </div>
          <Form.List name="assignedTo" initialValue={caseObject.assignedTo}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, indx) => (
                  <Row gutter={[8, 8]} key={indx}>

                    <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={12}>
                      <Form.Item
                        name={[name, "assignedToIds"]}
                        label="Assigned To"
                        className="input-label"
                        rules={[
                          {
                            required: true,
                            message: "Is required"
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          mode="multiple"
                          className="cust-input multi-select asignedto-input-height"
                          placeholder="Select Assigned To"
                          optionFilterProp="children"
                          onKeyUp={(e) => handleSearch(e, "AssignedTo")}
                         
                          disabled={indx < caseObject.assignedTo.length - 1|| (props.match.params.type === "disabled" || (caseObject.state !== "Submitted" && caseObject.state !== "Reopened"))}
                        >
                          {assignedTo?.map((assign, idx) => (
                            <Option key={idx} value={assign.id}>
                              {assign.assignedTo}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={7} xl={7} xxl={6}>
                      <Form.Item
                        name={[name, "assignedRemarks"]}
                        label="Assigned Remarks"
                        className="input-label"
                        rules={[
                          {
                            whitespace: true,
                            message: "Is required",
                          },
                          {
                            validator: validateContentRule
                          }
                        ]}
                      >
                        <Input
                          placeholder="Assigned Remarks"
                          className="cust-input"
                        
                          disabled={indx < caseObject.assignedTo.length - 1|| (props.match.params.type === "disabled" || (caseObject.state !== "Submitted" && caseObject.state !== "Reopened"))}
                          maxLength={100}

                        />
                      </Form.Item>
                    </Col>
                    <Col xs={21} sm={21} md={7} lg={4} xl={4} xxl={4}>
                      <Form.Item
                        name={[name, "caseSubState"]}
                        label="Case Sub-State"
                        className="input-label"
                        rules={[
                          {
                            required: true,
                            message: "Is required"
                          }
                        ]}
                      >
                        <Select
                          className="cust-input"
                          placeholder="Select Sub-State"
                          disabled={indx < caseObject.assignedTo.length - 1|| (props.match.params.type === "disabled" || (caseObject.state !== "Submitted" && caseObject.state !== "Reopened"))}
                         
                        >
                          {caseSubStatusLu?.map((item, idx) => (
                            <Option key={idx} value={item}>
                              {" "}
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                     
                      {indx === 0 ? (
                       
                        <Button
                          type="primary"
                          shape="circle"
                          className="primary-btn cancel-btn mt-30 mb-view"
                          style={{ padding: "0 14px" }}
                          onClick={()=>addRow(add) }
                          disabled={
                            (props.match.params.type === "disabled" || (caseObject.state !== "Submitted" && caseObject.state !== "Reopened"))
                          }
                        >
                          <span className="icon md plus" />
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          shape="circle"
                          className="primary-btn cancel-btn mt-30 mb-view "
                          style={{ padding: "0 14px" }}
                          disabled={
                            (props.match.params.type === "disabled" || (caseObject.state !== "Submitted" && caseObject.state !== "Reopened"))
                          }
                          onClick={() =>
                            confirm({
                              content: (
                                <div className="fs-14 text-white-50 ">
                                  Are you sure do you want to delete Assignee?
                                </div>
                              ),
                              title: (
                                <div className="fs-18 text-white-30 assignee-text">
                                  Delete Assignee ?
                                </div>
                              ),
                              okButtonProps: {
                                 loading: isDeleted,
                                 className: "primary-btn"
                              },
                              cancelButtonProps:{
                                className: "primary-btn cancel-btn"
                              },

                              onOk: () => { deleteAssignedToRow(indx, remove)
                               }
                            })
                          }
                        >
                           {loading && <Spin indicator={antIcon} />}
                          <span className="icon md delete" />
                        </Button>
                      )}
                     
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
          <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
          <div className="d-flex align-center my-16">
            <label className="text-center custom-checkbox">
              <input
                name="isCustomerDocument"
                type="radio"
                disabled={(props.match.params.type==="disabled" || caseObject.state == "Approved" || caseObject.state == "Cancelled" || caseObject.state == "Rejected")}
                className={`grid_check_box ${(props.match.params.type==="disabled" || caseObject.state == "Approved" || caseObject.state == "Cancelled" || caseObject.state == "Rejected" || caseObject.isCrypto) && "c-notallowed" || "c-pointer"}`}
                onChange={handleCustomerChange}
                checked={isCustomerDocument}
              />
              <span></span>
            </label>
            <span className="ml-8">Request for documents from customer</span>
          </div></Col>
          {(caseObject.type==="Receive Crypto"&& caseObject.isCrypto  && process.env.REACT_APP_ISTR == "true") &&
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
          <div className="d-flex align-center my-16">
            <label className="text-center custom-checkbox">
              <input
                name="isCustomerDetails"
                type="radio"
                className={`grid_check_box ${checkBoxClass}`}
                onChange={handleCustomerDetailsChange}
                 checked={isCustomerDetails}
            />
 </label>
            <span className="ml-8">Crypto</span>
          </div></Col>}
          </Row>
          {(isCustomerDocument || isCustomerDetails) && (
            <div>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={16} xxl={18}>
                  <Form.Item
                    name="customerCaseTitle"
                    label="Customer Case Title"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required",
                        whitespace: true
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Input
                      placeholder="Customer Case Title"
                      className="cust-input"
                      disabled={
                        props.match.params.type === "disabled" ||
                        (caseObject.state !== "Submitted" && caseObject.state !== "Reopened") ||
                        caseObject.isCrypto
                    }
                    
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Form.Item
                    name="templateName"
                    className="input-label mb-0"
                    label="Use Templates"
                  >
                    <Select
                      className={`cust-input ${(props.match.params.type === "disabled" || (caseObject.state !== "Submitted" && caseObject.state !== "Reopened")|| caseObject.isCrypto) && "w-80 cust-input-disabled"} ` }
                      placeholder="Use Templates"
                      onSelect={(e) => handleTemplateChange(e)}
                      disabled={
                        props.match.params.type === "disabled" ||
                        (caseObject.state !== "Submitted" && caseObject.state !== "Reopened") ||
                        caseObject.isCrypto
                    }
                    
                    >
                       {caseTemplateLuObj.map((item) => (
                        <Option key={item.id} value={item.remarks}>
                          {item.templateName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={24} xxl={24}>
                  <Form.Item
                    name="remarks"
                    label="Customer Remarks"
                    className="input-label"
                    rules={[
                      {
                        
                        whitespace: true
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <TextArea
                      placeholder="Customer Remarks"
                      showCount={500}
                      maxLength={500}
                      rows={4}
                      disabled={
                        props.match.params.type === "disabled" ||
                        (caseObject.state !== "Submitted" && caseObject.state !== "Reopened") ||
                        caseObject.isCrypto
                    }
                    
                    />
                  </Form.Item>
                </Col>
              </Row>
             { isCustomerDocument && <> <Title level={5} className="fs-16 fw-600 text-white-30 mb-16">
                Documents{" "}
                <Text className="fs-12 fw-100">
                  (when you select documents customer will be notified to submit
                  this documents)
                </Text>
              </Title>
              <Row gutter={24}>
                {docDetails?.map((docItem, idx) => (
                  <Col xs={24} sm={24} md={12} lg={8} xxl={6} key={idx}>
                    <div className="d-flex align-center mb-8">
                      <label className="text-center custom-checkbox">
                        <input
                          name="check"
                          type="checkbox"
                          checked={docItem.isChecked}
                          onChange={(e) => handleInputChange(e, docItem)}
                          className="grid_check_box"
                          disabled={!isCustomerDocument || (caseObject.state !== "Submitted" && caseObject.state !== "Reopened")}
                        />
                        <span></span>
                      </label>
                      <span className="ml-8">{docItem?.documentName}</span>
                    </div>
                  </Col>
                ))}
              </Row></>}

             {isCustomerDetails && process.env.REACT_APP_ISTR == "true" && <> <Title level={5} className="fs-16 fw-600 text-white-30 mb-16">
                Crypto{" "}
              </Title>
              <Row gutter={24}>
                {caseCryptoDoc?.map((docItem, idx) => (
                  <Col xs={24} sm={24} md={12} lg={8} xxl={6} key={idx}>
                    <div className="d-flex align-center mb-8">
                      <label className="text-center custom-checkbox">
                        <input
                          name="check"
                          type="checkbox"
                          checked={docItem.isChecked}
                          disabled={caseObject.isCrypto}
                          onChange={handleCustomerDetailsChange}
                          className="grid_check_box"
                        />
                        <span></span>
                      </label>
                      <span className="ml-8">{docItem.name || docItem?.documentName}</span>                    
                    </div>
                  </Col>
              ))}
              </Row></>}
            </div>
          )}

        </>
        <Info Id={caseObject?.id} Status={caseObject?.status} Createdby={caseObject?.createdBy} CreatedDate={caseObject?.createdDate} ModifiedDate={caseObject?.modifiedDate} Modifiedby={caseObject?.modifiedBy}  screenName={"cases"} infoDetails={true} />
        <div className="text-right mt-24">
              <Button
              disabled={isSaveDisable && (caseObject.state === "Approved" || caseObject.state === "Rejected")}
                type="primary"
                loading={btnDisabled}
                className="primary-btn"
                htmlType="submit"
              >
                          Save
              </Button>
          <Button
            type="primary"
            className="primary-btn cancel-btn"
            style={{ margin: "0 8px" }}
            onClick={backToCases}
          >
            Cancel
          </Button>
        </div>
      </Form>}
    </>
  );
};

const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData };
};
const connectDispatchToProps = (dispatch) => {
  return {
    setAction: (val) => {
      dispatch(setCurrentAction(val));
    },
    dispatch
  };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(CaseDetails);
