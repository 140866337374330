import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Typography,
  Tooltip,
  Modal,Alert
} from "antd";
import { publishShowActions } from "../grid.component/subscribir";
import { useHistory } from "react-router";
import Loader from "../loader.component";
import { getRiskScreenDetails } from "./api";
import { getFileURL } from '../documents.component/api';
import FilePreviewer from 'react-file-previewer';
import { Link } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
const { Title, Paragraph } = Typography;

const RiskDetialsView = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [riskDetails, setRiskDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [previewModal, setPreviewModal] = useState(false)
  const [previewPath, setPreviewPath] = useState(null)
  const [errorMsg,setErrorMsg]=useState(null)
  useEffect(() => {
    publishShowActions(false);
    getRiskScreenDetailsData();
  }, [props?.match?.params?.id]);//eslint-disable-line react-hooks/exhaustive-deps

  const backToCustomer = () => {
    history.push({
      pathname: "/customers"
    });
  };

  const getRiskScreenDetailsData = async () => {
    let response = await getRiskScreenDetails(
      props.match.params?.id
    );
    if (response.ok) {
      let obj = response.data;
      setLoading(false);
      setRiskDetails(response.data);
      form.setFieldsValue(obj);
    }
    else {
      setLoading(false);
      setErrorMsg(apiCalls.isErrorDispaly(response))
    }
  };

  const filePreview = async (file) => {
    let res = await getFileURL({ url: file });
    if (res.ok) {
      setPreviewModal(true);
      setPreviewPath(res.data);
    }else{
      setErrorMsg(apiCalls.isErrorDispaly(res))
    }
  }

  const filePreviewPath = () => {
    return previewPath;
  }
  const docPreviewClose = () => {
    setPreviewModal(false);
  }
 
  const renderViewData = (titleData, value) => {
    return  <Col
    xs={24}
    sm={24}
    md={24}
    lg={24}
    xl={7}
    xxl={8}
    className="item"
  >
    <label className="kpi-label">{titleData}</label>
    <Paragraph className="kpi-val fw-700 mb-0">
                    {value === false || value === true
                      ? value?.toString()
                      : "-"}
                  </Paragraph>
  </Col>
  }

  return (
    <>
     {errorMsg && (
					<Alert
						closable
						type="error"
						description={errorMsg}
						onClose={() => setErrorMsg(null)}
						showIcon
					/>
				)}
      <Title className="page-title">Risk Screen Details</Title>
      <Form
        initialValues={riskDetails}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        autoComplete="off"
      >
        {loading && <Loader className="text" />}
        <>
          <Row gutter={8}>
            <Col xl={24} xxl={24} className="bank-view">
              <Row className='company-List'>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  <label className="kpi-label">Name</label>
                  <Paragraph className="kpi-val fw-700 mb-0">
                    {riskDetails.customerOrCompanyName
                      ? riskDetails.customerOrCompanyName
                      : "-"}
                  </Paragraph>
                </Col>

                {renderViewData("AVM", riskDetails.isAVM)}
                {renderViewData("OTH", riskDetails.isOTH)}
                {renderViewData("PEP", riskDetails.isPEP)}
                {renderViewData("RCA", riskDetails.isRCA)}
                {renderViewData("SAN", riskDetails.isSAN)}
                {renderViewData("UNK", riskDetails.isUNK)}
                {renderViewData("WBL", riskDetails.isWBL)}

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {riskDetails.documentUri && (
                    <label className="kpi-label">Attachment</label>
                  )}
                  <div className="kyc-docs custom-kyc-doc">
                    {riskDetails.documentUri && <Link onClick={() => filePreview(riskDetails.documentUri)}>Report.pdf</Link>}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        
      </Form>
   
      <Modal
        className="documentmodal-width"
        destroyOnClose={true}
        title="Preview"
        width={1000}
        visible={previewModal}
        closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={docPreviewClose} /></Tooltip>}
        footer={<>
          <Button type="primary" onClick={docPreviewClose} className="primary-btn cancel-btn">Close</Button>
          <Button type="primary" className="primary-btn" onClick={() => window.open(previewPath, "_blank")}>Download</Button>
        </>}
      >
        <FilePreviewer hideControls={true} file={{ url: previewPath ? filePreviewPath() : null, mimeType: previewPath?.includes(".pdf") ? 'application/pdf' : '' }} />
      </Modal>
      <div className="text-right mt-24">
        <Button
          type="primary"
          className="primary-btn cancel-btn"
          style={{ margin: "0 8px" }}
          onClick={backToCustomer}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
export default RiskDetialsView;
