import React from "react";
import {
	toDataSourceRequestString,
	translateDataSourceResultGroups,
	groupBy
} from "@progress/kendo-data-query";
import { store } from "../../store";
import CryptoJS from "crypto-js";
import { Alert, Button, Dropdown, Menu } from "antd";
import moment from "moment";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { excellExportSubject } from "./subscribir";
import {
	setExpandedState,
	setGroupIds,
} from "@progress/kendo-react-data-tools";
import { GridToolbar } from "@progress/kendo-react-grid";
import apiCalls from '../../api/apiCalls'
import { PDFExport } from '@progress/kendo-react-pdf';
import logColor from '../../assets/images/logo-color.png';
import config from "../../config";
const filterOperators = {
	text: [
		{ text: "grid.filterContainsOperator", operator: "contains" },
		{ text: "grid.filterNotContainsOperator", operator: "doesnotcontain" },
		{ text: "grid.filterEqOperator", operator: "eq" },
		{ text: "grid.filterNotEqOperator", operator: "neq" },
		{ text: "grid.filterStartsWithOperator", operator: "startswith" },
		{ text: "grid.filterEndsWithOperator", operator: "endswith" },
		{ text: "grid.filterIsEmptyOperator", operator: "isempty" },
		{ text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
	],
	numeric: [
		{ text: "grid.filterEqOperator", operator: "eq" },
		{ text: "grid.filterNotEqOperator", operator: "neq" },
	],
	date: [
		{ text: "grid.filterAfterOrEqualOperator", operator: "gte" },
		{ text: "grid.filterAfterOperator", operator: "gt" },
		{ text: "grid.filterBeforeOperator", operator: "lt" },
		{ text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
	],
	datetime: [
		{ text: "grid.filterEqOperator", operator: "eq" },
		{ text: "grid.filterNotEqOperator", operator: "neq" },
		{ text: "grid.filterAfterOrEqualOperator", operator: "gte" },
		{ text: "grid.filterAfterOperator", operator: "gt" },
		{ text: "grid.filterBeforeOperator", operator: "lt" },
		{ text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
	],
	boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
};

const initialGroup = [];

const processWithGroups = (data, group) => {
	const newDataState = groupBy(data, group);
	setGroupIds({
		data: newDataState,
		group: group,
	});
	return newDataState;
};

export function withState(WrappedGrid) {
	return class StatefullGrid extends React.Component {
		constructor (props) {
			super(props);
			this.state = {
				dataState: this.props.dataState,
				additionalParams: null,
				data: [],
				group: this.props.dataState.group,
				isLoading: false,
				collapsedState: [],
				gridStatus: null,
				profile: store.getState().userConfig?.userProfileInfo
			};
			this.excelRef = React.createRef();
			this.tempRef = React.createRef(null)

			this.exportSubscriber = excellExportSubject.subscribe(() => {
				let orderToFollow = this.props.columns?.map(col => col.orderIndex)
				if (!this.props.columns[0].field) { // if first field is empty; i.e. the checkbox
					orderToFollow = orderToFollow?.map(i => i - 1).filter(ord => ord !== -1)
				}
				const columnsToExport = this.props.columns.filter(col => col.show === true)
				let columnIndexesToSkip = []
				if (columnsToExport.length !== this.props.columns.length) {
					for (let i = this.props.columns.length - 1; i >= 0; i--) {
						if (this.props.columns[i].show === false) { columnIndexesToSkip.push(i) }
					}
					if (!this.props.columns[0].field) { // if first field is empty; i.e. the checkbox
						columnIndexesToSkip = columnIndexesToSkip.map(i => i - 1)
					}
					if (columnIndexesToSkip.length > 0) {
						for (let index of columnIndexesToSkip) {
							orderToFollow.splice(index, 1)
						}
					}
				}
				const reorderCells = (currentRow, orderIndex) => {
					const newData = [];
					for (let i in orderIndex) {
						newData[orderIndex[i]] = currentRow[i]
					}
					return newData;
				}

				if (this.excelRef?.save) {
					let workbook = this.excelRef.workbookOptions(); // get the workbook.
					workbook.sheets[0].rows?.map((item, index) => {
						if (item.type === "data") {
							for (const i in columnsToExport) {
								const idx = columnsToExport.length === item.cells.length ? i : (i - 1);
								if (columnsToExport[i].filterType === "date") {
									if (item.cells[idx].value) {
										item.cells[idx].value = moment(apiCalls.convertUTCToLocalTime(item.cells[idx].value)).format(config?.dateFormates?.dateTimeFormate)
									}
								}
								if (columnsToExport[i].filterType === "numeric") {
									if (item.cells[idx].value)
										item.cells[idx].value = this.numberWithCommas(item.cells[idx].value);
									item.cells[idx].textAlign = "right";
								}

							}
						} else if (item.type === "header") {
							for (const i in item.cells) {
								item.cells[i].value = item.cells[i].value.props.children[0]
							}
						}
					});
					if (Number.isInteger(this.props.columns[0].orderIndex)) { // if there is an orderIndex 
						if (this.state.group.length > 0) {
							for (let i = 0; i < this.state.group.length; i++) {
								orderToFollow = orderToFollow.map(indx => indx + 1)
								orderToFollow.unshift(0)
							}
						}
						for (let i in workbook.sheets[0].rows) {
							if (workbook.sheets[0].rows[i].type === 'group-header') {
								continue
							}
							workbook.sheets[0].rows[i].cells = reorderCells(workbook.sheets[0].rows[i].cells, orderToFollow)
						}
					}
					if (columnIndexesToSkip.length > 0) { // if need to hide cols
						let columnIndexesToRemove = [];
						for (let i = workbook.sheets[0].rows[0].cells.length - 1; i >= 0; i--) {
							if (!workbook.sheets[0].rows[0].cells[i]) { columnIndexesToRemove.push(i) }
						}
						for (let i in workbook.sheets[0].rows) {
							for (let indexToRemove of columnIndexesToRemove) {
								workbook.sheets[0].rows[i].cells.splice(indexToRemove, 1)
							}
						}
					}
					this.excelRef.save(workbook);
				}
			});
		}
		numberWithCommas(x) {
			if (!x) {
                return ''
            } else if ((typeof x) === 'string') {
                return x
            }
			x = (typeof x) == 'string' ? x : x?.toString();
			var arParts = x?.split('.');
			 var intPart = parseInt(arParts[0])?.toLocaleString();
			 var decPart = (arParts.length > 1 ? arParts[1] : '');

			 return '' + intPart + (decPart ? ('.' + decPart) : '');
		}

		expandChange = (event) => {
			const item = event.dataItem;
			if (item.groupId) {
				const newCollapsedIds = !event.value
					? [...this.state.collapsedState, item.groupId]
					: this.state.collapsedState.filter(
						(groupId) => groupId !== item.groupId
					);
				this.setState({
					collapsedState: newCollapsedIds,
				});
			}
		};

		onGroupsToggle = () => {
			let collapseAllState = []
			if (this.state.result) {
				for (let i in this.state.result) {
					collapseAllState.push(this.state.result[i].groupId)
				}
			}
			const newCollapsedState = this.state.collapsedState.length
				? []
				:
				collapseAllState
			this.setState({
				collapsedState: newCollapsedState,
			});
		};

		refreshGrid() {
			this.fetchData(this.state.dataState);
		}
		resetToDefault(initialDataState) {
			this.setState({ ...this.state, dataState: initialDataState, group: initialDataState.group })
			this.fetchData(initialDataState)
		}
		componentWillUnmount() {
			this.exportSubscriber.unsubscribe();
		}

		handleExcelExport = () => {
			if (this.excelRef && this.excelRef.save) {
				let workbook = this.excelRef.workbookOptions();
				workbook.sheets[0].rows.forEach((item, index) => {
					if (item.type === "header") {
						for (const i in item.cells) {
							item.cells[i].value = this.props.columns[i].title; 
						}
					} else if (item.type === "data") {
						for (const i in this.props.columns) {
							const idx = this.props.columns.length === item.cells.length ? i : i - 1;
							if (this.props.columns[i].filterType === "date") {
								if (item.cells[idx].value) {
									item.cells[idx].value = 
									moment(apiCalls.convertUTCToLocalTime(item.cells[idx].value)).format(config?.dateFormates?.dateTimeFormate);
									
								}
							}
							if (this.props.columns[i].filterType === "numeric") {
								if (item.cells[idx].value) {
									item.cells[idx].value = this.numberWithCommas(item.cells[idx].value);
								}
								item.cells[idx].textAlign = "right";
							}
							if (this.props.columns[i]?.combine) {
								item.cells[idx].value = this.getCombineFieldValue(this.excelRef.props.data[index - 1], this.props.columns[i].combineFields);
							}
						}
					}
				});
				this.excelRef.save(workbook);
			}
		};
		

		exportToPDF = () => {
            if (this.tempRef?.current)
                this.tempRef?.current.save();
        }

		getCombineFieldValue = (dataItem, fields) => {
            for (const i in this.props.columns) {
                if (this.props.columns[i].filterType === "numeric") {
                    dataItem[fields[0]] = this.numberWithCommas(dataItem[fields[0]])
                    dataItem[fields[1]] = this.numberWithCommas(dataItem[fields[1]])
                }
            }
            return dataItem[fields[0]] && dataItem[fields[1]] ? `${dataItem[fields[0]]} / ${dataItem[fields[1]]}` : (dataItem[fields[0]] || dataItem[fields[1]]);
        }

		loadingPanel = (
			<div className="k-loading-mask">
				<span className="k-loading-text">Loading</span>
				<div className="k-loading-image"></div>
				<div className="k-loading-color"></div>
			</div>
		);
		render() {
			const dataToShow = (this.state.result && this.state.dataState.group.length > 0)
				? this.state.result
				: this.state.data;
			const newData = setExpandedState({
				data: dataToShow,
				collapsedIds: this.state.collapsedState,
			});
			return (
				<div style={{ position: "relative" }}>
					{this.state.isLoading && this.loadingPanel}
					{this.state.error && (
						<Alert
							style={{ marginBottom: 10, marginTop: 10 }}
							type="error"
							description={this.state?.error}
							showIcon
						/>
					)}
					{((window.location.pathname.includes('/pyrroscustomer/details') || window.location.pathname.includes('/openpaydcustomer/details')) && 
					 <div style={{height:"0",overflow:"hidden", width:"100%"}}>
                        <PDFExport margin={5} scale={0.55} paperSize="A4" repeatHeaders={true} fileName={window.location.pathname.includes('/pyrroscustomer/details') ? "Pyrros Customer Transaction History":"OpenPayd Customer Transaction History"} ref={this.tempRef}>
							<h1 style={{textAlign:'center'}} className="pdt-title">{window.location.pathname.includes('/pyrroscustomer/details') ? "Pyrros Customer Transaction History":"OpenPayd Customer Transaction History"}</h1>
                            <div className='statement-header logo-content'>
                                <div style={{margin:'16px 0'}}> <img src={logColor} className="logo" /></div>
                              
                            </div>
                            <div>
                                <table className="transaction-pdf-template" style={{ width:"100%"}}>
                                    <thead style={{ background: "#cccccc" }}>
                                        <th  style={{ width:"90px",textAlign:'left'}}>Date</th>
                                        <th style={{ width:"90px",textAlign:'left'}}>Transaction Type</th>
										<th style={{ width:"90px",textAlign:'left'}}>Currency</th>
                                        <th style={{ width:"90px",textAlign:'left'}}>Gross Amount</th>
                                        <th style={{ width:"90px",textAlign:'left'}}>Commission</th>
                                        <th style={{ width:"90px",textAlign:'left'}}>Net Amount</th>
                                        <th style={{ width:"90px",textAlign:'left'}}>Status</th>
                                        
                                    </thead>
                                    <tbody className='pdt-data'style={{ width:"100%"}}>
                                        {this.state?.data?.map(item => <tr>
											<td  style={{ width:"90px"}}>{moment(apiCalls?.convertUTCToLocalTime(item?.date)).format(config?.dateFormates?.dateTimeFormate)}</td>

                                            <td style={{ width:"90px"}}>{item?.transaction}</td>
											<td style={{ width:"90px"}}>{item.currency}</td>
                                            <td style={{ width:"90px"}}>{item.grossAmount}</td>
                                            <td style={{ width:"90px"}}>{item.commissions}</td>
                                            <td style={{ width:"90px"}}>{item?.netAmount}</td>
                                            <td style={{ width:"90px"}}>{item?.status}</td>
                                           
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </PDFExport>
                    </div>)}
					{((window.location.pathname.includes('/pyrroscustomer/details') || window.location.pathname.includes('/openpaydcustomer/details')) && this.props.showExcelExport) && (
						<div className='text-right secureDropdown export-pdf'>
							<Dropdown
								overlayClassName="secureDropdown depwith-drpdown transacton-drpdwn mobile-transaction"
								overlay={<Menu >
									<ul className="drpdwn-list pl-0">
										<li
										onClick={this.handleExcelExport}
										><a>Export to Excel</a></li>
										<li
										onClick={this.exportToPDF}
										><a>Export to Pdf</a></li>

									</ul>
								</Menu>}
								placement="bottomLeft"
							>
								<Button>Download Transactions<span className='icon c-pointer md export-excel ml-8'></span></Button>
							</Dropdown>
						</div>
					)}
                         
					{this.props.showExcelExport ? (
						<ExcelExport
							data={newData}
							fileName={this.props?.pKey + ".xlsx"}
							ref={(exporter) => {
								this.excelRef = exporter;
							}}>
							<WrappedGrid
								cellRender={this.cellRender}
								resizable={true}
								filterOperators={filterOperators}
								pageable={{ pageSizes: [5, 10, 20, 30, 40, 50, "All"] }}
								{...this.props}
								total={this.state.total}
								data={newData}
								group={this.state.dataState.group}
								onExpandChange={this.expandChange}
								expandField="expanded"
								skip={this.state.dataState.skip}
								pageSize={this.state.dataState.take}
								filter={this.state.dataState.filter}
								sort={this.state.dataState.sort}
								onDataStateChange={this.handleDataStateChange}>
								{this.state.dataState.group.length !== 0 && (
									<GridToolbar>
										<Button
											className="primary-btn"
											style={{ backgroundColor: "yellow" }}
											onClick={this.onGroupsToggle}>
											{this.state.collapsedState.length ? "Expand" : "Collapse"}{" "}
											Groups
										</Button>
									</GridToolbar>
								)}
								{this.props.children}
							</WrappedGrid>
						</ExcelExport>
					) : (
						<WrappedGrid
							cellRender={this.cellRender}
							resizable={true}
							filterOperators={filterOperators}
							pageable={{ pageSizes: [5, 10, 20, 30, 40, 50, "All"] }}
							{...this.props}
							total={this.state.total}
							data={newData}
							group={this.state.dataState.group}
							onExpandChange={this.expandChange}
							expandField="expanded"
							skip={this.state.dataState.skip}
							pageSize={this.state.dataState.take}
							filter={this.state.dataState.filter}
							sort={this.state.dataState.sort}
							onDataStateChange={this.handleDataStateChange}>
							{this.state.dataState.group.length !== 0 && (
								<GridToolbar>
									<button
										className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
										onClick={this.onGroupsToggle}>
										{this.state.collapsedState.length ? "Expand" : "Collapse"}{" "}
										Groups
									</button>
								</GridToolbar>
							)}
							{this.props.children}
						</WrappedGrid>
					)}
				</div>
			);
		}

		componentDidMount() {
			this.fetchData(this.state.dataState);
		}
		handleDataStateChange = (changeEvent) => {
			const isObjectEqual = (obj1, obj2) => {
				const obj1Keys = Object.keys(obj1);
				const obj2Keys = Object.keys(obj2);
				if (obj1Keys.length !== obj2Keys.length) return false;
				for (const key of obj1Keys) {
					if (obj1[key] !== obj2[key]) return false;
				}
				return true;
			}
			const didArrayChange = (arr1, arr2) => {
				if ((arr1 === undefined && arr2 === undefined) || (arr1 && !arr1.length && !arr2) || (arr2 && !arr2.length && !arr1)) {
					return false;
				}
				if (!arr1 || !arr2) return true;
				if (arr1.length !== arr2.length) return true;
				for (let i = 0; i < arr1.length; i++) {
					if (!isObjectEqual(arr1[i], arr2[i])) return true;
				}
				return false;
			};
			const applyDataToGrid = (changeEvents) => {
				let _dataState = changeEvents.dataState;
				if (isNaN(_dataState.take)) {
					_dataState.take = this.state.total;
				}
				this.setState({ dataState: _dataState });
				this.fetchData(_dataState);
			}

			const { group: prevGroup } = this.state.dataState
			const { group: eventGroup } = changeEvent.dataState
			applyDataToGrid(changeEvent)

			if (didArrayChange(prevGroup, eventGroup)) {
				const newDataState = processWithGroups(this.state.ungroupedData.length > 0 ? this.state.ungroupedData : this.state.data, changeEvent.dataState.group);
				this.setState({
					collapsedState: [],
					result: changeEvent.dataState.group.length > 0 ? newDataState : undefined,
					group: changeEvent.dataState.group,
				});
			}
			this.props.getDataStateFromChild(changeEvent.dataState)
		};
		_encrypt(msg, key) {
			msg = typeof msg == "object" ? JSON.stringify(msg) : msg;
			var salt = CryptoJS.lib.WordArray.random(128 / 8);

			key = CryptoJS.PBKDF2(key, salt, {
				keySize: 256 / 32,
				iterations: 10,
			});

			var iv = CryptoJS.lib.WordArray.random(128 / 8);

			var encrypted = CryptoJS.AES.encrypt(msg, key, {
				iv: iv,
				padding: CryptoJS.pad.Pkcs7,
				mode: CryptoJS.mode.CBC,
			});
			return salt?.toString() + iv?.toString() + encrypted?.toString();
		}
		fetchData(dataState) {
			if (dataState.filter) {
				dataState.filter.filters?.map((item) => {
					item.filters?.map((value) => {
						if (
							value.operator === "gte" ||
							value.operator === "gt" ||
							value.operator === "lte" ||
							value.operator === "lt"
						) {
							value.value = value.value
								? value.operator === "lte" || value.operator === "gt"
									? new Date(moment(value.value).format("YYYY-MM-DDT23:59:59"))
									: new Date(moment(value.value).format("YYYY-MM-DDT00:00:00"))
								: null;
						}
					});
				});
			}
			this.setState({ ...this.state, isLoading: true });
			const {
				oidc: { deviceToken },
				userConfig: { userProfileInfo },
				permissions: { currentScreenId, currentScreenTabId },
			} = store.getState();
			let queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state.
			const hasGroups = dataState.group && dataState.group.length;
			if (this.props.additionalParams) {
				let _additionalParams = "";
				for (let key in this.props.additionalParams) {
					_additionalParams =
						_additionalParams + `/${this.props.additionalParams[key]}`;
				}
				queryStr = _additionalParams + "?" + queryStr;
			} else {
				queryStr = "?" + queryStr;
			}
			const base_url = this.props.url;
			const Authorization = `Bearer ${deviceToken}`;
			const Authentication = this._encrypt(
				`{CustomerId:"${userProfileInfo?.id}",Action:"View", FeatureId:"${currentScreenTabId || currentScreenId}"}`,
				userProfileInfo.sk
			);

			const init = {
				method: "GET",
				accept: "application/json",
				headers: {
					Authorization: Authorization,
					AuthInformation: Authentication,
				},
			};
			fetch(`${base_url}${queryStr}`, init)
				.then(response => {
					if (response.ok) {
						this.setState({ ...this.state, gridStatus: response?.status, error: null })
						return response.json()
					} else {
						this.setState({ ...this.state, error: apiCalls.isErrorDispaly(response), isLoading: false, data: [] })
					}
				})
				.then(({ data, total }) => {
					function extractObjectsWithId(items) {
						let results = [];
						items.forEach(item => {
							if (item.items) {
								results = result.concat(extractObjectsWithId(item.items));
							} else if (item.id) {
								results.push(item);
							}
						});
						return results;
					}
					const originalData = (data && this.state.group.length > 0) ? extractObjectsWithId(data) : [];
					const result = processWithGroups(this.state.group.length > 0 ? originalData : data, this.state.group);
					this.setState({
						ungroupedData: originalData,
						data: hasGroups ? translateDataSourceResultGroups(data) : data,
						total,
						dataState,
						error: null,
						isLoading: false,
						result: this.state.group.length > 0 ? result : undefined,
					});
				})
				.catch(err => {
					if (this.state?.gridStatus === 401) {
						this.props.history.push("/accessdenied");
					} else {
						this.setState({ ...this.state, error: apiCalls.isErrorDispaly(err), isLoading: false })
					}

				});
		}
	}
}