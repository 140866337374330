import React, { Component } from "react";
import List from "../grid.component";
import { Typography, Spin,Alert } from "antd";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import config from "../../config";

const { Text } = Typography;

class Reconcile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			check: false,
			selection: [],
			selectedObj: {},
			KpisList: [],
			kpiLoader: true,
			errorMsg:null
		};
	}
	componentDidMount() {
		this.loadKpis();
	}
	loadKpis = async () => {
		let res = await apiCalls.getrecounicilkapiData();
		if (res.ok) {
			this.setState({ ...this.state, KpisList: res.data, kpiLoader: false,errorMsg:null });
		} else {
			this.setState({ ...this.state, kpiLoader: false,errorMsg:apiCalls.isErrorDispaly(res) });
		}
	};

	gridColumns = [
		{
			field: "startDate",
			title: "Start Date",
			filter: true,
			width:300,
			filterType: "date",
			customCell: (props) => (
				<td>
					<div className="gridLink" onClick={() => this.reconcileView(props)}>
					{props.dataItem.startDate ? <Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem.startDate && apiCalls.convertUTCToLocalTime(props.dataItem.startDate) || props.dataItem.startDate}</Moment> : props.dataItem.startDate}
					</div>
				</td>
			),
		},
		{
			field: "endDate",
			title: "End Date",
			filter: true,
			width:300,
			filterType: "date",
			customCell: (props) => (
				<td>
					<div>
					{props.dataItem.endDate ? <Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem.endDate && apiCalls.convertUTCToLocalTime(props.dataItem.endDate) || props.dataItem.endDate}</Moment> : props.dataItem.endDate}
					</div>
				</td>
			),
		},
		{
			field: "fiatTrCount",
			title: "Fiat Count",
			filter: true,
			dataType: "number",
			filterType: "numeric",
			width: 300 ,
		},
		{
			field: "cryptoTrCount",
			title: "Crypto Count",
			filter: true,
			dataType: "number",
			filterType: "numeric",
			width:240,
		},
	];

	reconcileView = (e) => {
		const items = e.dataItem;
		this.props.history.push(`/reconcile/view/${items.id}`);
		this.props.dispath(
			setBreadcrumb({
				key: `/reconcile/view/${items.id}`,
				val: "Reconcile view",
			})
		);
	};
	getBalance = (details, type) => {
		let sum = 0;
		for (let i in details) {
			if (details[i].isProvider === type) {
				sum = parseFloat(sum) + parseFloat(details[i].value);
			}
		}
		return sum;
	};

	tableData = (details, provider) => {
		let data;
		details.forEach((element) => {
			if (element.provider === provider) {
				data = element.value;
			}
		});
		return data;
	};

	render() {
		const{errorMsg}=this.state
		return (
			<>
			{errorMsg !== undefined && errorMsg !== null && (
					<Alert
						className="w-100 mb-16"
						type="warning"
						description={errorMsg}
						showIcon
					/>
				)}
				<div>
					<List
					    className="reconcilation-grid reclgrid-ncls"
						showActionBar={true}
						pKey={"reconcile"}
						ref={this.gridRef}
						url={
							process.env.REACT_APP_RECONCILE_GRID_API +
							`Reconcile/GetReconcileK`
						}
						columns={this.gridColumns}
					/>
				</div>
				<div className="case-ribbon mt-16">
					<Spin spinning={this.state.kpiLoader}>
						<div>
							<table className="pay-grid">
								<thead>
									<tr>
										<th style={{ width: 200 }}>Wallet Code</th>
										<th style={{ width: 350 }}>Suissebase Total Balance</th>
										<th style={{ width: 350 }}>Provider Total Balance</th>
										{}
										<th style={{ width: 200 }}>Suissebase</th>
										<th style={{ width: 200 }}>Suissebase Transit</th>
										<th style={{ width: 200 }}>Kraken</th>
										<th style={{ width: 200 }}>Fireblocks</th>
									</tr>
								</thead>
								<tbody>
									{this.state.KpisList.map((item, i) => {
										return (
											<>
												<tr key={i}>
													<td style={{ width: 200 }}>
														{" "}
														<span>{item.coin}</span>
													</td>
													<td style={{ width: 350 }}>
														<div className="d-flex align-center ">
															<Text className="db-kpi-label badge">
																<NumberFormat
																	className=""
																	value={this.getBalance(item.details, false)}
																	displayType={"text"}
																	decimalScale={8}
																	thousandSeparator={true}></NumberFormat>
															</Text>
														</div>
													</td>
													<td style={{ width: 350 }}>
														<div className="d-flex align-center ">
															<Text className="db-kpi-label badge">
																<NumberFormat
																	className=""
																	value={this.getBalance(item.details, true)}
																	displayType={"text"}
																	decimalScale={8}
																	thousandSeparator={true}></NumberFormat>
															</Text>
														</div>
													</td>

													<td style={{ width: 200 }}>
														<NumberFormat
															className="db-kpi-val1"
															value={this.tableData(item.details, "Suissebase")}
															decimalScale={8}
															thousandSeparator={true}
															displayType={"text"}
														/>
													</td>
													<td style={{ width: 200 }}>
														<NumberFormat
															className="db-kpi-val1"
															value={this.tableData(
																item.details,
																"Suissebase Transit"
															)}
															decimalScale={8}
															thousandSeparator={true}
															displayType={"text"}
														/>
													</td>
													<td style={{ width: 200 }}>
														<NumberFormat
															className="db-kpi-val1"
															value={this.tableData(item.details, "Kraken")}
															decimalScale={8}
															thousandSeparator={true}
															displayType={"text"}
														/>
													</td>
													<td style={{ width: 200 }}>
														<NumberFormat
															className="db-kpi-val1"
															value={this.tableData(item.details, "Fireblocks")}
															decimalScale={8}
															thousandSeparator={true}
															displayType={"text"}
														/>
													</td>
												</tr>
											</>
										);
									})}
								</tbody>
							</table>
						</div>
					</Spin>
				</div>
			</>
		);
	}
}
const connectDispatchToProps = (dispath) => {
	return {
		dispath,
	};
};
const connectStateToProps = ({ breadCrumb }) => {
	return {
		breadCrumb,
	};
};

export default connect(connectStateToProps, connectDispatchToProps)(Reconcile);
