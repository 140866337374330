import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getDeductionDetailView = (id) => {
    return clientApi.get(ApiControllers.withdraw + `Deduction/${id}`);
}
const getDeductionReasonDetails = () => {
    return clientApi.get(ApiControllers.common + `ControlCodes?codeCategory=Deductions`);
}
const getCustomerWalletsDetails = (customerId) => {
    return clientApi.get(ApiControllers.withdraw + `Deductions/walletbalance/${customerId}`);
}
const saveDeductionsDetails = (obj) => {
    return clientApi.post(ApiControllers.withdraw + `Deductions`,obj);
}
const saveBatchDeductionsDetails = (obj) => {
    return clientApi.post(ApiControllers.withdraw + `BatchDeductionSave`,obj);
}
const getCustomerWallets = () => {
    return clientApi.get(ApiControllers.withdraw + `Deductions/CustomersWallets`);
}
const getCustomerState = (state) => {
    return clientApi.get(ApiControllers.common + `statechange/Deduction/${state}`);
}
const getFrequencyLu = () => {
    return clientApi.get(ApiControllers.withdraw + `FrequencyLookUp`);
}
const saveCustomerState = (obj) => {
    return clientApi.put(ApiControllers.withdraw + `DeductionsStateChange`,obj);
}
const deleteDeductionRecord=(id)=>{
    return clientApi.delete(ApiControllers.withdraw + `Deductions/RecurringDeductions/${id}`)
  
  }

export { getDeductionDetailView,getDeductionReasonDetails,getCustomerWalletsDetails,saveDeductionsDetails,getCustomerWallets,getCustomerState,saveCustomerState,getFrequencyLu,saveBatchDeductionsDetails,deleteDeductionRecord}