import React, { useReducer } from "react";
import { connect } from "react-redux";
import { Radio, Alert } from "antd";
import DeductionCustomersGrid from './deductionCustomers.component/index';
import DeductionGrid from "./deductionhistory.component/deductionGrid";
import RecurringGrid from "./recurring.component";

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const Deductions = (props) => {
    const initialState = {
        selectedTab: 1,
        gridErrorMsg: null
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleDeductionTabs = (e) => {
        dispatch({ type: 'update', payload: { selectedTab: e.target.value, gridErrorMsg: null } });
    };

    const handleDeductionTab = () => {
        dispatch({ type: 'update', payload: { selectedTab: 2 } });
    };

    const handleRecurringTab = () => {
        dispatch({ type: 'update', payload: { selectedTab: 3 } });
    };

    const getErrorMsg = (val) => {
        dispatch({ type: 'update', payload: { gridErrorMsg: val } });
    };

    return (
        <>
            {state.gridErrorMsg && (
                <Alert
                    className="mb-16"
                    type="error"
                    description={state.gridErrorMsg}
                    showIcon
                />
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                className="mb-16"
            >
                <Radio.Group
                    value={state.selectedTab}
                    onChange={handleDeductionTabs}
                    className="buysell-toggle mb-0 deductions-toggle">
                    <Radio.Button value={1}>Customers</Radio.Button>
                    <Radio.Button value={2}>Deductions</Radio.Button>
                    <Radio.Button value={3}>Recurring</Radio.Button>
                </Radio.Group>
            </div>
            {state.selectedTab === 3 && <RecurringGrid handleDeductionTab={handleDeductionTab}handleRecurringTab={handleRecurringTab} selectedTab={state.selectedTab} getErrorMsg={getErrorMsg} />}
            {state.selectedTab === 2 && <DeductionGrid props={props} getErrorMsg={getErrorMsg} />}
            {state.selectedTab === 1 && <DeductionCustomersGrid handleDeductionTab={handleDeductionTab} handleRecurringTab={handleRecurringTab} getErrorMsg={getErrorMsg} />}
        </>
    );
};

export default connect()(Deductions);
