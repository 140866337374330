import { create } from "apisauce";
import { store } from "../store";
import CryptoJS from "crypto-js";
import { updateAccessDenied, updateTabAccessDenied } from "../reducers/permissionsReducer";

const clientFormApi = create({
	baseURL: process.env.REACT_APP_API_END_POINT + "/api",
});

const clientGridApi = create({
	baseURL: process.env.REACT_APP_RECONCILE_GRID_API,
});

const clientApi = create({
	baseURL: process.env.REACT_APP_API_END_POINT + "/api/v1/",
});
const companyApi = create({
	baseURL: process.env.REACT_APP_COMPANY_API_END_POINT + "/api/v1/",
});
const reportClient = create({
	baseURL: process.env.REACT_APP_API_END_POINT + "/api/v1/",
});
const bankApi=create({
    baseURL:process.env.REACT_APP_BANK_API_END_POINT + "/api/v1/",
});
const ipRegistry = create({
	baseURL:"https://api.ipstack.com"
});
const _encrypt = (msg, key) => {
	msg = typeof msg == "object" ? JSON.stringify(msg) : msg;
	var salt = CryptoJS.lib.WordArray.random(128 / 8);

	key = CryptoJS.PBKDF2(key, salt, {
		keySize: 256 / 32,
		iterations: 10,
	});

	var iv = CryptoJS.lib.WordArray.random(128 / 8);

	var encrypted = CryptoJS.AES.encrypt(msg, key, {
		iv: iv,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
	});
	return salt.toString() + iv.toString() + encrypted.toString();
};

const uploadClient = create({
    baseURL: process.env.REACT_APP_UPLOAD_API
})

const reportClientget = reportClient.get;
reportClient.get = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions,
	} = store.getState();
	const response = await reportClientget(url, null, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${permissions?.currentScreenTabId || permissions?.currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const get = clientApi.get;
clientApi.get = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions,
	} = store.getState();
	const response = await get(url, null, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${permissions?.currentScreenTabId || permissions?.currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		if(permissions?.currentScreenTabId){
			store.dispatch(updateTabAccessDenied(true));
		}else{
			store.dispatch(updateAccessDenied(true));
		}
		
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const post = clientApi.post;
clientApi.post = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await post(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${currentScreenTabId ||currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		if(currentScreenTabId){
			store.dispatch(updateTabAccessDenied(true));
		}else{
			store.dispatch(updateAccessDenied(true));
		}
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const put = clientApi.put;
clientApi.put = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await put(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${currentScreenTabId ||currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		if(currentScreenTabId){
			store.dispatch(updateTabAccessDenied(true));
		}else{
			store.dispatch(updateAccessDenied(true));
		}
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const delete1 = clientApi.delete;
clientApi.delete = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await delete1(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${currentScreenTabId||currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const clientFormApiget = clientFormApi.get;
clientFormApi.get = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions,
	} = store.getState();
	const response = await clientFormApiget(url, null, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${permissions?.currentScreenTabId || permissions?.currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const clientFormApipost = clientFormApi.post;
clientFormApi.post = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await clientFormApipost(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${currentScreenTabId ||currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const clientFormApiput = clientFormApi.put;
clientFormApi.put = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await clientFormApiput(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${currentScreenTabId ||currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const clientFormApidelete1 = clientFormApi.delete;
clientFormApi.delete = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await clientFormApidelete1(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "view"
					}", FeatureId:"${currentScreenTabId||currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const companyGet = companyApi.get;
companyApi.get = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await companyGet(url, null, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
				? _encrypt(
					`{CustomerId:"${userProfileInfo?.id}",Action:"${action || "View"
					}", FeatureId:"${currentScreenTabId || currentScreenId}"}`,
					userProfileInfo.sk
				)
				: "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const bankGet = bankApi.get;
bankApi.get = async (url, params, axiosConfig) => {
    const {
        oidc: { deviceToken },
        userConfig: { userProfileInfo },
        currentAction: { action },
    } = store.getState();
	const response = await bankGet(url, null, {
        headers: {
            Authorization: `Bearer ${deviceToken}`,
            AuthInformation: userProfileInfo?.id
                ? _encrypt(
                    `{CustomerId:"${userProfileInfo?.id}",Action:"${action || "View"
                    }"}`,
                    userProfileInfo.sk
                )
                : "",
        },
    });
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
    return response;
};

const bankPost = bankApi.post;
bankApi.post = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },		
	} = store.getState();
	const response = await bankPost(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
                ? _encrypt(
                    `{CustomerId:"${userProfileInfo?.id}",Action:"${action || "View"
                    }"}`,
                    userProfileInfo.sk
                )
                : "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
const bankPut = bankApi.put;
bankApi.put = async (url, params, axiosConfig) => {
	const {
		oidc: { deviceToken },
		userConfig: { userProfileInfo },
		currentAction: { action },
		permissions: { currentScreenId, currentScreenTabId },
	} = store.getState();
	const response = await bankPut(url, params, {
		headers: {
			Authorization: `Bearer ${deviceToken}`,
			AuthInformation: userProfileInfo?.id
                ? _encrypt(
                    `{CustomerId:"${userProfileInfo?.id}",Action:"${action || "View"
                    }",FeatureId:"${currentScreenTabId ||currentScreenId}"}`,
                    userProfileInfo.sk
                )
                : "",
		},
	});
	if (response.status === 401) {
		store.dispatch(updateAccessDenied(true));
	} else {
		store.dispatch(updateAccessDenied(false));
	}
	return response;
};
export {
	clientApi,
	reportClient,
	ipRegistry,
	companyApi,
	clientFormApi,
	clientGridApi,
	bankApi,
	uploadClient
};
