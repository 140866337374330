import React, { Component } from "react";
import List from "../grid.component";
import { connect } from "react-redux";
import {getNameLu} from "../customers.component/api";
import { Form,Col,Select,Button, Row } from "antd";

const { Option } = Select;
class Bonus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			check: false,
			selection: [],
			selectedObj: {},
            searchObj: {
                memberName: ""
              },
		};
        this.gridRef = React.createRef();
	}
	componentDidMount() {
		this.getNameLookup(null, null);
	}
	

  gridColumns = [
    {
      field: "date",
      title: "Date",
      filter: true, width:250,
      isShowTime: true,
      filterType: "date",
      
      },
    { field: "name", title: "Business Name/Personal Name", filter: true,width:300 },
    { field: "walletCode", title: "Wallet code", filter: true,width:200 },
    { field: "transactionType", title: "Transaction type", filter: true, width:200 },
    { field: "transactionValue", title: "Transaction value", filter: true, dataType: "number", filterType: "numeric", width:200 },
    { field: "baseCurrency", title: "Base Currency", filter: false,width:200 },
    { field: "baseValue", title: "Base Value", filter: true, dataType: "number", filterType: "numeric",width:200 },
    { field: "value", title: "Bonus Earned", filter: true, dataType: "number", filterType: "numeric",width:200 },

];

    getNameLookup = async (emails, memberName) => {
        let response = await getNameLu(
          emails === null ? null : emails,
          memberName === null ? null : memberName
        );
        if (response.ok) {
          if (memberName) {
            this.setState({ ...this.state, customerNames: response.data });
          } else {
            this.setState({ ...this.state, searchObj: { memberName: (response.data[0].id === "00000000-0000-0000-0000-000000000000" ? "" : response.data[0].id) }, customerNames: response.data });
          }
        }
      };
    
      handleChange = (value, prop) => {
        var val = "";
        let { accounttypeData, searchObj } = this.state;
        if (prop === "customerType") {
          let index = accounttypeData.findIndex(function (o) {
            return o.name === value;
          });
          val = accounttypeData[index].name;
          searchObj[prop] = prop === "customerType" ? val : value;
          this.setState({ ...this.state, searchObj });
        } else {
          const obj = this.state.customerNames?.find((item) => item.name === value);
          
          searchObj[prop] = obj.id === "00000000-0000-0000-0000-000000000000" ? "": obj.id;
          this.setState({ ...this.state, searchObj });
        }
      };
      handleSearch = (e, type) => {
        let sval = e.target.value;
        if (sval !== null && sval.length > 2) {
          if (type === "memberName") {
            this.getNameLookup(null, sval);
          }
        }
      };
      handlebtnSearch = () => {
        let { searchObj } = this.state;
        this.setState({ ...this.state, searchObj }, () => {
          this.gridRef.current.refreshGrid();
        });
      };
	
	render() {
		return (
			<>
            <Row  style={{ alignItems: "flex-end" }}>
             <Col sm={24} md={7} className="px-8">
                <Form.Item
                  name="names"
                  className="input-label mb-0"
                  label="Business Name/Personal Name"
                >
                  <Select
                    className="cust-input w-80"
                    showSearch
                    defaultValue="All"
                    onKeyUp={(e) => this.handleSearch(e, "memberName")}
                    onChange={(e) => this.handleChange(e, "memberName")}
                    placeholder="Select Name"
                  >
                    {this.state.customerNames?.map((item, idx) => (
                      <Option key={idx} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                
              </Col>
              <Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-right">
             
             <Button
               type="primary"
               className="primary-btn px-24 search-btn mt-20"
               htmlType="submit"
               onClick={this.handlebtnSearch}
             >Search
             </Button>
            
           </Col>
              </Row>
              <br/>
					<List
						showActionBar={true}
						pKey={"bonus"}
						ref={this.gridRef}
                        columns={this.gridColumns}
                        additionalParams={this.state.searchObj}
						url={
							process.env.REACT_APP_GRID_API +
							`Partner/GetBonusessK/Customer`
						}
                        showExcelExport={true}
					/>			
			</>
		);
	}
}
const connectDispatchToProps = (dispath) => {
	return {
		dispath,
	};
};
const connectStateToProps = ({ breadCrumb }) => {
	return {
		breadCrumb,
	};
};

export default connect(connectStateToProps, connectDispatchToProps)(Bonus);
