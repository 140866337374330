import React, { Component } from "react";
import {
  Typography,
  Button,
  Modal,
  Form,
  Input,
  message,
  Tooltip, Menu, Dropdown, Alert,Col,Select,Row
} from "antd";
import List from "../grid.component";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { validateContentRule } from "../../utils/custom.validator";
import { getKeyGeneration ,getStates,statusSaveMemberDeposit} from "./api";
import { connect } from "react-redux";
import {
  EmailShareButton, EmailIcon,
  FacebookShareButton, FacebookIcon,
  TelegramShareButton, TelegramIcon,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon
} from "react-share";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import Loader from "../loader.component";
import { publishShowActions } from "../grid.component/subscribir";
import config from "../../config";
const { Text } = Typography;
const { TextArea } = Input;
class DepositAdmin extends Component {
  constructor (props) {
    super(props);
    this.state = {
      depositerrorMsg: null,
      isOpen: false,
      disable: false,
      gridUrl: process.env.REACT_APP_GRID_API + "AppDeposit",
      selection: [],      
      stateChange: {},
      statesLst:[],
      showModal:false,
      check: false,
      selectedState:null,
      statusError:null,
      isStateSelected:true,
      selectedObj:{},
      stateLoading:true,
      emailError:null,
    };
    this.gridRef = React.createRef();
    this.formref = React.createRef();
    this.stausgridRef = React.createRef();
  }

  openModal = () => this.setState({ isOpen: true,emailError:null });
  closeModal = () => {
    this.formref.current.resetFields();
    this.setState({ isOpen: false });
  };

  shareMenu = (url) => {

    return <Menu className="share-adrs">
      <Menu.Item>
        <WhatsappShareButton te url={process.env.REACT_APP_WEB_URL} title={url} >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </Menu.Item>
      <Menu.Item>
        <EmailShareButton url={process.env.REACT_APP_WEB_URL} subject={"URL"} body={url} separator={";"} >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </Menu.Item>
      <Menu.Item>
        <TwitterShareButton url={process.env.REACT_APP_WEB_URL} title={url} >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </Menu.Item>
      <Menu.Item>
        <FacebookShareButton url={process.env.REACT_APP_WEB_URL} quote={url} >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item>
        <TelegramShareButton url={process.env.REACT_APP_WEB_URL} title={url} >
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
      </Menu.Item>
    </Menu>
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
              className="grid_check_box"
            />
            <span></span>
          </label>

      )
    },
    { field: "name", title: "Company Name", width: 180, filter: true },
    { field: "email", title: "Email", width: 250, filter: true },
    { field: "phoneNumber", title: "Phone Number", width: 170, filter: true },
    { field: "key", title: "Key", filter: true, width: 350 },
    {
      field: "",
      title: "Url",
      width: 560,
      customCell: (props) => (
        <td>
          <CopyToClipboard>
            <Text
              copyable
              className="fs-22 text-black-30 text-center custom-display"
            >
              {props.dataItem.url}
            </Text>
          </CopyToClipboard>
          <Dropdown overlay={() => this.shareMenu(props.dataItem.url)}>
            <span className="icon md share ml-16" />
          </Dropdown>
        </td>
      )
    },
    { field: "state", title: "Status", filter: true, width: 200 },
    {
      field: "", title: "", width: 100,
      customCell: (props) => (
        <td>
          <div className="d-flex align-center">

            {props.dataItem.actionType !== 'System Action' && <Tooltip title="status history">
              <span
                className="icon md info c-pointer ml-8"
                onClick={() => this.showDetails(props)}
              />
            </Tooltip>}
          </div>
        </td>)

    }
  ];
  createdColumns = [
    { field: "createdBy", title: "Name", width: 200, filter:false },
    { field: "status", title: "State", width: 200, filter:false },
     {
      field: "createdDate",
      title: "Date",
      width: 230,
      isShowTime: true,
      filter:false,
      customCell: (props) => (
        <td>
          <div>
            <Moment format={config?.dateFormates?.dateTimeFormate} >{props.dataItem.createdDate ? apiCalls.convertUTCToLocalTime(props.dataItem.createdDate) : props.dataItem.createdDate}</Moment>
          </div>
        </td>
      ),
    },
    {
      field: "remarks", title: "Remarks", width: 250,filter:false
    },
  ];
  componentDidMount=()=>{
    publishShowActions(true);
}
  showDetails=(props)=>{
    this.setState({...this.state, 
    innerGridUrl:process.env.REACT_APP_GRID_API + `Withdraw/WithDrawStatus/${props.dataItem?.id}`,
    showModal:true,
 },)
 }
  handleInputChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      warningMsg: null,
      depositerrorMsg:null,
    });
  };
  keyGeneration = async (values) => {
    this.setState({ ...this.state, disable: true });
    let response = await getKeyGeneration(
      values.email,
      this.props.userConfig?.userName
    );
    if (response.ok) {
      this.setState({ ...this.state, isOpen: false, disable: false,emailError:null });
      message.destroy();
      message.success({
        content: "Url sent successfully.",
        className: "custom-msg",
        duration: 4
      });
      this.formref.current.resetFields();
      this.gridRef.current.refreshGrid();
    } else {
      this.setState({ ...this.state, disable: false, emailError: apiCalls.isErrorDispaly(response) });
    }
  };
  onActionClick = (key) => {
    const actions = {
      "State Change": "statusChange",
    };
    this[actions[key]]();
  };
  statusChange = () => {
    this.setState({...this.state,depositerrorMsg:null,error:null,selectedState:this.state.selectedObj.state,stateLoading:true});
    if (!this.state.check) {
       message.destroy();
       this.setState({
        ...this.state,depositerrorMsg:"Please select the one record",
      });
    } else {
      getStates(this.state.selectedObj.state).then(response => {
        if (response.ok) {
            this.setState({ ...this.state, statesLst: response.data,depositerrorMsg:null,stateLoading: false});
            this.formref.current.setFieldsValue({...this.state,status:this.state.selectedObj.state,remarks:this.state.selectedObj.description,selectedState:this.state.selectedObj.state})
        }else{
          this.setState({ ...this.state,  error: apiCalls.isErrorDispaly(response),stateLoading:false });
        }
    })
      this.setState({...this.state,modal: true,errorMsg:null,depositerrorMsg:null,selectedState:this.state.selectedObj.state},);
    }
  };

  approveSave=async(values)=>{
    this.setState({...this.state,btnloading:true})
    let statusObj={
      Id:this.state.selectedObj.id,
      ScreenName : "MemberDeposit",
      ModifiedBy : this.props.userConfig.userName,
      description:values.remarks,
      Status: values.status,
      state : values.status,
      ModifiedDate : new Date(),
      StatusRemarks:values.remarks,
    }
    let res = await statusSaveMemberDeposit(statusObj);
    if(res.ok){
      this.setState({...this.state,btnloading:false,modal:false,selection:[],selectedObj:{},check:false,
        selectedState:null,isStateSelected:true,stateLoading:true})
      message.success({
        content :`Record ${values.status} successfully`,
        className: "custom-msg",
        duration: 1
      });
      this.gridRef.current.refreshGrid();
    }else{
      this.setState({...this.state,statusError:apiCalls.isErrorDispaly(res),selection:[],selectedObj:{},btnloading:false,})
    }
  }

  handleState=(val)=>{
    if(val=="Others"){
      this.formref.current.setFieldsValue({...this.state,remarks:null})
    }  
    this.setState({...this.state,selectedState:val,isStateSelected:false})
  }
  onClosePopUp=()=>{
    this.setState({...this.state,isOpen:false,modal:false,selection:[],
      selectedObj:{},check:false,selectedState:null,isStateSelected:true,stateLoading:true,emailError:null})
  }
  onCloseStatusPupUp=()=>{
    this.setState({...this.state,showModal:false});
    setTimeout(()=>{
      publishShowActions(true);
    },300)
  }
  render() {
    const { gridUrl, depositerrorMsg,emailError,showModal } = this.state;
    return (
      <>
        <div className="text-right mb-16">
          <Button
            type="primary"
            className="primary-btn  mt-16"
            onClick={() => {
              this.openModal();
            }}
          >
           <span className="icon lg new-deposit mr-12 mb-4" /> <span className="mt-8">New Deposit{" "}</span>
          </Button>
        </div>
        {depositerrorMsg !== undefined && depositerrorMsg !== null && (
            <Alert className="w-100 mb-16" type="error" showIcon description={depositerrorMsg} />
          )}
        <div>
          <List
            showActionBar={true}
            onActionClick={(key) => this.onActionClick(key)}
            url={gridUrl}
            key={"memberdepositcrypto"}
            ref={this.gridRef}
            columns={this.gridColumns}
          />
            <Modal
          title="Status History"
          className="crypto-list footer-remove hide-filter status-histry"
          destroyOnClose={true}
          visible={showModal}
          closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={this.onCloseStatusPupUp} /></Tooltip>}
          footer={null}
          width={800}
        >
         <List  
          className="custom-status-grid send-custom-grid"
          columnsIsNotReorderable={true}
          ref={this.stausgridRef}
          url={this.state.innerGridUrl}
          columns={this.createdColumns}
          showActionBar={true}
          sortable={false}
          pSize={"All"}
          ignoreSaved={true}
        />
        </Modal>
        </div>
        <Modal  title="Send Url"
          visible={this.state.isOpen}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.closeModal} />
            </Tooltip>
          }
            destroyOnClose={true}
            footer={false}
        >
            <Form  onFinish={this.keyGeneration}
            autoComplete="off"
            ref={this.formref}>
                {emailError !== undefined && emailError !== null && (
                <Alert className="w-100 mb-16" type="error" showIcon description={emailError} />
              )}
                  <div className="">
                  <Row gutter={24} className="mb-24 pb-24 border-bottom">
                    <Col sm={24}>
                <Form.Item
              name="email"
              label="Email"
              className="input-label"
              required
              rules={[
                {
                    validator:(_,Val)=>{
                        if (!Val) {
                            return Promise.reject("Is required");
                        } else if (Val.length < 5 || Val.length > 50) {
                    
                            return Promise.reject("Enter valid email");
                        
                        } else if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,100}$/.test(Val))) {
                            return Promise.reject("Enter valid email");
                        } else {
                            return validateContentRule(_,Val);
                        }
                    }
                }
            ]}
            >
              <Input
                maxLength={50}
                placeholder="Email "
                className="cust-input"
              ></Input>
            </Form.Item></Col></Row>
            </div>
                <div>
                <Form.Item className="text-right mb-0">
                     <Button
                       loading={this.state.disable}
                       type="primary"
                       className="primary-btn  mt-16"
                       htmlType="submit"
                    >
                        Send
                    </Button>
                </Form.Item>
                </div>
            </Form>
        </Modal>
        <Modal title="Confirm Pending/Receive/Others?"
            visible={this.state.modal}
            destroyOnClose={true}
            footer={false}
            closeIcon={
              <Tooltip title="Close">
                <span className="icon md x c-pointer" onClick={this.onClosePopUp} />
              </Tooltip>
            }
        >
             {this.state?.statusError !== undefined && this.state?.statusError !== null && (
                <Alert className="w-100 mb-16" type="error" showIcon description={this.state?.statusError} />
              )}
            <Form ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.approveSave}>
                {this.state?.stateLoading && <Loader/>}
                {<Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="status"
                    label="State"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      }
                    ]}
                  >
                    <Select
                      className="cust-input mb-0"
                      onChange={(e) => this.handleState(e)}
                      placeholder="Select State"
                      disabled={this.state?.selectedObj?.state=="Others"}

                    >
                      {this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                {this.state?.selectedState=="Others" && <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="remarks"
                      label="Remarks"                      
                      className="input-label"
                       rules={[
                      {
                        required: true,
                        message: "Is required"
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                    >
                      <TextArea
                        placeholder="Remarks"
                        maxLength={100}
                        rows={4}
                        showCount
                        disabled={this.state?.selectedObj?.state=="Others"}
                      />
                    </Form.Item>
                  </Col>}
                </Row>}
                <div>
                <Form.Item className="text-right mb-0">
                    <Button
                        type="primary"
                        className="primary-btn cancel-btn mr-8"
                        onClick={this.onClosePopUp}
                    >
                        Cancel
                    </Button>
                    {this.state?.selectedObj?.state !=="Others" &&<Button
                      	type="primary"
                        key="submit"
                        className="primary-btn"
                        htmlType="submit"
                        loading={this.state?.btnloading}
                        disabled={this.state?.isStateSelected}
                    >
                        Save
                    </Button>}
                </Form.Item>
                </div>
            </Form>
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(DepositAdmin);
