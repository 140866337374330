import React, { Component } from "react";
import { setBreadcrumb, } from "../../reducers/breadcrumbReducer";
import List from "../grid.component";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Tooltip from "antd/lib/tooltip";
import { message, Input } from "antd";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Alert from "antd/lib/alert";
import { connect } from "react-redux";
import CreateCase from "../../utils/createCase";
import { stateSave, excelExport, getStates } from "./api";
import { CaseRediractions } from '../../utils/caseRediractions';
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import apiCalls from "../../api/apiCalls";
const { Option } = Select;
const { TextArea } = Input;
class BatchPaymentsGrid extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selection: [],
      selectedObj: {},
      selectedCaseId: "",
      selectedObjs: [],
      statuserrorMsg: null,
      modal: false,
      caseModal: false,
      btnLoading: false,
      withdraw: true,
      stateChange: {},
      statesLst: [],
      btnDisabled: false,
      obj: {
        id: "",
        screenName: "Batch Payments",
        state: "",
        statusRemarks: "",
        pCommisionValue: null,
        sweepSourceCommisionValue: null,
        sweepDestinationCommissionValue: null,
      },
      gridUrl: process.env.REACT_APP_GRID_API + `/MassPayments/TransactionDetails/${props?.match?.params?.id}`,
    };
    this.gridRef = React.createRef();
    this.formref = React.createRef();
  }

  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <label className="text-center custom-checkbox">
          <input
            id={props.dataItem.id}
            name="check"
            type="checkbox"
            checked={this.state.selection.indexOf(props.dataItem.id) > -1}
            onChange={(e) => this.handleSelectionChange(props, e)}
            className="grid_check_box"
          />
          <span></span>
        </label>
      )
    },
    {
      field: "importTransactionId", title: "Transaction ID", width: 200, filter: true, locked: true,
      customCell: (props) => (
        <div className="gridLink table-data-align" onClick={() => this.redirectTransactionView(props)}>
          {props.dataItem.importTransactionId}
        </div>
      )
    },
    {
      field: "amount",
      title: "Amount",
      width: 150,
      filter: true,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "status", title: "Status", width: 150, filter: true,
    },
    {
      field: "createdDate",
      title: "Date",
      width: 250,
      filter: true,
      filterType: "date",
      isShowTime: true,
    },
    { field: "abaRoutingSwiftCode", title: "Bank ID", width: 150, filter: true },
    { field: "bankName", title: "Bank Name", width: 150, filter: true },
    { field: "bankAddress", title: "Bank Address", width: 150, filter: true },
    { field: "accountNumber", title: "Recipient Account Number/IBAN", width: 270, filter: true },
    { field: "beneficiaryName", title: "Beneficiary Name", width: 180, filter: true },
    { field: "addressbookStatus", title: "Whitelisting Status", width: 180, filter: true },
    { field: "address", title: "Beneficiary Address", width: 200, filter: true },
    { field: "reference", title: "Reference", width: 200, filter: true },
    {
      field: "caseids",
      title: "Case Number",
      filter: true,
      width: 160,
      customCell: (props) => (
        <td>
          <div className="table-data-align">
            {props.dataItem.caseIds?.map(item => <div className="gridLink" onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
          </div>
        </td>
      ),
    },
    { field: "batchId", title: "Batch ID", width: 250, filter: true },
  ];

  handleSelectionChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection, selectedObjs } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (idx > -1) {
      selection.splice(idx, 1);
      selectedObjs.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
      selectedObjs.push(rowObj);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      stateChange: rowObj,
      selection,
      warningMsg: null,
      selectedObjs,
    });
  };
  createCaseView = (CaseData) => {
    let propsData = this.props
    this.props.dispatch(updateCurrentScreen("cases"));
    CaseRediractions(CaseData, propsData)
  };

  createCase = () => {
    const { selection } = this.state;
    if (selection.length == 0) {
      this.setState({ ...this.state, warningMsg: "Please select the one record", error: null, selection: [], errorMessage: null, });
    } else if (selection.length >= 2) {
      this.setState({ ...this.state, warningMsg: 'Please select only one record', selection: [], error: null });
    }
    else {
      this.setState({ ...this.state, caseModal: true, check: false });
    }
  }

  hideCaseModal = () => {
    this.setState({
      ...this.state, caseModal: false, selection: [],
      selectedObj: [],
    }, () => this.gridRef.current.refreshGrid());
  }
  redirectTransactionView = (e) => {
    const items = e.dataItem;
    const val = items?.txdRefId;
    this.props?.history?.push(`/withdraw/${val}/fiat`)
    this.props.dispatch(
      setBreadcrumb({
        key: `/withdraw/` + val,
        val: `${items?.importTransactionId + " " + " / " + " " + items?.status}`
      })
    );
  };

  isErrorDispaly = (objValue) => {
    if (objValue.data && typeof objValue.data === "string") {
      return objValue.data;
    } else if (
      objValue.originalError &&
      typeof objValue.originalError.message === "string"
    ) {
      return objValue.originalError.message;
    } else {
      return "Something went wrong please try again!";
    }
  };
  statusChange = async () => {
    const { selection } = this.state;
    this.setState({ ...this.state, statuserrorMsg: null });
    if (selection.length === 0) {
      this.setState({ ...this.state, warningMsg: "Please select the one record" });
      this.useDivRef?.current?.scrollIntoView();
    } else {
      for (const obj of this.state.selectedObjs) {
        if (obj.status !== this.state.selectedObjs[0].status) {
          this.setState({
            warningMsg: "Please select same state records",
            selection: [],
            selectedObjs: []
          });
          this.useDivRef?.current.scrollIntoView();
          return;
        }
      }
      getStates(this.state.selectedObj.status).then(response => {
        if (response.ok) {
          this.setState({ ...this.state, statesLst: response.data, btnLoading: false, statuserrorMsg: null, btnDisabled: false });
        } else {
          this.setState({ ...this.state, statuserrorMsg: apiCalls.isErrorDispaly(response) });
        }
      })
      this.setState({ ...this.state, modal: true, warningMsg: null, selectedObj: this.state.selectedObjs[0], stateChange: this.state.selectedObjs[0], error: null }, () =>
        this.formref.current.setFieldsValue({
          status: this.state.selectedObj.status,
          type: this.state.selectedObj.type,
          statusRemarks: this.state.selectedObj.statusRemarks,
          isCheckBox: this.state.selectedObj.isCheckBox,
          pCommisionValue: this.state.selectedObj.pcomissionvalue,
          sweepDestinationCommissionValue: this.state.selectedObj.sweepDestinationcomissionValue,
          sweepSourceCommisionValue: this.state.selectedObj.sweepSourceComissionValue,
        })
      );
    }
  };
  stateChange = (val) => {
    if (val === "Reject" || val === "Approve") {
      this.formref.current.setFieldsValue({ statusRemarks: "" })
    }
    this.setState({ ...this.state, stateChange: { status: val }, statuserrorMsg: null, btnDisabled: true });
  };

  saveState = async (values) => {
    message.destroy();
    this.setState({ ...this.state, btnDisabled: true, isLoading: false, error: null, btnLoading: true });
    let statusObj = this.state.obj;
    statusObj.ids = this.state.selection
    statusObj.id = this.state.selectedObj.id;
    statusObj.AdminId = this.props.userConfig.id;
    statusObj.type = 'Fiat';
    statusObj.status = values.status || this.state.selectedObj.status;
    statusObj.statusRemarks = values.statusRemarks;
    statusObj.isCheckBox = this.state.selectedObj.isCheckBox;
    statusObj.transactionId = values.transactionId;
    statusObj.modifiedBy = this.props.userConfig.userName
    statusObj.pCommisionValue =
      values.pCommisionValue;
    statusObj.sweepDestinationCommissionValue =
      values.sweepDestinationCommissionValue;
    statusObj.sweepSourceCommisionValue =
      values.sweepSourceCommisionValue;
    statusObj.currency = this.state.selectedObj.currency || this.state.selectedObj.walletCode;
    let res = await stateSave(statusObj)
    if (res.ok) {
      message.destroy();
      message.success({
        content: `${this.state.selection?.length > 1 ? "Records" : "Record"} ${values.status || this.state.selectedObj?.status}  successfully`,
        className: "custom-msg",
        duration: 3
      })
      this.setState({
        ...this.state,
        modal: false,
        btnDisabled: false, isLoading: false,
        selection: [],
        selectedObj: [],
        selectedObjs: [],
        statuserrorMsg: null, btnLoading: false,

      });
      this.gridRef.current.refreshGrid();
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            modal: false,
            btnDisabled: false, isLoading: false,
            selection: [],
            selectedObj: [],
            selectedObjs: []
          }),
        1000
      );
    }
    else {
      this.setState({ ...this.state, btnDisabled: false, isLoading: false, statuserrorMsg: apiCalls.isErrorDispaly(res), btnLoading: false });
    }
  };
  validateNumber = (_, validNumberValue) => {
    if (validNumberValue === ".") {
      return Promise.reject("Please enter valid content");
    }
    return Promise.resolve();
  }
  excelExportTransaction = async () => {
    const { selection } = this.state;
    const ids = selection;
    let obj = { ids }
    if (selection.length === 0) {
      this.setState({ ...this.state, warningMsg: "Please select the one record" });
    }
    else {
      let res = await excelExport(obj);
      if (res.ok) {
        this.setState({ ...this.state, selection: [], statuserrorMsg: null });
        window.open(res.data, '_self');
      } else {
        this.setState({ ...this.state, statuserrorMsg: apiCalls.isErrorDispaly(res) })
      }
    }
  }

  render() {
    const { selectedObj, modal, caseModal } = this.state;

    const renderHeaderIcons = () => (
      <div className="text-right mb-16">
        <Tooltip title="State Change">
          <span className="icon c-pointer md state-change mr-12" onClick={() => this.statusChange()} />
        </Tooltip>
        <Tooltip title="Create Case">
          <span className="icon c-pointer md create-case mr-12" onClick={() => this.createCase()} />
        </Tooltip>
        {this.props?.match?.params?.currency !== "EUR" &&
          <Tooltip title="Export Excel">
            <span className="icon c-pointer md export-excel mr-0 ant-tooltip-open" onClick={() => this.excelExportTransaction()}></span>
          </Tooltip>
        }
      </div>
    );

    const renderWarningAlert = () => (
      <span>
        {this.state.warningMsg !== undefined && this.state.warningMsg !== null && (
          <Alert
            className="mb-16 w-100"
            type="warning"
            description={this.state.warningMsg}
            showIcon
          />
        )}
      </span>
    );

    const renderCreateCaseModal = () => (
      <Modal
        title="Create Case"
        visible={caseModal}
        width={1000}
        style={{ top: 16 }}
        closeIcon={
          <Tooltip title="Close">
            <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
          </Tooltip>
        }
        destroyOnClose
        footer={null}
      >
        <CreateCase
          FromScreen='BatchPayment'
          ScreenId={selectedObj?.[0]}
          CreateCaseId={selectedObj?.selectedCaseId}
          ScreenName="BatchPayment"
          closeModal={this.hideCaseModal}
        />
      </Modal>
    );

    const renderStatusModal = () => {
      const renderStatusErrorMsg = () => (
        this.state.statuserrorMsg !== undefined && this.state.statuserrorMsg !== null && (
          <Alert
            className="mb-12 send-poperror"
            type="error"
            description={this.state.statuserrorMsg}
            showIcon
          />
        )
      );
    
      const renderImportantNote = () => (
        !this.state.withdraw && (
          <div className="mb-8">
            <Alert
              message={
                <Form.Item
                  name="isCheckBox"
                  valuePropName="checked"
                  className="mb-0"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Please select checkbox')),
                    },
                  ]}
                >
                  <div className="d-flex">
                    <label className="text-center custom-checkbox mr-8">
                      <input
                        name="check"
                        type="checkbox"
                        checked={this.state.selectedObj.isCheckBox}
                        onChange={(e) => this.handleImpNote(e)}
                        disabled={this.state.selectedObj.status === "Rejected" || this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1}
                      />
                      <span></span>
                    </label>
                    Important Note:
                  </div>
                </Form.Item>
              }
              description={<p className="text-red fs-14 mb-0 l-height-normal">Please ensure that you have transferred funds from Signet to Kraken prior to approval. Please click the checkbox above to confirm.</p>}
              type="info"
              className="imp-alert"
            />
          </div>
        )
      );
    
      const renderTransactionID = () => (
        <Col xs={24}>
          <Form.Item
            name="transactionId"
            label="Transaction ID"
            className="input-label"
            rules={[
              {
                required: this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Reject" && this.state.selectedObj.status !== "Rejected" ? true : false,
                message: "Is required",
                whitespace: true
              },
            ]}
          >
            <Input
              placeholder="Transaction ID"
              maxLength={150}
              className='cust-input'
              disabled={
                this.state.selectedObj.status === "Rejected" ||
                this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1
                  ? true
                  : false
              }
            />
          </Form.Item>
        </Col>
      );    
      return (
        <Modal
          className="text-break"
          title="Confirm Pending/Approve/Reject?"
          visible={modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={() => this.setState({ modal: false, selection: [], errorMsg: null, selectedObjs: [], statuserrorMsg: null })} />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {renderStatusErrorMsg()}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.saveState}
            >
              {renderImportantNote()}
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                {!this.state.withdraw && renderTransactionID()}
              </Row>
            </Form>
          </div>
        </Modal>
      );
    };
    

    return (
      <>
        {renderHeaderIcons()}
        {renderWarningAlert()}
        <List
          ignoreSaved={true}
          ref={this.gridRef}
          url={this.state.gridUrl}
          columns={this.gridColumns}
        />
        {renderCreateCaseModal()}
        {renderStatusModal()}
      </>
    );
  }

}
const connectStateToProps = ({ oidc, userConfig }) => {
  return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(BatchPaymentsGrid);