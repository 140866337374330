import React, { Component } from "react";
import { Alert } from "antd";
import List from "../grid.component";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import { setCurrentAction } from "../../reducers/actionsReducer";
import Moment from "react-moment";
import apiCalls from "../../api/apiCalls";
import config from "../../config";
class Payments extends Component {
  constructor (props) {
    super(props);
    this.state = {
      check: false,
      selection: [],
      selectedObj: {},
      alert: false,
      error: null,
      warningMsg: null
    };
    this.edit = this.edit.bind(this);
    this.gridRef = React.createRef();
  }
  componentDidMount() {
    setCurrentAction(null);
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <td className="text-center">
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
              className="grid_check_box"
            />
            <span></span>
          </label>
        </td>
      )
    },
    {
      field: "createdDate",
      title: "Date",
      filter: true,
      filterType: "date",
      locked: true,
      width: 180,
      customCell: (props) => (
        <td>
          <div className="gridLink" onClick={() => this.paymentsView(props)}>
          {props.dataItem.createdDate ? <Moment format={config?.dateFormates?.dateFormate}>{props.dataItem.createdDate && apiCalls.convertUTCToLocalTime(props.dataItem.createdDate) || props.dataItem.createdDate}</Moment> : props.dataItem.createdDate}
          </div>
        </td>
      )
    },
    {
      field: "name",
      title: "Name",
      filter: true,
      width: 180
    },
    { field: "email", title: "Email", filter: true, width: 250 },
    { field: "currency", title: "Currency", filter: true, width: 120 },
    { field: "totalAmount", title: "Total Amount", dataType: "number", filterType: "numeric", width: 200, filter: true },
    { field: "approvedAmount", title: "Approved Amount", dataType: "number", filterType: "numeric", width: 200, filter: true },
    { field: "count", title: "Count", filter: true, dataType: "number", filterType: "numeric", width: 100 },
    {
      field: "modifiedDate",
      title: "Modified Date",
      filter: true,
      filterType: "date",
      width: 150,
    },
    { field: "modifiedBy", title: "Modified By", width: 200, filter: true },
    { field: "state", title: "Status", filter: true, width: 120, }
  ];
  paymentsView = (e) => {
    this.props.dispath(setCurrentAction(null));
    const items = e.dataItem;
    const val = items.id;
    this.props.history.push("/payments/" + val + "/disabled");
    this.props.dispath(
      setBreadcrumb({
        key: "/payments/" + val + "/disabled",
        val: e.dataItem.name
      })
    );
  };
  edit = () => {
    if (!this.state.check) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record' })
    } else {
      setCurrentAction(null);
      const obj = this.state.selectedObj;
      const val = obj.id;
      this.props.history.push("/payments/" + val + '/edit');
      this.props.dispath(
        setBreadcrumb({
          key: "/payments/" + val + '/edit',
          val: obj.name
        })
      );
    }
  };
  handleInputChange = (prop, e) => {
    const rowChecked = prop.dataItem;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection,
      warningMsg: null
    });
  };

  onActionClick = (key) => {
    const actions = {
      edit: "edit"
    };
    this[actions[key]]();
  };

  render() {
    const { error, warningMsg } = this.state;
    return (
      <>
        {error !== undefined && error !== null && (
          <Alert type="error" className="mb-16" showIcon description={error} />
        )}
        {warningMsg !== undefined && warningMsg !== null && (
          <Alert
            className="w-100 mb-16"
            type="warning"
            description={warningMsg}
            showIcon
          />
        )}
        <div>
          <List
            showActionBar={true}
            onActionClick={(key) => this.onActionClick(key)}
            pKey={"payments"}
            ref={this.gridRef}
            url={process.env.REACT_APP_GRID_API + `MassPayments/AdminPayments`}
            columns={this.gridColumns}
          />
        </div>
      </>
    );
  }
}

const connectDispatchToProps = (dispath) => {
  return {
    dispath
  };
};
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return {
    breadCrumb,
    oidc,
    userConfig: userConfig?.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(Payments);
