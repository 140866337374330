import { Menu, Layout } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setCurrentAction } from "../reducers/actionsReducer";
import { getFeatures, getPermissions, updateCurrentScreenId } from "../reducers/permissionsReducer";
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Sider } = Layout;
class SideNav extends Component {
	state = {
		collapsed: false,
		path: '',
		parentClass: "",
		childClass: "",
		isEnableParent:null
	};
	componentDidMount() {
		this.props.fetchMenu(this.props?.userProfile?.id,this.props?.userProfile?.appId);
	}
	getActiveKey = () => {
		if(window.location?.pathname.indexOf('accessdenied')>-1){
			return this.state.prevPath
		}else{
			let path = window.location?.pathname;
			path = path === "/" ? "/dashboard" : path;
			path = path.split('/')[1];
			path = '/'+path
			if(path!==this.state.prevPath){
				this.setState({...this.state, prevPath:path})
			}
			return path;
		}
	};
	render() {
		let GroupMenu;
		if(this.props.permissions?.menuItems){
		 GroupMenu = [...new Map(this.props.permissions?.menuItems.map((m) => [m.group, m])).values()] 
		}else{
		 GroupMenu =[];
		}

		return (
			<>
				<Sider
					collapsed={this.props.sider}
					width="220"
					breakpoint="lg"
					theme="light"
					collapsedWidth={65}
					className="sidernav"
					style={{
						overflow: "auto",
						position: "fixed",
						left: 0,
					}}
					collapsible
					// collapsed={collapsed}
					onCollapse={this.onCollapse}>
					{this.state.isEnableParent==null&&<Menu theme="light" mode="inline" className={this.state.parentClass} style={{left:"100%"}}
					>
						{GroupMenu.length>0 && GroupMenu.map((item)=> {
						return <Menu.Item onClick={() => {
							this.setState({ ...this.state, parentClass: "menu-ul", childClass: "sub-menu",isEnableParent:item.group})
							this.props.setAction(null);
							this.props.setScreenId(null)
							const firstScreen = this.props.permissions?.menuItems?.filter((menuitem) => menuitem.group === item.group)[0];
							if(firstScreen){
							this.props.fetchMenuItemPermissions(
								firstScreen.id,
								this.props?.userProfile?.id
							);
							this.props.history.push(firstScreen.path)
							}
						}} icon={<span className={`icon lg ${item.groupIcon||'dashboard'}`} />} >
							<Link>{item.group}</Link>
						</Menu.Item>})}
						
					</Menu>}
					{this.state.isEnableParent!=null&&<Menu className="cust-memu"
						theme="light"
						mode="inline"
						defaultSelectedKeys={[this.getActiveKey(window.location.pathname)]}
						selectedKeys={[this.getActiveKey(window.location.pathname)]}>
							<Menu.Item onClick={() => {
							this.setState({ ...this.state, parentClass: "", childClass: "",isEnableParent:null })
							
						}}>
							<Link onClick={() => {
									this.props.setAction(null);
									this.props.setScreenId(null)
									this.props.fetchMenuItemPermissions(
										this.props.permissions?.menuItems[0].id,
										this.props?.userProfile?.id
									);
									this.props.history.push(this.props.permissions?.menuItems[0].path)	
								}}><ArrowLeftOutlined /><span style={{"padding-left":"10px"}}>{this.state.isEnableParent}</span></Link>
							
						</Menu.Item>
						{this.props.permissions?.menuItems?.map((item) => (item.parentId==null && item.group===this.state.isEnableParent) &&(
							<Menu.Item
								key={item.path}
								icon={<span className={`icon lg ${item.iconName}`} />}
								onClick={() => {
									this.props.setAction(null);
									this.props.setScreenId(null)
									this.props.fetchMenuItemPermissions(
										item.id,
										this.props?.userProfile?.id
									);
									this.props.history.push(item.path)	
								}}>
								<Link>{item.label}</Link>
							</Menu.Item>
						))}
					</Menu>}
					
				</Sider>
			</>
		);
	}
}
const mapStateToProps = ({ permissions, oidc, userConfig }) => {
	return { permissions, userProfile: userConfig.userProfileInfo };
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchMenu: (customerId,appid) => {
			dispatch(getFeatures(customerId,appid));
		},
		fetchMenuItemPermissions: (pKey, userId) => {
			dispatch(getPermissions({ customerId: userId, perKey: pKey }));
		},
		setAction: (val) => {
			dispatch(setCurrentAction(val));
		},
		setScreenId: (val,) => {
			dispatch(updateCurrentScreenId({key:'currentScreenTabId',data:val}));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideNav));
