import { getBuySell, getSelectedCoinDetails } from "../components/buySell.component/api";

//Actions
const HANDLE_FETCH = "handleFetch";
const FETCH_BUYSELL = 'fetchBuysell';
const FETCH_BUYSELL_SUCCESS = 'fetchBuysellSuccess';
const FETCH_BUYSELL_REJECT = 'fetchBuysellReject';
const FETCH_BUYSELL_UPDATE = 'fetchBuysellUpdate';

//Action Type
const handleFetch = (payload) => {
    return { type: HANDLE_FETCH, payload }
}
const fetchBuysell = () => {
    return {
        type: FETCH_BUYSELL,
    }
}
const fetchBuysellSuccess = (payload) => {
    return {
        type: FETCH_BUYSELL_SUCCESS,
        payload
    }
}
const fetchBuysellReject = (payload) => {
    return {
        type: FETCH_BUYSELL_REJECT,
        payload
    }
}
const fetchBuysellUpdate = (payload) => {
    return {
        type: FETCH_BUYSELL_UPDATE,
        payload
    }
}
//InitialState
let initialState = {
    buysellData: { loading: false, data: [], error: null },
    buysellRowData: {},
    selectedCoin: {}
}

const handleBuysell = (buysell_id) => {
    return async (dispatch) => {
        dispatch(fetchBuysellSuccess({ key: "buysellData", loading: true, data: [] }));
        const response = await getBuySell(buysell_id);
        if (response.ok) {
            dispatch(fetchBuysellSuccess({ key: "buysellData", loading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchBuysellReject({
                key: "buysellData", loading: false, data: [],
                error: response.status === 401 ? (response.data.message) : response.originalError
            }))
        }
    }
}

const fetchSelectedCoinDetails = (coin, customer_id) => {
    return async (dispatch) => {
        dispatch(handleFetch({ key: "selectedCoin", loading: true, data: null }));
        const response = await getSelectedCoinDetails(coin, customer_id);
        if (response.ok) {
            dispatch(handleFetch({ key: "selectedCoin", loading: false, data: response.data }));
        } else {
            dispatch(handleFetch({ key: "selectedCoin", loading: false, data: null, error: response.originalError.message }));
        }
    }
}

//Reducer
const buysellReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case HANDLE_FETCH:
            state = { ...state, [action.payload.key]: { ...state[action.payload.key], ...action.payload } };
            return state;
        case FETCH_BUYSELL:
            return { ...state, isLoading: true }
        case FETCH_BUYSELL_SUCCESS:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, isLoading: action.payload.loading } }
        case FETCH_BUYSELL_REJECT:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, isLoading: action.payload.loading } }
        case FETCH_BUYSELL_UPDATE:
            return { ...state, userRowData: action.payload }
        default:
            return state;
    }

}

//Export
export default (buysellReducer);
export {
    handleBuysell, fetchBuysellUpdate, fetchBuysell, fetchBuysellReject, fetchBuysellSuccess,
    fetchSelectedCoinDetails
};