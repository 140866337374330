import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";

const getAddandEdit = (controlId) => {
    return clientApi.get(ApiControllers.common + `ControlCodes/${controlId}`)
}
const saveControl = (saveobj, id) => {
    return id === "00000000-0000-0000-0000-000000000000" ? clientApi.post(ApiControllers.common + `ControlCodes`, saveobj) : clientApi.put(ApiControllers.common + `ControlCodes/${id}`, saveobj);
}
const activeInactive = (obj) => {
    return clientApi.put(ApiControllers.master + "ControlCodes/Status", obj)
}

export { getAddandEdit, saveControl, activeInactive }