

import React, { useEffect,useReducer } from "react";
import { Row, Col, Typography, Button, Alert } from "antd";
import Loader from "../../loader.component";
import { publishShowActions } from "../../grid.component/subscribir";
import { getDeductionDetailView } from "../api";
import { connect } from 'react-redux';
import apiCalls from "../../../api/apiCalls";
import { PlusCircleOutlined } from '@ant-design/icons';
import DocumentsUploade from '../../../utils/documents';
import Moment from "react-moment";
import config from "../../../config";
import { Link } from 'react-router-dom';
import { setBreadcrumb } from "../../../reducers/breadcrumbReducer";

const { Title } = Typography;
const reducers = (state, action) => {
	switch (action.type) {
		case 'update':
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
const initialState = {
	loading: false,
	error:null,
	deductionViewObj:{},
	isModalVisible:false,
	docViewObjs:null
}
const DeductionsView = (props) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	useEffect(() => {
		publishShowActions(false);
		getLoadData();
	}, []);

	const getLoadData = async () => {
		dispatch({ type: 'update', payload: { loading: true } });
		let response = await getDeductionDetailView(props.match.params.id);
		if (response.ok) {
			dispatch({ type: 'update', payload: { error: null,deductionViewObj: response.data,docViewObjs: response.data.adminDocuments } });
		} else {
			dispatch({ type: 'update', payload: { error: apiCalls.isErrorDispaly(response) } });
		}
		dispatch({ type: 'update', payload: { loading: false } });
	};

	const backToDeductions = () => {
		dispatch({ type: 'update', payload: { docViewObjs: null } });
		props.history.push("/deductions");
	};

	const docOpen = () => {
		dispatch({ type: 'update', payload: { isModalVisible: true } });
	}
	const closeModal = () => {
		dispatch({ type: 'update', payload: { isModalVisible: false } });
	}
	const updateAttachemnts = (attachemnts) => {
		dispatch({ type: 'update', payload: { docViewObjs: attachemnts } });
	}

	const redirectCustomerView = () => {
		const val = state.deductionViewObj.customerId;
		props.dispatch(
			setBreadcrumb({
				key: "/customers/details/" + val,
				val: state.deductionViewObj.customerName || state?.deductionViewObj?.businessName
			})
		);
		localStorage.setItem("MyURL", "/customers/details/" + val)
		window.open("/customers/details/" + val);
	}
 
	const renderDetailValue=(valueData,typeData)=>{
     if(typeData === "num"){
		return valueData ? valueData?.toLocaleString() : "0";
	 }else{
		return valueData ? valueData : "-";
	 }
}
	const renderViewData = (titleData, value,type) => {
		if (titleData == "Business Name/Personal Name") {

			return <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
				<div>
					<label className="kpi-labels">{state.deductionViewObj?.isBusiness === true ? <><b>Business Name</b>/<>Personal Name</> </> : <><>Business Name</>/<b>Personal Name</b></>}</label>
					<br />
					<span className="kpi-val c-pointer">
						<Link 
						onClick={() => redirectCustomerView()}
						>
							{value || "--"}
						</Link>
					</span>
				</div>
			</Col>
		}
		else {
			return <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
				<div>
					<label className="kpi-label">{titleData}</label>
					<div className="kpi-val"> {renderDetailValue(value,type)}</div>
				</div>
			</Col>
		}
	}
	return (
		<>
			{state.loading && <Loader />}
			{state.error && <div style={{ width: '100%' }}>
				<Alert
					className="w-100 mb-16 align-center mt-0"
					type="warning"
					description={state.error}
					showIcon
				/>
			</div>}
			<Title className="page-title"> DEDUCTION DETAIL VIEW</Title>
			{state.deductionViewObj && (
				<>
					<Row gutter={8}>
						<Col xl={24} xxl={24} className="bank-view">

							<Row className="kpi-List" style={{ paddinTop: '10' }}>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Transaction ID</label>
										<div className=" kpi-val">{state.deductionViewObj?.deductionTransactionId || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Submission Date</label>
										<div className="kpi-val">
											{state.deductionViewObj?.submissionDate && <Moment format={config?.dateFormates?.dateTimeFormate}>{apiCalls.convertUTCToLocalTime(state.deductionViewObj?.submissionDate)}</Moment> || "_"}
										</div>
									</div>
								</Col>
								
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Approval Date</label>
										<div className="kpi-val">
											{state.deductionViewObj?.approvalDate && <Moment format={config?.dateFormates?.dateTimeFormate}>{apiCalls.convertUTCToLocalTime(state.deductionViewObj?.approvalDate)}</Moment> || "_"}
										</div>
									</div>
								</Col>
								{renderViewData("Business Name/Personal Name", state.deductionViewObj?.businessName)}
								{renderViewData("Customer/Contract ID", state.deductionViewObj?.referenceId)}
								{renderViewData("Reason For Deduction", state.deductionViewObj?.reasonForDeduction)}
								{renderViewData("Amount in USD", state.deductionViewObj?.amountInUSD,"num")}
								{renderViewData("Currency", state.deductionViewObj?.currency)}
								{renderViewData("Provider Cost", state.deductionViewObj?.providerCost,"num")}
								{renderViewData("Profit in USD", state.deductionViewObj?.profitInBase,"num")}
								{renderViewData("Admin", state.deductionViewObj?.createdBy)}
								{renderViewData("Status", state.deductionViewObj?.status)}
								{state.deductionViewObj?.reasonForRejection && renderViewData("Reason For Rejection", state.deductionViewObj?.reasonForRejection)}
							</Row>
						</Col>
					</Row>
				</>
			)}
			<p className='mt-16 mb-0'>
				<b>Admin Upload</b> <PlusCircleOutlined onClick={() => docOpen()} /></p>
			<Row gutter={24} className="mb-24 pb-24 border-bottom">
				<>
					<Col xs={24} sm={24} md={24} xl={24} xxl={24}>
						<DocumentsUploade
							pop_up_cancel={() => closeModal()}
							docID={state.deductionViewObj?.id}
							previewModal={state.isModalVisible}
							attachmentUpdate={(attachemnts) =>
								updateAttachemnts(attachemnts)
							}
							docViewObj={state.docViewObjs}
							screenTitle={"Deductions"}
							ModifiedBy={props.userConfig.userName}
						/>
					</Col>
				</>
			</Row>


			<div className="text-right mt-24">
				<Button
					type="primary"
					className="primary-btn cancel-btn"
					style={{ margin: "0 8px" }}
					onClick={backToDeductions}>
					Cancel
				</Button>
			</div>
		</>

	);
};
const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo }
}

export default connect(connectStateToProps)(DeductionsView);
