import React, { useState } from "react";
import { Typography, Modal, Alert, Tooltip, Button } from "antd";
import Changepassword from "../../components/changepassword";
import { connect } from "react-redux";
import {withdrawVerifyObj } from "../../reducers/UserprofileReducer";
import apiCalls from "../../api/apiCalls";
const { Paragraph, Text } = Typography;
const Security = ({ userConfig, userProfileInfo }) => {
  const [isChangepassword, setisChangepassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const useDivRef = React.useRef(null);
  const [isLoading,setIsLoading]=useState(false);
 
  const onClose = () => {
    setisChangepassword(false);
  };
  const handleshowPassword = async() => {
    setIsLoading(true);
    setisChangepassword(false);
    const res=await apiCalls.resetPassword(userConfig?.id);
    if(res.ok){
      setIsLoading(false);
      setisChangepassword(true);
    }
    else{
      setIsLoading(false);
      setErrorMsg(apiCalls.isErrorDispaly(res))
    }

  }
  return (
    <>
      <div>
        <div ref={useDivRef}></div>
        {errorMsg !== null && (
          <Alert
            className="mb-12"
            type="error"
            message={"Send Verification"}
            description={errorMsg}
            onClose={() => setErrorMsg(null)}
            showIcon
          />
        )}
        <div className="box contact-info">
          <Text className="basicinfo" >Change Password</Text>
          <Paragraph className="basic-decs mt-12 mb-16">Choose a unique password to protect your account.</Paragraph>
          <ul className="user-list pl-0">
            <li className=""
            >
              <div className="basic-decs">
                <Button
                  type="primary"
                  className="primary-btn reset-paswrd-mb"
                  loading={isLoading}
                  onClick={handleshowPassword}>Reset Password</Button>
                   {isChangepassword && <div className="reset-paswrd-mt">
                    <Text
                      className="basicinfo mb-0"
                    > Check Your Email</Text>
                    <Paragraph className="basic-decs mt-0">
                      Email send successfully to : <b>{userConfig?.email}</b> please check and reset your password.</Paragraph>
                  </div>
                }
              </div>
            </li>
          </ul>
        </div>
        <Modal
          title="Change Password"
          closeIcon={<Tooltip title="Close"><span className="icon md close-pop c-pointer" onClick={() => onClose()} /></Tooltip>}
          footer={null}
        >
          <Changepassword onSubmit={() => setisChangepassword(false)} />
        </Modal >
      </div>
    </>
  );
};
const connectStateToProps = ({ userConfig, userProfile }) => {
  return {
    userConfig: userConfig.userProfileInfo, userProfile, twoFA: userConfig.twoFA
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    fetchWithdrawVerifyObj: (obj) => {
      dispatch(withdrawVerifyObj(obj))
    },
    dispatch
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(Security);