import React ,{ useState } from "react";
import { Collapse, Typography } from "antd"
import List from "../grid.component"

const PartnerDetails=()=>{
    const [customergridparams, setCustomergridparams] = useState({});
    const { Title } = Typography;
     const { Panel } = Collapse;
     const gridRef = React.createRef();
     const customerId=window.location.pathname.split('/')[3]
    const bonusGridColumns = [
        {
          field: "date",
          title: "Date",
          filter: true,
          width: 250,
          isShowTime: true,
          filterType: "date"
        },
        {
          field: "name",
          title: "Business Name/Personal Name",
          filter: true,
          width: 300
        },
        { field: "walletCode", title: "Wallet code", filter: true, width: 200 },
        {
          field: "transactionType",
          title: "Transaction type",
          filter: true,
          width: 200
        },
        {
          field: "transactionValue",
          title: "Transaction value",
          filter: true,
          dataType: "number",
          filterType: "numeric",
          width: 200
        },
        {
          field: "baseCurrency",
          title: "Base Currency",
          filter: false,
          width: 200
        },
        {
          field: "baseValue",
          title: "Base Value",
          filter: true,
          dataType: "number",
          filterType: "numeric",
          width: 200
        },
        {
          field: "value",
          title: "Bonus Earned",
          filter: true,
          dataType: "number",
          filterType: "numeric",
          width: 200
        }
      ];

     const ReferralGridColumns = [
        {
          field: "joiningDate",
          title: "Joining Date",
          filter: true,
          filterType: "date",
          isShowTime: true
        },
        { field: "name", title: "Business Name/Personal Name", filter: true },
        {
          field: "bonusEarned",
          title: "Bonus Earned",
          filter: false,
          dataType: "number",
          filterType: "numeric"
        },
        { field: "tradeIntiated", title: "Trade Initiated ", filter: false }
      ];

      const loadTrans = () => {
        setCustomergridparams((prevParams) => ({
          ...prevParams,
          customerid: customerId,
        }));
      };
    return(
             <div>
                <Collapse
                  style={{ margin: "-22px 0 0" }}
                  className="trans-collapse"
                  onChange={loadTrans}
                  expandIcon={() => <span className="icon md downangle" />}
                >
                  <Panel
                    header="Partner Bonus Transactions"
                    key="1"
                    className="fs-16 fw-500"
                  >
                    <List
                      ignoreSaved={true}
                      showActionBar={true}
                      pKey={"bonus"}
                      ref={gridRef}
                      columns={bonusGridColumns}
                      additionalParams={{ customerid: customerId }}
                      url={
                        process.env.REACT_APP_GRID_API +
                        `Partner/GetBonusessK/Customer`
                      }
                      showExcelExport={true}
                    />
                  </Panel>
                </Collapse>
                <Title></Title>
                <Collapse
                  className="trans-collapse"
                  onChange={loadTrans}
                  expandIcon={() => <span className="icon md downangle" />}
                >
                  <Panel
                    header="Partner Referral Transactions"
                    key="1"
                    className="fs-16 fw-500"
                  >
                    <List
                      ignoreSaved={true}
                      showActionBar={true}
                      pKey={"bonus"}
                      ref={gridRef}
                      columns={ReferralGridColumns}
                      additionalParams={{ customerid: customerId, ...customergridparams }}
                      url={
                        process.env.REACT_APP_GRID_API +
                        `Partner/GetRefferalCodessK`
                      }
                      showExcelExport={true}
                    />
                  </Panel>
                </Collapse>
                </div>
    )
}
export default PartnerDetails