import { getSwap } from "../components/swap.component/api";

//Actions
const FETCH_USERS = 'fetchUsers';
const FETCH_USERS_SUCCESS = 'fetchUsersSuccess';
const FETCH_USERS_REJECT = 'fetchUsersReject';
const FETCH_USERS_UPDATE = 'fetchUsersUpdate';

//Action Type
const fetchUsers = () => {
    return {
        type: FETCH_USERS,
    }
}
const fetchUsersSuccess = (payload) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload
    }
}
const fetchUsersReject = (payload) => {
    return {
        type: FETCH_USERS_REJECT,
        payload
    }
}
const fetchUsersUpdate = (payload) => {
    return {
        type: FETCH_USERS_UPDATE,
        payload
    }
}
//InitialState
let initialState = {
    swapData: { loading: false, data: [], error: null },
    userRowData: {}

}

const handleSwap = (swap_id) => {
    return async (dispatch) => {
        dispatch(fetchUsersSuccess({ key: "swapData", loading: true, data: [] }));
        const response = await getSwap(swap_id);
        if (response.ok) {
            dispatch(fetchUsersSuccess({ key: "swapData", loading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchUsersReject({ key: "swapData", loading: false, data: [], error: response.originalError }))
        }
    }
}
const swapReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case FETCH_USERS:
            return { ...state, isLoading: true }
        case FETCH_USERS_SUCCESS:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, isLoading: action.payload.loading } }
        case FETCH_USERS_REJECT:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, isLoading: action.payload.loading } }
        case FETCH_USERS_UPDATE:
            return { ...state, userRowData: action.payload }
        default:
            return state;
    }
}

//Export
export default (swapReducer);
export { handleSwap, fetchUsers, fetchUsersUpdate, fetchUsersReject, fetchUsersSuccess };