const config = {
    loginMethod: "identify",
    cryptoBalance: [
        {
            id: 1,
            title: 'Crypto Balance',
            currency: '$',
            value: '205.5529',
            shortcode: 'BTC',
            btcValue: '30.60'

        }
    ],
    fiatBalance: [
        {
            id: 1,
            title: 'Fiat Balance',
            currency: '$',
            value: '2,055.00',
            shortcode: 'BTC',
            btcValue: '30.60'
        }
    ],
    YesterdayPNL: [
        {
            id: 1,
            title: 'Yesterday’s PNL',
            currency: '$',
            value:-0.19,
            gain: '0',
            loss: '-0.19',
            isArrow: true,
            up: false,
            percentage: '0.6%'
        }
    ],
    thirtydaysPNL: [
        {
            id: 1,
            title: '30 Days PNL',
            currency: '$',
            value :0.06,
            gain: '+0.06',
            loss: '0',
            isArrow: true,
            up: true,
            percentage: '0.65%'
        }
    ],

}
export default config;