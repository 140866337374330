export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
export function newGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
  });
}
export function getDocObj(customerId, path, docName,size, id, detailId) { 
  return {
      "id": id || newGUID(),
      "transactionId": null,
      "adminId": "00000000-0000-0000-0000-000000000000",
      "date": null,
      "type": null,
      "customerId": customerId,
      "caseTitle": null,
      "caseState": null,
      "remarks": null,
      "status": null,
      "state": null,
      "details": [
          {
              "documentId": "00000000-0000-0000-0000-000000000000",
              "documentName": docName,
              "id": detailId || newGUID(),
              "isChecked": true,
              "remarks": size,
              "state": null,
              "status": false,
              "Path": path
          }
      ]
  }
}
export function getCaseObject(values,caseObject,assignedToId,commonModel,userConfig,isCheck,isChecked){
    return {
        "id": "00000000-0000-0000-0000-000000000000",
        "caseNumber": caseObject.caseNumber,
        "caseTitle": values.caseTitle,
        "assignedTo": [
          {
            "assignedToIds": `${assignedToId}`,
            "assignedToNames": null,
            "assignedRemarks": null,
            "caseSubState": "Assigned",
            "recordStatus": "Added",
            "id": "00000000-0000-0000-0000-000000000000"
          }
        ],
        "commonModel": commonModel,
        "remindDate": null,
        "closedDate": null,
        "customerId": caseObject.customerId,
        "remarks": values.remarks,
        "templateName":values.templateName,
        "state": "Submitted",
        "createdDate": new Date(),
        "createdBy": userConfig?.userName,
        "modifiedDate": null,
        "modifiedBy": null,
        "status": false,
        "caseDetails":
          caseObject.caseDetails
        ,
        "documentNames": null,
        "customerCaseTitle": values.customerCaseTitle,
        "isCustomerDocument": isCheck === true ? true : false,
        "isCrypto":isChecked===true?true:false,
        "typeId": caseObject.typeId,
        "type": caseObject.type,
        "caseStateLu": [
          "Submitted",
          "Approved",
          "Cancelled"
        ],
        "caseSubStateLu": [
          "Submitted",
          "Re-assigned"
        ],
        "documentDetails": null
      }
}
export function numberWithCommas(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}