import React, { Component } from "react";
import { Upload, Typography, Modal, Col, Tooltip, Button, Alert, Spin } from "antd";
import DocumentPreview from "../shared/documentPreview";
import { ApiControllers } from "../../api/config";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
const { Dragger } = Upload;
const { Text } = Typography;

const EllipsisMiddle = ({ suffixCount, children }) => {
  const start = children?.slice(0, children.length - suffixCount).trim();
  const suffix = children?.slice(-suffixCount).trim();
  return (
    <Text className="btn-textstyle upload-size docnames cust-pop-doc" ellipsis={{ suffix }}>
      {start}
    </Text>
  );
};
class RiskScreenUpLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidFile: true,
      docReplyObjs: props.getRiskData ? [...props.getRiskData] : [],
      docPreviewDetails: null,
      errorMessage: null,
      loader:false,
      uploadErrorMessage:null
    };
  }
  handleUpload = ({ file }) => {
      this.props.uploadError(this.state.uploadErrorMessage)
    if (file.status === 'done' && this.state.isValidFile) {
      let docObj = [...this.state.docReplyObjs]
      docObj.push({ id: this.props.riskScreenId, documentId: file.response.id, fileName: file.response.fileName, recordStatus: 'Added', fileSize:file.size })
      this.setState({ ...this.state, docReplyObjs: [...docObj],loader:false,errorMessage:null })
      this.props.attachmentUpdate([...docObj])
    } 
   
    else if (!this.state.isValidFile) {
      return Upload.LIST_IGNORE;
    }
  };

  beforeUpload = (file) => {
    this.setState({ ...this.state, errorMessage: null,uploadErrorMessage:null })
    let fileType = { "application/pdf": true, "application/PDF": true };
    let isFileName = file.name.split(".").length === 2;
    this.setState({ ...this.state, errorMessage: null,uploadErrorMessage:null })
    if (fileType[file.type] && isFileName) {
      this.setState({ ...this.state, isValidFile: true, errorMessage: null,loader:true ,uploadErrorMessage:null});
      return true;
    }
 else {
      this.setState({
        ...this.state,
        isValidFile: false,
        loader:false,
        uploadErrorMessage: isFileName
          ? "File is not allowed. You can only upload PDF  files"
          : "File don't allow double extension"
      });
      
      this.props.uploadError(this.state.uploadErrorMessage)
      return Upload.LIST_IGNORE;
    }
  };

   formatBytes=(bytes, decimals = 2)=>{
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed()) + ' ' + sizes[i];
}

  deleteDocument = async (doc, idx, isAdd) => {
     let docObj = [...this.state.docReplyObjs]
    if (doc.recordStatus === 'Added') {
      for (let i in docObj) {
        if (docObj[i].documentId === doc.documentId) {
          docObj.splice(parseInt(i), 1);
        }
      }
    } else {
      for (let i in docObj) {
        if (docObj[i].documentId === doc.documentId) {
          docObj[i].recordStatus = 'Deleted'
        }
      }
    }
    this.setState({ ...this.state, docReplyObjs: [...docObj] })
    this.props.attachmentUpdate([...docObj])
  };

  docPreviewClose = () => {
    this.setState({ ...this.state, previewModal: false, docPreviewDetails: null });
  };
  docPreviewOpen = (data) => {
    this.setState({ ...this.state, previewModal: true, docPreviewDetails: { id: data.documentId, fileName: data.fileName } });
  };

  redirectToIframe = () => {
    window.open(process.env.REACT_APP_RISK_URL, 'popup', 'width=700,height=700')
  };

  backToRiskData = () => {
    this.setState({ ...this.state, iframeView: false });
  };

  render() {
    const { errorMessage } = this.state;

    return (
      <>
        {errorMessage !== null && (
          <Alert type="error" description={errorMessage} showIcon />
        )}
        
        <div>
        <span>Click{" "}<Link onClick={() => this.redirectToIframe()}>here</Link>{" "}
to do screening  </span>

        </div>
        
        <div className='mb-24 mt-8'>
          <Spin spinning={this.state.loader}>
          <Dragger
            accept=".PDF"
            className="upload mt-4"
            multiple={true}
            action={
              process.env.REACT_APP_UPLOAD_API +
              "api/v1/" +
              ApiControllers.common +
              "UploadFileNew?screenName=RiskScreen&fieldName=uploadfile&tableName=Common.Documents"
            }
            showUploadList={false}
            beforeUpload={(props) => {
              this.beforeUpload(props);
            }}
            onChange={(props) => {
              this.handleUpload(props);
            }}
            headers={{ Authorization: `Bearer ${this.props.user.deviceToken}` }}
          >
            <p className="ant-upload-drag-icon">
              <span className="icon xxxl doc-upload" />
            </p>
            <p className="ant-upload-text upload-title">
              Drag and drop or browse to choose file
            </p>
            <p className="ant-upload-hint upload-text">
              only PDF files are allowed
            </p>
          </Dragger>
          </Spin>
        <div className="docfile-container">

          {this.state.docReplyObjs?.map((file, idx1) => (
            file.recordStatus !== "Deleted" && (
              <div key={idx1} className="riskdocfile uploaddoc-margin cust-upload-style">
                <span
                  className={`icon xl ${(file?.fileName?.slice(-3) === "zip" ? "file" : "") ||
                    (file?.fileName?.slice(-3) === "pdf" ? "file" : "image")
                    } mr-16`}
                />

                <div
                  className="docdetails c-pointer pr-8"
                  onClick={() => this.docPreviewOpen(file)}
                >
                  <EllipsisMiddle suffixCount={6} >{file?.fileName}</EllipsisMiddle>
                  <span className="file-sizestyle" >
                    {this.formatBytes(file?.fileSize)}

                  </span>
                </div>

                <span
                  className="icon md close c-pointer"
                  onClick={() =>
                    this.deleteDocument(file, idx1, true)
                  }
                />
              </div>
            )
          ))}
        </div>
        </div> 
    
        {this.state.previewModal && (
          <DocumentPreview
            previewModal={this.state.previewModal}
            handleCancle={this.docPreviewClose}
            upLoadResponse={this.state.docPreviewDetails}
          />
        )}
        
        <Modal
          visible={this.state.iframeView}
          closeIcon={
            <Tooltip title="Close">
              {" "}
              <span
                className="icon md x c-pointer"
                onClick={() => this.backToRiskData()}
              />{" "}
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={() => this.backToRiskData()}
                Cancel
              ></Button>{" "}
            </>
          }
        >
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Iframe
              url="https://riskscreen.com/"
              width="640px"
              height="320px"
              id=""
              className=""
              display="block"
              position="relative"
            />
          </Col>
          
        </Modal>
       
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    user: oidc
  };
};
export default connect(connectStateToProps)(RiskScreenUpLoad);
