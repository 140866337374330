import { Input, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
const LocalCryptoSwapperCmp = ({ localAmt = 0, cryptoAmt = 0, localCurrency = "USD", maxValue, cryptoCurrency, onChange, isConvertionLoad, isSwaped, onCurrencySwap }) => {
    const [symbols] = useState({
        "EUR": "€",
        "USD": "$",
        "GBP": "£",
        "CHF": "₣",
        "SGD": "S$"
    });

    return <div className="p-relative">
        <div className="enter-val-container">
            <div className='enterval-style'>
                <Text className="fs-14 text-white-30 label">Amount ({!isSwaped ? localCurrency : cryptoCurrency})</Text>
                <NumberFormat id="amtInput"
                    className="cust-input from-input custinput-width"
                    
                     maxLength={9} customInput={Input} thousandSeparator={true} prefix={isSwaped ? "" : symbols[localCurrency]}
                    decimalScale={isSwaped ? 8 : 2}
                    autoComplete="off"
                    placeholder="0.00"
                    value={isSwaped ? cryptoAmt : localAmt}
                    onValueChange={({ value }) => {
                        onChange(value);
                    }}
                    allowNegative={false}
                />
            </div>
            <span className="val-swap c-pointer" onClick={onCurrencySwap}>
                <span className="icon lg swap" />
            </span>
            <div className='swap-right'>
                <Text className="fs-14 text-white-30 label">Amount ({!isSwaped ? cryptoCurrency : localCurrency})</Text>
                <NumberFormat
                    value={isSwaped ? localAmt : cryptoAmt}
                    customInput={Input}
                    className="cust-input to-input custinput-width"
                    displayType={'input'}
                    disabled
                    thousandSeparator={true}
                    prefix={isSwaped ? symbols[localCurrency] : ""}
                />
                {isConvertionLoad && <Spin size="small" />}
            </div>
        </div>
    </div>

}
export default LocalCryptoSwapperCmp;