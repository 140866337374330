import React from 'react';
import ReactDOM from 'react-dom';
import '@progress/kendo-theme-default/dist/all.css';
import 'antd/dist/antd.css';
import './assets/css/style.css';
import './assets/css/framework.css';
import './assets/css/light-theme.css';
import './App.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import IdleCmp from './authentication/idleCmp';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { updateWorker } from './reducers/serviceWorker';
import { store } from './store';
import { getConfig } from './authentication/auth0/config';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./authentication/auth0/history";
import { Provider } from 'react-redux';
const onUpdate = () => {
  store.dispatch(updateWorker());
}
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams:{
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    ...(config.audience ? { audience: config.audience } : null)
  }, 
  onRedirectCallback,
};
ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
        <IdleCmp />
        </Provider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorkerRegistration.register({ onUpdate });

reportWebVitals();
