import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";

const getAlertsLu = () => {
  return clientApi.get(ApiControllers.customers + "Alert/Alerts");
};

const saveAlert = (obj) => {
  return clientApi.post(ApiControllers.customers + `Alerts/Alert`, obj);
};
const updateAlert = (obj) => {
  return clientApi.put(ApiControllers.customers + `Alerts/Alert`, obj);
};

const getAlert = (Id) => {
  return clientApi.get(ApiControllers.customers + `Alerts/${Id}`);
};

const statusAlert = (obj) => {
  return clientApi.put(ApiControllers.customers + `Alerts/Status`, obj);
};

const getNumberLu = () => {
  return clientApi.get(ApiControllers.customers +`cases/Alerts`);
};
const getNameandEmail = (email,name) => {
  return clientApi.get(ApiControllers.customers + `Customers/KYC/KYB/${email}/${name}`);
};
const getStates = (status) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${"Alerts"}/${status}`
	);
};

export {
  getAlertsLu,
  getAlert,
  statusAlert,
  updateAlert,
  saveAlert,
  getNumberLu,
  getNameandEmail,
  getStates
};
