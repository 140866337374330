import React, { Component } from "react";
import { Alert, Form, Modal, Tooltip, Input,Button, message, Typography } from 'antd';
import apiCalls from "../../../api/apiCalls";
import { commissionsTierGet, saveTier } from '../api';
import Loader from '../../../components/loader.component';
import { publishShowActions } from "../../grid.component/subscribir";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { validateContentRule} from '../../../utils/custom.validator';
import { newGUID } from "../../../utils/service"
const { Paragraph } = Typography;
class CommissionsTier extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tierData: [], errorMessage: null, loader: false,
      selection: [], selectedObj: {}, check: false,
      warningMsg: null, deleteModal: false, errorModal: false,
       showTier: false,
      btnDelTierLoading: false,
      btnLoader: false,
      showError:null,
      deleteTierObj:null,
      deleteConfirmModal:false,
      deleteShowPop:false,
      setFromValue:null,
      tiarsDetailsType:false,
      tiresDetailsData:[],
      errorType:false,
      newDeletePoup:false
    }
    this.formref = React.createRef();
  }
  componentDidMount = () => {
    this.getCommissionsTier();
    publishShowActions(false);
    window.scroll(0,0)
  }
  getCommissionsTier = async () => {
    this.setState({ ...this.state, loader: true, errorMessage: null });
    let res = await commissionsTierGet(this.props?.match?.params?.id);
    if (res.ok) {
      this.setState({ ...this.state, loader: false, tierData: res.data, tiresDetailsData:res.data, errorMessage: null });

    }
    else {
      this.setState({ ...this.state, loader: false, tierData: [], errorMessage: apiCalls.isErrorDispaly(res) });
      window.scrollTo(0, 0)
    }
  }
  handleInputChange = (event, i) => {
    this.setState({...this.state,selectedObj:{}})
    const rowChecked = event;
    const target = i.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection?.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection?.splice(idx, 1);
    } else {
      selection?.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection: selection,
      warningMsg: null,
      showTier:false
    });
  };
  handleBack = () => {
    this.setState({
      ...this.state,
      errorModal: false, deleteModal: false,selection:[]
    });
  }
  addTier = () => {
    const {tierData}=this.state;
    let obj={"id": newGUID(),
     "commissionId": this.props.match?.params?.id,name:"",fromValue:"",toValue:"",withdrawFiat:"",depositFiat:"",withdrawCrypto:"",depositCrypto:"",buy:"",sell:"",  "recOrder": null,
    createdDate :new Date(),
  userCreated: this.props.userConfig.userName,
    modifiedDate: new Date(),
    modifiedby: this.props.userConfig.userName,
    status: "active",
    state: null}
    tierData.push(obj);
    this.setState({ errorMessage: null, selection: [], warningMsg: null, error: null, showTier: true,deleteModal:false ,check:false})
  }

  deleteTier = async () => {
    const {tierData,selectedObj}=this.state;
    const tierObj=Object.assign([],tierData)
    this.setState({...this.state, errorMessage: null,selection: [],selectedObj:{} });
    const newRecords = tierObj.filter(record => (record.id !== selectedObj?.id
       &&(record.state!=="Deleted")
        ));
    this.setState({ errorMessage: null, selection: [], warningMsg: null, error: null, btnDelTierLoading: false })
    if (!this.state.check) {
      this.setState({...this.state, errorMessage: null, warningMsg: "Please select the one record", error: null, deleteModal: false });
    }
    else if(selectedObj.name==="" && selectedObj.state){
      this.setState({...this.state,errorMessage: null,selection: [], error: null, deleteModal: false,tierData:newRecords,showTier:true, check: false, });
      this.formref.current.resetFields();
    } 
    else if(this.state.check){
      this.setState({...this.state, errorMessage: null, warningMsg: null, error: null, deleteModal:true,check: false });

    }
  }
  nuberValidater = (_, value) => {
    let value1 = parseFloat(value?.replace(/,/g, ''));
    if (value1 > "1") {
      return Promise.reject("Amount exceeded");
    }else if(value==="."){
      return Promise.reject("Is required");
    }
    return Promise.resolve();

  }
  deleteCommissionsTier = async () => {
    const { tierData } = this.state;
    for (var k in tierData) {
      if (tierData[k].id === this.state.selectedObj?.id) {
        tierData[k].state = 'Deleted';
      }
    }
    this.setState({...this.state, errorMessage: null, selection: [], warningMsg: null, error: null, btnDelTierLoading: false,tierData:tierData,deleteModal: false })

  }
  handleCancel=()=>{
    if(this.state.selectedObj.state === 'Deleted' || this.state.tiarsDetailsType){
     this.setState({...this.state,deleteShowPop:true}) 
    }else{
     this.props.history.push("/commissions");
     this.formref.current.resetFields();
    }   
   }
   handleBackToCommission=()=>{
     this.setState({...this.state,deleteShowPop:false}) 
   }
   hadleCommission=()=>{
     this.props.history.push("/commissions");
   }
  tierUpdate = (e, name,item,index) => {
    let value=e.target.value;
    const { tierData } = this.state;
      let data = tierData?.find((tier,ind) =>  ind=== index)
    let _obj = data
      _obj[name] = value;
      _obj["modifiedDate"]=new Date()
      _obj["modifiedby"]=this.props.userConfig.userName
      _obj.state=data.recOrder?"Modified":"Added"
      let _objData = [_obj]
      this.setState({...this.state,tiarsDetailsType:true})
      if(value!==""){
        tierData.forEach(obj => _objData.find((o) => o.id === _obj.id) || obj);
    }
  }
  tierSave = async () => {
    const { tierData } = this.state;
      this.setState({ ...this.state, btnLoader: true,errorMessage:null });
      let res = await saveTier(tierData);
      if (res.ok) {
          message.success({
            content:"Tier saved successfully",
            className: "custom-msg",
            duration: 3
          });
        
        this.setState({ ...this.state, showTier: false, btnLoader: false, tierData: null,selectedObj:{},deleteModal:false,check: false,warningMsg:null,deleteShowPop:false,tiarsDetailsType:false,selection:[]});
        this.formref.current.resetFields();
        this.getCommissionsTier();
      } else if (res.data.title === "There is an overlap in 30-Day Volume between the tiers") {
        this.setState({ ...this.state, btnLoader: false, errorModal: true, });
      } else {
        this.setState({ ...this.state, showTier: true, errorMessage: apiCalls.isErrorDispaly(res), btnLoader: false, errorModal: false });
        window.scroll(0,0)

      }
  }
  validateNumber = (_, validNumberValue) => {
    if (typeof validNumberValue === "number") {
      validNumberValue = validNumberValue.toString();
    }
    if (validNumberValue === ".") {
        return Promise.reject("Please enter valid content");
    }
    return Promise.resolve();
}
  handleErrorModalClose = () => {
    this.setState({ ...this.state, errorMessage: null, btnLoader: false, errorModal: false });
  }
  render() {
    const { tierData, errorMessage, deleteModal, warningMsg, errorModal, loader } = this.state;
    const tierDataValidation=this.state.tierData?.filter(item=>item.state!=="Deleted")
    return (<>
      {loader && <Loader />}
      {warningMsg !== undefined && warningMsg !== null && warningMsg !== "" && (
        <div style={{ width: '100%' }}>
          <Alert
            className="w-100 mb-16 align-center"
            type="warning"
            description={warningMsg}
            showIcon
          />
        </div>
      )}
      {errorMessage !== undefined && errorMessage !== null && (
        <Alert
          className="w-100 mb-16"
          type="error"
          description={errorMessage}
          showIcon
        />
      )}
      <div className="text-right mb-16">
        <Tooltip title="Add"><span className="icon c-pointer md add mr-12"
          onClick={() => this.addTier()}
        /></Tooltip>
        <Tooltip title="Delete"><span className="icon c-pointer md delete mr-0 ant-tooltip-open"
          onClick={() => this.deleteTier()}
        ></span></Tooltip>

      </div>
      <Modal
        title="Confirm Delete"
        visible={deleteModal}
        style={{ top: 90 }}
        closeIcon={
          <Tooltip title="Close">
            <span className="icon md x c-pointer"
              onClick={() => this.handleBack()}
            />
          </Tooltip>
        }
        destroyOnClose
        footer={<>
          <Button
            type="primary"
            className="primary-btn cancel-btn"
						style={{ margin: "0 8px" }}
            onClick={() => this.handleBack()}
          >
            No
          </Button>
          <Button
            type="primary"
            className="primary-btn"
            loading={this.state.btnDelTierLoading}
            onClick= {this.deleteCommissionsTier}
          >
            Yes
          </Button>
        </>}>
        <div>
      {  this.state.selectedObj.recOrder!==null &&<>
        <p className="mb-16" >Your deleting this {this.state.selectedObj.name} tier, you need to readjust the remining tiers. </p>
        <p className="mb-16">Are you sure do you want to delete this {this.state.selectedObj.name} tier? </p></>}
        {this.state.selectedObj.recOrder===null &&<>
        <p className="mb-16">Are you sure, do you really want to delete ? </p>
        </>}
        </div>
      </Modal>
      <Modal title="Error Message"
        visible={errorModal}
        closable={false}
        closeIcon={false}
        footer={<>
          <Button
          type="primary"
          className="primary-btn cancel-btn"
          style={{ margin: "0 8px" }}
            onClick={() => this.handleErrorModalClose()}
          >
            Cancel
          </Button>
        </>}>
        <h4 className="text-white-30 fs-16 fw-400">There is an overlap in 30-day Volume between the tiers</h4>
      </Modal>
          <Form  onFinish={this.tierSave} autoComplete="off"  ref={this.formref}>
               <div className="tier-section table-scroll">
            <table border="1" className="Tier-table">
              <tr className="table-header-row"><th className="" rowSpan="2"></th>
                <th className="" rowSpan="2">Tier</th>
                <th colspan="2">30-Day Volume($)</th>
                <th colSpan="2">Fiat</th>
                <th colspan="4">Crypto</th>
              </tr>
              <tr className="table-header-row">
                <th>From</th>
                <th>To</th>
                <th>Withdraw</th>
                <th>Deposit</th>
                <th>Withdraw</th>
                <th>Deposit</th>
                <th>Buy</th>
                <th>Sell</th>
              </tr>
              {this.state.tierData?.map((item,index,{name,...tiersData}) => <>
              {(item?.state!=="Deleted" || !this.state.selectedObj?.id ) &&
                <tr>
                  <td className="text-center">
                    <div className="d-flex align-center mr-8">
                      <label className="text-center custom-checkbox margin-auto">
                        <input name="check" type="checkbox"
                          id={item.id}
                          className="cust-input"
                          checked={this.state.selection.indexOf(item?.id) > -1}
                          onChange={(e) => this.handleInputChange(item, e,)} />
                        <span></span>{" "}
                      </label>

                    </div>
                  </td>
                  <td>
                    <Form.Item className="commission-input mb-0"
                    name={[item.recOrder,name,"name",index]}
                    {...tiersData}
                    rules={[
                      {
                        required:item.name===""?true: false,
                        message: 'Is required',
                      },
                      {
                        whitespace: true,
                        message: "Is required",
                      },
                      {
                        validator: validateContentRule,
                      },
                    ]}
                    >
                      <Input maxLength={50} type="text" className="cust-input pl-0 mb-0"
                        defaultValue={item.name}
                        name="name"
                        onChange={(e) => this.tierUpdate(e, "name", item.recOrder,index)}
                       
                        />
                    </Form.Item>
                  </td>
                  <td><Form.Item className="commission-char mb-0"
                    name={[item.recOrder,name, 'fromValue',index]}
                    {...tiersData}
                    rules={[
                      {
                        required:item.fromValue===""?true: false,
                        message: 'Is required',
                      },
                      {
                        whitespace: true,
                        message: "Is required",
                      },
                      {
                        validator: validateContentRule,
                      },
                      {
                        type:"number",
                        validator: this.validateNumber
                      }
                    ]}
                  >
                    <NumberFormat
                  
                      decimalSeparator={false}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this?.tierUpdate(e, "fromValue", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={13}
                      defaultValue={item.fromValue}
                      name="fromValue"
                    />
                  </Form.Item></td>
                  <td>
                    <Form.Item className="commission-char mb-0"
                   name={[item.recOrder,name, 'toValue',index]}
                   {...tiersData}
                   rules={[
                     {
                       whitespace: true,
                       message: "Is required",
                     },
                     {
                      type:"number",
                      validator: this.validateNumber
                    },
                     {
                       validator: validateContentRule,
                     },
                     {
                      type:"number",
                       validator: (_, val) => {
                         if (!item.toValue && tierDataValidation.length !== index + 1) {
                           return Promise.reject("Is required");
                         }
                         return Promise.resolve();
                       }
                    },
                    
                   ]}>
                    <NumberFormat
                    name="toValue"
                      decimalSeparator={false}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this?.tierUpdate(e, "toValue", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={13}
                      defaultValue={item.toValue}
                    />

                  </Form.Item></td>
                  <td> <Form.Item className="commission-input-small mb-0"
                   name={[item.recOrder,name ,'withdrawFiat',index]}
                   {...tiersData}
                   rules={[
                     {
                      required:item.withdrawFiat===""?true: false,
                       message: 'Is required',
                     },
                     {
                       whitespace: true,
                       message: "Is required",
                     },
                     {
                       validator: validateContentRule,
                     },
                     {
                       validator:this.nuberValidater
                       }
                   ]}
                  >
                    <NumberFormat
                    name="withdrawFiat"
                      decimalScale={2}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this.tierUpdate(e, "withdrawFiat", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={6}
                      defaultValue={item.withdrawFiat}
                    />

                  </Form.Item></td>
                  <td><Form.Item className="commission-input-small mb-0"
                  name={[item.recOrder,name, 'depositFiat',index]}
                  {...tiersData}
                  rules={[
                    {
                      required:item.depositFiat===""?true: false,
                      message: 'Is required',
                    },
                    {
                      whitespace: true,
                      message: "Is required",
                    },
                    {
                      validator: validateContentRule,
                    },
                    {
                      validator: this.nuberValidater,
                    }
                  ]}

                  >
                    <NumberFormat
                      decimalScale={2}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this.tierUpdate(e, "depositFiat", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={6}
                      defaultValue={item.depositFiat}
                    />
                  </Form.Item></td>
                  <td><Form.Item className="commission-input-small mb-0"
                   name={[item.recOrder,name ,'withdrawCrypto',index]}
                   {...tiersData}
                   rules={[
                     {
                      required:item.withdrawCrypto===""?true: false,
                       message: 'Is required',
                     },
                     {
                       whitespace: true,
                       message: "Is required",
                     },
                     {
                       validator: validateContentRule,
                     },
                     {
                      validator: this.nuberValidater,
                    }
                   ]}
                  >
                    <NumberFormat
                      decimalScale={2}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this.tierUpdate(e, "withdrawCrypto", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={6}
                      defaultValue={item.withdrawCrypto}
                    />
                  </Form.Item></td>
                  <td><Form.Item className="commission-input-small mb-0"
                  name={[item.recOrder, name,'depositCrypto',index]}
                  {...tiersData}
                  rules={[
                    {
                      required:item.depositCrypto===""?true: false,
                      message: 'Is required',
                    },
                    {
                      whitespace: true,
                      message: "Is required",
                    },
                    {
                      validator: validateContentRule,
                    },
                    {
                      validator: this.nuberValidater,
                    }
                  ]}
                  >
                    <NumberFormat
                      decimalScale={2}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this.tierUpdate(e, "depositCrypto", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={6}
                      defaultValue={item.depositCrypto}
                    />
                  </Form.Item></td>
                  <td><Form.Item className="commission-input-small mb-0"
                   name={[item.recOrder, name,'buy',index]}
                   {...tiersData}
                   rules={[
                     {
                       required:item.buy===""?true: false,
                       message: 'Is required',
                     },
                     {
                       whitespace: true,
                       message: "Is required",
                     },
                     {
                       validator: validateContentRule,
                     },
                     {
                      validator: this.nuberValidater,
                    }
                   ]}
                  >
                    <NumberFormat
                      decimalScale={2}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this.tierUpdate(e, "buy", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={6}
                      defaultValue={item.buy}
                    />
                  </Form.Item></td>
                  <td><Form.Item className="commission-input-small mb-0"
                      name={[item.recOrder, name,'sell',index]}
                      {...tiersData}
                      rules={[
                        {
                          required:item.sell===""?true: false,
                          message: 'Is required',
                        },
                        {
                          whitespace: true,
                          message: "Is required",
                        },
                        {
                          validator: validateContentRule,
                        },
                        {
                          validator: this.nuberValidater,
                        }

                      ]}

                  >
                    <NumberFormat
                      decimalScale={2}
                      customInput={Input}
                      className="cust-input pl-0 mb-0"
                      onChange={(e) => this.tierUpdate(e, "sell", item.recOrder,index)}
                      thousandSeparator={true}
                      allowNegative={false}
                      maxLength={6}
                      defaultValue={item.sell}
                    />

                  </Form.Item></td>
                </tr>}</>)}
                <tr>
                  <td colspan="10">
                {tierData?.length === 0  &&
                <div className="text-center"><span>No data available</span></div> }
        </td>
        </tr>
               
              
            </table>
            </div>  
            
            <Form.Item className="btn-grp">
            {tierData?.length!==0 &&
                    <Button type="primary" className="primary-btn" htmlType="submit"
                      loading={this.state.btnLoader}
                      >
                      Save
                    </Button>
               } 
               <Button
							type="primary"
							className="primary-btn cancel-btn"
							style={{ margin: "0 8px" }}
              onClick={this.handleCancel}>
                      Cancel
						</Button>
                  </Form.Item>
                  <Form.Item>
                  <Modal title="Confirm"
        visible={this.state.deleteShowPop}
        closable={false}
        footer={<>
          <Button
            type="primary"
            className="primary-btn cancel-btn"
            onClick={() => this.handleBackToCommission()}
           
          >
            No
          </Button>
          <Button
            type="primary"
            className="primary-btn"
            onClick={() => this.hadleCommission()}
          >
            Yes
          </Button>
        </>}>
        <h4 className="text-white-30 fs-16 fw-400">Do you want to cancel, your changes may not be save?</h4>
      </Modal>
      </Form.Item>
          </Form>
    </>)
  }
}
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData,
  };
};
export default connect(
  connectStateToProps,
)(CommissionsTier);