
import React, { Component } from "react";
import {
	Typography,
	Row,
	Col,
	Spin,
	message,
	Button,
	Tooltip,
	Modal,
	Empty,
} from "antd";
import Moment from "react-moment";
import apiCalls from "../../api/apiCalls";
import List from "../grid.component";
import config from "../../config";

const { Text } = Typography;

class ReconcileDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previousSate: [],
			formLoader: false,
			cryptoModal: false,
			selectedModal: {},
		};
	}

	componentDidMount() {
		this.getDetail();
	}

	gridColumns = [
		
		{
			field: "date",
			title: "Date",
			filter: true,
			filterType: "date",
			width: 180,
			customCell: (props) => (
				<td>
					<div>
					{props.dataItem.date ? <Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem.date && apiCalls.convertUTCToLocalTime(props.dataItem.date) || props.dataItem.date}</Moment> : props.dataItem.date}
					</div>
				</td>
			),
		},
		{
			field: "userName",
			title: "User Name",
			filter: true,
			width: 250,
		},
		{
			field: "docType",
			title: "Transaction ",
			filter: true,
			width: 180,
		},
		{
			field: "fromWalletCode",
			title: "From Wallet Code",
			filter: true,
			width: 250,
		},
		{
			field: "toWalletCode",
			title: "To Wallet Code",
			filter: true,
			width: 250,
		},
		{
			field: "fromValue",
			title: "From Value",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},

		
		{
			field: "toValue",
			title: "To Value",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "fromValueBefore",
			title: "From Before Value",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toValueBefore",
			title: "To Before Value",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "fromValueAfter",
			title: "From After Value",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		
		{
			field: "toValueAfter",
			title: "To After Value",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comission",
			title: "Commission",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComission",
			title: "Provider Commission",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepFee",
			title: "Sweep Fee",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepFeeInBase",
			title: "Sweep Fee in Base",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comissionInBase",
			title: "Commission in Base",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComissionInBase",
			title: "Provider Commission in Base",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profit",
			title: "Profit",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profitInBase",
			title: "Profit in Base",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "transactionResponse",
			title: "Provider Response",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
			customCell: (props) => (
				<td>
					<Tooltip title="View More">
						<span
							className="icon md info c-pointer ml-8"
							style={{ float: "center" }}
							onClick={() => this.infoModal(props)}
						/>
					</Tooltip>
				</td>
			),
		},
	];
	infoModal = (props) => {
		this.setState({
			...this.state,
			cryptoModal: true,
			selectedModal: JSON.parse(props.dataItem.transactionResponse),
		});
	};

	getDetail = async () => {
		this.setState({ ...this.state, formLoader: true });
		let response = await apiCalls.getReconcilDetails(
			this.props.match.params.docid
		);
		if (response.ok) {
			let _data = Object.entries(response.data)?.map(([key, value]) => ({
				key,
				value,
			}));
			let filteredData = _data.filter(
				(item) => item.key !== "reconcileId" && item.key !== "walletId"
			);
			this.setState({
				...this.state,
				previousSate: filteredData,
				formLoader: false,
			});
		} else {
			this.setState({ ...this.state, formLoader: false });
			message.destroy();
			message.error({
				content: apiCalls.isErrorDispaly(response),
				className: "custom-msg",
			});
		}
	};
	bactoDetailGrid = () => {
		this.props.history.push({
			pathname: `/reconcile/view/${this.props.match.params.id}`,
		});
	};
	render() {
		const { selectedModal } = this.state;
		return (
			<>
				<div className="case-ribbon">
					<Spin spinning={this.state.formLoader}>
						<Row gutter={[16, 16]}>
							{this.state.previousSate?.map((item) => (
								<Col xs={24} md={24} lg={12} xl={8} xxl={6}>
									<div className="ribbon-item">
										<div className="ml-16">
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												{item.key.replace(/([A-Z])/g, " $1").trim()}
											</Text>
											<div className="fw-600 text-white-30 fs-16 l-height-normal">
												<Text>{item.value}</Text>
											</div>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Spin>
				</div>
				<List
					// showActionBar={true}
					ignoreSaved={true}
					columnsIsNotReorderable={true}
					onActionClick={(key) => this.onActionClick(key)}
					pKey={"reconcile"}
					ref={this.gridRef}
					url={
						process.env.REACT_APP_RECONCILE_GRID_API +
						`Reconcile/GetReconcileTransactions/${this.props.match.params.docid}`
					}
					columns={this.gridColumns}
				/>
				<div className="text-right mt-24">
					<Button
						type="primary"
						className="primary-btn cancel-btn"
						style={{ margin: "0 8px" }}
						onClick={this.bactoDetailGrid}>
						Cancel
					</Button>
		
				
				</div>
				<Modal
					title={<p>Info Details</p>}
					visible={this.state.cryptoModal}
					className="crypto-list"
					destroyOnClose
					closeIcon={
						<Tooltip title="Close">
							<span
								className="icon md x c-pointer"
								onClick={() =>
									this.setState({ ...this.state, cryptoModal: false })
								}
							/>
						</Tooltip>
					}
					footer={
						<Button
						type="primary"
						className="primary-btn cancel-btn"
							onClick={() =>
								this.setState({ ...this.state, cryptoModal: false })
							}>
							Close
						</Button>
					}>
					{selectedModal ? (
						<div className="case-ribbon">
							<Row gutter={[8, 8]} wrap={true}>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Status
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.status}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Opentm
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.opentm}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Starttm
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.starttm}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Expiretm
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.expiretm}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Type
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.type}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Type
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.type}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Pair
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.pair}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Order Type
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.ordertype}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Price
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.price}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Price2
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.price2}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Leverage
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.leverage}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Order
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.order}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Close
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.descr?.close ||
														"--"}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Vol
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.vol}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Vol_exec
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.vol_exec}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Cost
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.cost}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Fee
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.fee}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Price
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.price}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Stop Price
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.stopprice}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Limit Price
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.limitprice}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Misc
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.misc || "--"}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												oflags
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.oflags}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												trades
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.trades}</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												reason
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>
													{selectedModal.result?.MainResult?.reason || "--"}
												</Text>
											</div>
										</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="ribbon-item">
										<div className="ml-16" style={{ flex: 1 }}>
											<Text className="fw-300 text-white-50 fs-12 text-captz">
												Closetm
											</Text>
											<div
												className="fw-600 text-white-30 fs-14 l-height-normal"
												style={{ wordBreak: "break-all" }}>
												<Text>{selectedModal.result?.MainResult?.closetm}</Text>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					) : (
						<>
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description="No Data Found"></Empty>
						</>
					)}
				</Modal>
			</>
		);
	}
}
export default ReconcileDetails;
