import React, { Component } from 'react';
import List from "../grid.component";
import { message, Button, Alert } from 'antd';
import { connect } from 'react-redux';

class MassPaymentsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridUrl: process.env.REACT_APP_GRID_API + "MassPayments/GetMassPaymentDetailsById",
            params: {massPaymentId: props.match?.params.id},
            alert: false,
        }
        this.gridRef = React.createRef();
    }

    gridColumns = [
        { field: "name", title: "Name", width: 180, filter: true },
        { field: "accountNo", title: "Account Number", filter: true, width: 150 },
        { field: "bankName", title: "Bank Name", filter: true, width: 150 },
        { field: "shift_BICCode", title: "Shift/BIC Code", filter: true, width: 150 },
        { field: "amount", title: "Amount", filter: true, width: 150 },
        { field: "currency", title: "Currency", filter: true, width: 150 },
        { field: "friendlyName", title: "Friendly Name", filter: true, width: 150 },
        { field: "memo", title: "Memo", filter: true, width: 150 },
        { field: "paymentType", title: "Payment Type", filter: true, width: 150 },
        { field: "country", title: "Country", filter: true, width: 150 },
        { field: "status", title: "Status", filter: true, width: 150 },
        { field: "oneTime", title: "One Time", filter: true, width: 150 },
        { field: "remarks", title: "Remarks", filter: true, width: 150 },
    ]
    
     backUploadMassPayments = () => {
        this.props.history.push({
            pathname: '/masspayments'
        });
    }
   
    success = () => {
        message.success({
            content: 'Record ' + (this.state.selectedObj.status === 'Active' ? 'deactivated' : 'activated') + ' successfully',
            className: 'custom-msg',
            duration: 1
        });
    };
   
    render() {
        const { gridUrl,params } = this.state;
        return (<>
            {this.state.alert &&
                <div className="custom-alert" ><Alert
                    message="Please select the one record"
                    type="warning"
                    showIcon
                    closable={false}
                />
                </div>}
   
            <List showActionBar={false} pKey={"masspayments"} url={gridUrl} additionalParams={params} ref={this.gridRef} columns={this.gridColumns} />
            <div className="text-right mt-24">
                <Button
                    type="primary"
                    className="primary-btn cancel-btn"
                    style={{ margin: '0 8px' }}
                    onClick={this.backUploadMassPayments}
                >
                    Cancel
                </Button>
            </div>
        </>

        );
    }
}
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
    return { breadCrumb, oidc, userConfig: userConfig.userProfileInfo }
}

export default connect(connectStateToProps, (dispatch) => { return { dispatch } })(MassPaymentsDetails);