import { clientApi } from "../../api";
import { ApiControllers } from "../../api/config";
const getPaymentDetails = (id) => {
  return clientApi.get(ApiControllers.masspayment + `BillPayments/payment/${id}`);
};
const savePaymentDetails = (obj) => {
  return clientApi.put(ApiControllers.masspayment + `UpdateAdminpayments`, obj);
};
const getBankData = (addressId) => {
  return clientApi.get(ApiControllers.masspayment + `BankDetails/${addressId}`);
}
const getFileURL = (obj) => {
  return clientApi.post(ApiControllers.customers + `FetchFile`, obj)
}
const getStates = (status) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${"Bill Payments"}/${status}`
	);
};
export { getPaymentDetails, savePaymentDetails, getBankData, getFileURL,getStates };