import React,{useEffect} from "react";
import {useHistory,withRouter} from "react-router-dom"

const CallbackPage = (props) => {
    const history = useHistory()
    useEffect(() => {
        handleRedirect();
    }, []);
    const handleRedirect = (user) => {
        if(localStorage.getItem('myurl')){
            props.history.push(localStorage.getItem('myurl'));
            localStorage.removeItem("myurl");
        }else{
            history.push("/dashboard");
            localStorage.removeItem("__url");
        }
     }

    return (
        <div className="loader">Loading .....</div>
    );

}
export default withRouter(CallbackPage);