import React from "react";
import { Row, Col, Typography } from "antd";
const AddressInfo = ({addressDetails,border}) => {
    const {  Paragraph } = Typography;
  return (
    <>
      
      <>
        <Row gutter={[16, 24]}  className={border ?`applicant-content`:''}>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Street</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {addressDetails?.street}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Street (line2)</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {addressDetails?.subStreet}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">City</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {addressDetails?.town}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">State</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {addressDetails?.state}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Postal/Zip Code</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {addressDetails?.postCode}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Country</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {addressDetails?.country}
              </Paragraph>
            </div>
          </Col>
        </Row>
      </>
    </>
  );
};

export default AddressInfo;
