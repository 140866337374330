import React, { Component } from "react";
import { connect } from "react-redux";
import { Button,Typography,Row,Col,Alert } from "antd";
import apiCalls from "../../api/apiCalls";
import {setStep} from '../../reducers/transactionComplianceReducer';
import NumberFormat from 'react-number-format';
const { Title } = Typography;
class TransactionSummary extends Component {
  state={
    errorMsg:null,
    btnLoading:false,
    
  }
      saveWithdrawal=async()=>{
        this.setState({...this.state,errorMsg:null,btnLoading:true});
        let data = Object.assign({}, this.props.transactionCompliance.withdrawFinalObj);
        data.accountNumber = apiCalls.encryptValue(data.accountNumber, this.props.userConfig?.sk);
        data.beneficiaryAccountName =apiCalls.encryptValue(data.beneficiaryAccountName, this.props.userConfig?.sk);
        data.beneficiaryAccountAddress =  apiCalls.encryptValue(data.beneficiaryAccountAddress, this.props.userConfig?.sk);
        data.routingNumber =  apiCalls.encryptValue(data.routingNumber, this.props.userConfig?.sk);
        data.bankName = apiCalls.encryptValue(data.bankName, this.props.userConfig?.sk);
        data.bankAddress = apiCalls.encryptValue(data.bankAddress, this.props.userConfig?.sk);
        let response = await apiCalls.savewithdrawFiat(data)
        if(response.ok){
          this.setState({...this.state,errorMsg:null,btnLoading:false});
            this.props.dispatch(setStep("withdraw_transaction_success"))
        }
        else{
          this.setState({...this.state,btnLoading:false, errorMsg:apiCalls.isErrorDispaly(response)})
          }
        }
      
    render(){
      const {errorMsg,btnLoading} =this.state;
        return(
            <>
            {errorMsg !== undefined && errorMsg !== null && (
              <Alert
                className="w-100 mb-16"
                type="warning"
                description={errorMsg}
                showIcon
              />
            )}
        <Row style={{ alignItems: "flex-end" }} className="">
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">Whitelist Name</label>
            <div className="kpi-val">{this.props.transactionCompliance.withdrawFinalObj.favouriteName || "-"}</div>
            </Col>
           
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12"> Withdrawal Amount </label>              
              <div className="kpi-val">
                                        {this.props.transactionCompliance.withdrawFinalObj.requestedAmount ? 
                                       ( <NumberFormat value={this.props.transactionCompliance.withdrawFinalObj.requestedAmount} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                                   :("-")} {' '}
                                       {this.props.transactionCompliance.withdrawFinalObj.walletCode}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">Effective Fees </label>
            <div className="kpi-val"> 
                                       {this.props.transactionCompliance?.withdrawFinalObj?.totalFee ? <NumberFormat value={this.props.transactionCompliance?.withdrawFinalObj?.totalFee} decimalSeparator="." displayType={'text'} thousandSeparator={true} />:"0"}
                                      
                                    {' '}
                {this.props.transactionCompliance.withdrawFinalObj.walletCode}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">Fees </label>
            <div className="kpi-val">
                                       {this.props.transactionCompliance?.withdrawFinalObj?.comission ? <NumberFormat value={this.props.transactionCompliance?.withdrawFinalObj?.comission} decimalSeparator="." displayType={'text'} thousandSeparator={true} /> : "0"}
                                      
                                    {' '}
                {this.props.transactionCompliance.withdrawFinalObj.walletCode}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">Tier Discount </label>
            <div className="kpi-val">
                                       {this.props.transactionCompliance?.withdrawFinalObj?.tierDiscount ? <NumberFormat value={this.props.transactionCompliance?.withdrawFinalObj?.tierDiscount} decimalSeparator="." displayType={'text'} thousandSeparator={true} /> : "0"}
                                      
                                    {' '}
                {this.props.transactionCompliance.withdrawFinalObj.walletCode}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">SuisseBase Credit Used </label>
            <div className="kpi-val">
                                       {this.props.transactionCompliance?.withdrawFinalObj?.sbCredit ? <NumberFormat value={this.props.transactionCompliance?.withdrawFinalObj?.sbCredit} decimalSeparator="." displayType={'text'} thousandSeparator={true} /> : "0"}
                                      
                                    {' '}
                {this.props.transactionCompliance?.withdrawFinalObj?.walletCode}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">  Beneficiary Will Receive  </label>
            <div className="kpi-val">
              
                                         <NumberFormat 
                                         value={this.props.transactionCompliance?.withdrawFinalObj?.totalValue} 
                                         decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                        
                                    {' '}
                 {this.props.transactionCompliance?.withdrawFinalObj?.walletCode}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">    Bank Name </label>
            <div className="kpi-val"> {this.props.transactionCompliance?.withdrawFinalObj?.bankName}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">Bank Account Number/IBAN</label>
            <div className="kpi-val"> {this.props.transactionCompliance?.withdrawFinalObj?.accountNumber}</div>
            </Col>
            <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24 kpi-List">
            <label className="fs-12">BIC/SWIFT/ABA Routing/UK Sort Code</label>
            <div className="kpi-val">{this.props.transactionCompliance?.withdrawFinalObj?.routingNumber}</div>
            </Col>
           </Row>
            <ul className="pl-0 ml-16 text-white-50 my-36">
                        <li>
                          <Title  className="fs-18 fw-600  mb-0" >
                          Please ensure that the above details are accurate and correct as the transaction is irreversible.
                          </Title>
                        </li>
                      
                      </ul>
           
            <div className="text-right">

                        <Button
                          htmlType="submit"
                          size="large"
                          loading={btnLoading}
                          className="pop-btn primary-btn preview-btn"
                          onClick={this.saveWithdrawal}
                        >
                         Confirm
                        </Button>
                        </div>
            </>
        )
    }
}
const connectStateToProps = ({
    userConfig,transactionCompliance
  }) => {
    return {
      userConfig: userConfig.userProfileInfo,transactionCompliance
    };
  };
  export default connect(connectStateToProps,)(TransactionSummary);
