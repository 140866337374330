import {getTransaction} from "../components/transaction.component/api";

//Actions
const FETCH_TRANSACTION = 'fetchTransaction';
const FETCH_TRANSACTION_SUCCESS = 'fetchTransactionSuccess';
const FETCH_TRANSACTION_REJECT = 'fetchTransactionReject';
const FETCH_TRANSACTION_UPDATE = 'fetchTransactionUpdate';

//Action Type
const fetchTransaction = () => {
    return {
        type: FETCH_TRANSACTION,

    }
}
const fetchTransactionSuccess = (payload) => {
    return {
        type: FETCH_TRANSACTION_SUCCESS,
        payload
    }
}
const fetchTransactionReject = (payload) => {
    return {
        type: FETCH_TRANSACTION_REJECT,
        payload
    }
}
const fetchTransactionUpdate = (payload) => {
    return {
        type: FETCH_TRANSACTION_UPDATE,
        payload
    }
}
//InitialState
let initialState = {
    transactionData: { loading: false, data: [], error: null },
    transactionRowData: {}
}


const handleTransaction = (transId) => {
    return async (dispatch) => {
        dispatch(fetchTransactionSuccess({ key: "transactionData", loading: true, data: [] }));
        const response = await getTransaction(transId);
        if (response.ok) {
            dispatch(fetchTransactionSuccess({ key: "transactionData", loading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchTransactionReject({ key: "transactionData", loading: false, data: [], 
            error: response.status === 401 ? (response.data.message) : response.originalError  }))
        }
    }
}

//Reducer
const transactionReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case FETCH_TRANSACTION:
            return { ...state ,loading:true}
        case FETCH_TRANSACTION_SUCCESS:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        case FETCH_TRANSACTION_REJECT:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        case FETCH_TRANSACTION_UPDATE:
            return { ...state, userRowData: action.payload }
        default:
            return state;
    }

}

//Export
export default (transactionReducer);
export { handleTransaction, fetchTransactionUpdate, fetchTransaction };