import React, { Component } from 'react';
import { Row, Col, Typography } from 'antd';
import Loader from '../loader.component';
import { handleSwap } from '../../reducers/swapReducer';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import apiCalls from '../../api/apiCalls';

class SwapDetailView extends Component {
    state = {
        swap: {},
        isLoading: false,
    }
    componentDidMount() {
        var selectedId = this.props.match.params.id
        this.props.fetchSwapData(selectedId)
    }

    render() {
        const { Title } = Typography
        const { data: swapData, isLoading } = this.props.swapStore.swapData;
        return (
            <>
                {isLoading && <Loader />}
                <Title className="page-title">Swap Detail View</Title>
                <Row gutter={[8, 8]}>
                    <Col xl={24} xxl={24} className="bank-view">
                        <Row className="kpi-List">
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md user" />
                                <div>
                                    <label className="kpi-label">Name</label>
                                    <div className="kpi-val">{swapData?.memberName}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md user" />
                                <div>
                                    <label className="kpi-label">User Name</label>
                                    <div className="kpi-val">{swapData?.userName}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md email" />
                                <div>
                                    <label className="kpi-label">Email</label>
                                    <div className=" kpi-val">{swapData?.memberEmail}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md date" />
                                <div>
                                    <label className="kpi-label">Date</label>
                                    <div className="kpi-val"><Moment format="DD/MM/YYYY hh:mm:ss A">{swapData?.date ? apiCalls.convertUTCToLocalTime(swapData?.date) : swapData?.date}</Moment></div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md wallet" />
                                <div>
                                    <label className="kpi-label">From Wallet</label>
                                    <div className="kpi-val">{swapData?.fromWalletCode}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md wallet" />
                                <div>
                                    <label className="kpi-label">To Wallet</label>
                                    <div className="kpi-val">{swapData?.toWalletCode}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md file" />
                                <div>
                                    <label className="kpi-label">From Value</label>
                                    <div className="kpi-val">
                                        <NumberFormat value={swapData?.fromValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md file" />
                                <div>
                                    <label className="kpi-label">To Value</label>
                                    <div className="kpi-val">
                                        <NumberFormat value={swapData?.toValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md wallet" />
                                <div>
                                    <label className="kpi-label">From Wallet Name</label>
                                    <div className="kpi-val">{swapData?.fromWalletname}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md wallet" />
                                <div>
                                    <label className="kpi-label">To Wallet Name</label>
                                    <div className="kpi-val">{swapData?.toWalletName ? swapData?.toWalletName : "-"}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Provider Cost</label>
                                    <div className=" kpi-val  fw-700">
                                        <NumberFormat value={swapData?.exicutedPrice} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md commission" />
                                <div>
                                    <label className="kpi-label">Commission</label>
                                    <div className=" kpi-val  fw-700">
                                        <NumberFormat value={swapData?.comissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md commission" />
                                <div>
                                    <label className="kpi-label">Provider Commission</label>
                                    <div className=" kpi-val  fw-700">
                                        <NumberFormat value={swapData?.providerComission} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md difference" />
                                <div>
                                    <label className="kpi-label">Profit</label>
                                    <div className=" kpi-val  fw-700">
                                        <NumberFormat value={swapData?.profit} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md commission" />
                                <div>
                                    <label className="kpi-label">Commission Type (%)</label>
                                    <div className=" kpi-val  fw-700">
                                        <NumberFormat value={swapData?.comissiontype} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Total Amount</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.totalAmount} renderText={() => swapData?.totalAmount !== "" ? swapData?.totalAmount : '--'} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Amount in USD</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.amountInUsd} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                           
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">From Amount in USD</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.fromAmountInUSD} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Comission Percentage</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.comissionPercentage} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                            <span className="icon md commission" />
                                <div>
                                    <label className="kpi-label">Comission Value in USD</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.comissionValueInUSD} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Provider Comission Percentage</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.pComissionPercentage} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Provider Comission Type</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.pComissionType} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Provider Comission Value</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.pComissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col> */}
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                            <span className="icon md commission" />
                                <div>
                                    <label className="kpi-label">Provider Comission Value in USD</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.pComissionValueInUSD} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">Profit in USD</label>
                                    <div className=" kpi-val">
                                        <NumberFormat value={swapData?.profitInUSD} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

const connectStateToProps = ({ swapStore }) => {
    return { swapStore }
}
const connectDispatchToProps = dispatch => {
    return {
        fetchSwapData: (id) => {
            dispatch(handleSwap(id))
        },
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(SwapDetailView);