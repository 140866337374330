import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";

const getRolesLU = (id) => {
    return clientApi.get(ApiControllers.security + `Users/Roles/`+id);
}
const saveUser = (saveobj,id) => {
    return id !== "00000000-0000-0000-0000-000000000000"?clientApi.put(ApiControllers.security+'Users/'+id, saveobj):clientApi.post(ApiControllers.security+'Users', saveobj)
}
const emailCheck = (email,type) => {
    if(type==='user'){
        return clientApi.get(ApiControllers.customers + 'IsExist/'+email+'/UserName');
    }else{
        return clientApi.get(ApiControllers.customers +'IsExist/'+email+'/Email');
    }
}
const getUser = (userId) => {
    return clientApi.get(ApiControllers.security +'Users/' +userId);
}
const activeInactive = (obj) => {
	return clientApi.put(ApiControllers.master + `User/Status`, obj);
};

export  {getRolesLU,saveUser,emailCheck,getUser,activeInactive}
