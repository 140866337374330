import React, { useState, useEffect } from 'react'
import List from "../grid.component";
import { Form, Row, Col, Select, Typography, Alert } from "antd";
import apiCalls from '../../api/apiCalls';
import Moment from 'react-moment';
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import config from '../../config';
const DashBoardGrid = (props) => {
  const [stateluData, setStateLuData] = useState([]);
  const [addressStateLu, setAddressStateLu] = useState([]);
  const [searchObj, setSearchObj] = useState({ status: "all" });
  const [errorMsg, setErrorMsg] = useState(null);
  const { Option } = Select;
  const { Title } = Typography;
  const gridRef = React.createRef();
  const screen = props?.screenName;
  const addressName = {
    "ownbusiness": "My Company",
    "individuals": "Individuals",
    "otherbusiness": "Other Business",
    "Business": "Other Business",
    "myself": "My Self"
  };
  const gridColumns = [
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      locked: true,
      width: 180,
      customCell: (props1) => (
        <div className="gridLink" onClick={() => gotoViewDetails(props1, screen)}>
          {props1?.dataItem?.transactionId}
        </div>
      )
    },
    {
      field: "date", title: "Submission Date", filter: true, width: 200, isShowTime: true,
      filterType: "date",
      customCell: (props2) => (
        <td>
          <div>
            {props2.dataItem?.date && <Moment format={config?.dateFormates?.dateTimeFormate}>{props2.dataItem?.date && apiCalls.convertUTCToLocalTime(props2.dataItem?.date) || props2.dataItem?.date}</Moment> || props2.dataItem?.date}
          </div>
        </td>
      ),
    },
    {
      field: "userName", title: "Business Name/Personal Name", filter: true, width: 270, customCell: (props3) => (
        <td className="whitelist-name">
          <div className="d-flex">
            <div className="white-address">{props3.dataItem.userName}</div>
            {(props3?.dataItem?.digitalTranfer || props3?.dataItem?.digitalTranfer === false) && screen == "Receive" && (
              <div className="file-labels ml-8 fs-12 address-label address-label-width">
                {props3?.dataItem?.digitalTranfer && "Pyrros" || props3?.dataItem?.digitalTranfer === false && "OpenPayd"}
              </div>
            )}
            {props3?.dataItem?.bankAccountType && screen === "Send" && (
              <div className="file-labels ml-8 fs-12 address-label address-label-width">
                {props3?.dataItem?.bankAccountType}
              </div>
            )}
          </div>
        </td>
      ),
    },
    { field: "currency", title: "Currency", filter: true, width: 115 },
    {
      field: "amount", title: "Amount", filter: true, width: 170, dataType: "number",
      filterType: "numeric",
    },
    {
      field: "status", title: "Status", filter: true, width: 180,
      customCell: (data) => {
        const statusColorConfig = props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status === data.dataItem.status);
        const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
        const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';

        return (
          <td className="whitelist-name">
            <div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
              {data.dataItem.status}
            </div>
          </td>
        );
      }
    },];
  const AddressBookGrid = [
    {
      field: "date", title: "Created Date", filter: true, filterType: "date", width: 250, locked: true,
      customCell: (props) => (
          <div className="gridLink"
            onClick={() => gotoViewDetails(props, screen)}          >
            {props.dataItem?.date && (
              <Moment format={config?.dateFormates?.dateTimeFormate}>
                {apiCalls.convertUTCToLocalTime(props.dataItem.date)}
              </Moment>
            )}
            {!props.dataItem?.date && ("--")}
          </div>
      ),
    },
    {
      field: "name",
      title: "Name",
      filter: true,
      width: 160,

    },

    {
      field: "whiteListName", title: "Whitelist Name", filter: true, width: 300, customCell: (pros) => (
        <td className="whitelist-name">
          <div className="d-flex">
            <div className="white-address">	{pros.dataItem.whiteListName}</div>
            {pros?.dataItem?.addressType && (
              <div className="file-labels ml-8 fs-12 text-nowrap">
                {addressName[pros?.dataItem?.addressType]}
              </div>
            )}</div>
        </td>
      ),
    },
    {
      field: "currencynetwork", title: "Currency/Network", filter: true, width: 200,
    },
    {
      field: "satatus", title: "Whitelisting Status", filter: true, width: 200,
      // customCell: (data) => {
      //   const statusColorConfig = props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status === data.dataItem.satatus);
      //   const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
      //   const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';

      //   return (
      //     <td className="whitelist-name">
      //       <div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
      //         {data.dataItem.satatus}
      //       </div>
      //     </td>
      //   );
      // }
    },];

  useEffect(() => {
    getStatesData()
  }, [])
  useEffect(() => {
    gridRef.current?.refreshGrid();
  }, [searchObj])
  const getStatesData = async () => {
    let res = await apiCalls.getStates(screen === "Address Book" ? "Dashboard AddressBook" : "Dashboard Transactions", "All");
    if (res.ok) {
      if (screen === "Address Book") {
        setAddressStateLu(res.data);
      }
      setStateLuData(res.data);
    } else {
      setErrorMsg(apiCalls.isErrorDispaly(res));
    }
  }
  const handleStateChange = (item) => {
    setSearchObj({ status: item });
  }
  const gotoViewDetails = (item, SelectedScreen) => {
    if (SelectedScreen === "Receive") {
      if (item.dataItem.type.toLowerCase() == "fiat") {
        localStorage.setItem("MyURL", `deposit/${item.dataItem.id}/fiatDetails/view`)
        window.open(`deposit/${item.dataItem.id}/fiatDetails/view`, "_blank");
        props.prop.dispatch(
          setBreadcrumb({
            key: "/deposit/" + item.dataItem.id + "/fiatDetails/view",
            val: item.dataItem.transactionId + " / " + item.dataItem.status,
          })
        );
      } else {
        localStorage.setItem("MyURL", `deposit/${item.dataItem.id}/cryptoDetailView`)
        window.open(`deposit/${item.dataItem.id}/cryptoDetailView`, "_blank");
        props.prop.dispatch(
          setBreadcrumb({
            key: "/deposit/" + item.dataItem.id + "/cryptoDetailView",
            val: item.dataItem.transactionId + " / " + item.dataItem.status,
          })
        );
      }
    } else if (SelectedScreen === "Send") {
      localStorage.setItem("MyURL", `withdraw/${item.dataItem.id}/fiat`)
      if (item.dataItem.type.toLowerCase() == "fiat") {
        window.open(`withdraw/${item.dataItem.id}/fiat`, "_blank");
        props.prop.dispatch(
          setBreadcrumb({
            key: "/withdraw/" + item.dataItem.id,
            val: ((item.dataItem.transactionId) + ((item.dataItem.transactionId || item.dataItem.transactionId === null) ? ("  " + "/") : " ") + " " + item.dataItem.status)
          })
        );
      } else {
        localStorage.setItem("MyURL", `withdraw/${item.dataItem.id}/crypto`)
        window.open(`withdraw/${item.dataItem.id}/crypto`, "_blank");
        props.prop.dispatch(
          setBreadcrumb({
            key: "/withdraw/" + item.dataItem.id,
            val: ((item.dataItem.transactionId) + ((item.dataItem.transactionId || item.dataItem.transactionId === null) ? ("  " + "/") : " ") + " " + item.dataItem.status)
          })
        );
      }
    } else if (SelectedScreen === "Address Book") {
      localStorage.setItem("MyURL", `addressbook/fiatView/${item.dataItem.payeeId}/${item.dataItem.id}`)
      if (item.dataItem.type.toLowerCase() == "fiat") {
        window.open(`addressbook/fiatView/${item.dataItem.payeeId}/${item.dataItem.id}`, "_blank");
        props.prop.dispatch(
          setBreadcrumb({
            key: `/addressbook/fiatView/${item.dataItem.payeeId}/${item.dataItem.id}`,
            val: item.dataItem.name,
          })
        );
      } else {
        localStorage.setItem("MyURL", `addressbook/cryptoView/${item.dataItem.payeeId}/${item.dataItem.id}`)
        window.open(`addressbook/cryptoView/${item.dataItem.payeeId}/${item.dataItem.id}`, "_blank");
        props.prop.dispatch(
          setBreadcrumb({
            key: `/addressbook/cryptoView/${item.dataItem.payeeId}/${item.dataItem.id}`,
            val: item.dataItem.name,
          })
        );
      }
    }
  }
  const selectdOptionData = (Items) => {
    return Items.map((item) => (
      <Option key={item.code} value={item.name}>
        {item.name}
      </Option>
    ))
  }
  return (
    <>
      {errorMsg && (
        <Alert
          closable
          className='mb-16'
          type="error"
          description={errorMsg}
          onClose={() => setErrorMsg(null)}
          showIcon
        />
      )}
      <Row style={{ alignItems: "flex-end" }} className='align-center'>
        <Col sm={24} xs={24} md={24} lg={5} className="px-8">
          <Title className='page-title fs-18'>{props?.screenName}</Title>
        </Col>

        {screen !== "Address Book" && <Col sm={24} xs={24} md={7} className="px-8">   <Form.Item
          name="customerType"
          className="input-label mb-0"
        >
          <Select
            defaultValue="All"
            className="cust-input w-100 mb-8"
            showSearch
            placeholder="Select Address Type"
            onChange={(item) => handleStateChange(item)}
          >
            {selectdOptionData(stateluData)}
          </Select>
        </Form.Item></Col>}
        {screen === "Address Book" && <Col sm={24} xs={24} md={7} className="px-8">   <Form.Item
          name="customerType"
          className="input-label mb-0"
        >
          <Select
            defaultValue="All"
            className="cust-input w-100 mb-8"
            showSearch
            placeholder="Select Address Type"
            onChange={(item) => handleStateChange(item)}
          >
            {selectdOptionData(addressStateLu)}
          </Select>
        </Form.Item></Col>}

      </Row>
      <List
        url={process.env.REACT_APP_GRID_API + `${props?.URL}/${searchObj.status}`}
        columns={props?.screenName == "Address Book" ? AddressBookGrid : gridColumns}
        showActionBar={true}
        showExcelExport={true}
        pSize={5}
        ref={gridRef}
      />
    </>
  )
}
const connectDispatchToProps = (dispath) => {
  return {
    dispath
  };
};
const connectStateToProps = ({ breadCrumb, userConfig }) => {
  return {
    breadCrumb,
    userConfig: userConfig.userProfileInfo,

  };
};

export default connect(connectStateToProps, connectDispatchToProps)(DashBoardGrid);