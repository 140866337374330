import React,{useEffect,useState} from 'react';
import { Layout, Menu, Dropdown, Typography,Alert} from 'antd';
import BreadcrumbComponent from './breadcrumb';
import SideNav from './side.nav';
import { Link, withRouter } from 'react-router-dom';
import SuisseBaseContent from './content';
import logoColor from '../assets/images/logo-color.png'
import CallbackPage from '../authentication/callback.component';
import Footer from './footer'
import OnBoarding from './onboard.component';
import sumsubLogo from '../assets/images/sumsub-logo.png';
import fireblockLogo from '../assets/images/Fireblocks.svg';
import Zoho from '../assets/images/zoho.png';
import Egnite from '../assets/images/egnite.svg';
import UBS from '../assets/images/ubs.svg';
import OnePswd from '../assets/images/onepswd.png';
import Koinly from '../assets/images/koinly.svg';
import { clearPermissions } from "../reducers/permissionsReducer";
import defaultuser from "../assets/images/defaultuser.jpg";
import openpay from "../assets/images/openpayd.svg";
import bcb from "../assets/images/badge-blue.svg";
import blaze from "../assets/images/blaze.svg";
import scorechain from "../assets/images/scorechain.svg";
import elliptic from "../assets/images/logo-e.svg";
import auth0 from "../assets/images/auth0.svg";
import aml from "../assets/images/aml-logo.svg";
import worldcheck from "../assets/images/worldcheck.png";
import Xe from "../assets/images/xe-logo.svg";
import xero from "../assets/images/xero-logo.svg";
import zohoblue from "../assets/images/blue-logo.svg";
import klarpay from "../assets/images/klarpay.svg";
import Arival from "../assets/images/arival.png";
import novalogo from "../assets/images/nova-logo.png";
import { profileSuccess, setToken,userLogout } from "../reducers/authReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux';
import northdata from "../assets/images/northdata.png";
import jotformseeklogo from "../assets/images/jotform-seeklogo.svg";
import linkurious from "../assets/images/linkurious.svg";

const Apps = [
    {
        name: 'UBS', url: 'https://ebanking-ch3.ubs.com', logo: UBS
    },
    {
        name: 'klarpay', url: 'https://www.klarpay.com', logo: klarpay
    },
    {
        name: 'Arival', url: 'https://app.arival.com/public/login', logo: Arival
    },
    {
        name: 'Openpayd', url: 'https://secure.openpayd.com', logo: openpay
    },
    {
        name: 'Bcbpayment', url: 'https://console.bcbpayments.com/login', logo: bcb
    },
    {
        name: 'Blaze', url: 'https://www.blaze.financial', logo: blaze
    },
]

const Aml = [
    {
        name: 'ScoreChain', url: 'https://app.scorechain.com/', logo: scorechain
    },
    {
        name: 'Elliptic', url: 'https://app.elliptic.co/navigator/transactions', logo: elliptic
    },
    {
        name: 'Auth0', url: 'https://breadcrumbs.us.auth0.com', logo: auth0
    },
    {
        name: 'AMLBot', url: 'https://web.amlbot.com/login', logo: aml
    },
    {
        name: 'WorldCheck', url: 'https://worldcheck.refinitiv.com', logo: worldcheck
    },
    {
        name: 'SumSub', url: 'https://cockpit.sumsub.com/checkus#/login', logo: sumsubLogo
    },
]
const Crm = [
    {
        name: 'Nova', url: 'https://app.novahq.com', logo: novalogo
    },
    {
        name: 'ZOHO', url: 'https://crmplus.zoho.com', logo: Zoho
    },
    {
        name: 'Fireblocks', url: 'https://console.fireblocks.io/welcome/login', logo: fireblockLogo
    },
]
const Tools = [
    {
        name: 'Egnite', url: 'https://suissebase.egnyte.com/', logo: Egnite
    },
    {
        name: 'Onepassword', url: 'https://barucholdings.1password.com/signin', logo: OnePswd
    },
    {
        name: 'IBAN calculator', url: 'https://www.xe.com/ibancalculator/', logo: Xe
    },
    {
        name: 'NorthData', url: 'https://www.northdata.com/', logo: northdata
    },
    {
        name: 'Jotform', url: 'https://www.jotform.com/login/', logo: jotformseeklogo
    },
    {
        name: 'LINKURIOUS', url: 'https://lke-prod-openscreening-linkurious-enterprise.lkfgx49jga.k8s.prod.linkurious.net/guest?key=oscr0100&populate=nodeId&item_id=116964146', logo: linkurious
    },
]
const Accounting = [
    {
        name: 'Xero', url: 'https://login.xero.com/', logo: xero
    },
    {
        name: 'Koinly', url: 'https://app.koinly.io/login', logo: Koinly
    },
    {
        name: 'Zoho', url: 'https://www.zoho.com/books/', logo: zohoblue
    },
]

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const handlemenus=(items)=>{
   return  items.map((item, idx) => <li key={idx} className="c-pointer" onClick={() => window.open(item.url, "_blank")}>
                    <img src={item.logo} alt="" />
                </li>)
}
const appMenu = (
    <Menu>
        <div className='text-align-inside'>
            <div className='megamenu-title'>Banking Partners</div>
        <ul className="appmenu-list">
            {handlemenus(Apps)}
        </ul>
        </div>
        <div className='text-align-inside'>
            <div className='megamenu-title'>AML</div>
            <ul className="appmenu-list">
            {handlemenus(Aml)}
            </ul>
        </div>
        <div className='text-align-inside'>
            <div className='megamenu-title'>CRM</div>
            <ul className="appmenu-list">
            {handlemenus(Crm)}
            </ul>
        </div>
        <div className='text-align-inside'>
            <div className='megamenu-title'>Tools</div>
            <ul className="appmenu-list">
            {handlemenus(Tools)}
            </ul>
        </div>
        <div className='text-align-inside'>
            <div className='megamenu-title'>Accounting</div>
            <ul className="appmenu-list">
            {handlemenus(Accounting)}
            </ul>
        </div>
    </Menu>
);

const LayoutComponent =(props)=>  {
const [collapsed,setCollapsed] = useState(false);
const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently,logout } = useAuth0();

  const  userProfile=(key)=> {
        props.history.push("/userprofile/" + key);
    }

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            props.acctoken(token)
            props.updateProfile(user);
        } catch (error) {
        }
    };

    useEffect(()=>{
       if (!isAuthenticated) {
        props.dispatch(userLogout());
         loginWithRedirect();
       } else {
        const URL =localStorage.getItem("MyURL");
        if(URL){
            props.history.push(URL)
        }
           props.updateProfile(user)
           callApi()
       }
    },[])
   
  const  toggle = () => {      
        setCollapsed(!collapsed);
    };

   const redirect = () => {
        window.open(process.env.REACT_APP_USER_URL, "_self")
    }
    const settingMenu = (
        <Menu>
            <Title className="fs-20 textDark my-8 fw-500 mx-30">Manage Your Account</Title>
            <ul className="pl-0 drpdwn-list">

                    <li className={`d-flex justify-content align-center c-pointer fw-500 userdrp ${window.location.pathname.indexOf("/userprofile/1") > -1 && 'menuactive'}`} style={{ borderBottom: "1px solid var(--bgDarkGrey)" }} onClick={() => { userProfile(1) }}>
                    <Link ><span className='icon lg profile-icon mr-16'></span>Profile Info</Link>
                </li>
                    <li className={`d-flex justify-content align-center c-pointer fw-500 userdrp ${window.location.pathname.indexOf("/userprofile/2") > -1 && 'menuactive'}`} style={{ borderBottom: "1px solid var(--bgDarkGrey)" }} onClick={() => { userProfile(2) }}>
                    <Link ><span className='icon lg security-icon mr-16'></span>Security</Link>
                </li>
                <li className="d-flex justify-content align-center c-pointer profile-btn" onClick={() => {
                    props.dispatch(clearPermissions());
                    logout();
                }}>
                    <Link >Logout</Link>
                </li>
            </ul>
        </Menu>
    );
    if (!isAuthenticated || !props.oidc.profile || !props.oidc.deviceToken) {
        return <div className="loader">Loading .....</div>
    }else if ((!props.oidc.user) && window.location.pathname.includes('callback')) {
        return <CallbackPage />
    } else if (props.oidc.profile && !props.userProfile) {
        return <OnBoarding />
    } else if (props.userProfile && props.userProfile?.role == "Customer") {
        return <>{redirect}</>
    } else {
        const sidebarToggleClass = `${collapsed ? "sidebar-close" : "sidebar-open"}`;
        
            return (
                <>  
                    <Layout className={sidebarToggleClass} hasSider={true}>
                        <Header className="tlv-header" id="area">
                            <div>
                                <span className="icon lg toggle-icon trigger toggle-space c-pointer" onClick={toggle}></span>
                                <img src={logoColor} alt="logo" className="p-relative tlv-logo ml-12" />
                            </div>
                            <Menu theme="light" mode="horizontal" className="header-right">
                                <Dropdown overlay={settingMenu} trigger={['click']} placement="topRight" arrow overlayClassName="secureDropdown" getPopupContainer={() => document.getElementById('area')}>
                                    <Menu.Item key="1"><span className='profile-acname cusror-non'>{`${props.userProfile?.firstName} ${props.userProfile?.lastName}`}</span><span className='c-pointer'><img src={props.userProfile?.imageURL || defaultuser} className="default-user" /></span></Menu.Item>
                                </Dropdown>
                                <Dropdown overlay={appMenu} trigger={['click']} placement="topRight" arrow overlayClassName="secureDropdown appDropdown" getPopupContainer={() => document.getElementById('area')}>
                                    <Menu.Item key="2"><span className="icon md app-menu ml-8" /></Menu.Item>
                                </Dropdown>
                            </Menu>
                        </Header>
                        <Layout className="site-layout">
                            <SideNav sider={collapsed} />
                            <Layout style={{ marginTop: 15 }}>
                                {(props.location.pathname != '/') && <BreadcrumbComponent />}
                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: '16px',
                                        position: 'relative',
                                        marginTop: '45px'
                                    }}
                                >
                                    <div className={`main-content ${props.location.pathname == '/dashboard' || props.location.pathname == '/' ? "db-container" : null}`}>
                                        {props.serviceWReducer?.isUpdateAvailable && <Alert showIcon
                                            message="New Software Release Available"
                                            description="New software release! Sign out and Sign in again"
                                            type="warning"
                                            className='app-update'
                                        />}
                                        <SuisseBaseContent />
                                    </div>
                                    <Footer />
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                </>)
        }
    
    }
     const mapStateToProps = ({ oidc, userConfig }) => {
        return { oidc, userProfile: userConfig.userProfileInfo }
    
    }
    const mapDispatchToProps = dispatch => {
        return {
            updateProfile: (info) => { dispatch(profileSuccess(info)) },
            acctoken: (data) => { dispatch(setToken(data)) },
            dispatch
        }
    }
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LayoutComponent));