import React, { useEffect, useState } from 'react';
import { Collapse, Dropdown, Menu, Tooltip, Typography ,Empty} from 'antd';
import Alert from "antd/lib/alert";
import moment from 'moment';
const POACheck = (props) => {
  const { Title } = Typography;
  const [PoaDetails, setIsPoaDetails] = useState(props?.poaData?.checks)
  const [ctrlOwnerShipData, setCtrlOwnerShipData] = useState(props?.ctrlOwnerShipDetails)
  const [companyStructureData, setCompanyStructureDetails] = useState(props?.companyStructureDetails)
  const [companyOfficersData, setCompanyOfficersData] = useState(props?.companyOfficers)
  const [companyCheckData,setcompanyCheckData] =useState(props?.companyCheck)
  const [companySignificantPersons,setCompanySignificantPersons]=useState(props?.companySignificantPersons)
  const [errorMsg, setErrorMsg] = useState(null)
  const { Panel } = Collapse;


  useEffect(() => {
    setIsPoaDetails(props?.poaData?.checks)
  }, [props?.poaData?.checks])

  useEffect(() => {
    setCtrlOwnerShipData(props?.ctrlOwnerShipDetails)
  }, [props?.ctrlOwnerShipDetails])

  useEffect(() => {
    setCompanyStructureDetails(props?.companyStructureDetails)
  }, [props?.companyStructureDetails])


  useEffect(() => {
    setCompanyOfficersData(props?.companyOfficers)
  }, [props?.companyOfficers])

  useEffect(() => {
    setcompanyCheckData(props?.companyCheck)
  }, [props?.companyCheck])

  useEffect(() => {
    setCompanySignificantPersons(props?.companySignificantPersons)
  }, [props?.companySignificantPersons])

  const UserProfileMenu = () => {

    return (
      <Menu>
        <ul className={`admin-actions-mb mb-0 pl-0`}>
          <li className='action-title-mb c-pointer' >
            <Tooltip placement="bottom" title={''}>
              <span className={`icon c-pointer md mr-8`} ></span>
            </Tooltip>
          </li>
        </ul>
      </Menu>
    );
  }
  return (
    <div className='poa-accordian'>
      {errorMsg && (
        <div className="mt20">
          <Alert
            closable
            type="error"
            description={errorMsg}
            onClose={() => setErrorMsg(null)}
            showIcon
          />
        </div>
      )}
      <Collapse className='mt20 trans-collapse'>
        {<Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Proof of Address Check</span></span>} key="1">

          <div className='d-flex justify-content wrap-auto'>
            <div>
              <Title level={5} className=''>Check Data</Title>
              <div className='poatable-feilds'>
                <label className='kpi-label'>Country : </label>
                {PoaDetails && <p className='kpi-val fw-700'>{PoaDetails[0]?.inputDoc?.country}</p>}
              </div>
              <div className='poatable-feilds'>
                <label className='kpi-label'>Document Type : </label>
                {PoaDetails && <p className='kpi-val fw-700'>Proof of address</p>}
              </div>
              <div className='poatable-feilds'>
                <label className='kpi-label'>First Name : </label>
                {PoaDetails && <p className='kpi-val fw-700'>{PoaDetails[0]?.inputDoc?.firstName}</p>}
              </div>
            </div>
            <div>
              <Title level={5} className=''>Document Organisation</Title>
              <label className='kpi-label'>ID as Proof of Address : {PoaDetails && <span className={`status-dot ${PoaDetails?.[0]?.answer?.toLowerCase()}`}></span>} </label>
              <div className='poatable-feilds'>
                <label className='kpi-label'>
                  Company Type :
                </label>
                {PoaDetails && <p className='kpi-val fw-700'>{PoaDetails?.[0]?.poaCheckInfo?.companyContact?.type}</p>}
              </div>
            </div>
          </div>
          <table className='poa-table'>
            <thead>
              <tr>
                <th className='text-left'>Check</th>
                <th className='text-left'>Result</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='kpi-val fw-700'>Unexpired</td>
                <td>{PoaDetails && <span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.unexpired?.toLowerCase()}`}></span>}</td>
              </tr>
              <tr>
                <td className='kpi-val fw-700'>Acceptable Type</td>
                <td>{PoaDetails && <span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.acceptableType?.toLowerCase()}`}></span>}</td>
              </tr>
              <tr>
                <td className='kpi-val fw-700'>Valid Address</td>
                <td>{PoaDetails && <span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.validAddress?.toLowerCase()}`}></span>}</td>
              </tr>
              <tr>
                <td className='kpi-val fw-700'>Unique Type/Sub Type</td>
                <td>{PoaDetails && <span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.answer?.toLowerCase()}`}></span>}</td>
              </tr>
            </tbody>
          </table>

        </Panel>}

      </Collapse>

      {props?.screen == "KYB" &&
        <>
          <Collapse className='mt20 trans-collapse'>
            {<Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Company Structure</span></span>} key="2">
            {companyStructureData?.sumsubCompany?.length > 0 && 

              <div className='sm-scroll' >
              <div className='d-flex company-structure'>
                <div className='company-card parent-card'>
                  <div className='d-flex'>
                    <span className='icon bank-icon'></span><Title level={5} className=''>{companyStructureData?.fullName}</Title>
                  </div>
                  <span className='approved badge'>{companyStructureData?.status}</span>
                </div>

                <div className='children-content'>
                  {companyStructureData?.sumsubCompany?.map((item, index) =>
                    <div className='company-card child-card'>
                      <div className='d-flex '>
                        <span className='icon user'></span><Title level={5} className='mb-4'>{item?.fullName}</Title>
                      </div>
                      <span className='approved badge'>{item?.status}</span>
                      <p className='mb-0 mt-4 min-h-22'>{item?.position?.join(', ')}</p>
                    </div>)}



                </div>

              </div>
              </div>}
              {companyStructureData?.sumsubCompany?.length == 0 && 
              <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />}
              


            </Panel>}

          </Collapse>

          <Collapse className='mt20 trans-collapse'>
            {<Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Control and Ownership Structure</span></span>} key="3">
             <>
              <div className='d-flex justify-content wrap-auto'>
                <p className='kpi-val'>Individuals</p>


              </div>
              {ctrlOwnerShipData?.length > 0 && 
              <table className='poa-table control-ownership'>
                <thead>
                  <tr>
                    <th className='text-left'>Type</th>
                    <th className='text-left'>Full Name</th>
                    <th className='text-left'>Email</th>
                    <th className='text-left'>Position</th>
                    <th className='text-left'>Status</th>

                  </tr>
                </thead>
                <tbody>
                  {ctrlOwnerShipData?.map((item, index) => (
                    <tr key={index}>
                      <td className='kpi-val fw-700'>{item.type}</td>
                      <td>{item.fullName}</td>
                      <td>{item.email}</td>
                      <td>{item.position ? item.position.join(', ') : '-'}</td>
                      {/* <td>{item?.inRegistry ? item?.inRegistry : '-'}</td> */}
                      <td>{item.status}</td>
                      {/* <td className='text-center'>
                      <Dropdown overlay={UserProfileMenu} trigger={['click']} placement="bottomCenter" arrow overlayClassName=""                    >
                        <p className={`icon c-pointer md moretools mr-0 mb-0 `}></p>
                      </Dropdown>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>}
              {ctrlOwnerShipData?.length == 0 && 
              <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />}
              
              </>
             
            </Panel>}

          </Collapse>

          <Collapse className='mt20 trans-collapse'>
            {<Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Company Check</span></span>} key="4">

            <div className='table-scroll'>
              {companyCheckData && 
                <table className='poa-table company-check'>
                  <thead>
                    <tr>
                      <th className='text-left'>Company Name</th>
                      <th className='text-left'>Alternative Names</th>
                      <th className='text-left'>Registration Numbers</th>
                      <th className='text-left'>License Number/EIN</th>
                      <th className='text-left'>Expiry Date</th>
                      <th className='text-left'>Registration Number</th>
                      <th className='text-left'>Status</th>
                      <th className='text-left'>VAT Number</th>
                      <th className='text-left'>Tax Number</th>
                      <th className='text-left'>Name Of Business (SIC)</th>
                      <th className='text-left'>Type</th>
                      <th className='text-left'>Incorporated On</th>
                      <th className='text-left'>Legal Form</th>
                      <th className='text-left'>Start Date</th>
                      <th className='text-left'>Registered On</th>
                      <th className='text-left'>Registered Office Address</th>
                      <th className='text-left'>Web Page</th>
                      <th className='text-left'>Telephone</th>
                      <th className='text-left'>Source</th>
                      <th className='text-left'>Last Updated</th>

                    </tr>
                  </thead>
                  <tbody>

                    <tr>

                      <td className='kpi-val fw-700'>{companyCheckData?.companyname}</td>
                      
                      <td>{companyCheckData?.alternativeNames}</td>
                      <td>{companyCheckData?.registrationNumbers}</td>
                      <td>{companyCheckData?.licensenumberEIN}</td>
                      <td>{moment(companyCheckData.expiryDate).format('DD-MM-YYYY')}</td>
                      <td>{companyCheckData?.registrationNumber}</td>
                      <td>{companyCheckData?.status}</td>
                      <td>{companyCheckData?.vatNumber}</td>
                      <td>{companyCheckData?.taxNumber}</td>
                      <td>{companyCheckData?.natureofBusiness}</td>
                      <td>{companyCheckData?.type}</td>

                      <td>{moment(companyCheckData?.incorporatedon).format('DD-MM-YYYY')}</td>
                      <td>{companyCheckData?.leagalForm}</td>
                      <td>{moment(companyCheckData?.startDate).format('DD-MM-YYYY')}</td>
                      <td>{moment(companyCheckData?.registeredon).format('DD-MM-YYYY')}</td>

                      <td>{companyCheckData?.registeredofficeaddress}</td>
                      <td>{companyCheckData?.webPage || "--"}</td>
                      <td>{companyCheckData?.telePhone}</td>
                      <td>{companyCheckData?.source}</td>
                      <td>{moment(companyCheckData?.lasteUpdated).format('DD-MM-YYYY')}</td>


                    </tr>
                  </tbody>
                </table>}
              {companyCheckData == {} && <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />}
            
           </div>


            </Panel>}

          </Collapse>


          <Collapse className='mt20 trans-collapse'>
            {<Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Company Officers</span></span>} key="5">

             
               <div className='table-scroll'>
               {companyOfficersData?.length > 0 && 

                <table className='poa-table control-ownership'>
                  <thead>
                    <tr>
                      <th className='text-left'>Full Name</th>
                      <th className='text-left'>ID Number</th>
                      <th className='text-left'>Date of Birth</th>
                      <th className='text-left'>Nationality</th>
                      <th className='text-left'>Country</th>
                      <th className='text-left'>Occupation</th>
                      <th className='text-left'>Appointed On</th>
                      <th className='text-left'>Corresponde Address</th>
                      <th className='text-left'>Role</th>
                      <th className='text-left'>Status</th>
                      <th className='text-left'>Last Updated</th>
                      <th className='text-left'>Phone</th>
                      <th className='text-left'>Email</th>

                    </tr>
                  </thead>
                  <tbody>
                  {companyOfficersData?.map((item, index) => (

                    <tr key={index}>

                      <td className='kpi-val fw-700'>{item?.fullName}</td>
                      
                      <td>{item?.idNumber}</td>
                      <td>{moment(item.dob).format('DD-MM-YYYY')}</td>
                      <td>{item?.nationality}</td>
                      <td>{item?.country}</td>
                      <td>{item?.occupation}</td>
                      <td>{moment(item?.appliedOn).format('DD-MM-YYYY')}</td>
                      <td>{item?.correesponseAddress}</td>
                      <td>{item?.role}</td>
                      <td>{item?.status}</td>
                      <td>{item?.lastUpdated || "--"}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.email}</td>


                    </tr>
                  ))}
                  </tbody>
                </table>}
                {companyOfficersData?.length == 0 && <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />}
           
           </div>
             


            </Panel>}

          </Collapse>

          <Collapse className='mt20 trans-collapse'>
            {<Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Persons with Significant Control</span></span>} key="6">

            <div className='table-scroll'>
              {companySignificantPersons?.length > 0 &&
                <table className='poa-table control-ownership'>
                <thead>
                  <tr>
                    <th className='text-left'>Full Name</th>
                    <th className='text-left'>ID Number</th>
                    <th className='text-left'>Date of Birth</th>
                    <th className='text-left'>Nationality</th>
                    <th className='text-left'>Country</th>
                    <th className='text-left'>Occupation</th>
                    <th className='text-left'>Type</th>
                    <th className='text-left'>Notified On</th>
                    <th className='text-left'>Correspondence</th>
                    <th className='text-left'>Nature Of</th>
                    <th className='text-left'>Direct Ownership</th>
                    <th className='text-left'>Indirect Ownership</th>
                    <th className='text-left'>Beneficial Ownership</th>
                    <th className='text-left'>Status</th>
                    <th className='text-left'>Last</th>
                    <th className='text-left'>Phone</th>
                    <th className='text-left'>Email</th>

                  </tr>
                </thead>
                <tbody>
                  {companySignificantPersons?.map((item, index) => (
                    <tr key={index}>
                      <td className='kpi-val fw-700'>{item.fullName}</td>
                      <td>{item.idNumber}</td>
                      <td>{moment(item?.dob).format('DD-MM-YYYY')}</td>
                      <td>{item.nationality}</td>
                      <td>{item.country}</td>
                      <td>{item.occupation}</td>
                      <td>{item.type}</td>
                      <td>{moment(item?.notifiedOn).format('DD-MM-YYYY')}</td>
                      <td>{item.correspondenceAddress}</td>
                      <td>{item.natureOfControl}</td>
                      <td>{item.directOwnership}</td>
                      <td>{item.indirectOwnership}</td>
                      <td>{item.beneficialOwnership}</td>
                      <td>{item.status}</td>
                      <td>{moment(item?.lastUpdated).format('DD-MM-YYYY')}</td>
                      <td>{item.phone}</td>
                      <td>{item.email}</td>

                    </tr>
                  ))}
                </tbody>
              </table>}
              {companySignificantPersons?.length == 0 &&
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />}
              </div>


            </Panel>}

          </Collapse>

         

        </>}
    </div>
  );
};

export default POACheck;
