import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  Tooltip,
  Modal,
  Empty,
  Form,
  Select,Alert
} from "antd";
import { publishShowActions } from "../grid.component/subscribir";
import { useHistory } from "react-router";
import { countryLu, getCompanyDetailss } from "./api";
import Loader from "../loader.component";
import List from "../grid.component";
import { connect } from "react-redux";
import { getFileURL } from '../documents.component/api';
import FilePreviewer from 'react-file-previewer';
import { Link } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import config from "../../config";
const { Title, Paragraph } = Typography;
const { Option } = Select;
const CompanyDetialsView = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [accountInfo, setAccountInfo] = useState([]);
  const [isDeepSearch, setIsDeepSearch] = useState(false);
  const [selectObj, setSelectObj] = useState({});
  const [count, setCount] = useState(0);
  const [comDetails, setComDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [gridUrl, setGridUrl] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [previewPath, setPreviewPath] = useState(null);
  const [errorMsgs, setErrorMsgs] = useState(null);  
  const [luData, setLuData] = useState(true);
  const [searchFlag,setSearchFlag]=useState(false);

  useEffect(() => {
    publishShowActions(false);
    getKompanyDetail();
    handleClick();

  }, [props?.match?.params?.id]);

  const gridColumns = [
    { field: "peopleType", title: "People Type", filter: true, width: 200 },
    { field: "name", title: "Name", filter: true, width: 250},
    { field: "role", title: "Role", filter: true, width: 250 },
    { field: "position", title: "Position", filter: true, width: 250 },
    {
      field: "dateOfBirth",
      title: "Date of Birth",
      filter: true,
      filterType: "date",
      width: 200
    },
    {
      field: "startDate",
      title: "Start Date",
      filter: true,
      filterType: "date",
      width: 200
    },
    { field: "address", title: "Address", filter: true, width: 290 },
    { field: "isCorporate", title: "Is Corporate", filter: true, width: 290 },
    {
      field: "percentage",
      title: "Percentage (%)",
      filter: true,
      width: 250,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "shareCapital",
      title: "Share Capital",
      filter: true,
      width: 280,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "shareCurrency",
      title: "Share Currency",
      filter: true,
      width: 280
    },
    { field: "status", title: "Status", width: 150, filter: true }
  ];
  const backToCustomer = () => {
    history.push({
      pathname: "/customers"
    });
  };
  const getKompanyDetailsData = async (comId, index) => {
     setLoading(true);
    let response = await getCompanyDetailss(
      props.match.params.id,
      index?.key ? index?.key : index
    );
    if (response.ok) {
      if (accountInfo.length > 0) {
        setErrorMsgs(null)
        setComDetails(response.data);
        setLoading(false);
        form.setFieldsValue(response.data);
        setCount(1)
      } else {
        setLoading(false);
        form.setFieldsValue(response.data);
        setComDetails(response.data);
        setCount(1);
      }
     setLoading(false);
    }else{
      setErrorMsgs(apiCalls.isErrorDispaly(accountInfo))
      setLoading(false);
    }
  
  };


  const getKompanyDetail = async () => {
    setLoading(true)
    let response = await getCompanyDetailss(props.match.params.id,null);
    if (response.ok) {
      setComDetails(response.data);
      setLoading(false)
      setErrorMsgs(null)
      companypeoplesk(response.data?.kompanyId);
      form.setFieldsValue({companyName:response.data.companyName})
    }else{
      setLoading(false)
      setErrorMsgs(apiCalls.isErrorDispaly(response))
    }
  }

  const companypeoplesk = (kompanyId) => {
    setGridUrl(
      process.env.REACT_APP_GRID_API + `Komapany/companypeoplesk/${kompanyId}`
    );
  };


  const filePreview = async (file) => {
    let res = await getFileURL({ url: file });
    if (res.ok) {
      setPreviewModal(true);
      setPreviewPath(res.data);
      setErrorMsgs(null)
    }else{
      setErrorMsgs(apiCalls.isErrorDispaly(res))
    }
  }
  const filePreviewPath = () => {
    return previewPath;
  }
  const docPreviewClose = () => {
    setPreviewModal(false);
  }
  const handleDeepSearch = () => {
    TypesOFAccount(true);
    setSearchFlag(false)
  };
  const handleClick = async () => {
    if (luData || !accountInfo.length >= 1) {
      let response = await countryLu(
        props.match.params.id,
        isDeepSearch
      );
      if (response.ok) {
        setLuData(false)
        setErrorMsgs(null)
          setAccountInfo(response.data);
      }else{
        setErrorMsgs(apiCalls.isErrorDispaly(response))
      }
    }
  };
  const TypesOFAccount = async (deppserach) => { 
    setLoading(true);
    form.setFieldsValue({companyName:null})
    let response = await countryLu(
      props.match.params.id,
      deppserach || isDeepSearch
    );
    if (response.ok) {
      setAccountInfo(response.data);
      setErrorMsgs(null)
      setLoading(false);
      const data=response.data.find((item)=>item.kompanyId==comDetails?.kompanyId)
      if(data !=undefined){
        form.setFieldsValue({companyName:data.companyName})
      }
      setSelectObj(data);
      form.setFieldsValue({companyName:null})
      setComDetails(null);
    }else{
      setErrorMsgs(apiCalls.isErrorDispaly(response))
    setLoading(false);
    setSearchFlag(false)
    }
  };
  const handleKompany = async (comId, index) => {
    setSearchFlag(true)
    let val = accountInfo.filter((item) => {
      if (item.companyName === comId) {
        return item;
      }
    });
    if(val[0].kompanyId==comDetails?.kompanyId){
      setSearchFlag(false)
     }
    setSelectObj(val[0]);
    setCount(0) 
  };
  const handleSearch = () => {
    if (accountInfo.length > 0) {
      setIsDeepSearch(false);
      if (count === 0) {
        companypeoplesk(selectObj?.kompanyId);
        getKompanyDetailsData(props.match.params.id, selectObj?.kompanyId);
        form.setFieldsValue({companyName:selectObj.companyName})
        setCount(1);
        form.setFieldsValue(comDetails);
      }
    } else {
      setIsDeepSearch(true);
    }
    setSearchFlag(false)
  };

  const options = accountInfo?.map((item) => (
    <Option key={item.kompanyId} className="fw-300" value={item.companyName}>
      {item.companyName}
    </Option>
  ));
  return (
    <>
    {errorMsgs && (
					<Alert
						closable
						type="error"
						description={errorMsgs}
						onClose={() => setErrorMsgs(null)}
						showIcon
					/>
				)}

      {comDetails?.kompanyId &&
        <Title className="page-title">Kompany Details</Title>
      }

      <Form
        initialValues={comDetails}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        autoComplete="off"
      >
        <Row className="mb-16">
          <Col sm={24} md={24} className="text-left ">
            If you want to see more kompanies list in kompany dropdown, Please
            click on
            <Link
              style={{ textDecoration: "revert" }}
              className="fs-16"
              onClick={handleDeepSearch}
            >
              {" "}
              More Kompanies.{" "}
            </Link>
          </Col>
        </Row>
        <Row className="mb-16">
          <Col sm={24} md={7} className="pl-4 pr-8">
            <Form.Item name="companyName" className="input-label mb-0">
              <Select
                className="cust-input w-100 "
                showSearch
                onChange={(e, index) => handleKompany(e, index)}
                placeholder="Select Kompany Name"
              >
                {options}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={3} className="px-8 text-right ">
            <Button
              type="primary"
              className="primary-btn px-24 search-btn "
              onClick={handleSearch}
              disabled={searchFlag? false : true}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      {loading ? <Loader className="text" />:<>
      {comDetails?.kompanyId !== null && comDetails?.kompanyId && <>
        <>
          {" "}
          <Row gutter={8}>
            <Col xl={24} xxl={24} className="bank-view">
              <Row className='company-List'>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails.companyName && <>
                    <label className="kpi-label">Kompany Name</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {comDetails.companyName || "-"}
                    </Paragraph>
                  </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails.registrationNumber && <>
                    <label className="kpi-label">Registration Number</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {comDetails.registrationNumber || "-"}
                    </Paragraph>
                  </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails?.dateOfIncorporation && <>
                    <label className="kpi-label">Date Of Incorporation</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                    <Moment format={config?.dateFormates?.dateFormate}>{comDetails?.dateOfIncorporation && apiCalls.convertUTCToLocalTime(comDetails?.dateOfIncorporation) || comDetails?.dateOfIncorporation}</Moment>

                    </Paragraph>
                  </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails.legalForm && <>
                    <label className="kpi-label">Legal Form</label>
                    <Paragraph className="kpi-val fw-700 mb-0">

                      {comDetails.legalForm || "-"}
                    </Paragraph>
                  </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails.mainSourceName && <>
                    <label className="kpi-label">Main Source Name</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {comDetails.mainSourceName || "-"}
                    </Paragraph>
                  </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails.address && <>
                    <label className="kpi-label">Address</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {comDetails.address || "-"}
                    </Paragraph>
                  </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                > 
                    <label className="kpi-label">Last Announcement Date</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {comDetails.lastAnnouncementDate !=null &&
                      <Moment format={config?.dateFormates?.dateFormate}>{comDetails.lastAnnouncementDate && apiCalls.convertUTCToLocalTime(comDetails.lastAnnouncementDate) || comDetails.lastAnnouncementDate}</Moment>
                           }
                      {comDetails.lastAnnouncementDate ==null && "-"}     
                    </Paragraph>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails?.lastUpdate && <>
                    <label className="kpi-label">Last Update</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {
                        <Moment format={config?.dateFormates?.dateFormate}>{comDetails?.lastUpdate && apiCalls.convertUTCToLocalTime(comDetails?.lastUpdate) || comDetails?.lastUpdate}</Moment>
                      }
                    </Paragraph>
                  </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >{comDetails.country && <>
                  <label className="kpi-label">Country</label>
                  <Paragraph className="kpi-val fw-700 mb-0">
                    {comDetails.country || "-"}
                  </Paragraph>
                </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >{comDetails.status && <>
                  <label className="kpi-label">Status</label>
                  <Paragraph className="kpi-val fw-700 mb-0">
                    {comDetails.status || "-"}
                  </Paragraph>
                </>}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={7}
                  xxl={8}
                  className="item"
                >
                  {comDetails.azureUrl && (
                    <label className="kpi-label">Attachment</label>
                  )}
                  <div >
                    {comDetails.azureUrl && <a onClick={() => filePreview(comDetails.azureUrl)}>Report.pdf</a>}
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        </>
        <> {gridUrl && <>
          <Title className="page-title mt-36">UBO Discovery</Title>
          <List url={gridUrl} key={gridUrl} columns={gridColumns} /></>}
           </> </>
        ||
        <>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Data Found"
          ></Empty>
        </>
      }</>}

      <Modal
        className="documentmodal-width"
        destroyOnClose={true}
        title="Preview"
        width={1000}
        visible={previewModal}
        closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={docPreviewClose} /></Tooltip>}
        footer={<>
          <Button type="primary" onClick={docPreviewClose} className="primary-btn cancel-btn">Close</Button>
          <Button type="primary" className="primary-btn" onClick={() => window.open(previewPath, "_blank")}>Download</Button>
        </>}
      >
        <FilePreviewer hideControls={true} file={{ url: previewPath && filePreviewPath() || null, mimeType: previewPath?.includes(".pdf") && 'application/pdf' || '' }} />
      </Modal>
      {comDetails?.kompanyId &&
        <div className="text-right mt-24">
          <Button
            type="primary"
            className="primary-btn cancel-btn"
            style={{ margin: "0 8px" }}
            onClick={backToCustomer}
          >
            Cancel
          </Button>
        </div>
      }
    </>
  );
};
const connectStateToProps = ({  userConfig }) => {
  return {
    userConfig: userConfig?.userProfileInfo
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(CompanyDetialsView)