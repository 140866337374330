import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";
const userNameLuSearch = (user_id) => {
    return clientApi.get(ApiControllers.master + `AuditLogs/Users/${user_id}`);
}
const getFeatureLuSearch = () => {
    return clientApi.get(ApiControllers.master + `AuditLogs`);
}
const getAuditLogInfo = (id) =>{
    return clientApi.get(ApiControllers.master + `AuditlogInfo/${id}`)
}
const getRetry = (id) =>{
    return clientApi.get(ApiControllers.customers + `RetryVaultAccount/${id}`)
}

export {userNameLuSearch, getFeatureLuSearch, getAuditLogInfo, getRetry}