import { ApiControllers } from "../../api/config";
import { clientApi } from "../../api";
const getCard=(id)=>{
return clientApi.get(ApiControllers.banks+`Card/${id}`)

}

const saveCard=(obj)=>{
    return clientApi.post(ApiControllers.banks+`Card/Banks`,obj)

}
const editCard=(id)=>{
    return clientApi.put(ApiControllers.banks+`Card/${id}`)
}
const activeInactive = (obj) => {
	return clientApi.put(ApiControllers.master + `banks/Status`, obj);
};
const adminVerifications=()=>{
    clientApi.get(ApiControllers.customers+`AdminVerifications`);

    
}
export{getCard,saveCard,editCard,activeInactive,adminVerifications}

// https://devapi.suissebase.io/api/v1/Banks/Personal/{id}
// https://devapi.suissebase.io/api/v1/Banks/Personal/Banks
// https://devapi.suissebase.io/api/v1/Banks/Personal/{id}
