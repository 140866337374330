import React, { useEffect, useState } from 'react';
import { Button, Input, Form, notification, Typography, Alert, message,Spin } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined,LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getmemeberInfo } from '../../reducers/configReduser';
import apiCalls from '../../api/apiCalls';
import { validateContentRule, validateContent } from '../../utils/custom.validator'

notification.config({
  placement: "topRight",
  rtl: true
});
const ChangePassword = ({ userConfig, onSubmit, userProfile, getmemeberInfoa, trackAuditLogData }) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [initialValues, setInitialValues] = useState({
    "Email": userConfig?.email,
    "CurrentPassword": "",
    "Password": "",
    "ConfirmPassword": ""
  })
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [changePasswordResponse, setChangePasswordResponse] = useState({ error: false, messsage: "", isLoading: false });
  useEffect(() => {
    if (userProfile && userProfile?.isNew) {
      setChangePasswordResponse({ error: false, messsage: "", isLoading: false });
      form.resetFields();
    }

  }, [userProfile]);// eslint-disable-line react-hooks/exhaustive-deps

  const saveUserPass = async (values) => {
    setBtnDisabled(true);
    if (values.CurrentPassword === values.Password) {
      setBtnDisabled(false)
      setChangePasswordResponse({ error: false, messsage: "", isLoading: false });
      passwordResponce(true, "Current password and New password should not be same", false);
    } else {
      setBtnDisabled(true);
      passwordResponce(false, '', false);
      initialValues.info = JSON.stringify(trackAuditLogData)
      let obj = Object.assign({}, initialValues);
      obj.ConfirmPassword = apiCalls.encryptValue(obj.ConfirmPassword, userConfig.sk)
      obj.CurrentPassword = apiCalls.encryptValue(obj.CurrentPassword, userConfig.sk)
      obj.Password = apiCalls.encryptValue(obj.Password, userConfig.sk)
      obj.Email =apiCalls.encryptValue(obj.Email, userConfig.sk)
      obj.info = apiCalls.encryptValue(obj.info, userConfig.sk)
      const result = await apiCalls.changePassword({password:obj.Password});
      setChangePasswordResponse({ error: false, messsage: "", isLoading: false });
      if (result.ok) {
        setBtnDisabled(false);
        message.success({ content: 'Password changed successfully', className: 'custom-msg',duration:3 });
        passwordResponce(false, '', false);
        form.resetFields();
        
        onSubmit()
        getmemeberInfoa(userConfig.userId)
      } else {
        setBtnDisabled(false);
        setChangePasswordResponse({ error: false, messsage: "", isLoading: false });
        passwordResponce(true,apiCalls.isErrorDispaly(result), false);
      }
    }
  }


  const passwordResponce = (isError, msg, isloading) => {
    setChangePasswordResponse({ error: isError, messsage: msg, isLoading: isloading });
  }
  const handleChange = (prop, val) => {
    let object = { ...initialValues };
    object[prop] = val.currentTarget.value;
    setInitialValues(object);
  }
 const antIcon = (
    <LoadingOutlined
        style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
        spin
    />
  );
  return (<>
   <Text
                className="mb-16 fs-14 fw-200"
              >Choose a unique password to protect your account.</Text>
    <div className="mt-16">
   
      <Form form={form}
        initialValues={{
          "Email": userConfig?.email,
          "CurrentPassword": "",
          "Password": "",
          "ConfirmPassword": ""
        }} onFinish={(values) => saveUserPass(values)} enableReinitialize>
        {changePasswordResponse.messsage !== "" && (
          <Typography>
            <Alert className='erroralert-icon'
              type={changePasswordResponse?.error ? "error" : "success"}
              showIcon
              description={changePasswordResponse.messsage}
            />
          </Typography>
        )}

        <div className="d-flex">
          <Text
            className="input-label fw-600"
          >Current Password</Text>
          <span style={{ color: "var(--textWhite30)", paddingLeft: "2px" }}>*</span>
        </div>
        <Form.Item
          className="custom-forminput mb-16"
          name="CurrentPassword"
          required
          rules={[
            {
              required: true, message: 'Please enter current password'
            },
            {
              validator: validateContentRule
            }

          ]}
        >

          <Input.Password placeholder={'Type your current password'} maxLength={15} value={initialValues.CurrentPassword} className="cust-input mb-8 pr-0 change-space input-txtcenter" onChange={(e) => handleChange("CurrentPassword", e)} iconRender={visible => (visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined />)} />
        </Form.Item>
        <div className="d-flex">
          <Text
            className="input-label fw-600"
          >New Password</Text>
          <span style={{ color: "var(--textWhite30)", paddingLeft: "2px" }}>*</span>
        </div>
        <Form.Item
          name="Password"
          className="custom-forminput mb-16"
          required
          rules={[
            {
              validator(rule, value) {
                if (!value) {
                  return Promise.reject('Please enter new password')
                  
                } else if (!value || !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&_]).{8,15}$/.test(value))) {
                  return Promise.reject(
                    "Password must have at least 8 characters and cannot contain common words or patterns. Try adding numbers, symbols, or characters to make your password longer and unique."

                  )
                } else if (!validateContent(value)) {
                  return Promise.reject(
                    "Please enter valid content"
                  )
                } else {
                  return validateContentRule(rule, value)
                }
              },
            }
          ]}
        >

          <Input.Password
            placeholder={'Type your new password'}
            maxLength={15}
            value={initialValues.Password}
            onChange={(e) => handleChange("Password", e)}
            className="input-txtcenter cust-input mb-8 pr-0 change-space pass-onhover" iconRender={visible => (visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <div className="d-flex">
          <Text
            className="input-label fw-600"
          >Confirm Password</Text>
          <span style={{ color: "var(--textWhite30)", paddingLeft: "2px" }}>*</span>
        </div>
        <Form.Item
          required
          className="custom-forminput mb-16"
          name="ConfirmPassword"
          dependencies={["password"]}
          //hasFeedback
          rules={[
            {
              required: true,
              message:('Please enter confirm password'),

            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("Password") === value) {
                  return validateContentRule(rule, value);
                }
                return Promise.reject(
                  "Password does not match"
                );
              },
            })
          ]}
        >

          <Input.Password
            placeholder={'Re-type your new password'}
            maxLength={15}
            value={initialValues.ConfirmPassword}
            onChange={(e) => handleChange("ConfirmPassword", e)}
            className="input-txtcenter cust-input mb-8 pr-0 change-space" iconRender={visible => (visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <div className="text-right mt-24">
        <Button
            htmlType="submit"
            type="primary"
            className="primary-btn"
            loading={btnDisabled}
          >{changePasswordResponse.isLoading && <Spin indicator={antIcon} />}{" "}
            <Text >SAVE</Text>
          </Button>
          <Button
            htmlType="button"
            type="primary"
            className="primary-btn cancel-btn ml-8"
            onClick={() => onSubmit()}>
            <Text>CANCEL</Text>
          </Button> 
        </div>
      </Form>
    </div>
  </>)
}

const connectStateToProps = ({ buySell, userConfig, userProfile }) => {
  return { buySell, trackAuditLogData: userConfig.trackAuditLogData, userConfig: userConfig.userProfileInfo, userProfile }
}
const connectDispatchToProps = dispatch => {
  return {
    getmemeberInfoa: (useremail) => {
      dispatch(getmemeberInfo(useremail));
    },
  }
}
export default connect(connectStateToProps, connectDispatchToProps)(ChangePassword);