import React, { Component } from 'react';
import { Tabs,Typography } from 'antd'
import ProfileInfo from './profileInfo';
import Security from './security'
import QueryString from 'query-string'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const { TabPane } = Tabs;
class UserProfile extends Component {
    constructor(props) {
        super(props);
   this.state = {
        isProfile: false,
        isSecurity: false,
        isSetting: false,
        tabPosition: 'left',
        activeTab: this.props.match.params.key ? this.props.match.params.key : "1",
    }
}
    componentDidMount() {
        let activeKey = QueryString.parse(this.props.history.location.search)?.key;
        if (activeKey) {
            this.setState({ ...this.state, activeTab: activeKey});
        }
    }
    
    handleProfile = () => {
        this.setState({ isProfile: true })
    }
    handleSecurity = () => {
        this.setState({ isSecurity: true, isProfile: false })
    }

    render() {
        const {Text } = Typography;
        const { tabPosition, activeTab } = this.state;

        if(this.props.match.params.key !== activeTab){
            if(this.props.match.params.key){
                this.setState({ ...this.state, activeTab: this.props.match.params.key })
            }else{
                this.props.history.push(`/userprofile/${activeTab}`)
            }
        }

        return (<>

            <div className="main-container hidden-mobile">
                <Tabs tabPosition={tabPosition} className="user-list" activeKey={this.state.activeTab} onChange={(key) =>{ 
                    this.props.history.push(`/userprofile/${key}`)
                     this.setState({ ...this.state, activeTab: key })}}>
                    <TabPane tab={<span>
                        <span className="icon lg profile-icon mr-8" />
                        <Text component={Tabs.TabPane.tab} >Profile Info</Text></span>} key="1">
                        {this.state.activeTab === 1 && <ProfileInfo />}
                    </TabPane>
                    <TabPane tab={<span><span className="icon lg security-icon mr-16" />
                        <Text className="f-16  mt-16" >Security</Text>
                    </span>} key="2">
                        {this.state.activeTab === 2 && <Security />}
                    </TabPane>
                </Tabs>
            </div>
            <div className="main-container visible-mobile profile-tabs">
            <Tabs tabPosition="top" className="user-list user-tablist" activeKey={this.state.activeTab} onChange={(key) =>{
             this.props.history.push(`/userprofile/${key}`)
                 this.setState({ ...this.state, activeTab: key })}}>
                    <TabPane tab={<span>
                        <span className="icon lg profile-icon mr-8" />
                        <Text component={Tabs.TabPane.tab} >Profile Info</Text></span>} key="1">
                        {this.state.activeTab == 1 && <ProfileInfo />}
                    </TabPane>
                    <TabPane tab={<span><span className="icon lg security-icon mr-8" />
                        <Text className="f-16  mt-16" >Security</Text>
                    </span>} key="2">
                        {this.state.activeTab == 2 && <Security />}
                    </TabPane>
                </Tabs>
            </div>
        </>);
    }
}
const connectStateToProps = ({ addressBookReducer}) => {
    return { addressBookReducer }
}
export default connect( connectStateToProps)(withRouter(UserProfile));
