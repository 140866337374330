import React, { Component } from "react";
import { connect } from "react-redux";
import { Button,Typography,Alert } from "antd";
import apiCalls from "../../api/apiCalls";
import {setStep} from '../../reducers/transactionComplianceReducer'
import Currency from "../shared/number.format";
const { Text } = Typography;
class TransactionCryptoSummary extends Component {
   state={
	loading:false,
	errorMsg:null,
   }
      saveWithdrawal=async()=>{
		this.setState({...this.state,loading:true,errorMsg:null})
		let response = await apiCalls.savewithdrawFiat(this.props.transactionCompliance.withdrawCryptoObj)
        if(response.ok){
			this.setState({...this.state,loading:false,errorMsg:null})
			this.props.dispatch(setStep("withdraw_transaction_crypto_success"))
        }
		else{
			this.setState({...this.state,loading:false, errorMsg:apiCalls.isErrorDispaly(response)})
		  }
		}
		
    render(){
		let {errorMsg,loading}=this.state;
        return(
            <>
            {errorMsg !== undefined && errorMsg !== null && (
              <Alert
                className="w-100 mb-16"
                type="warning"
                description={errorMsg}
                showIcon
              />
            )}
            <div
							className="fs-36 text-white-30 fw-200 text-center"
							style={{ lineHeight: "36px" }}>
							<Currency
								prefix={""}
								decimalPlaces={8}
								defaultValue={
									this.props.transactionCompliance?.withdrawCryptoObj?.totalValue
								}
								suffixText={
									this.props.transactionCompliance.withdrawCryptoObj?.walletCode
								}
							/>{" "}
						</div>
						<div className="text-white-50 fw-400 text-center fs-14 mb-16">
							<Currency
								defaultValue={this.props.transactionCompliance?.withdrawCryptoObj?.amountInUsd
                                }
								prefix={""}
								decimalPlaces={2}
								type={"text"}
								suffixText={"USD"}
							/>
						</div>
						<div className="pay-list fs-14">
              <Text className="fw-400 text-black">Exchange Rate</Text>
							<Currency
								defaultValue={
                                    this.props.transactionCompliance?.withdrawCryptoObj?.exchangeRate
                                }
								decimalPlaces={8}
								prefix={""}
								className="fw-400 text-white-30"
								prefixText={`1 ${this.props.transactionCompliance?.withdrawCryptoObj?.walletCode}
                                = ${"USD"}`}
							/>
						</div>
						<div className="pay-list fs-14">
              <Text className="fw-400 text-black">Amount</Text>
							<Currency
								prefix={""}
								className={"text-black"}
								decimalPlaces={8}
								defaultValue={this.props.transactionCompliance?.withdrawCryptoObj?.totalValue
								}
								suffixText={
									this.props.transactionCompliance?.withdrawCryptoObj?.walletCode
								}
							/>
						</div>
						<div className="pay-list fs-14">
                                <Text
								className="fw-400 text-black"
							> Effective Fees</Text>
							<Text
								className="fw-400 text-black"
								style={{
									width: "250px",
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									textAlign: "end",
								}}>
                                    {this.props.transactionCompliance?.withdrawCryptoObj?.comission || "0"} {"  "}{this.props.transactionCompliance?.withdrawCryptoObj?.walletCode}
                                
							</Text>
						</div>
						<div className="pay-list fs-14">
                                <Text
								className="fw-400 text-black"
							> Fees</Text>
							<Text
								className="fw-400 text-black"
								style={{
									width: "250px",
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									textAlign: "end",
								}}>
                                    {this.props.transactionCompliance?.withdrawCryptoObj?.totalFee || "0"} {"  "}{this.props.transactionCompliance?.withdrawCryptoObj?.walletCode}
                                
							</Text>
						</div>
						<div className="pay-list fs-14">
                                <Text
								className="fw-400 text-black"
							> Tier Discount</Text>
							<Text
								className="fw-400 text-black"
								style={{
									width: "250px",
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									textAlign: "end",
								}}>
                                    {this.props.transactionCompliance?.withdrawCryptoObj?.tierDiscount || "0"} {"  "}{this.props.transactionCompliance?.withdrawCryptoObj?.walletCode}
                                
							</Text>
						</div>
						<div className="pay-list fs-14">
							<Text
								className="fw-400 text-black"
								content="address"
								component={Text}
							> Address</Text>
							<Text className="fw-400 text-black">
							{ this.props.transactionCompliance?.withdrawCryptoObj?.toWalletAddress}
							</Text>
						</div>
						<div className="pay-list fs-14">
							<Text
								className="fw-400 text-black"
								content="address"
								component={Text}
							> Network</Text>
							<Text className="fw-400 text-black">
							{ this.props.transactionCompliance?.withdrawCryptoObj?.network}
							</Text>
						</div>
           
			<div className="text-right mt-24">
                        <Button
                          htmlType="submit"
                          size="large"
                          className="pop-btn  primary-btn preview-btn"
						  loading={loading}
                          onClick={this.saveWithdrawal}
                        >
                         Confirm
                        </Button>
						</div>
            </>
        )
    }
}
const connectStateToProps = ({
    userConfig,transactionCompliance
  }) => {
    return {
      userConfig: userConfig.userProfileInfo,transactionCompliance
    };
  };
  export default connect(connectStateToProps,)(TransactionCryptoSummary);