import React, { Component } from 'react';
import { Row, Col, Typography, Alert, Button, Empty, Modal, Tooltip } from 'antd';
import Loader from '../loader.component';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { setCurrentAction } from '../../reducers/actionsReducer';
import apiCalls from '../../api/apiCalls';
import { publishShowActions } from '../grid.component/subscribir';
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import BankInfo from "../info/BankInfo";
import { getPyrrosWithdrawalDetails, getEvolveWithdrawalDetails, getFileURL } from './api';
import FilePreviewer from "react-file-previewer";
import { bytesToSize } from "../../utils/service";
import DocumentPreview from '../shared/documentPreview';
import config from '../../config';
const { Title,Text } = Typography;
const EllipsisMiddle = ({ suffixCount, children }) => {
    const start = children?.slice(0, children.length - suffixCount).trim();
    const suffix = children?.slice(-suffixCount).trim();
    return (
        <Text
            className="mb-0 fs-14 docnames c-pointer d-block"
            style={{ maxWidth: "100% !important" }}
            ellipsis={{ suffix }}>
            {start}
        </Text>
    );
};
class WithdrawDetailSummary extends Component {
    constructor (props) {
        super(props);
        this.state = {
            detailsObj: {},
            loading: false,
            error: null,
            previewModal: false,
            previewPath: null,
            docPreviewModal:false,
            docPreviewDetails:null,
        }
    }
    componentDidMount() {
        publishShowActions(false);
        this.props.setAction(null)
        this.setState({ ...this.state, loading: false })
        this.loadData();
    }
    loadData = async () => {

        this.setState({ ...this.state, loading: true, error: null })
        let response
        if (this.props?.location?.pathname === `/pyrroswithdrawal/${this.props.match.params.id}/view`) {
            response = await getPyrrosWithdrawalDetails(`GetPyrrosWithdrawalDetails`,this.props.match?.params?.id);
        }
         else if (this.props?.location?.pathname === `/openpaydwithdrawal/${this.props.match.params.id}/view`) {
            response = await getPyrrosWithdrawalDetails(`GetopenpaydWithdrawalDetails`,this.props.match?.params?.id);
        }else {
            response = await getEvolveWithdrawalDetails(this.props.match.params.id);
        }
        if (response.ok) {
            this.setState({ detailsObj: response.data, loading: false })
        } else {
            this.setState({ ...this.state, loading: false, error: apiCalls.isErrorDispaly(response) })

        }
    }
    redirectCustomerView = (items) => {
        const val = items.customerId;
        if(this.props?.location?.pathname?.includes('openpaydwithdrawal')){
            window.open("/openpaydcustomer/details/" + val);
            this.props.dispatch(
                setBreadcrumb({
                    key: "/openpaydcustomer/details/" + val,
                    val: items?.name
                })
            );
        }else{
            window.open("/pyrroscustomer/details/" + val);
            this.props.dispatch(
                setBreadcrumb({
                    key: "/pyrroscustomer/details/" + val,
                    val: items?.name
                })
            );
        }        
    }
    docPreview = async (file) => {
        let res = await getFileURL({ url: file.documentURL });
        if (res.ok) {
            this.setState({ ...this.state, previewModal: true, previewPath: res.data,error:null })
        } else {
            this.setState({ ...this.state, error: apiCalls.isErrorDispaly(res) })
        }
    };
    filePreviewPath = () => {
        return this.state.previewPath;
    };
    handleClose = () => {
        if (this.props?.location?.pathname === `/pyrroswithdrawal/${this.props.match.params.id}/view`) {
            this.props.history.push("/pyrroswithdrawal");
        } else {
            this.props.history.push("/evolvewithdrawal");
        }

    }
    docPreviewClose = () => {
        this.setState({ ...this.state, previewModal: false })
    }
    setUrl = (url) => {      
        return url?.split("/").pop();
    }
    docPreviewfile = async (file) => {
        let res = await getFileURL(file.id);
        if (res.ok) {
            this.setState({ ...this.state, previewModal: true, docpreviewdata:true, previewPath: file,error:null})
        }else{
            this.setState({...this.state,error:apiCalls.isErrorDispaly(res)})
        }
    };
     docPreviewOpen=(data)=>{
        this.setState({...this.state,docPreviewModal:true,docPreviewDetails :{id:data.id,fileName:data.fileName}})
	}
     docPreviewClose=()=>{
        this.setState({...this.state,docPreviewModal:false,docPreviewDetails :null})

	}

     renderEmptyState = () => (
        this.state.detailsObj === null && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Data Found"
          />
        )
      );
      renderErrorAlert = () => (
        this.state.error !== undefined && this.state.error !== null && (
          <Alert
            className="mb-16"
            type="error"
            showIcon
            description={this.state.error}
          />
        )
      );
    handleDates = (label,date) => (
        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
            <span className="icon md date" />
            <div>
                <label className="kpi-label">{label}</label>
                <div className="kpi-val">
                    <Moment format={config?.dateFormates?.dateTimeFormate}>{date && apiCalls.convertUTCToLocalTime(date)}</Moment>
                </div>
            </div>
        </Col>
    )
    handleWithdrawdata=(labels,val,classNams)=>(
        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
        <span className={`icon md ${classNams}`} />
        <div>
        <label className="kpi-label">{labels}</label>
        <div className="kpi-val">
            {val || "--"}
        </div>
        </div>
    </Col>
    )
    handleWithDrawCommissions=(commissionLable,commissionVal,commisionClass)=>(
        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
        <span className={`icon md ${commisionClass}`} />
        <div>
            <label className="kpi-label">{commissionLable}</label>
            <div className=" kpi-val">
                {commissionVal ?
                    (<NumberFormat value={commissionVal} decimalSeparator="." displayType={'text'} thousandSeparator={true} />) : ("-")}
            </div>
        </div>
    </Col>
    )
    handleDocuments=()=>(
        this.state.detailsObj?.docRepositories?.map((file) => (
            <Col xs={24} sm={24} md={12} lg={8} xxl={8} className="">
                <div
                    className="docfile mr-8 mt-24 d-flex align-center"
                    key={file.id}>
                    <span
                        className={`icon xl ${(file.fileName?.slice(-3) === "zip" && "file") ||
                            (file.fileName?.slice(-3) !== "zip" && "") ||
                            ((file.fileName?.slice(-3) === "pdf" ||
                                file.fileName?.slice(-3) === "PDF") &&
                                "file") ||
                            (file.fileName?.slice(-3) !== "pdf" &&
                                file.fileName?.slice(-3) !== "PDF" &&
                                "image")
                            } mr-16`}
                    />
                    <div
                        className="docdetails c-pointer"
                        onClick={() => this.docPreviewOpen(file)}
                        >
                        {file.name !== null ? (
                            <EllipsisMiddle suffixCount={6}>
                                {file.fileName}
                            </EllipsisMiddle>
                        ) : (
                            <EllipsisMiddle suffixCount={6}>Name</EllipsisMiddle>
                        )}
                        <span className="fs-12 text-secondary">
                            {bytesToSize(file.fileSize)}
                        </span>
                    </div>
                </div>
            </Col>
        ))
    )
    renderWithdrawCommissions=()=>{
        const {detailsObj} = this.state;
        return (<>
            {this.handleWithDrawCommissions("Gross Amount",detailsObj?.requestedAmount,"cash")} 
            {this.handleWithDrawCommissions("Commission",detailsObj?.commission,"commission")}
            {this.handleWithDrawCommissions("Commission in Base",detailsObj?.commissionInBase,"commission")}
            {this.handleWithDrawCommissions("Fees",detailsObj?.originalCommission,"commission")}
            {this.handleWithDrawCommissions("Tier Discount",detailsObj?.tierDiscount,"commission")}
            {this.handleWithDrawCommissions("Provider Commission",detailsObj?.providerCommission,"commission")}
            {this.handleWithDrawCommissions("Provider Commission in Base",detailsObj?.providerCommissionInBase,"commission")}
            {this.handleWithDrawCommissions("Sweep Source Commission",detailsObj?.sweepSourceComission,"commission")}
            {this.handleWithDrawCommissions("Sweep Source in Base",detailsObj?.sweepSourceComissionInBase,"commission")}
            {this.handleWithDrawCommissions("Sweep Destination Commission",detailsObj?.sweepDestinationComission,"commission")}
            {this.handleWithDrawCommissions("Sweep Destination in Base",detailsObj?.sweepDestinationComissionInBase,"commission")}
            {this.handleWithDrawCommissions("Total Cost",detailsObj?.totalCost,"commission")}
            {this.handleWithDrawCommissions("Net Amount",detailsObj?.netAmount,"cash")}
            {this.handleWithDrawCommissions("Profit",detailsObj?.profit,"commission")}
            {this.handleWithDrawCommissions("Profit in Base",detailsObj?.profitInBase,"commission")}
            </>)
    }
    renderNameItem = () => {
        const {detailsObj} = this.state;
     return (<Col xs={24} sm={24} md={12} lg={6} xxl={6}>
        <span className="icon md user" />
        <div>
            <label className="kpi-labels">{detailsObj?.isBusiness === true ? <><b>Business Name</b>/<>Personal Name</> </> : <><>Business Name</>/<b>Personal Name</b></>}</label>
            <br />
        <span className="kpi-val c-pointer">
        <a href="#/" onClick={() => this.redirectCustomerView(detailsObj)}>
        {detailsObj?.name || "-"}
        </a>
        </span>
        </div>
    </Col>)
    }
    renderRejectedReason = () => {
        const {detailsObj} = this.state;
        return (
            <Col xs={24} sm={24} md={12} lg={!detailsObj.customerRemarks ? 6 : 12} xxl={!detailsObj.customerRemarks ? 6 : 12}>
                {detailsObj?.state === "Rejected" && <div className='ant-col'>
                    <span className="icon md file" />
                    <div>
                        <label className="kpi-label">Reason For Rejection</label>
                        <div className="kpi-val" rows={2}
                            maxLength={1000}>
                            {(detailsObj?.reasonOfCancellation === " " && ("-" || detailsObj?.reasonOfCancellation === null) && "-") || (detailsObj?.reasonOfCancellation || "-")}
                        </div>
                    </div>
                </div>}
            </Col>
        )
    }
    renderWithdrawData = () => {
        const {detailsObj} = this.state;
        return (<>
            {this.handleWithdrawdata("User Name", detailsObj?.userName, "user")}
            {this.handleWithdrawdata("Email Name", detailsObj?.email, "email")}
            {this.handleWithdrawdata("Currency", detailsObj?.currency, "wallet")}
            {this.handleWithdrawdata("Receiver's Name", detailsObj?.receiverName, "user")}
            {this.handleWithdrawdata("Receiver's Bank Name", detailsObj?.receiversBankName, "bank")}
            {this.handleWithdrawdata("Receiver's Bank Account Number/IBAN", detailsObj?.receiversBankAccountNumber, "bank")}
            </>)
    }

    render() {
        const { detailsObj, loading } = this.state;

        return (
            <>
                {loading && <Loader />} <Title className="page-title">Send Detail View</Title>
                {this.renderEmptyState()}
                {detailsObj && <>
                    {this.renderErrorAlert()}
                    <Row gutter={10}>
                        <Col xl={24} xxl={24} className="bank-view">
                            <Row className="kpi-List">
                                {this.handleDates("Date",detailsObj?.date)}
                                {this.handleWithdrawdata("Transaction ID",detailsObj?.transactionId,"user")}
                                {this.handleWithdrawdata("Customer/Contract ID",detailsObj?.referenceId,"user")}
                                 {this.renderNameItem()}
                                 {this.renderWithdrawData()}
                                 {this.renderWithdrawCommissions()}
                                 {this.handleWithdrawdata("Status", detailsObj?.state, "status")}
                                
                                {!this.props?.location?.pathname.includes("/openpaydwithdrawal") && this.handleWithdrawdata("Reason For Transfer",detailsObj?.reasonOfTransfer,"file")}
                                {this.handleWithdrawdata("Approved By",detailsObj?.modifiedBy,"status")}
                                {detailsObj?.status === "Approved" && this.handleDates("Approved Date",detailsObj?.modifiedDate)}
                                {detailsObj?.status === "Cancelled" && this.handleWithdrawdata("Cancelled By",detailsObj?.modifiedBy,"status")}
                                {detailsObj?.status === "Cancelled" && this.handleDates("Cancelled Date",detailsObj?.modifiedDate)}
                                {this.renderRejectedReason()}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
							{this.handleDocuments()}
						      </Row>
                    <BankInfo Id={this.props.match.params.id} Status={detailsObj?.state} Createdby={detailsObj?.createdBy} CreatedDate={detailsObj?.createdDate} ModifiedDate={detailsObj?.modifiedDate} Modifiedby={detailsObj?.modifiedBy} />
                </>}
                <Modal
                    className="documentmodal-width"
                    destroyOnClose={true}
                    title="Preview"
                    width={1000}
                    visible={this.state.previewModal}
                    closeIcon={
                        <Tooltip title="Close">
                            <span
                                className="icon md x c-pointer"
                                onClick={this.docPreviewClose}
                            />
                        </Tooltip>
                    }
                    footer={
                        <>
                            <Button
                                type="primary"
                                onClick={this.docPreviewClose}
                                className="primary-btn cancel-btn"
                            >
                                Close
                            </Button>
                            <Button
                                type="primary"
                                className="primary-btn"
                                onClick={() =>
                                    window.open(this.state.previewPath, "_blank")
                                }
                            >
                                Download
                            </Button>
                        </>
                    }
                >
                    <FilePreviewer
                        hideControls={true}
                        file={{
                            url: this.state.previewPath ? this.filePreviewPath() : null,
                            mimeType: this.state?.previewPath?.includes(".pdf")
                                ? "application/pdf"
                                : ""
                        }}
                    />
                </Modal>
                {this.state?.docPreviewModal && 
							<DocumentPreview
							previewModal={this.state?.docPreviewModal}
							handleCancle={this.docPreviewClose}
							upLoadResponse={this.state?.docPreviewDetails}
						/>
							}
               
            </>
        )
    }
}
const connectStateToProps = ({ withdrawStore, userConfig }) => {
    return { withdrawStore, userConfig: userConfig.userProfileInfo }
}
const connectDispatchToProps = dispatch => {
    return {
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(WithdrawDetailSummary);