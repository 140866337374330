import React, { Component } from 'react';
import success from '../../assets/images/success.png';
import { connect } from "react-redux";
class WithdrawCryptoSuccess extends Component {
 
    render() {
      
        return (
            <>
                <div className="success-pop text-center mt-10">
                    <img src={success} className="confirm-icon" alt={"success"} />
                    <div className="text-white-30 decalration-title fw-200 mb-4" >Your transaction has been processed successfully</div>
                </div>
            </>
        );
    }
}
const connectStateToProps = ({ sendReceive, userConfig }) => {
    return {
        sendReceive,
        userProfile: userConfig.userProfileInfo,
        trackAuditLogData: userConfig.trackAuditLogData
    };
};
export default connect(connectStateToProps, null)(WithdrawCryptoSuccess);
