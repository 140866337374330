import React, { Component } from "react";
import { Radio } from "antd";
import IntroducerReports from './reportsTab.component';
import IntroducerDetails from './introducerTab.component/index';

class Introducer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1, // Default tab is set to 1
        };
    }

    handleDepositTabs = (e) => {
        this.setState({ selectedTab: e.target.value });
    }

    render() {
        const { selectedTab } = this.state;

        return (
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    className="mb-16"
                >
                    <Radio.Group
                        value={selectedTab}
                        onChange={this.handleDepositTabs}
                        className="buysell-toggle mb-0"
                    >
                        <Radio.Button value={1}>Introducers</Radio.Button>
                        <Radio.Button value={2}>Reports</Radio.Button>
                    </Radio.Group>
                </div>

                {/* Conditional Rendering Based on selectedTab */}
                {selectedTab === 1 && <IntroducerDetails />}
                {selectedTab === 2 && <IntroducerReports />}
            </>
        );
    }
}

export default Introducer;
