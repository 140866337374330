import * as React from "react";
import {
    GridColumn,
    Grid,
    GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { withState } from "./grid";
import { ColumnMenu } from "./columnMenu";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { mainSubject, publishShowActions } from "./subscribir";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import Loader from "../loader.component";
import { message } from "antd";
import { clientApi } from "../../api";
import { saveGridLayoutFalse, resetGridLayoutFalse } from "../../reducers/gridLayoutReducer";
import { store } from "../../store";
import moment from "moment";
import config from "../../config";

const StatefullGrid = withState(Grid);
const CustomLockedCell = (props, customCell) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
        <td
            style={props.style} // this applies styles that lock the column at a specific position
            className={props.className} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}>
            {customCell ? customCell(props) : value}
        </td>
    );
};

class List extends React.Component {
    listener;
    constructor(props) {
        super(props);
        this.eleRef = React.createRef();

        this.state = {
            columns: [],
            layoutHasChanged: false,
            dataState: {},
            loading: true,
            isBtnLoading: false,
            featureId: store.getState().permissions?.currentScreenTabId || store.getState().permissions?.currentScreenId,
            customerId: store.getState().userConfig?.userProfileInfo?.id,
            saveGridLayout: store.getState().saveGridModal?.saveGridLayout,
            resetGridLayout: store.getState().saveGridModal?.resetGridLayout,
            username: store.getState().userConfig?.userProfileInfo?.userName
          };
          this.handleColumnReorder = this.handleColumnReorder.bind(this);
          this.handleColumnMenu = this.handleColumnMenu.bind(this);
    }

    refreshGrid() {
        publishShowActions(this.props.showActionBar);
        this.eleRef.current?.refreshGrid();
    }
    renderDate = (props) => {
        if (props.dataItem[props.field]) {
            return (
                <td>
                    <Moment format={config?.dateFormates?.dateFormate}>
                        {this.convertUTCToLocalTime(props?.dataItem[props.field])}
                    </Moment>
                </td>
            );
        } else {
            return <td>{props.dataItem[props.field]}</td>;
        }
    };
    renderDateTime = (props) => {
        if (props.dataItem[props.field]) {
            return (
                <td>
                    <Moment format={config?.dateFormates?.dateTimeFormate} globalLocal={true}>
                        {this.convertUTCToLocalTime(props?.dataItem[props.field])}
                    </Moment>
                </td>
            );
        } else {
            return <td>{props.dataItem[props.field]}</td>;
        }
    };
    convertUTCToLocalTime = (dateString) => {
        return moment.utc(dateString).tz(process.env.REACT_APP_TIMEZONE)//.format("DD/MM/YYYY hh:mm:ss A");
    };
    renderNumber = (props) => {
        return (
            <td>
                {" "}
                <NumberFormat
                    value={props?.dataItem[props.field]}
                    decimalSeparator="."
                    displayType={"text"}
                    thousandSeparator={true}
                />
            </td>
        );
    };
    componentDidMount() {
        publishShowActions(this.props.showActionBar);
        this.listener = mainSubject.subscribe((data) =>
            this.props.onActionClick ? this.props.onActionClick(data) : ""
        );

        this.unsubscribe = store.subscribe(() => {
            this.setState({
              featureId: store.getState().permissions?.currentScreenTabId || store.getState().permissions?.currentScreenId,
              customerId: store.getState().userConfig?.userProfileInfo?.id,
              saveGridLayout: store.getState().saveGridModal?.saveGridLayout,
              resetGridLayout: store.getState().saveGridModal?.resetGridLayout,
              username: store.getState().userConfig?.userProfileInfo?.userName
            });
          });
        
        
        const getSaveLayoutData = async() => {
            let savedColumnOrder;
            let savedDataState;
            const res = await clientApi.get(`${process.env.REACT_APP_GRID_API}MetaData/Metadata/${this.state.customerId}/${this.state.featureId}`);
            if(res.status === 200 && !this.props.ignoreSaved ){
                try{
                    let metaData = JSON.parse(res.data.metaData)
                    if(metaData.columns && metaData.dataState){
                        savedColumnOrder = metaData.columns
                        savedDataState = metaData.dataState
                    } else{ // old API details
                        savedColumnOrder = metaData
                        savedColumnOrder = savedColumnOrder.map((col, indx)=>({...col, show: true, originalIndex: indx}))
                        savedDataState = null
                    }   
                } catch{
                    savedColumnOrder = null
                    savedDataState = null
                } 
            }
            else{
                savedColumnOrder = null
                savedDataState = null
            }

            if (savedColumnOrder) {
                const getCustomCell = (column, i) =>{
                    if(column?.customCell || column?.customCell === ""){
                        return this.props.columns[i]?.customCell
                    }
                    else{
                        return null
                    }
                }

                let columnToSave = [];
                let changeToNull = false;
                for(let i in savedColumnOrder){
                    const col = {...savedColumnOrder[i], customCell: getCustomCell(savedColumnOrder[i], i)}
                    columnToSave.push(col)
                    if(savedColumnOrder[i]?.field !== this.props.columns[i]?.field){changeToNull = true}
                }
                savedColumnOrder = columnToSave

                if(savedColumnOrder.length !== this.props.columns?.length){
                    console.log("Reverting to default since there has been a change in the amount of columns")
                    savedColumnOrder = null
                } 
                if(changeToNull){
                    console.log("Reverting to default since there has been a change in the column fields")
                    savedColumnOrder = null
                }
                
              }
                  const columnsFromProps = this.props?.columns?.map((col, indx)=>({...col, show: true, orderIndex: indx, originalIndex: indx}))
              const pageSizeToDisplay = savedDataState?.take ?
                  (savedDataState.take === "All" ? NaN : savedDataState.take) 
                  : 
                  (this.props.pSize || 10)
              const pageToSkip = savedDataState?.skip ? savedDataState.skip : 0
              if(savedDataState){
              savedDataState = {...savedDataState, take: pageSizeToDisplay, skip: pageToSkip}
              }  

            this.setState({
              ...this.state,
              columns: savedColumnOrder || columnsFromProps,
              loading: false,
              dataState: savedDataState || {filter: undefined, sort: undefined, skip: pageToSkip, take: pageSizeToDisplay, group: []}
            })
            
        };
        getSaveLayoutData()
    }
    componentWillUnmount() {
        publishShowActions(false);
        this.listener.unsubscribe();
        this.unsubscribe();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.saveGridLayout === false && this.state.saveGridLayout === true){
            this.onSaveHandler();
        }
        if(prevState.resetGridLayout === false && this.state.resetGridLayout === true){
            this.resetToDefault();
        }
    }

    gridFilterData = (column) => {
        if (column.filterType === "date") {
            if (column.isShowTime) {
                return this.renderDateTime;
            } else {
                return this.renderDate;
            }
        } else if (column.filterType === "number") {
            return this.renderNumber;
        } else if (column.filterType === "datetime") {
            return this.renderDateTime;
        } else {
            return null;
        }
    };

    onColumnsApply = (columnsState) => {
        this.setState({
        ...this.state,
          columns: columnsState,
          layoutHasChanged: true
        });
      };
    resetToDefault = async () =>{
    const columnsFromProps = this.props.columns.map((col, indx)=>({...col, show: true, orderIndex: indx, originalIndex: indx}))
    const initialDataState = {filter: undefined, sort: undefined, skip: 0, take: this.props?.pSize ? this.props.pSize : 10, group: []}
    this.setState({
        ...this.state,
        columns: columnsFromProps,
        dataState: initialDataState ,
        layoutHasChanged: true,
    })
    
    const resetGridLayoutData = async () => {  
        const res = await clientApi.delete(`${process.env.REACT_APP_GRID_API}MetaData/ResetSaveLayout/${this.state.customerId}/${this.state.featureId}`);
        if(res.status === 200){
            this.setState({...this.state, isBtnLoading: false, layoutHasChanged: false})
            store.dispatch(resetGridLayoutFalse())
            this.statusSuccess('Layout reset successfully')
        } else{
            this.setState({...this.state, isBtnLoading: false})
        }   
    };

    resetGridLayoutData()
    this.eleRef.current?.resetToDefault(initialDataState);
    }
    
    CustomOrGroupHeaderCell =(props,column) =>{
        if (props.rowType === "groupHeader") {
            return null;
          }
        if(column.locked){
            return CustomLockedCell(props, column.customCell)
        }
        if(column.customCell){
            return column.customCell(props)
        }
    }

    handleColumnMenu(props) {
        return (
          <ColumnMenu
            {...props}
            columns={this.state.columns}
            onColumnsApply={this.onColumnsApply}
          />
        );
      }

    gridChildrenData = (column, indx) => {
        if (column.children) {
            return (
                <GridColumn
                    key={indx}
                    locked={column.locked}
                    columnMenu={this.handleColumnMenu}
                    children={column.children}
                    field={column.field}
                    title={<span>{column.title} {column.isFiltered && <span className="k-icon k-i-filter left-five"/>} </span>}
                    width={column.width+(column.isFiltered ? 40 : 0)}
                    cell={column.customCell || this.gridFilterData(column)}
                    filter={column.filterType || "text"}
                    format="{0:#,0.##########}"
                    reorderable={!column.locked}
                    orderIndex={column.orderIndex}
                />
            );
        } else {
            return (
                <GridColumn
                    key={indx}
                    locked={column.locked}
                    columnMenu={this.handleColumnMenu}
                    field={column.field}
                    title={<span>{column.title} {column.isFiltered && <span className="k-icon k-i-filter left-five" />} </span>}
                    width={column.width+(column.isFiltered ? 40 : 0)}
                    cell={(!column?.locked && !column?.customCell) ? this.gridFilterData(column) : (props)=>this.CustomOrGroupHeaderCell(props,column)}
                    filter={column.filterType || "text"}
                    format="{0:#,0.##########}"
                    reorderable={!column.locked}
                    orderIndex={column.orderIndex}
                />
            );
        }
    };

    handleColumnReorder = (event) => {
        for(let i=0; i < event.columns.length; i++){
            if(event.columns[i].reorderable === true){
                break
            }
            if(event.columns[i].orderIndex !== i ){
                return
            }
        }

        let columnsToSave = [];
        if(this.state.columns.length === event.columns.length){
            for (let i in this.state.columns){
                const col = {...this.state.columns[i], orderIndex: event.columns[i]?.orderIndex}
                columnsToSave.push(col)
            }
        } else{
            let newArr = []
            let toMinus = 0; 
            for (let i in this.state.columns){
                let colToPush
                if(this.state.columns[i].show){
                    colToPush = {...this.state.columns[i], orderIndex: event.columns[i-toMinus]?.orderIndex}
                } else{
                    colToPush = {...this.state.columns[i]}
                    toMinus++
                }
                newArr.push(colToPush)
            }
            newArr.sort((a, b) => a.orderIndex - b.orderIndex);
            newArr = newArr.map((column, indx) => ({...column, orderIndex: indx }))
            newArr.sort((a, b) => a.originalIndex - b.originalIndex);
            columnsToSave = newArr
        }

        this.setState({
          ...this.state,
          columns: columnsToSave,
          layoutHasChanged: true,
        })
      }

      dataStateChangeHandler = (dataState) =>{
        let filterFields = []
        let filterIndex = []
        let cols = this.state.columns.map(col => ({...col, isFiltered : false}))
        if(dataState?.filter?.filters){
            for(let i in dataState.filter.filters){
                filterFields.push(dataState.filter.filters[i].filters[0].field)
            }

            for(let field of filterFields){
                for(let i in this.state.columns){
                    if(this.state.columns[i].field === field){
                        filterIndex.push(parseInt(i))
                        break
                    }
                }
            }
            for(let index of filterIndex){
                cols[index].isFiltered = true
            }
        }

        let pageSizeToDisplay = dataState.take ? dataState.take : "All"
        this.setState({...this.state, dataState: {...dataState, take: pageSizeToDisplay}, layoutHasChanged: true, columns: cols})
    }
      
    onSaveHandler = async() => {
        const columnsToSave = this.state.columns?.map((column) => {
            return {
              ...column,
              customCell: column.customCell ? column.field : null,
            }
          })
        
          this.setState({...this.state, isBtnLoading: true})

        const saveGridLayoutData = async (objs) => {  
            const res = await clientApi.post(`${process.env.REACT_APP_GRID_API}MetaData/SaveMetaData`, objs);
            if(res.status === 200){
                this.setState({...this.state, isBtnLoading: false, layoutHasChanged: false})
                store.dispatch(saveGridLayoutFalse())
                this.statusSuccess('Layout saved successfully')
            } else{
                this.setState({...this.state, isBtnLoading: false})
            }   
        };

        const metaData = {
            columns: columnsToSave,
            dataState: this.state.dataState
        }

        const obj = {  
            "customerId": this.state.customerId,
            "featureId": this.state.featureId,
            "metaData": JSON.stringify(metaData),
            "createdBy": this.state.username
            }

        saveGridLayoutData(obj);
        this.refreshGrid()
    }

    statusSuccess = (content) => {
        message.destroy();
        message.success({
            content: content,
            className: 'custom-msg',
            duration: 4
        });
    };
    
    render() {
        const { url, additionalParams } = this.props;
        return (
            <div>
                {
                this.state.loading ? (<Loader />) : 
                (<StatefullGrid
                    reorderable={this.props.columnsIsNotReorderable ? false : true}
                    // groupable={this.props.columnsIsNotReorderable ? false : true}
                    groupable={false} // set to false temporarily until API call is fixed
                    cellRender={this.cellRender}
                    onColumnReorder={this.handleColumnReorder}
                    url={url}
                    additionalParams={additionalParams}
                    ref={this.eleRef}
                    callback={this.callback}
                    {...this.props}
                    getDataStateFromChild={this.dataStateChangeHandler}
                    dataState={this.state.dataState}
                    columns={this.state.columns}
                    >
                    {(this.state.columns)?.map((column, indx) => column.show && this.gridChildrenData(column, indx))}

                </StatefullGrid>)
                }
            </div>
        );
    }
}

export default (List);
