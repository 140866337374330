import { getCustomer} from "../components/customers.component/api";
import { appInsights } from "../layout/appinsights";

//Actions
const FETCH_USERS = 'fetchUsers';
const FETCH_USERS_SUCCESS = 'fetchUsersSuccess';
const FETCH_USERS_REJECT = 'fetchUsersReject';
const FETCH_USERS_UPDATE = 'fetchUsersUpdate';

//Action Type
const fetchUsers = () => {
    return {
        type: FETCH_USERS,
    }
}
const fetchUsersSuccess = (payload) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload
    }
}
const fetchUsersReject = (payload) => {
    return {
        type: FETCH_USERS_REJECT,
        payload
    }
}
const fetchUsersUpdate = (payload) => {
    return {
        type: FETCH_USERS_UPDATE,
        payload
    }
}
const trackEvent = (obj) => {
    appInsights.trackEvent({
        name: obj.Feature, properties: {"Type": 'Admin',"Action": obj.Action,"Username": obj.Action.userName,"MemeberId": obj.Action.id,"Feature": obj.Feature,"Remarks": obj.Remarks,"Duration": 1,"Url": window.location.href,"FullFeatureName": obj.FullFeatureName}
    });
}
const trackPageview = (obj) => {
    appInsights.trackPageView({
        name: obj.Feature, properties: {"Type": 'Admin',"Action": obj.Action,"Username": obj.Action.userName,"MemeberId": obj.Action.id,"Feature": obj.Feature,"Remarks": obj.Remarks,"Duration": 1,"Url": window.location.href,"FullFeatureName": obj.FullFeatureName}
    });
}
//InitialState
let initialState = {
    customerData: {  isLoading: false, data: [], error: null },
    userRowData: {}
}

const handleMembers = (member_id) => {
    return async (dispatch) => {
        dispatch(fetchUsersSuccess({ key: "customerData",  isLoading: true, data: [] }));
        const response = await getCustomer(member_id);
        if (response.ok) {
            dispatch(fetchUsersSuccess({ key: "customerData",  isLoading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchUsersReject({ key: "customerData",  isLoading: false, data: [], error: response.originalError }))
        }
    }
}

//Reducer
const customerReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case FETCH_USERS:
            return { ...state,  isLoading: true }
        case FETCH_USERS_SUCCESS:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error,isLoading:action.payload.isLoading } }
        case FETCH_USERS_REJECT:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error,isLoading:action.payload.isLoading } }
        case FETCH_USERS_UPDATE:
            return { ...state, userRowData: action.payload }
        default:
            return state;
    }

}

//Export
export default (customerReducer);
export { handleMembers, fetchUsersUpdate, fetchUsers,trackEvent,trackPageview};