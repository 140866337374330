import React from "react";
import { Row, Col, Typography } from "antd";
import AddressInfo from "./addressInfo";
import Moment from "react-moment";
import apiCalls from "../../api/apiCalls";
import config from "../../config";
const UtilityBill = ({ details }) => {
  const { Title, Paragraph } = Typography;
  return (
    <div>
      <Title level={5} style={{ margin: "0" }}>
        Proof of Address
      </Title>
      <div className="applicant-content">
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">First Name</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {details?.firstName}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Last Name</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {details?.lastName}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Middle Name</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {details?.middleName}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Issue Date</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {details?.issueDate}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Number</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {details?.number}
              </Paragraph>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xxl={6}>
            <div>
              <label className="kpi-label">Valid Until</label>
              <Paragraph className="kpi-val fw-700 mb-0">
                {details?.validUntil ? (
                  <Moment format={config?.dateFormates?.dateFormate}>
                    {details?.validUntil
                      && apiCalls.convertUTCToLocalTime(details?.validUntil)
                      || details?.validUntil}
                  </Moment>
                ) : (
                  details?.validUntil
                )}
              </Paragraph>
            </div>
          </Col>
        </Row>
        {details?.address && (
            <>
              <Title level={5} className="mt20">
                Address
              </Title>
              <div>
                <AddressInfo addressDetails={details?.address} border={false} />
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default UtilityBill;
