import React, { Component } from "react";
import {
	Tooltip,
	message,
	Button,
	Typography,
	Row,
	Col,
	Divider,
	Upload,
	Collapse,
	Input,
	Spin,
	Popconfirm,
	Empty,
	Alert,
	Form
} from "antd";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { validateContent, validateContentRule } from "../../utils/custom.validator";
import { getCasesViewData, getCaseLu } from "./api";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Loader from "../loader.component";
import NumberFormat from "react-number-format";
import {
	approveDoc,
	getDocumentReplies,
	saveDocNewReply,
	uuidv4,
} from "../documents.component/api";
import apiCalls from "../../api/apiCalls";
import Mome from "moment";
import { publishShowActions } from "../grid.component/subscribir";
import { connect } from "react-redux";
import { success, warning, error } from "../../utils/messages";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { ApiControllers } from '../../api/config'
import DocumentPreview from "../shared/documentPreview";
import Info from "../info/Info";
import config from "../../config";
const { Title, Text } = Typography;
const { Panel } = Collapse;

const EllipsisMiddle = ({ suffixCount, children }) => {
	const start = children?.slice(0, children.length - suffixCount).trim();
	const suffix = children?.slice(-suffixCount).trim();
	return (
		<Text
			className="mb-0 fs-14 docnames c-pointer d-block"
			style={{ maxWidth: "100%" }}
			ellipsis={{ suffix }}>
			{start}
		</Text>
	);
};
class CaseView extends Component {
	constructor (props) {
		super(props);
		this.state = {
			viewObj: {},
			modal: false,
			previewModal: false,
			docDetails: [],
			loading: true,
			error: null,
			errorMessage: null,
			errorWarning: null,
			documentReplies: {},
			docReplyObjs: [],
			previewPath: null,
			isSubmitting: false,
			uploadLoader: false,
			isMessageError: null,
			isValidFile: true,
			docErrorMessage: null,
			PreviewFilePath: null,
			caseViewObject: {},
			commonModel: {},
			assignedTo: [],
			detailsItem: [],
			isApproved: false,
			isrejected: false,
			docPreviewDetails:null,
			errorMsg:null,
			docState:"",
			isRequired:false,
			caseActiveKey:this.props.match.params.key
			
		};
		this.formRef = React.createRef();
	}

	componentDidMount() {
		this.getCasesView();
		window.scrollTo(0, 0);
		publishShowActions(false);
	}

	reloadCase = () =>{
		this.setState({
			viewObj: {},
			modal: false,
			previewModal: false,
			docDetails: [],
			error: null,
			errorMessage: null,
			errorWarning: null,
			documentReplies: {},
			docReplyObjs: [],
			previewPath: null,
			isSubmitting: false,
			uploadLoader: false,
			isMessageError: null,
			isValidFile: true,
			docErrorMessage: null,
			PreviewFilePath: null,
			caseViewObject: {},
			commonModel: {},
			assignedTo: [],
			detailsItem: [],
			isApproved: false,
			isrejected: false,
			docPreviewDetails:null,
			docState:"",
			saveDocId:'',
			
		})
		this.getCasesView(true);
		window.scrollTo(0, 0);
		publishShowActions(false);
		
	}

	backToCases = () => {
		setCurrentAction(null);
		this.props.history.push({
			pathname: `/cases/${this.state.caseActiveKey}`,
		});
	};
	editCase = () => {
		const obj = this.state.viewObj;
		const val = obj.id;
		this.props.history.push(`/cases/${this.state.caseActiveKey}/` + val + `/casedetails/edit`);
		this.props.dispatch(
			setBreadcrumb({
				key: `/cases/${this.state.caseActiveKey}/` + val + "/casedetails/edit",
				val: obj.caseNumber,
			})
		);
	};
	fetchAssignedToLu = async () => {
		this.setState({ ...this.state, loading: true, error: null });
		const { viewObj } = this.state;
		let assignedIdList = viewObj?.assignedTo;
		let assignedNames = [];
		let response = await getCaseLu(null);
		if (response.ok) {
			for (let x in assignedIdList) {
				let data = assignedIdList[x].assignedToIds.split(",");
				let namelist = [];
				for (let i in data) {
					let nameObj = response.data.find((item) => item.id === data[i]);
					namelist.push(nameObj?.assignedTo);
				}
				assignedIdList[x].assignedNames = namelist.join(", ");
                assignedNames.push(assignedIdList[x].assignedNames);
				this.setState({
					...this.state.viewObj.assignedTo,
					assignedTo: { ...this.state.viewObj.assignedTo, assignedNames },
				});
				this.setState({
					...this.state,
					loading: false,
				});
			}
		} else {
			warning("Data not getting from the server!");
		}
	};
	getCasesView = async (isSet) => {
		if(isSet){
		this.setState({ ...this.state, loading: true, error: null });
		}
		let response = await getCasesViewData(this.props.match.params.id);
		if (response.ok) {
			this.setState({
				...this.state,
				viewObj: response.data,
				commonModel: response.data.commonModel,
				docDetails: response.data.caseDetails,docState:response.data?.state
			});
			const detailsObjs = response.data?.caseDetails?.filter(
				(item) => item.isChecked === true
				
			);
			for (let i in detailsObjs) {
				let data = detailsObjs[i];
				this.state.detailsItem.push(data);
			}			
			this.fetchAssignedToLu();
		} else {
			error(response.data);
		}
		setTimeout(() => {
			this.setState({ ...this.state, loading: false });
		}, 5500);
	};
	loadDocReplies = async (id) => {
		let docReObj = this.state.docReplyObjs.filter(
			(item) => item.docunetDetailId !== id
		);
		this.setState({
			...this.state,
			documentReplies: {
				...this.state.documentReplies,
				[id]: { loading: true, data: [], error: null },
			},
			docReplyObjs: docReObj,
			docErrorMessage: null,
		});
		const response = await getDocumentReplies(id);
		if (response.ok) {
			this.setState({
				...this.state,
				documentReplies: {
					...this.state.documentReplies,
					[id]: {
						loading: false,
						data: response.data.map((item) => {
							return {
								...item,
								repositories:
									item.repositories && item?.repositories !== "string"
										? JSON.parse(item.repositories)
										: [],
							};
						}),
						error: null,
					},
				},
			});
		} else {
			this.setState({
				...this.state,
				documentReplies: {
					...this.state.documentReplies,
					[id]: { loading: false, data: [], error: response.data },
				},
			});
		}
	};
	formatBytes(bytes) {
		if (bytes === 0) return "0 Bytes";
		const k = 1024;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed()) + " " + sizes[i];
	}
	deleteDocument = async (doc, idx, isAdd) => {
		let item = { ...doc };
		item.repositories.splice(idx, 1);
		item.repositories = JSON.stringify(item.repositories);
		item.state = "Rejected";
		if (isAdd) {
			let objs = [...this.state.docReplyObjs];
			objs = objs.filter((ditem) => ditem.docunetDetailId !== doc.id);
			this.setState({ ...this.state, docReplyObjs: objs });
			return;
		}
		const response = await saveDocNewReply(item);
		message.destroy();
		if (response.ok) {
			warning("Document has been deleted");
			this.loadDocReplies(doc.id);
		} else {
			warning(response.data);
		}
	};
	handleReplymessage = (_message, doc) => {
		this.setState({ ...this.state, errorMessage: null,docErrorMessage:null });
		let replyObjs = [...this.state.docReplyObjs];
		let item = this.isDocExist(replyObjs, doc.id);
		let obj;
		if (item) {
			obj = item;
			obj.reply = _message;
			obj.repliedBy = this.props.userProfileInfo?.firstName;
			replyObjs = this.uopdateReplyObj(obj, replyObjs);
		} else {
			obj = this.messageObject(doc.id);
			obj.reply = _message;
			obj.repliedDate = new Date();
			obj.repliedBy = this.props.userProfileInfo?.firstName;
			replyObjs.push(obj);
		}
		this.setState({ ...this.state, docReplyObjs: replyObjs,docErrorMessage:null });
	};
	parseData=(obj)=>{
		if (obj.repositories === "string") {
			return JSON.parse(obj.repositories);
		} else {
			return obj.repositories ? obj.repositories : [];
		}
	}
	handleUpload = ({ file }, doc) => {
		this.setState({ ...this.state, errorMessage: null, uploadLoader: true,isApproved:false, saveDocId:doc.id });
		if (file.status === "done" && this.state.isValidFile) {
			let replyObjs = [...this.state.docReplyObjs];
			let item = this.isDocExist(replyObjs, doc.id);
			let obj;
			if (item) {
				obj = item;
				const ObjPath = function () {
					return this.parseData(obj);
				};
				obj.repositories = ObjPath();
				obj.repliedDate = new Date();
				obj.repositories.push({
					fileName: file?.response?.fileName,
					id: file?.response?.id,
					fileSize: file?.response?.fileSize,
					state:"Submitted",
				});
				obj.repliedBy = this.props.userConfig?.firstName;
				replyObjs = this.uopdateReplyObj(obj, replyObjs);
			} else {
				obj = this.messageObject(doc.id);
				obj.repliedDate = new Date();
				obj.repositories.push({
					fileName:file?.response?.fileName,
					id: file?.response?.id,
					fileSize:file?.response?.fileSize,
					state:"Rejected",
				});
				obj.repliedBy = this.props.userConfig?.firstName;
				replyObjs.push(obj);
			}
			this.setState({
				...this.state,
				docReplyObjs: replyObjs,
				uploadLoader: false,
				isApproved:false,
			});
		} else if (file.status === "error") {
			this.setState({ ...this.state, uploadLoader: false, isSubmitting: false, errorWarning: null, errorMessage:apiCalls.uploadErrorDisplay(file.response)});
		} else if (!this.state.isValidFile) {
			this.setState({
				...this.state,
				uploadLoader: false,
				isSubmitting: false,
			});
		}
	};
	beforeUpload = (file) => {
		this.setState({ ...this.state, errorWarning: null });
		let fileType = {
			"image/png": true,
			"image/jpg": true,
			"image/jpeg": true,
			"image/PNG": true,
			"image/JPG": true,
			"image/JPEG": true,
			"application/pdf": true,
			"application/PDF": true,
		};
		if (fileType[file.type]) {
			this.setState({ ...this.state, isValidFile: true, errorWarning: null });
			return true;
		} else {
			this.setState({ ...this.state, isValidFile: false, errorWarning: "File is not allowed. You can upload jpg, png, jpeg and PDF files" });
			return Upload.LIST_IGNORE;
		}
	};
	getUploadedFiles = (id) => {
		let data = this.state.docReplyObjs.filter(
			(item) => item.docunetDetailId === id
		)[0];
		if (data && data.repositories) {
			data.repositories =
				(typeof data.repositories == "string" ? JSON.parse(data.repositories) : data.repositories) ||
				[];
			return data;
		} else {
			return { repositories: [] };
		}
	};
	docApprove = async (doc) => {
		this.setState({ ...this.state, isApproved: true,docErrorMessage:null ,isMessageError:null,isRequired:false})
		let item= this.isDocExist(this.state.docReplyObjs, doc.id)
		if (!item || !item.reply || !validateContent(item?.reply)||item===undefined) {
			this.setState({
				...this.state,
				isMessageError: doc.id.replace(/-/g, ""),
				docErrorMessage: "Is required",
				isApproved:false,
			});
			return;
		}		
		if(item!==undefined){
		item.path = null;
		item.status = "Approved";
		item.repliedDate = Mome().format("YYYY-MM-DDTHH:mm:ss");
		item.note = this.state.docDetails.note;
		item.customerId = this.state.viewObj.customerId;
		item.repliedBy = this.props.userConfig?.firstName;
		item.caseId=doc?.caseId;
		item.info="{}";
		const response = await approveDoc(item);
		this.setState({ ...this.state, isMessageError: null, errorWarning: null, errorMessage: null, isSubmitting: true,isApproved:true });
		if (response.ok) {
			this.setState({ ...this.state, isApproved: false,errorMsg:null })
			success("Document has been approved");
			this.updateDocRepliesStatus(doc, "Approved");
			this.loadDocReplies(doc.id);
		} else {
			this.setState({ ...this.state, isApproved: false,errorMsg:apiCalls.isErrorDispaly(response) })
		}
		
	}
	};
	docReject = async (doc) => {
		this.setState({
			...this.state,
			isMessageError: null,
			docErrorMessage: null, isrejected: true
		});
		let item = this.isDocExist(this.state.docReplyObjs, doc.id);
		if (!item || !item.reply || !validateContent(item?.reply)||item===undefined) {
			this.setState({
				...this.state,
				isMessageError: doc.id.replace(/-/g, ""),
				docErrorMessage: "Is required",isrejected:false
			});
			return;
		}		
		this.setState({ ...this.state, isSubmitting: true, isrejected: true });
		item.path = null;
		item.status = "Rejected";
		item.repliedDate = Mome().format("YYYY-MM-DDTHH:mm:ss");
		item.note = this.state.docDetails.note;
		item.customerId = this.state.viewObj.customerId;
		item.repliedBy = this.props.userConfig?.firstName;
		item.caseId= doc?.caseId;
		item.info="{}";
		
		const response = await saveDocNewReply(item);
		message.destroy();
		if (response.ok) {
			success("Document has been rejected");
			this.setState({ ...this.state, isSubmitting: false, errorWarning: null, errorMessage: null, isrejected: false,errorMsg:null });
			this.updateDocRepliesStatus(doc, "Rejected");
			this.loadDocReplies(doc.id);
		} else {
			this.setState({ ...this.state, isSubmitting: false, isrejected: false,errorMsg:apiCalls.isErrorDispaly(response) });
			
		}
		let objs = [...this.state.docReplyObjs];
		objs = objs.filter((ditem) => ditem.docunetDetailId !== doc.id);
		this.setState({
			...this.state,
			docReplyObjs: objs,
			isSubmitting: false,
			isMessageError: null,
		});
		document.getElementsByClassName(`${doc.id.replace(/-/g, "")}`).value = "";

	};
	isDocExist(lstObj, id) {
		const lst = lstObj.filter((obj) => {
			return obj.docunetDetailId === id;
		});
		return lst[0];
	}
	messageObject = (id) => {
		return {
			id: uuidv4(),
			docunetDetailId: id,
			repositories: [],
			reply: "",
			repliedBy: "",
			repliedDate: null,
			isCustomer: false,
		};
	};
	uopdateReplyObj = (item, list) => {
		for (let obj of list) {
			if (obj.id === item.id) {
				Object.assign(obj, item);
			}
		}
		return list;
	};
	updateDocRepliesStatus = (doc, Status) => {
		let docDetails = [this.state.docDetails];
		for (let item of docDetails) {
			if (item.id === doc.id) {
				item.state = Status;
			}
		}
		this.setState({
			...this.state,
			docDetails: { ...this.state.docDetails, details: docDetails },
		});
		this.reloadCase();
	};
	docPreviewClose = () => {
        this.setState({ ...this.state, previewModal: false, docPreviewDetails: null });
      };
    docPreviewOpen = (data) => {
        this.setState({ ...this.state, previewModal: true, docPreviewDetails: { id: data.id, fileName: data.fileName } });
      };

	redirectCustomerView = () => {
		const items = this.state.viewObj;
		const val = items.customerId;
		this.props.dispatch(
			setBreadcrumb({ key: "/customers/details/" + val, val: this.state.viewObj.commonModel?.Name })
		);
		localStorage.setItem("MyURL","/customers/details/" + val)
		window.open("/customers/details/" + val);
	}
	renderWithLinks(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = text?.split(urlRegex);
      
        return parts?.map((part, index) => {
          if (part?.match(urlRegex)) {
            return (
              <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            );
          } else {
            return <span key={index}>{part}</span>;
          }
        });
      }
	  getIconClass(key, value) {
		let iconClass = "description"; // Default value if key is null
	
		if (key === "Currency" && value === "EUR") {
			iconClass = "EUR";
		}
		else if(key==="Customer/Contract ID"){
			return "Customer ID"
			} 
		
		else if (key !== null) {
			iconClass = key;
		}
	
		return iconClass;
	}
	getDisplayValue(key, value, redirectCustomerView) {
		if (value === null || value === " ") {
			return '-';
		} else if (isNaN(value) || (key === 'Transaction Id' || key === 'Bank Account number/IBAN' || key === 'Bank Name' || key === "Wallet Address")) {
			if (key === 'Name') {
				return <Link onClick={redirectCustomerView}>{value}</Link>;
			} else {
				return value;
			}
		} else {
			return <NumberFormat value={value} decimalSeparator="." displayType={'text'} thousandSeparator={true} />;
		}
	}
	getStateClassName(doc) {
		const state = doc?.state?.toLowerCase();
		let className = "";
	
		if (state) {
			className += state + "-lbl ";
	
			if (doc?.state === "Approved") {
				className += "status-lbl ";
			} else if (doc?.state === "Rejected") {
				className += "reject-lbl ";
			} else if (doc?.state === "Submitted") {
				className += "subm-lbl ";
			} else if (doc?.state === "Requested") {
				className += "request-lbl ";
			}
		}
	
		return className.trim();
	}
	renderCommonModalData=(commonModel)=>{
		return <div className="case-ribbon">
		<Row gutter={[16, 16]}>
			{commonModel &&
				Object.entries(commonModel).map(([key, value], idx) => (
					<Col
						key={idx}
						xs={key === "description" ? 24 : 22}
						md={key === "description" ? 24 : 20}
						lg={key === "description" ? 24 : 12}
						xl={key === "description" ? 24 : 8}
						xxl={key === "description" ? 24 : 6}
					>
						<div className="ribbon-item">
							<span
								className={`icon md ${this.getIconClass(key, value)}`}
							/>
							<div className="ml-16" style={{ flex: 1 }}>
								<Text className="fw-300 text-white-50 fs-12 ">
									{key}
								</Text>
								<div className='fw-600 text-white-30 fs-16 l-height-normal' style={{ width: "30", wordBreak: "break-all" }} >
								{this.getDisplayValue(key, value, this.redirectCustomerView)}
								</div>
							</div>
						</div>
					</Col>
				))}
		</Row>
	</div>
	}
	getAlertMsg=(alertMessage,doc)=>{
	return (this.state?.saveDocId===doc.id && alertMessage !== undefined && alertMessage !== null && (
			<div style={{ width: "100%" }}>
				<Alert
					className="w-100 mb-16"
					type="error"
					description={alertMessage}
					showIcon
				/>
			</div>
		));
	}
	render() {
		const { docErrorMessage, errorMessage, errorWarning, uploadLoader, viewObj, commonModel, loading, isApproved, isrejected } = this.state;
		if (loading) {
			return <Loader />;
		}
		return (
			<>
			{this.state.errorMsg && <div style={{ width: '100%' }}>
					<Alert className="mb-16 w-100" type="error"
						description={this.state.errorMsg} showIcon />
				</div>}
				{viewObj != null &&
					<>
						<div
							style={{
								border: "2px dashed var(--borderGrey)",
								padding: "12px 16px",
								borderRadius: 10,
								marginBottom: 16
							}}
						>
							<Row gutter={[16, 16]}>
								<Col xs={24} md={24} lg={7} xl={8} xxl={6}>
									<Text className="fw-300 text-white-50 fs-12">
										Case Number
									</Text>
									<Title level={5} className="m-0 mb-8 l-height-normal">
										{viewObj?.caseNumber}
									</Title>
								</Col>
								<Col xs={24} md={24} lg={10} xl={8} xxl={9}>
									<Text className="fw-300 text-white-50 fs-12">Case Title</Text>
									<Title level={5} className="m-0 mb-8 l-height-normal">
										{viewObj?.caseTitle}
									</Title>
								</Col>
								<Col xs={24} md={24} lg={3} xl={6} xxl={6}>
									<Text className="fw-300 text-white-50 fs-12">Case State</Text>
									<Title level={5} className="m-0 mb-8 l-height-normal">
										{viewObj?.state}
									</Title>
								</Col>
							{(viewObj.state === "Reopened" && viewObj?.reason) &&
								<Col xs={24} md={24} lg={7} xl={8} xxl={6}>
									<Text className="fw-300 text-white-50 fs-12">Reason</Text>
									<Title level={5} className="m-0 mb-8 l-height-normal">
										{viewObj?.reason || "-"}
									</Title>
								</Col>
							}
							<Col xs={24} md={24} lg={4} xl={1} xxl={3}
								className={`${viewObj?.reason?"":"text-right"}`}
							>
									<Button
										type="primary"
										className="primary-btn px-24 search-btn"
										onClick={this.editCase}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</div>
						{this.renderCommonModalData(commonModel)}
						{viewObj.assignedTo?.map((item, idx) => (
							<div key={idx}
								style={{
									border: "2px dashed var(--borderGrey)",
									padding: "12px 16px",
									borderRadius: 10,
									marginBottom: 16
								}}
							>
								<Row gutter={[16, 16]} key={idx}>
									<Col xs={24} md={24} lg={14} xl={8} xxl={6}>
										<Text className="fw-300 text-white-50 fs-12">
											Assigned To
										</Text>
										<Title level={5} className="m-0 mb-8 l-height-normal">
											{item?.assignedNames}
										</Title>
									</Col>
									<Col xs={24} md={24} lg={6} xl={8} xxl={6}>
										<Text className="fw-300 text-white-50 fs-12">
											Assigned Remarks
										</Text>
										<Title level={5} className="m-0 mb-8 l-height-normal">
											{item?.assignedRemarks || "-"}
										</Title>
									</Col>
									<Col xs={24} md={24} lg={4} xl={6} xxl={6}>
										<Text className="fw-300 text-white-50 fs-12">
											Case Sub State
										</Text>
										<Title level={5} className="m-0 mb-8 l-height-normal">
											{item?.caseSubState}
										</Title>
									</Col>
								</Row>
							</div>
						))}
						<div className="px-16">
							<Text className="fw-300 text-white-50 fs-12">
								Customer Remarks
							</Text>
							<Title level={5} className="m-0 mb-8 l-height-normal">
							{this.renderWithLinks(viewObj?.remarks)}
							</Title>
						</div>
						<Divider />
						<Title className="mb-16 fs-18 l-height-normal mt-24">
						   {viewObj?.isCrypto && process.env.REACT_APP_ISTR == "true" ?	"Crypto" : "Request for documents from customer"}
						</Title>
						<div>
							{this.state.detailsItem.map((doc, idx) => (
								<Collapse key={idx}
									onChange={(key) => {
										if (key) {
											this.loadDocReplies(doc.id);
										}
									}}
									accordion
									className="accordian mb-24"
									expandIcon={() => <span className="icon md downangle" />}
								>
									<Panel
										header={doc?.documentName}
										key={idx + 1}
										extra={
											this.state?.docState ? (
												<span
													className={this.getStateClassName(doc)}
												>
													{doc?.state}
												</span>
											) : (
												""
											)
										}
									>
										{this.state?.documentReplies[doc.id]?.data?.map(
											(reply, index) => (
												<div key={index} className="reply-container">
													<div className="user-shortname">
														{reply.repliedBy?.slice(0, 2)}
													</div>
													<div className="reply-body">
														<Text className="reply-username">
															{reply.repliedBy}
														</Text>
														<Text className="reply-date">
															<Moment format={config?.dateFormates?.dateTimeFormate}>
																{reply.repliedDate}
															</Moment>
														</Text>
														<p className="reply-txt">{reply.reply}</p>
														<div className="docfile-container">
															{reply?.docRepositories?.map((file, _idx) => (
																<div key={_idx} className="docfile docfile_show">
							            <span className={`icon xl ${(file.fileName.slice(-3) === "zip" ? "file" : "") || (file.fileName.slice(-3) === "pdf" ? "file" : "image")} mr-16`} />
																	<div
																		className="docdetails c-pointer"
																		onClick={() => this.docPreviewOpen(file)}

																	>
																		<EllipsisMiddle suffixCount={6}>
																			{file.fileName}
																		</EllipsisMiddle>
																		<span className="fs-12 text-secondary">
																			{this.formatBytes(file?.fileSize)}
																		</span>
																	</div>
																	{doc.state !== "Approved" && (
																		<Popconfirm
																			title="Are you sure to delete this document?"
																			onConfirm={() =>
																				this.deleteDocument(reply, _idx)
																			}
																			okText="Yes"
																			
																			cancelText="NO"
																			
																		>
																			
																		</Popconfirm>
																	)}
																</div>
															))}
														</div>
													</div>
												</div>
											)
										)}
										{this.getAlertMsg(errorMessage,doc)}
										{this.getAlertMsg(errorWarning,doc)}
										{this.state.documentReplies[doc.id]?.loading && <div className="text-center"><Spin size="large" /></div>}
										{this.state?.documentReplies[doc.id]?.data &&
											this.state?.documentReplies[doc.id]?.data?.length !== 0 &&!this.state.documentReplies[doc.id]?.loading &&
											viewObj.state !== "Approved" &&
											viewObj.state !== "Rejected" && (
												<>
													<Form
														onFinish={() => this.docReject(doc)}
													>
														<div className="reply-container">
															<div className="user-shortname">
																{this.props.userConfig?.firstName?.slice(0, 2)}
															</div>
															<div className="reply-body">
																<div className="chat-send mb-0 writemsg-input">
																	<Form.Item
																		name=""
																		rules={[
																			{ required: true, whitespace:true,
																				message: "Is required" },
																			{
																				validator: validateContentRule,
																			},
																		]}>
																		<Input
																			maxLength={200}
																			autoFocus
																			type="text"
																			className={doc.id.replace(/-/g, "")}
																			id={doc.id.replace(/-/g, "")}
																			onChange={({ currentTarget: { value } }) =>
																				this.handleReplymessage(value, doc)
																			}
																			placeholder="Write your message..."
																			size="large"
																			bordered={false}
																			multiple={true}
																			validator={validateContent}
																		/>
																	</Form.Item>
																	<div className="d-flex align-center">
																		<Tooltip title="Attachments">
																			<Upload
																				accept=".pdf,.jpg,.jpeg,.png, .PDF, .JPG, .JPEG, .PNG"
																				onChange={(props) =>
																					this.handleUpload(props, doc)
																				}
																				beforeUpload={(props) => {
																					this.beforeUpload(props);
																				}}
																				showUploadList={false}
																				action={
																					process.env.REACT_APP_UPLOAD_API +
																					"api/v1/" +
																					ApiControllers.common +
																					"UploadFileNew?screenName=Cases&fieldName=uploadfile&tableName=Common.Documents"
																				  }
																				headers={{ Authorization: `Bearer ${this.props?.user?.deviceToken}` }}
																			>
																				<span className="icon md attach mr-16 c-pointer" />
																			</Upload>{" "}
																		</Tooltip>
																	</div>
																</div>
																{this.state.isMessageError ===
																	doc.id.replace(/-/g, "") && (
																		<div style={{ color: "red" }}>
																			{docErrorMessage}
																		</div>
																	)}

																{this.state?.saveDocId===doc.id && uploadLoader && <div className="text-center mt-24">
																	<Spin />
																</div>}

																	<div className="docfile-container upload-filedoc">
																		{this.getUploadedFiles(doc.id)?.repositories?.map(
																			(file, idxx) => (
																				<Col xs={8} sm={8} md={8} lg={8} xxl={8} className="">
																				<div key={idxx} className="docfile mt-8">
																					<span
																						className={`icon xl ${(file.fileName?.slice(-3) === "zip"
																							? "file"
																							: "") ||
																							(file.fileName?.slice(-3) === "pdf"
																								? "file"
																								: "image")
																							} mr-16`}
																					/>
																					<div
																						className="docdetails c-pointer"
																						onClick={() => this.docPreviewOpen(file)}
																					>
																						<EllipsisMiddle suffixCount={6}>
																							{file.fileName}
																						</EllipsisMiddle>
																						<span className="fs-12 text-secondary">
																							{this.formatBytes(file?.fileSize)}
																						</span>
																					</div>
																					<span
																						onClick={() =>
																							this.deleteDocument(
																								this.getUploadedFiles(doc.id),
																								idxx,
																								true
																							)
																						}
																						className="icon md close c-pointer"
																					/>
																				</div>
																				</Col>
																			)
																		)}
																	</div>

															</div>
															
														</div>
														<div>
															<Form.Item className="text-center">
																<Button
																	htmlType="submit"
																	disabled={this.state?.isApproved}
																	className="primary-btn cancel-btn reject-btn"
																	style={{ margin: "0 8px" }}
																	loading={isrejected}
																>
																	Reject
																</Button>
																<Button
																	type="primary"
																	disabled={this.state?.isrejected||doc?.state === "Approved"}
																	className="primary-btn"
																	loading={isApproved}
																	onClick={() => this.docApprove(doc)}
																>
																	Approve
																</Button>

															</Form.Item>
															</div>
													</Form>

												</>
											)}
										{(!this.state?.documentReplies[doc.id]?.data ||
											this.state?.documentReplies[doc.id]?.data?.length ===
											0)&& !this.state.documentReplies[doc.id]?.loading && (
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description="No documents submitted"
												/>
											)}
									</Panel>
								</Collapse>
							))}
							{this.state.detailsItem.length === 0  && (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description="No documents available"
								/>
							)}
							
							 {this.state.previewModal && (
          <DocumentPreview
            previewModal={this.state.previewModal}
            handleCancle={this.docPreviewClose}
            upLoadResponse={this.state.docPreviewDetails}
          />
        )}
						</div>
						<Info Id={viewObj?.id} Status={viewObj?.status} Createdby={viewObj?.createdBy} CreatedDate={viewObj?.createdDate} ModifiedDate={viewObj?.modifiedDate} Modifiedby={viewObj?.modifiedBy}  screenName={"cases"} infoDetails={true} />
						<div className="text-right mt-24">
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								style={{ margin: "0 8px" }}
								onClick={this.backToCases}
							>
								Cancel
							</Button>
						</div>
					</>
	}
			</>
		);
	}
}

const connectStateToProps = ({ oidc, userConfig }) => {
	return {
		userConfig: userConfig.userProfileInfo, user: oidc
	};
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(CaseView);
