import React, { Component } from "react";
import List from "../grid.component";
import CreateCase from "../../utils/createCase";
import { Tooltip, Modal,Alert} from "antd";
import { connect } from "react-redux";
class PendingDeposit extends Component{
    constructor (props) {
        super(props);
        this.state = {
          check: false,
          selectedObj: {},
          selection: [],
          caseModal: false,
          selectedObjs:[],
          werningMsg:null
        };
        this.gridRef = React.createRef();
      }
  gridColumns = [
    {
        field: "",
        title: "",
        width: 50,
        locked: true,
        customCell: (props) => (
          <td>
            <label className="text-center custom-checkbox">
              <input
                id={props.dataItem.id}
                name="check"
                type="checkbox"
                checked={this.state.selection.indexOf(props.dataItem.id) > -1}
                onChange={(e) => this.checkChange(props, e)}
              />
              <span></span>{" "}
            </label>
          </td>
        )
      },
      { field: "date", title: "Date", filter: true ,width: 130,filterType:'date'},
      { field: "senderName", title: "Sender Name", filter: true ,width: 150},
      {field: "grossAmount", title: "Gross Amount",filter: true,width: 200,},
      { field: "currency", title: "Currency", filter: true ,width: 130,},
      { field: "bankName", title: "Bank Name", filter: true ,width: 130,},
      { field: "bankAccountNumber", title: "Bank Account Number", filter: true ,width: 230,},
      { field: "senderBankName", title: "Sender's Bank Name", filter: true ,width: 230,},
      { field: "senderBankAccountIBAN", title: "Sender's Bank Account/IBAN", filter: true ,width: 230,},
        { field: "reference", title: "Reference", filter: true,width: 150, },
      ];
      checkChange = (prop, e) => {
        const rowObj = prop.dataItem;
        const value =
          e.currentTarget.type === "checkbox"
            ? e.currentTarget.checked
            : e.currentTarget.value;
        const name = e.currentTarget.name;
        let { selection } = this.state;
        let idx = selection.indexOf(rowObj.id);
        if (selection) {
          selection = [];
        }
        if (idx > -1) {
          selection.splice(idx, 1);
        } else {
          selection.push(rowObj.id);
        }
        this.setState({
          ...this.state,
          [name]: value,
          selectedObj: rowObj,
          selection,
          warningMsg: null
        });
       
      }
      hideCaseModal = () => {
        this.setState({ ...this.state, caseModal: false, selection:[],});
        this.gridRef.current.refreshGrid()
        }
        onActionClick = (key) => {
          const action = {
            "Create Case": "createCase",
            "add":"Adddeposit",
          };
          this[action[key]]();
        };
        Adddeposit=()=>{

          this.props.history.push(`/deposit/${this.state.selectedObj?.id}/fiatDetails/edit/true`)

        }
        createCase = () => {
          const { selection } = this.state;
            if(!this.state.check || selection.length == 0){
            this.setState({ ...this.state,errorMessage:null,selection:[],selectedObjs:[],werningMsg:"Please select the one record",error: null, });
          }
           else if(selection.length >1){
            this.setState({ ...this.state,errorMessage:null,selection:[],selectedObjs:[],werningMsg:"Please select only one record",error: null });
          }
           else {
          this.setState({ ...this.state, caseModal: true,check: false });
          }
        }
      render(){
        const{werningMsg}=this.state;
        return(<>
         {werningMsg !== undefined && werningMsg !== null && (
          <Alert
            className="mb-16 w-100"
            type="warning"
            description={werningMsg}
            showIcon
          />
        )}
        
                <p className="badge-style">FIAT</p>
            <List
                  showActionBar={true}
                  pKey={"roles"}
                  url={process.env.REACT_APP_GRID_API + "Bank/GetPendingDepositsK"}
                  columns={this.gridColumns}
                  ref={this.gridRef}
                  onActionClick={(key) => this.onActionClick(key)}
                  showExcelExport={true}
                />
                 <Modal
          title="Create Case"
          visible={this.state.caseModal}
          width={1000}
          style={{top: 16}}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          destroyOnClose
          footer={null}
        >
         <CreateCase 
           FromScreen={"Pending Deposit"}
           ScreenId={this.state.selectedObj.id}
           CreateCaseId={this.state.selectedCaseId}
           ScreenName={"Pending Deposit"}
           closeModal={this.hideCaseModal} />
        </Modal>
            </>)
      }
   
}
export default connect((dispatch) => {
	return { dispatch };
})(PendingDeposit);