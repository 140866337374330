import React, { Component } from 'react';
import { Modal, Tooltip, Form, Button, Input, Col, Row,Alert } from 'antd';
import { connect } from "react-redux";
import {deleteCaseTemplate ,getCasesTemplate,saveCasesTemplate} from './api';
import { validateContentRule } from "../../utils/custom.validator";
import apiCalls from '../../api/apiCalls';
import Loader from "../loader.component";
import { success } from "../../utils/messages";

const { TextArea } = Input
class CaseTemplate extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
      btnLoader:false,
      deletebtn:false,
      gridUrl: process.env.REACT_APP_GRID_API + "/Case/CaseTemplates",
      getCaseTemplateObj:{},
      errorMsg:null,
      loading:false
      
    }
    this.formref = React.createRef();
    this.gridRef = React.createRef();
  }
  componentDidMount() {
    this.getCaseTemplate();
  }
  getCaseTemplate = async () => {
    this.setState({ ...this.state, errorMsg:null,loading:true });
    let response = await getCasesTemplate(this.props.selectedObj?.id ||"00000000-0000-0000-0000-000000000000" )
    if (response.ok) {
      this.setState({ ...this.state, getCaseTemplateObj: response.data,loading:false });
      this.formref.current?.setFieldsValue({remarks:response?.data?.remarks,templateName:response?.data?.templateName})
    }
    else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response),loading:false });
    }
  }
  saveCaseTemplate = async(value) => {
    this.setState({ ...this.state, open: false,  btnLoader:true,errorMsg:null })
    let obj = {
      "id": this.props.selectedObj?.id?this.props.selectedObj?.id:"00000000-0000-0000-0000-000000000000",
      "templateName": value.templateName,
      "remarks": value.remarks,
      "createdBy": this.props.userConfig.userName,
      "createddate": new Date(),
      "modifiedBy": this.props.userConfig.userName,
      "modifiedDate": new Date(),
      "info" : JSON.stringify(this.props.trackAuditLogData),
    }
    let res=await saveCasesTemplate(obj);
    if(res.ok){
      this.setState({ ...this.state, open: false,  btnLoader:false,errorMsg:null })
      success("Template details saved successfully");
      this.props.handleModelSaveClose();
      this.gridRef.current?.refreshGrid();
    }else{
      this.setState({ ...this.state, open: false,  btnLoader:false,errorMsg: apiCalls.isErrorDispaly(res), })
    }
  }
  deleteCaseTemplate = async () => {
    this.setState({ ...this.state,  deletebtn:true, })
    let res = await deleteCaseTemplate(this.props.selectedObj?.id);
    if (res.ok) {
      this.setState({ ...this.state,  deletebtn:false,errorMsg:null })
      this.props.handleModelSaveClose();
      this.gridRef.current?.refreshGrid();
      success("Template deleted successfully");
    }
    else{
      this.setState({ ...this.state,  deletebtn:false, errorMsg: apiCalls.isErrorDispaly(res) })
    }
  }
  handleCancel = () => {
    this.props.handleModelClose();
    this.setState({ ...this.state,  errorMsg:null, })
    this.gridRef.current?.refreshGrid();
  }
  render() {
    const {btnLoader}=this.state;
    const {selectedObj}=this.props;
    return (
      <>
        <Modal
          title={`${selectedObj.id?"Edit Template":"Add Template"}`}
          visible={this.props.addModalshow}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer risk-close"
                onClick={this.handleCancel}
              />
            </Tooltip>
          }
          footer={null}
        >
          {this.state.errorMsg !== null && <div style={{ width: '100%' }}>
      <Alert className="mb-16 w-100" type="error" description={this.state.errorMsg} showIcon/></div> }
      {this.state.loading && <Loader />}
          <Form
          initialValues={this.state.getCaseTemplateObj}
            ref={this.formref}
            className="risk-popup"
            onFinish={this.saveCaseTemplate}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <Form.Item
                  name="templateName"
                  label="Template Name"
                  className="input-label note-label"
                  rules={[
                    {
                      required: true,
                      message: 'Is required',
                    }, {
                      whitespace: true,
                      message: 'Is required',
                    },
                    {
                      validator: validateContentRule,
                    }
                  ]}
                >
                  <Input placeholder="Template Name" className="cust-input" maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Form.Item
                    className="input-label note-label"
                    name="remarks"
                    label="Remarks"
                    rules={[
                      {
                        required: true,
                        message: 'Is required',
                      }, {
                        whitespace: true,
                        message: 'Is required',
                      },
                      {
                        validator: validateContentRule,
                      }
                    ]}
                  >
                    <TextArea
                      placeholder="Remarks"
                      maxLength={500}
                      showCount={200}
                      rows={4}>
                    </TextArea>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item className="mb-0">
              <div className="text-right">
                <Button
                  type="primary"
                  className="primary-btn mr-8"
                  htmlType="submit"
                  loading={btnLoader}
                >
                  Save{" "}
                </Button>
                <Button
                  type="primary"
                  className="primary-btn cancel-btn "
                  onClick={this.handleCancel}
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
             visible={this.props.deleteCaseTempleteModal}
             title={"Confirm Delete"}
             closeIcon={
               <Tooltip title="Close">
                 <span
                   className="icon md x c-pointer risk-close"
                   onClick={this.props.handleCaseTemplateModelDeleteClose}
                 />
               </Tooltip>
             }
             footer={
             <Form.Item className="mb-0">
                  <div className="text-right">
                    <Button
                      type="primary"
                      className="primary-btn cancel-btn mr-8"
                      onClick={this.props.handleCaseTemplateModelDeleteClose}
                    >
                      {" "}
                      No{" "}
                    </Button>
                    <Button
                      type="primary"
                      className="primary-btn"
                      htmlType="submit"
                      onClick={()=>this.deleteCaseTemplate()}
                      loading={this.state.deletebtn}
                    >
                      Yes{" "}
                    </Button>
                  </div>
                </Form.Item>}>
                {this.state.errorMsg !== null && <div style={{ width: '100%' }}>
      <Alert className="mb-16 w-100" type="error" description={this.state.errorMsg} showIcon/></div> }
     
                <p className="mb-0"> Are you sure, do you really want to delete ?</p>
            </Modal>
      </>
    )
  }
}
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData,
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(CaseTemplate);

