import { Spin, Tooltip, Dropdown, Menu } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { getPermissions } from '../../reducers/permissionsReducer';
import { actionSubject, onExcellExport, publish } from '../grid.component/subscribir';
import { saveGridLayoutTrue, resetGridLayoutTrue } from '../../reducers/gridLayoutReducer';
class ActionsToolbar extends Component {
    actionBarlistener;
    state = {
        showToolBar: true
    }
    componentDidMount() {
        this.actionBarlistener = actionSubject.subscribe(data => {
            this.setState({ showToolBar: data })
        });
    }
    componentWillUnmount() {
        this.actionBarlistener.unsubscribe();
    }
    getIconCss = (item) => {
        if (item.key === 'disable') {
            return "status"
        }
        else if (item.key.indexOf('/') > -1) {
            return item.key.replace("/", "_")
        } else {
            return item.key;
        }

    }
   
    userProfileMenu = ()=>{
        const actionList = this.props.permissions?.[(this.props?.permissions?.currentScreenTabId)||(this.props?.permissions?.currentScreenId)]?.filter((item)=>(item.permissionName !== "view" && (item.values)));
        if(actionList?.length>2){
        return <Menu>
            <ul className={`admin-actions-mb mb-0 pl-0`}>
                {actionList?.map((item, indx) => {
                        return <>{indx > 2 && <li className='action-title-mb c-pointer' key={indx} onClick={() => {
                            this.props?.setAction(item.permissionName);
                            if (item.permissionName === "Export Excel") {
                                onExcellExport()
                            } else if(item.permissionName === "Save Layout"){
                                this.props?.saveGridLayoutTrue()
                            } else if(item.permissionName === "Reset Layout"){
                                this.props?.resetGridLayoutTrue()
                            } else {
                                publish(item.permissionName);
                            }
                        }}>
                            <Tooltip placement="bottom" title={item.toolTip}>
                                <span className={`icon c-pointer md ${item.icon} mr-8`} ></span>
                            </Tooltip>
                            {item.permissionName == "Risk Screen" && "Screening"} 
                            {item.permissionName !== "Risk Screen" && item.permissionName} 

                            
                            </li>}
                        </>
                })}
            </ul>
        </Menu>
    }
}
    getDropdown = () =>{
        const actionList = this.props.permissions?.[(this.props?.permissions?.currentScreenTabId)||(this.props?.permissions?.currentScreenId)]?.filter((item)=>(item.permissionName !== "view" && (item.values)))
        if(actionList?.length>0){
            return <>
            {actionList.map((action,index)=><>
                {index < 3 && <li key={index}>
                    <Tooltip placement="bottom" title={action.toolTip}>
                        <span className={`icon c-pointer md ${action.icon} mr-0`} onClick={() => {
                            this.props?.setAction(action.permissionName);
                            if (action.permissionName === "Export Excel") {
                                onExcellExport()
                            } else if(action.permissionName === "Save Layout"){
                                this.props?.saveGridLayoutTrue()
                            } else if(action.permissionName === "Reset Layout"){
                                this.props?.resetGridLayoutTrue()
                            } else {
                                publish(action.permissionName);
                            }
                        }}></span>
                    </Tooltip>
                </li>}
                {index ===3 && <Dropdown overlay={this.userProfileMenu} trigger={['click']} placement="bottomCenter" arrow overlayClassName=""
                    getPopupContainer={() => document.getElementById('area')}>
                    <p className={`icon c-pointer md moretools mr-0 mb-0 `}></p>
                </Dropdown>
                }
            </>)}
            </>
        }else{
            return <></>
        }
    }
    render() {
        if (!this.state.showToolBar) {
            return null
        }
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                </div>
                {(this.props.permissions?.loading && window.location?.pathname !== "/accessdenied") && <Spin size="default" className="admin-actions mb-0"></Spin>}
                {!this.props.permissions?.loading && <ul className={`admin-actions mb-0 `}>

                    {this.getDropdown()}

                </ul>}

            </div>
        );
    }
}
const mapStateToProps = ({ permissions, oidc, userConfig }) => {
    return { permissions, oidc, userConfig: userConfig.userProfileInfo }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchPermissions: (permission_key, customerId) => {
            if (permission_key) {
                dispatch(getPermissions({ customerId, perKey: permission_key }))
            }
        },
        setAction: (actn) => {
            dispatch(setCurrentAction(actn))
        },
        saveGridLayoutTrue: () =>{ dispatch(saveGridLayoutTrue()) },
        resetGridLayoutTrue: () =>{ dispatch(resetGridLayoutTrue()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionsToolbar);
