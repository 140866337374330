import React,{Component} from"react";
import List from "../grid.component";
class CustomerAddressBook extends Component{
    constructor(props){
        super(props);
        this.state={
            gridUrl: process.env.REACT_APP_GRID_API + "Address",
  }
    }
    componentDidMount(){
		if(process.env.REACT_APP_ISDOCUSIGN=="true"){
			const obj=[ {
                field: "declarationForm",
                title: "Declaration Form",
                filter: true,
                width: 200,
            }]
			this.gridColumns.splice(5,0,...obj)
        }
	}
    gridColumns = [
        {
            field: "whitelistName",
            title: "Whitelist Name",
            filter: true,
            width: 250,
        }, {
            field: "currencyType",
            title: "Type",
            filter: true,
            width: 100,
        },
        {
            field: "asset",
            title: "Asset",
            filter: "true",
            width: 200,
        },
        {
            field: "bankAccount",
            title: "Bank Account/IBAN/Wallet Address ",
            filter: true,
            width: 300,
        },
        {
            field: "status",
            title: "Whitelisting Status",
            filter: true,
            width: 200,
        },
        {
            field: "reasonForRejection",
            title: "Reason For Rejection",
            filter: true,
            width: 300,
        },
    ];
    render(){
        return(
            <>
			&emsp;&emsp;
            <List
                  ignoreSaved={true}
                  style={{ margin: "-22px 0 0" }}
                  url={this.state.gridUrl}
                  additionalParams={{ customerid: this.props.gridID }}
                  columns={this.gridColumns}
                  className="customerAddress-grid"
                />
	            </>
        )
    }
}
export default CustomerAddressBook;