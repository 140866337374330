import React, { useEffect, useState } from 'react';
import { Form, Alert } from 'antd'
import { connect } from 'react-redux';
import apicalls from "../../api/apiCalls";
import { getAllCustomerBackupAddress } from './api';
import Loader from "../loader.component";
const BackUpAddress = (props) => {
    const [form] = Form.useForm();
    const useDivRef = React.useRef(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const cryptoObj = [{ code: "BTC", network: "BTC" }, { code: "ETH/USDT/USDC", network: "ERC-20" }];
    const fiatData = [{ code: "USD" }, { code: "EUR" }, { code: "GBP" }, { code: "CHF" }];
    const [backupAllAddresses, setBackupAllAddresses] = useState([]);
    useEffect(() => {
        getBackupAllAddresses(props?.id);
    }, []); // eslint-disable-next-line react-hooks/exhaustive-deps
    const getBackupAllAddresses = async (id) => {
        setIsLoading(true);
        let res = await getAllCustomerBackupAddress(id);
        if (res.ok) {
            setIsLoading(false);
            setErrorMsg(null);
            setBackupAllAddresses(res.data);
        } else {
            setIsLoading(false);
            useDivRef.current.scrollIntoView(0, 0)
            setErrorMsg(apicalls.isErrorDispaly(res))
        }
    }
    const defaultCryptoValue = (network) => {
        if (backupAllAddresses?.length !== 0 || backupAllAddresses !== null) {
            const data = backupAllAddresses?.filter(item => item?.network === network);
            if (data[0]?.network) {
                return <><td>{data[0]?.whitelistName}</td> <td>{data[0]?.walletAddress}</td></>
            } else {
                return <><td>{"-"}</td><td> {"-"}</td></>
            }
        }
    }
    const defaultFiatValue = (code) => {
        if (backupAllAddresses?.length !== 0 || backupAllAddresses !== null) {
            const data = backupAllAddresses?.filter(item => item?.walletCode === code);
            if (data[0]?.walletCode) {
                return <><td>{data[0]?.whitelistName}</td><td> {data[0]?.accountNumber}</td></>
            } else {
                return <><td>{"-"}</td><td> {"-"}</td></>
            }
        }
    }

    return (<>
        <div ref={useDivRef}></div>
        {errorMsg !== null && (
            <Alert
                className="mb-12"
                type="error"
                description={errorMsg}
                showIcon
            />
        )}
        {isLoading && <Loader />}
        {!isLoading && <Form layout="vertical" form={form}>
            <div className="basicprofile-info">
                <div className="responsive_table db-ts-grid">
                    <p className="crypto-text-mb fw-800 fs-18">Crypto Addresses</p>
                    <table className='view mb-view cust-cryptofiat-view'>
                        <thead>
                            <tr>
                                <th style={{width: "18%"}}>Coin</th>
                                <th style={{width: "39%"}}>Whitelist Name</th>
                                <th style={{}}>Wallet Address</th>
                            </tr>
                        </thead> 
                            <tbody>
                                {cryptoObj?.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <div className='ts-tbdate'>
                                                    <span className='ts-date'>{item?.code}</span></div>
                                            </td>
                                            {defaultCryptoValue(item.network)}
                                        </tr>
                                    )
                                })}
                            </tbody>
                    </table>
                </div>
                <div className="responsive_table db-ts-grid">
                    <p className="crypto-text-mb fw-800 fs-18">Fiat Addresses</p>
                    <table className='view mb-view cust-cryptofiat-view fiat-col-width'>
                        <thead>
                            <tr>
                                <th style={{width: "18%"}}>Currency</th>
                                <th style={{width: "39%"}}>Whitelist Name</th>
                                <th style={{}}>Bank Account Number/IBAN</th>
                            </tr>
                        </thead>
                        
                            <tbody>
                                {fiatData?.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <div className='ts-tbdate'>
                                                    <span className='ts-date'>{item?.code}</span></div>
                                            </td>
                                            {defaultFiatValue(item.code)}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        
                    </table>
                </div>
            </div>
        </Form>}
    </>)
}
const connectStateToProps = ({ userConfig }) => {
    return { customer: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData }
}

export default connect(connectStateToProps)(BackUpAddress);