import { clientApi } from "../../api"
import { ApiControllers } from "../../api/config"
const crypto = require('crypto');
const uuidv4 = () => {
    let randumnumberval
    let buf = crypto.randomBytes(16)
    randumnumberval = buf.toString('hex')
    return (randumnumberval.substr(0, 8) + "-" + randumnumberval.substr(8, 4) + "-" + randumnumberval.substr(12, 4) + "-" + randumnumberval.substr(16, 4) + "-" + randumnumberval.substr(20, 12))
}

const getDocList = () => {
    return clientApi.get(ApiControllers.documents + `Request/Documents`);
}
const sendRequest = (obj) => {
    return clientApi.post('/Documents', obj);
}
const getDocDetails = (id) => {
    return clientApi.get(ApiControllers.documents + `${id}`)
}
const getDocumentReplies = (id) => {
    return clientApi.get(ApiControllers.documents + `Accounts/${id}/Messages`)
}
const saveDocReply = (obj) => {
    return clientApi.post(ApiControllers.documents + `Messages`, obj)
}
const saveDocNewReply = (obj) => {
    return clientApi.post(ApiControllers.documents + `Admin/Accounts`, obj)
}
const approveDoc = (obj) => {
    return clientApi.post(ApiControllers.documents + `Admin/Accounts`, obj)
}
const getFileURL = (obj) => {
    return clientApi.post(ApiControllers.customers + `FetchFile`, obj)
}

export { sendRequest, getDocList, uuidv4, getDocDetails, getDocumentReplies, saveDocReply, approveDoc, getFileURL,saveDocNewReply }