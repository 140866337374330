import React, { Component } from 'react';
import { connect } from "react-redux";
import { publishShowActions } from "../grid.component/subscribir";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {Tabs } from 'antd';
const { TabPane } = Tabs;
class Topups extends Component {
    constructor(props) {

        super(props);
        this.state = {
        }
    }
componentDidMount=()=>{
    publishShowActions(false);
}
    render() {
       const products =[{
        "employee" : "Jhon",
        "topup" : "100",
        "date" : "28-03-2022",
        "currency" : "USD",
        "status" : "Pending",
    },
    {
        "employee" : "Smith joy",
        "topup" : "100",
        "date" : "28-03-2022",
        "currency" : "USD",
        "status" : "Completed",
    },
    {
        "employee" : "verruu",
        "topup" : "100",
        "date" : "25-03-2022",
        "currency" : "USD",
        "status" : "Declined",
    },
    {
        "employee" : "elon",
        "topup" : "100",
        "date" : "23-03-2022",
        "currency" : "USD",
        "status" : "Completed",
    }]
        return (
            <>
            <div>
            <Tabs defaultActiveKey={1} className='card-list mt-24'>
                        <TabPane tab={"Employee"} key="1" >
                        
                        </TabPane>
                    </Tabs>
                <div className="text-right">
							<button
								className="primary-btn px-23 custom-btn prime mb-8"
								type="button">
								Approved
							</button>
						</div>
            </div>
                <Grid
                    style={{
                        height: "400px",
                    }}
                    data={products}
                >
                    <GridColumn field="employee" title="Employee" />
                    <GridColumn field="topup" title="Topup" />
                    <GridColumn field="date" title="Date" />
                    <GridColumn field="currency" title="Currency" />
                    <GridColumn field="status" title="Status" />
                </Grid>
            </>
        )
    }
}

const connectStateToProps = ({ oidc, userConfig }) => {
    return { userConfig: userConfig.userProfileInfo, oidc, trackAuditLogData: userConfig.trackAuditLogData };
};
export default connect(connectStateToProps, (dispatch) => {return { dispatch };})(Topups);