import React, { Component } from 'react';
import { Row, Col, Typography, Alert, Form, Tabs, Spin, Button, Modal, Tooltip, Select, Input, message } from "antd";
import Loader from "../loader.component";
import defaultUser from "../../assets/images/defaultuser.jpg";
import List from "../grid.component";
import Moment from "react-moment";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import { handleMembers } from "../../reducers/customerReducer";
import { getBankCustomer, showBalance, evolveSaveStatusChange, pyrrosSaveStatusChange, oPenPaydStatus, saveOpenpydStatus, GridColorsType } from './api';
import NumberFormat from 'react-number-format';
import BankInfo from '../info/BankInfo';
import { Link } from 'react-router-dom';
import { publishShowActions } from "../grid.component/subscribir";
import config from '../../config';
import { validateContentRule } from '../../utils/custom.validator';
const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

class BankCustomerDetailView extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeTab: "1",
      customerdata: {},
      errorMsg: null,
      error: null,
      TransactionGridUrl: "",
      loader: false,
      isRealBalanceShow: true,
      realBalance: "",
      realBalanceBtnLoader: false,
      gridUrl: process.env.REACT_APP_GRID_API + `bank/OpenpaydCustomerWallets`,
      rowBalances: {},
      rowLoaders: {},
      isModal: false,
      stateErrorMsg: null,
      stateChange: {},
      stateLoading: false,
      stateBtnLoading: false,
      stateLu: [],
      selectedState: null,
      isLoading: false,
      selection: [],
      selectedObj: {},
      isAccountbtn: false,
      selectedAction: null,
      isAccountError: null,
      isAccountOpenModal: false,
      selectedkey: null,
      colorsCode: [],
      infoModal:false,

    }
    this.gridRef = React.createRef();
    this.useDivRef = React.createRef()
    this.formRef = React.createRef();
    this.form = React.createRef();

  }
  gridColumns = [
    {
      field: "date",
      title: "Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: true,
      width: 250,
      customCell: (props) => (
        <>
          <Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem?.date ?
            apiCalls.convertUTCToLocalTime(props.dataItem?.date) : props.dataItem?.date}</Moment>
        </>
      ),
    },
    { field: "transaction", title: "Transaction Type", filter: true, width: 220 },
    {
      field: "currency",
      title: "Currency",
      filter: true,
      width: 120,
      customCell: (props) => {
        const currencyColorConfig = this.state?.colorsCode?.find(colorConfig => colorConfig.currency === props.dataItem.currency);
        const currencyColor = currencyColorConfig?.color.toLowerCase() || 'black';
        const textColor = (currencyColor === 'blue' || currencyColor === 'darkgreen' || currencyColor === 'green') ? 'white' : 'black';

        return (
          <td className="whitelist-name">
            <div className={`white-address ibancolor ${currencyColor}`} style={{ color: textColor }}>
              {props.dataItem.currency}
            </div>
          </td>
        );
      },

    },

    {
      field: "grossAmount",
      title: "Gross Amount",
      width: 150,
      filter: true,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "commissions",
      title: "Commission",
      width: 150,
      filter: true,
      dataType: "number",
      filterType: "numeric",
    },
    { field: "providerCommission", title: "Provider Commission", filter: true, width: 220, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComission", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationComission", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    {
      field: "netAmount",
      title: "Net Amount",
      width: 200,
      filter: true,
      dataType: "number",
      filterType: "numeric",
    },
    { field: "profit", title: "Profit", filter: true, width: 120, dataType: "number", filterType: "numeric" },
    { field: "status", title: "Status", filter: true, width: 160, },
  ];

  gridWallets = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <td>
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state?.selection?.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
            />
            <span></span>
          </label>
        </td>
      )
    },
    {
      field: "",
      title: "",
      width: 220,
      customCell: (props) => (
        <td>
          <img
            src={props.dataItem?.impagePath}
            alt="coins"
            width="36"
            height="36"
            style={{ marginRight: 16 }}
          />
          {props.dataItem?.currency}
        </td>
      )
    },
    { field: "iban", title: "IBAN Number", filter: true, width: 240 },
    { field: "bankBalance", title: "Available Balance", filter: true, width: 170, dataType: "number", filterType: "numeric" },
    {
      field: "realBalance",
      title: "Real Balance",
      filter: true,
      width: 250,
      dataType: "number", filterType: "numeric",
      customCell: (props) => {
        const isLoading = this.state.rowLoaders[props.dataItem.id] || false;
        const realBalance = this.state.rowBalances[props.dataItem.id];
        const currency = props?.dataItem.currency

        return (
          <td>
            {(realBalance === null || realBalance === undefined) && !isLoading ? (
              <div>
                <Link className="kpi-val" type="primary" onClick={() => this.showRealBalance(props.dataItem.id)}>
                  Click here to see balance
                </Link>
              </div>
            ) : (
              <div className='d-flex'>
                {isLoading && <Spin spinning={true} className='mb-8' ></Spin>}
                {!isLoading && (
                  <p className="kpi-val fw-700 mb-0 mb-8">
                    {currency === 'EUR' ? "€" : '£'}
                    {realBalance !== undefined && realBalance !== null ? (
                      <NumberFormat value={realBalance} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                    ) : (
                      0
                    )}
                  </p>

                )}
                {!isLoading && <Tooltip title='Refresh'>
                  <span className='icon md refresh ml-8 c-pointer' onClick={() => this.showRealBalance(props.dataItem.id)}></span>
                </Tooltip>}
              </div>
            )}
          </td>
        );
      }
    },

    {
      field: "bic",
      title: "BIC/SWIFT/ABA Routing/UK Sort Code",
      filter: true,
      width: 320,
    },
    {
      field: "bankReferenceNumber",
      title: "Bank Reference Number",
      filter: true,
      width: 350,
    },
    {
      field: "accountStatus",
      title: "Bank Status",
      filter: true,
      width: 180,

    },
    {
      field: "status",
      title: "Status",
      filter: true,
      width: 200,

    },
    {
      field: "reasonForRejection",
      title: "Reason For Rejection",
      filter: true,
      width: 200,

    }
  ];

  cryptoColumns = [
    {
      field: "createdBy", title: "Name", width: 250,filter:false
    },
    {
      field: "currency", title: "Currency", width: 250,filter:false
    },
    { field: "status", title: "State", width: 250,filter:false },

    {
      field: "createdDate",
      title: "Date",
      width: 250,
      isShowTime: true,
      filter:false,
      customCell: (props) => (
        <td>
          <div>
            <Moment format={config?.dateFormates?.dateTimeFormate} >{props.dataItem.createdDate ? apiCalls.convertUTCToLocalTime(props.dataItem.createdDate) : props.dataItem.createdDate}</Moment>
          </div>
        </td>
      ),
    },

    { field: "remarks", title: "Remarks", width: 180,filter:false },

  ];
  handleInputChange = (event, i) => {
    const rowChecked = event.dataItem;
    const target = i.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection: selection,
      errorMsg: null,
      stateErrorMsg: null,
    });
  };

  statusChange = () => {
    if (this.state.selection?.length <= 0) {
      this.setState({
        ...this.state,
        errorMsg: "Please select the one record",
      });
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            alert: false,
          }),
        1000
      );
    }
    else if (this.state?.selectedObj?.id === null || this.state?.selectedObj?.status === null) {
      this.setState({
        errorMsg: "Bank account not created",
        selection:[],
        selectedObj:[],
        isAccountOpenModal:false,
        isModal:false

      });
      return;
    }
     else {
      this.getStatesLu();
      this.setState(
        {
          ...this.state,
          isModal: true,
          stateChange: {
            status: this.state?.selectedObj?.status,
            ReasonofCancellation: this.state?.selectedObj?.status === "Approved" ? this.state?.selectedObj?.remarks : this.state.selectedObj?.reasonForRejection,
          }
        },
        () => {
          this.setState({
            ...this.state,
            stateLoading: true,
          });
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                stateLoading: false,
              }),
            1000
          );

          setTimeout(
            () =>
              this.formref?.current?.setFieldsValue({
                ...this.state,
                status: this.state.selectedObj?.status,
                ReasonofCancellation: this.state.selectedObj?.status === "Approved" ? this.state.selectedObj?.remarks : this.state.selectedObj?.reasonForRejection,
              }),
            1000
          );
        }
      );
    }

  };

  stateChange = (val, prop) => {
    let { stateChange } = this.state;
    stateChange.status = val;
    this.setState({ ...this.state, stateChange, selectedState: val, stateErrorMsg: null });
    this.formref?.current?.setFieldsValue({
      ...this.state,
      ReasonofCancellation: null,
      Remarks: null
    })
  };

  handleCancel = (e) => {
    this.setState({
      ...this.state,
      isModal: false,
      selection: [],
      stateErrorMsg: null,
      errorMsg: null,
      selectedState: null
    });
  };

  getStatesLu = async () => {
    let res = await apiCalls.getStates("customer", this.state.selectedObj?.status)
    if (res.ok) {
      this.setState({ ...this.state, stateLu: res.data })
    } else {
      this.setState({ ...this.state, stateErrorMsg: apiCalls.isErrorDispaly(res) });
    }
  }

  renderAlertMsgData = (errorMsgData) => {
    return errorMsgData !== undefined && errorMsgData !== null && (
      <div style={{ width: '100%' }}>
        <Alert
          className="w-100 mb-16 align-center"
          type="warning"
          description={errorMsgData}
          showIcon
        />
      </div>
    )
  }

  isField1Disabled(disabledStatuses) {
    const { selectedObj } = this.state;
    return disabledStatuses.includes(selectedObj?.status?.toLowerCase());
  }

  customerStateChange = async (values) => {
    let stateObj = { customerId: "", id: "", state: "", ModifiedBy: '' }
    this.setState({ ...this.state, stateBtnLoading: true });
    stateObj.id = this.state.selectedObj.id;
    stateObj.customerId = this.state.selectedObj.customerId;
    stateObj.state = values.status || this.state.selectedObj.status;
    stateObj.ModifiedBy = this.props?.userConfig.userName;
    if (values?.status === "Rejected") {
      stateObj.ReasonofCancellation = values?.reasonForRejection || null
    }
    else if (values?.status === "Approved") {
      stateObj.remarks = this.formref?.current?.getFieldValue("Remarks");
      stateObj.ReasonofCancellation = ""
    }
    let response;
    if (this.props?.location?.pathname === '/evolvecustomer') {
      response = await evolveSaveStatusChange(stateObj);
    } else if (this.props?.location?.pathname === '/pyrroscustomer') {
      response = await pyrrosSaveStatusChange(stateObj)
    } else {
      response = await oPenPaydStatus(stateObj)
    }
    this.renderSuccessData(response, stateObj);
  };

  renderSuccessData = (response, stateObj) => {
    if (response.ok) {
      this.success(stateObj.state);
      this.gridRef?.current?.refreshGrid();
      this.setState({ ...this.state, stateBtnLoading: false, stateErrorMsg: null, selection: [], isModal: false, selectedState: null, isAccountOpenModal: false });
    } else {
      this.setState({ ...this.state, stateBtnLoading: false, stateErrorMsg: apiCalls.isErrorDispaly(response) });
    }
  }
  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Reject":
        status = "Rejected";
        break;
      case "Rejected":
        status = "Rejected";
        break;
      case "Pending":
        status = "Pending";
        break;
      default: return null;
    }
    message.success({
      content:
        "Record " +
        status +
        " successfully",
      className: "custom-msg",
      duration: 4
    });
  };
  isAccountModalOpen = (key) => {
    if (this.state.selection.length <= 0) {
      this.setState({
        ...this.state,
        errorMsg: "Please select the one record",
      });
    }else if (this.state?.selectedObj?.id === null || this.state?.selectedObj?.status === null) {
      this.setState({
        errorMsg: "Bank account not created",
        selection:[],
        selectedObj:[],
        isAccountOpenModal:false,
        isModal:false

      });
      return;
    }
     else if (this.state?.selectedObj?.status !== "Approved" && this.state?.selectedObj?.accountStatus !== "Freezed") {
      this.setState({
        ...this.state, selection: [],
        errorMsg: "Only approved accounts can freeze/close",
      });

      window.scrollTo(0, 0)
    }
    else if (key === 'wallets') {
      this.setState({
        ...this.state, selection: [],
        screenName: 'wallets'
      })
      window.scrollTo(0, 0)
    }
    else {
      let action;
      if (key?.toLowerCase() === "close") {
        action = "Close";
      } else if (this.state.selectedObj?.accountStatus === "Freezed") {
        action = "Unfreeze";
      } else {
        action = "Freeze";
      }
      this.setState(prevState => ({ ...prevState, selectedAction: action, selectedkey: key, isAccountOpenModal: true, isAccountError: null }));
    }
  }
  handleCloseAccModal = () => {
    this.setState({ ...this.state, isAccountOpenModal: false, selection: [], selectedAction: null });
  }
  handleSaveAccount = async () => {
    this.setState({ ...this.state, isAccountbtn: true });
    let obj;
    if (this.state?.selectedkey == "freeze") {
      obj = {
        id: this.state.selectedObj?.id,
        state: this.state.selectedObj.status !== "Approved" ? "UNFREEZE" : "FREEZE",
        ModifiedBy: this.props?.userConfig.userName,
      }
    }
    else {
      obj = {
        id: this.state.selectedObj?.id,
        state: "CLOSE",
        ModifiedBy: this.props?.userConfig.userName,
      }
    }

    let res = await saveOpenpydStatus(obj);
    if (res.ok) {
      message.success({
        content: `Account ${this.state.selectedAction}d successfully`,
        className: "custom-msg",
        duration: 3
      });
      this.setState({ ...this.state, isAccountOpenModal: false, isAccountbtn: false, selection: [] })
      setTimeout(() => {
        this.gridRef?.current?.refreshGrid()
      }, 2000)
    } else {
      this.setState({ ...this.state, isAccountError: apiCalls.isErrorDispaly(res), isAccountbtn: false });
    }
  }
  changeTab = activeKey => {
    if (activeKey === "1") {
      this.loadCustomers()
    }
    else if (activeKey === "2") {
      let bankTypes = this.props?.location?.pathname?.split('/');
      let Url;
      if (bankTypes[1] === 'pyrroscustomer') {
        Url = process.env.REACT_APP_GRID_API + `Bank/PyrrosTransaction`;
      } else if (bankTypes[1] === 'openpaydcustomer') {
        Url = process.env.REACT_APP_GRID_API + "Bank/OpenpaydTransaction";
      } else {
        Url = process.env.REACT_APP_GRID_API + `Bank/EvolveTransaction`;
      }
      this.setState({ ...this.state, TransactionGridUrl: Url })
    }
    this.setState({
      activeTab: activeKey,
      errorMsg: null, realBalanceBtnLoader: false, rowBalances: {}, selection: []
    });
    this.gridRef?.current?.refreshGrid()

  };
  loadCustomers = async () => {
    let bankTypes = this.props?.location?.pathname?.split('/');
    let bankType;
    if (bankTypes[1] === 'pyrroscustomer') {
      bankType = "CreatePyrrosCustomer";
    } else if (bankTypes[1] === 'openpaydcustomer') {
      bankType = "CreateOpenpaydCustomer";
    } else {
      bankType = "CreateEvolveCustomer";
    }
    let selectedId = this.props.match.params.id;
    this.setState({ ...this.state, loader: true });
    let response = await getBankCustomer(bankType, selectedId);
    if (response.ok) {
      this.setState({
        ...this.state, customerdata: response.data, loader: false, errorMsg: null,
      });
      this.form?.current?.setFieldsValue({ ...response.data });
    }
    else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response), loader: false });
    }
  };
  DivRef = React.createRef();
  componentDidMount = () => {
    publishShowActions(false);
    this.useDivRef?.current?.scrollIntoView();
    this.loadCustomers();
    this.gridColorTypes();
  };
  showRealBalance = async (id) => {
    this.setState((prevState) => ({
      rowLoaders: {
        ...prevState.rowLoaders,
        [id]: true,
      },
      errorMsg: null,
    }));
    if (id === null) {
      this.setState({
        errorMsg: "Bank account not created",
        rowBalances:'',
        selection:[],
        selectedObj:[],
        rowLoaders:false

      });
      return;
    }
    let response = await showBalance(id);
    if (response.ok) {
      const balance = response.data !== null ? response.data : 0;
      this.setState((prevState) => ({
        rowBalances: {
          ...prevState.rowBalances,
          [id]: balance,
        },
        rowLoaders: {
          ...prevState.rowLoaders,
          [id]: false,
        },
        errorMsg: null,
      }));
    } else {
      this.setState((prevState) => ({
        rowLoaders: {
          ...prevState.rowLoaders,
          [id]: false,
        },
        rowBalances: {
          ...prevState.rowBalances,
          [id]: 0,
        },
        errorMsg: apiCalls.isErrorDispaly(response),
      }));
      window.scrollTo(0, 0);
    }
  };




  getAlertError = () => {
    const { errorMsg } = this.state;
    return errorMsg !== null && errorMsg !== undefined && (
      <Alert
        className="w-100 mb-16 align-center"
        type="error"
        description={errorMsg}
        showIcon
      />
    )
  }
  getCustomerDetailsData = (lable, value) => (
    <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item">
      <label className="kpi-label">
        {lable}
      </label>
      <p className="kpi-val fw-700 mb-0">
        {value || "-"}
      </p>
    </Col>
  )
  getCustomerBalanceData = (balancelable, balanceValue) => (
    <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
      <label className="kpi-label">{balancelable}</label>
      <p className=" kpi-val fw-700 mb-0">€
        {balanceValue ?
          (<NumberFormat value={balanceValue}
            decimalSeparator="." displayType={'text'} thousandSeparator={true} />) : ("0")}
      </p>
    </Col>
  )
  getCustomedata = () => {
    const { customerdata } = this.state;
    return (<>
      {customerdata?.accountType === "Personal" && (
        <>
          {this.getCustomerDetailsData("First Name", customerdata?.firstName)}
          {this.getCustomerDetailsData("Last Name", customerdata?.lastName)}
        </>
      )}
      {customerdata?.accountType === "Business" && (
        <>
          {this.getCustomerDetailsData("Business Name", customerdata?.businessName)}
        </>
      )}
      {this.getCustomerDetailsData(customerdata?.accountType === "Personal" ? "Country of Residence" : "Country of Business", customerdata?.accountType === "Personal" && (customerdata?.countryofResidence || "-") || (customerdata?.countryOfBusiness || "-"))}
      {this.getCustomerDetailsData("Pending Action", customerdata?.pendingActions)}
      {customerdata.status === "Rejected" && this.getCustomerDetailsData("ReasonofCancellation", customerdata?.reasonForRejection)}
      {this.getCustomerDetailsData("Account Type", customerdata?.accountType)}
    </>)
  }
  getCustomerDetails = () => {
    const { customerdata } = this.state;
    return (<>
      {this.props?.location?.pathname == `/evolvecustomer/details/${this.props.match.params.id}/view` && (
        this.getCustomerDetailsData("Account Number", customerdata?.accountNumber))}
      {this.props?.location?.pathname == `/evolvecustomer/details/${this.props.match.params.id}/view` && (
        this.getCustomerDetailsData("Routing Number", customerdata?.routingNumber))}
     
    </>)
  }

  gridColorTypes = async () => {
    const res = await GridColorsType();
    if (res.ok) {
      this.setState({ ...this.state, colorsCode: res?.data?.result });
    }
  }

  infoDataOpen = () => {
    const { selection,selectedObj } = this.state;
    if (selection.length <= 0) {
      this.setState({
        errorMsg: "Please select one record",
      });
      return;
    }
    else if (selectedObj?.id === null) {
      this.setState({
        errorMsg: "Bank account not created",
        infoModal:false,
        selection:[],
        selectedObj:[]

      });
      return;
    }
    this.setState({
      infoModal: true,
    });
  };
  
  
  hideCaseModal = () => {
    this.setState({ ...this.state, infoModal: false,selection:[],selectedObj:[]});
  }
  render() {
    const { TransactionGridUrl, loader, customerdata } = this.state;
    return (
      <>
        <div ref={this.useDivRef}></div>
        {loader && <Loader />}
        <Title className="page-title">Customer Detail View</Title>
        {this.getAlertError()}
        <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
          <TabPane tab="Profile" key="1">
            {this.state.activeTab === "1" && (
              <>
                {" "}
                {customerdata && (
                  <Form
                    name="customer"
                    autoComplete="off"
                    ref={this.form}
                  >
                    <Row className="detailview">
                      <Col xs={24} sm={24} md={24} lg={9} xl={6} xxl={5} className="view-left" >
                        <div className="text-center user">
                          <img src={customerdata.imageURL ? customerdata.imageURL : defaultUser} alt="User" className="user-circle" />
                          <div className="kpi-val text-captz fw-700 fs-18 user-name">
                            {customerdata.userName}
                          </div>
                        </div>

                        <div className="kpi-item">
                          <span className="icon xl email" />
                          <div>
                            <label className="kpi-label">Email</label>
                            <div
                              className="kpi-val"
                              style={{ wordBreak: "break-all" }}
                            >
                              {customerdata?.email || "--"}
                            </div>
                          </div>
                        </div>
                        <div className="kpi-item">
                          <span className="icon xl phone" />
                          <div>
                            <label className="kpi-label">Phone</label>
                            <div className="kpi-val">
                              {customerdata?.phone || "-"}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={15} xl={18} xxl={19} className="view-right" >
                        <Row className='cust-row-design'>
                          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item">
                            <label className="kpi-label">Customer/Contract ID</label>
                            <Paragraph className="kpi-val fw-700 mb-0" copyable>
                              {customerdata?.referralCode || "-"}
                            </Paragraph>
                          </Col>
                          {this.getCustomedata()}
                          
                          {this.getCustomerDetails()}
                        </Row>
                      </Col>
                    </Row>
                    <BankInfo
                      Id={this.state?.selection?.id}
                      Status={customerdata.status}
                      Createdby={customerdata?.createdBy}
                      CreatedDate={customerdata.createdDate}
                      ModifiedDate={customerdata?.modifiedDate}
                      Modifiedby={customerdata?.modifiedBy}
                      infoData={"profile"}
                      screenName={this.state?.screenName}
                    />
                  </Form>
                )}{" "}
              </>
            )}
          </TabPane>

          <TabPane tab="Wallets" key="3" onChange={this.changeTab}>
            &emsp;&emsp;
            {this.state.activeTab === "3" && (
              <>
                {<div className="text-right mb-16">
                  <Tooltip title="State Change">
                    <span className="icon c-pointer md state-change mr-12" onClick={this.statusChange} />
                  </Tooltip>
                  <Tooltip title="Freeze/Unfreeze">
                    <span className="icon c-pointer md freeze mr-12" onClick={() => this.isAccountModalOpen("freeze")} />
                  </Tooltip>
                  <Tooltip title="Close">
                    <span className="icon c-pointer md disable mr-8" onClick={() => this.isAccountModalOpen("Close")} />
                  </Tooltip>
                  <Tooltip title="Status History">
                    <span
                      className="icon md info-black c-pointer"
                      onClick={() => this.infoDataOpen("wallets")}
                    />
                  </Tooltip>

                </div>}
                <List
                  key={this.gridRef}
                  url={this.state?.gridUrl}
                  ref={this.gridRef}
                  additionalParams={{ customerid: this.props.match.params.id }}
                  columns={this.gridWallets}
                //showExcelExport={true}
                //pKey={this.props?.location?.pathname.includes("/pyrroscustomer") ? "Pyrros Customer Wallets" : "Openpayd Customer Wallets"}


                />

              </>
            )}
          </TabPane>

          <TabPane tab="Transactions" key="2" onChange={this.changeTab}>
            &emsp;&emsp;
            {this.state.activeTab === "2" && (
              <>
                <List
                  key={this.gridRef}
                  style={{ margin: "-22px 0 0" }}
                  url={TransactionGridUrl}
                  ref={this.gridRef}
                  additionalParams={{ customerid: this.props.match.params.id }}
                  columns={this.gridColumns}
                  showExcelExport={true}
                  pKey={this.props?.location?.pathname.includes("/pyrroscustomer") ? "Pyrros Customer Transaction History" : "Openpayd Customer Transaction History"}


                />

              </>
            )}
          </TabPane>

        </Tabs>

        <Modal
          title={"Confirm Pending/Approve/Reject?"}
          className="custom-width text-break"
          visible={this.state.isModal}
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={this.handleCancel}
              />
            </Tooltip>
          }
          footer={null}
        >
          {this.renderAlertMsgData(this?.state?.stateErrorMsg)}
          <Form ref={this.formref} initialValues={this?.state?.selectedObj}
            onFinish={this.customerStateChange}>
            <Row gutter={24} className="mb-24 pb-24 border-bottom">
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  className="input-label"
                  rules={[
                    {
                      required: true,
                      message: "Is required"
                    }
                  ]}
                >

                  <Select
                    className="cust-input mb-0"
                    onChange={(e) => this.stateChange(e)}
                    disabled={this.isField1Disabled(["approved", "rejected", "closed", "Closed", "freezed"])}
                    placeholder="Select State"
                  // value={this.state?.selectedObj?.status}
                  >
                    {this.state?.stateLu?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
              {(this.state?.selectedObj?.status === "Rejected" || this.state?.stateChange?.status === "Rejected") && (
                <Col xs={24}>
                  <Form.Item
                    name="reasonForRejection"
                    className="input-label"
                    label="Reason For Rejection"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <TextArea
                      placeholder="Reason For Rejection"
                      maxLength={100}
                      rows={4}
                      showCount
                      onChange={(e) => this.formref?.current?.setFieldsValue({ ReasonofCancellation: e.target.value })}
                      disabled={this.isField1Disabled(["approved", "rejected"])}
                    />
                  </Form.Item>
                </Col>)}
            </Row>
            <div>
              {this.state.stateLoading && <Loader />}
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  {(this.state.selectedObj?.status !== "Rejected" && this.state.selectedObj?.status !== "Approved" && this.state.selectedObj?.status !== "Closed" && this.state.selectedObj?.status !== "Freezed") &&
                    <Button
                      type="primary"
                      key="submit"
                      className="primary-btn"
                      htmlType="submit"
                      disabled={!this.state?.selectedState}
                      loading={this.state.stateBtnLoading}
                    >
                      Save
                    </Button>}
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>

        <Modal
          title={`Confirm ${this.state?.selectedAction}?`}
          className="custom-width text-break"
          visible={this.state.isAccountOpenModal}
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={() => this.handleCloseAccModal()}
              />
            </Tooltip>
          }
          footer={<div className="text-right">
            <Button
              type="primary"
              className="primary-btn cancel-btn mr-8"
              onClick={() => this.handleCloseAccModal()}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              key="submit"
              className="primary-btn"
              htmlType="submit"
              onClick={() => this.handleSaveAccount("Freeze")}
              loading={this.state.isAccountbtn}
            >
              Save
            </Button>
          </div>}

        >
          {this.renderAlertMsgData(this.state?.isAccountError)}
          <p>Do you really want to {this.state?.selectedAction?.toLowerCase()} the account  ?</p>
        </Modal>

        <Modal
          title="Status History"
          visible={this.state?.infoModal}
          width={1000}
          className="crypto-list footer-remove hide-filter status-histry"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={this.hideCaseModal}
            >
              Close
            </Button>
          }
        >
          <List
            url={process.env.REACT_APP_GRID_API + `Bank/BankTransactionStatus`}
            additionalParams={{ TypeId: this.state?.selectedObj?.id }}
            columns={this.cryptoColumns}
            ref={this.gridRef}
            className="custom-status-grid send-custom-grid"
            sortable={false}
            columnsIsNotReorderable={true}
          />

        </Modal>
      </>
    )
  }
}


const connectStateToProps = ({
  customerStore,
  userConfig,
  permissions
}) => {
  return { permissions, customerStore, userConfig: userConfig.userProfileInfo };
};
const connectDispatchToProps = (dispatch) => {
  return {
    fetchUserData: (id) => {
      dispatch(handleMembers(id));
    },
    dispatch
  };
};

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(BankCustomerDetailView)