
import { setBreadcrumb } from "../reducers/breadcrumbReducer";
import { updateCurrentScreen } from '../reducers/permissionsReducer';
export const CaseRediractions=(CaseData,propsData)=>{
    const val = CaseData.caseId
    propsData.history.push("/cases/1/" + val + "/view");
    propsData.dispatch(
        setBreadcrumb({ key: "/cases/1/" + val + "/view", val: CaseData.caseNumber })
    );
}


export const redirectToCaseView = (caseData,propsData) => {
    const val = caseData?.caseId;
    propsData.dispatch(updateCurrentScreen("cases"));
    propsData.history.push("/cases/1/" + val + "/view");
    propsData.dispatch(
      setBreadcrumb({ key: "/cases/1/" + val + "/view", val: caseData.caseNumber })
    );
  };


       