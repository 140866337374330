import { Row, Col, Typography,Button, Form,Spin, Alert } from "antd";
import { bytesToSize } from "../../utils/service";
import Info from "../info/Info";
import { useEffect, useState } from "react";
import apiCalls from "../../api/apiCalls";
import DocumentPreview from "../shared/documentPreview";
import RiskDetailsPage from "./riskDetailsPage";
import PropTypes from "prop-types";
import { getRiskScreenData, getRiskScreenDetails } from "./api";
const ScreeningDetails = () => {
   
    const [riskPage, setRiskPage] = useState(false);
    const [riskloader, setRiskloader] = useState(false);
    const [uploadData, setUploadData] = useState({});
    const [uploadData2, setUploadData2] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [infoDetails, setInfoDetails] = useState(false);
    const [previewDocDetails, setPreviewDocDetails] = useState({});
    const [previewModal, setPreviewModal] = useState(false);
    const type = window.location.pathname.split('/')[4]
    const customerId = window.location.pathname.split('/')[3]
    const { Paragraph, Text } = Typography;

    const EllipsisMiddle = ({ suffixCount, children }) => {
        const start = children?.slice(0, children.length - suffixCount).trim();
        const suffix = children?.slice(-suffixCount).trim();
        return (
            <Text
                className="mb-0 fs-14 docnames c-pointer d-block"
                style={{ maxWidth: "100% !important" }}
                ellipsis={{ suffix }}
            >
                {start}
            </Text>
        );
    };

    EllipsisMiddle.propTypes = {
        suffixCount: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,      
    };

    const RiskScreenPage = () => {
        setRiskPage(true);
    };
    const handleCancle = () => {
        setPreviewModal(false);
    };

    const GoBackRiskTab = () => {
        getRiskScreenDetailsData();
        getRiskScreensData();
        setRiskPage(false);
        setRiskloader(false);
    };
    const GoSaveRiskTab = () => {
        getRiskScreenDetailsData();
        getRiskScreensData();
        setRiskPage(false);
    };
    useEffect(() => {
        getRiskScreenDetailsData();
        getRiskScreensData();
    }, []);
    const getRiskScreenDetailsData = async () => {
        setErrorMsg(null);
        setRiskloader(true);
    try{
        let response = await getRiskScreenDetails(customerId);
        if (response?.ok) {
            setUploadData(response?.data);
            setErrorMsg(null);
            setRiskloader(false);
            setInfoDetails(true);
        } else {
            setErrorMsg(apiCalls.isErrorDispaly(response));
            setRiskloader(false);
            setInfoDetails(false);
        }
    }
    catch(error){
        setErrorMsg(error)
        setRiskloader(false);
    }
    };
    const getRiskScreensData = async () => {
        setErrorMsg(null);
        setRiskloader(true);
        let response = await getRiskScreenData(customerId);
        if (response?.ok) {
            setUploadData2(response?.data);
            setErrorMsg(null);
            setRiskloader(false);
            setInfoDetails(true);
        } else {
            setErrorMsg(apiCalls.isErrorDispaly(response));
            setRiskloader(false);
            setInfoDetails(false);
        }
    };

    const getRenderCustomerValue = (customervalue) => {
        if (customervalue === true) {
            return "True"
        }
        else if (customervalue === false) {
            return "False"
        }
        else {
            return customervalue || "-"

        }
    }

    const handleClick = (item) => {
        setPreviewDocDetails({
            id: item?.documentId,
            fileName: item?.fileName,
        });
        setPreviewModal(true);
    };
    const handleAlertClose=()=>{
        setErrorMsg(null);
    }
    return (
        <>
        	{errorMsg === undefined || errorMsg !== null && <div style={{ width: '100%' }}>
				<Alert
					className="w-100 mb-16 align-center mt-0"
					type="warning"
					description={errorMsg}
					showIcon
                    closable
                    onClose={handleAlertClose}
				/>
			</div>}
            <Spin spinning={riskloader}>

                <Form autoComplete="off">
                    <>
                        {type !== "view" && (
                            <div className="text-right mb-8">
                                <Button
                                    type="primary"
                                    className="primary-btn text-right"
                                    style={{ margin: "8px 8px" }}
                                    onClick={RiskScreenPage}
                                >
                                    Edit
                                </Button>
                            </div>
                        )}
                        <Row gutter={8}>
                            <Col xl={24} xxl={24} className="bank-view">
                                <Row className="company-List">
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label htmlFor className="kpi-label">Name</label><br />

                                        <a onClick={RiskScreenPage} className="kpi-val fw-700 mb-0 c-pointer">
                                            {getRenderCustomerValue(uploadData?.customerOrCompanyName)}
                                        </a>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">AVM </label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData?.isAVM)}
                                        </Paragraph>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">OTH </label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData?.isOTH)}

                                        </Paragraph>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">PEP</label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData?.isPEP)}
                                        </Paragraph>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">RCA </label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData?.isRCA)}
                                        </Paragraph>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">SAN </label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData?.isSAN)}
                                        </Paragraph>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">UNK </label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData?.isUNK)}
                                        </Paragraph>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">WBL </label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData?.isWBL)}
                                        </Paragraph>
                                    </Col>

                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={8}
                                        xxl={8}
                                        className="item"
                                    >
                                        <label className="kpi-label">Note</label>
                                        <Paragraph className="kpi-val fw-700 mb-0">
                                            {getRenderCustomerValue(uploadData2?.description)}
                                        </Paragraph>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        className="item"
                                    >
                                        <label className="kpi-label">Attachment</label>
                                        {uploadData2?.files !== null && (
                                            <Row>
                                                {uploadData2?.files?.map((item) => (
                                                    <Col
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={12}
                                                        xl={8}
                                                        xxl={8}
                                                        className="item risk-uploads-col"
                                                        key={item?.documentId}
                                                    >

                                                        <div className="docfile mt-4 d-flex align-center risk-uploads">
                                                            <span
                                                                className={`icon xl ${((item?.fileName?.slice(-3) === "pdf" ||
                                                                    item?.fileName?.slice(-3) ===
                                                                    "PDF") &&
                                                                    "file")
                                                                    } mr-16`}
                                                            />
                                                            <div className="docdetails c-pointer" onClick={() => handleClick(item)}>
                                                                {item?.fileName !== null ? (
                                                                    <EllipsisMiddle suffixCount={6}>
                                                                        {item?.fileName}
                                                                    </EllipsisMiddle>
                                                                ) : (
                                                                    <EllipsisMiddle suffixCount={6}>
                                                                        Name
                                                                    </EllipsisMiddle>
                                                                )}
                                                                <span className="fs-12 text-secondary">
                                                                    {item?.fileSize
                                                                        ? bytesToSize(item?.fileSize)
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>)}
                                        {uploadData2?.files == null &&
                                            <Col
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={12}
                                                xl={8}
                                                xxl={8}
                                                className="item risk-uploads-col"
                                            >

                                                <Paragraph className="kpi-val fw-700 mb-0">
                                                    {"--"}
                                                </Paragraph>
                                            </Col>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    <Info
                        Id={uploadData2?.id}
                        Createdby={uploadData2?.createdby}
                        CreatedDate={uploadData2?.createdDate}
                        ModifiedDate={uploadData2?.modifieddate}
                        Modifiedby={uploadData2?.modifiedby}
                        infoDetails={infoDetails}
                    />
                </Form>
            </Spin>
            {previewModal && (
                <DocumentPreview
                    previewModal={previewModal}
                    handleCancle={handleCancle}
                    upLoadResponse={previewDocDetails}
                />
            )}
            {riskPage && (
                <RiskDetailsPage
                    pop_up_cancel={GoBackRiskTab}
                    riskModal={riskPage}
                    gridID={customerId}
                    pop_up_save={GoSaveRiskTab}
                />
            )}
        </>
    )
}

export default ScreeningDetails