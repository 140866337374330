import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";

const getKeyGeneration = (emailId, username) => {
    return clientApi.get(ApiControllers.memberDeposits + `KeyGeneration/${emailId}/${username}`)
}
const getStates = (status) => {
    return clientApi.get(
      ApiControllers.common + `statechange/${"memberdepositcrypto"}/${status}`
    );
  };
  const statusSaveMemberDeposit = (obj) => {
    return clientApi.put(ApiControllers.memberDeposits + `MemberDeposit/Status`,obj);
  };
export  {getKeyGeneration,getStates,statusSaveMemberDeposit} 