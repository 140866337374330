
import React, { Component } from "react";
import {
  Row,
  Col,
  Tooltip,
  Modal,
  Button,
} from "antd";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import config from "../../config";
class BankInfo extends Component {
  constructor (props) {
    super(props);
    this.state = {
      infoModal: false,
      loading: true,
      stateLoading: true,
    };
   
    this.gridRef = React.createRef();
  }

  cryptoColumns = [
    {
      field: "createdBy", title: "Name", width: 250,filter:false
    },
    {
      field: "currency", title: "Currency", width: 250,filter:false
    },
    { field: "status", title: "State", width: 250,filter:false },

    {
      field: "createdDate",
      title: "Date",
      width: 250,
      isShowTime: true,
      filter:false,
      customCell: (props) => (
        <td>
          <div>
            <Moment format={config?.dateFormates?.dateTimeFormate} >{props.dataItem.createdDate ? apiCalls.convertUTCToLocalTime(props.dataItem.createdDate) : props.dataItem.createdDate}</Moment>
          </div>
        </td>
      ),
    },

    { field: "remarks", title: "Remarks", width: 180,filter:false },

  ];
  hideCaseModal = () => {
    this.setState({ ...this.state, infoModal: false});
  }
  openCaseModal = () => {
    this.setState({ ...this.state, infoModal: true});
  }
  render() {
    const { infoModal } = this.state;

    return (
      <div>
        <Row gutter={24}>
          {this.props.Createdby && <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
            <div className="record-history d-flex align-center ml-0">
              <div style={{ marginLeft: "5px" }}>
                {this.props.Createdby}{" "} created this on {" "}
                <Moment format={config?.dateFormates?.dateTimeFormate}>{this.props.CreatedDate ? apiCalls.convertUTCToLocalTime(this.props.CreatedDate) : this.props.CreatedDate}</Moment>
              </div> </div>
          </Col>}

          {(this.props.Modifiedby) && <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
            <div className="record-history d-flex align-center ml-0">
             { this.props?.infoData !== "profile" && <Tooltip title="Click here to view status history">
                <span
                  className="icon md info c-pointer"
                  onClick={this.openCaseModal}
                />
              </Tooltip>}
              <div className="record-history-text">

                <div style={{ marginLeft: "5px" }}>
                  {this.props.Modifiedby}{" "} modified this on {" "}

                  <Moment format={config?.dateFormates?.dateTimeFormate} >{this.props.ModifiedDate ? apiCalls.convertUTCToLocalTime(this.props.ModifiedDate) : this.props.ModifiedDate}</Moment>
                </div>
              </div>
            </div>
          </Col>}
        </Row>

       {(this.props?.infoData !== "profile" ) && <Modal
          title="Status History"
          visible={infoModal}
          width={1000}
          className="crypto-list footer-remove hide-filter status-histry"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={this.hideCaseModal}
              >
              Close
            </Button>
          }
        >
          <List
            url={process.env.REACT_APP_GRID_API + `Bank/BankTransactionStatus`}
            additionalParams={{ TypeId: this.props.Id }}
            columns={this.cryptoColumns}
            ref={this.gridRef}
            className="custom-status-grid send-custom-grid"
            sortable={false}
            columnsIsNotReorderable={true}
          />

        </Modal>}
      </div>
    );
  }
}
export default BankInfo;