import { useEffect, useState } from "react";
import FilePreviewer from "react-file-previewer";
import { Modal, Tooltip, Button,Alert, Spin } from "antd";
import { getFileURL } from "../customers.component/api";

const DocumentPreview = (props) => {
  const [previewPath, setPreviewPath] = useState(null);
  const [previewfileName, setPreviewfileName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const mimeType = {
    pdf: "application/pdf",
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    xls: "application/xls",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    txt: "text/plain",
    MP4: "video/mp4",
    AVI: "video/x-msvideo",
    QuickTime: "video/quicktime",
    WMV: "video/x-ms-wmv",
    mp4: "video/mp4",
    mov: "video/quicktime",
    MOV: "video/quicktime",
    wmv: "video/x-ms-wmv",
    avi: "video/x-msvideo",
    quicktime: "video/quicktime",
  };
  const [mimetypefiles, setMimeTypeFiles] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    docPreview();
  }, []);

  const docPreview = async () => {
    setIsLoading(true);
    let res = await getFileURL(props?.upLoadResponse?.id);
    let extensions =null
    if (res.ok) {
      setIsLoading(false);
      setPreviewfileName(props?.upLoadResponse?.fileName || props?.upLoadResponse?.name);
      if(props?.upLoadResponse?.fileName){
         extensions = props?.upLoadResponse?.fileName.split(".");
      }else{
         extensions = props?.upLoadResponse?.name.split(".");
      }
      let isFileName = extensions[extensions.length - 1];
      setMimeTypeFiles(mimeType[isFileName]?.toLowerCase());
      setPreviewPath(res.data);
    } else {
      setIsLoading(false);
      setErrorMsg(isErrorDispaly(res));
    }
  };
  const isErrorDispaly = (objValue) => {
    if (objValue.data && typeof objValue.data === "string") {
      return objValue.data;
    } else if (
      objValue.originalError &&
      typeof objValue.originalError.message === "string"
    ) {
      return objValue.originalError.message;
    } else {
      return "Something went wrong please try again!";
    }
  };
  return (
    <>
      {errorMsg !== null && (
        <Alert
          type="error"
          description={errorMsg}
          onClose={() => setErrorMsg(null)}
          showIcon
        />
      )}
      <Modal
        className="documentmodal-width"
        title="Preview"
        width={1000}
        visible={props.previewModal}
        closeIcon={
          <Tooltip title="Close">
            <span
              className="icon md c-pointer close"
              onClick={props.handleCancle}
            />
          </Tooltip>
        }
        footer={
          <>
            <div className="d-flex excel-btn mb-36">
              <Button
                type="primary"
                onClick={props.handleCancle}
                className="primary-btn cancel-btn mr-8 "
              >
                Close
              </Button>
              <a
                className={previewPath && "primary-btn pop-btn detail-popbtn" || "primary-btn pop-btn detail-popbtn c-notallowed"}
                //style={{paddingTop:"9px"}}
                download={previewfileName}
                href={`data:${mimetypefiles};base64,` + previewPath}
                title="Download pdf document"
              >
                Download
              </a>
            </div>
          </>
        }
      >
        <Spin spinning={isLoading}>
          {(mimetypefiles !== "video/mp4" && mimetypefiles !== "video/x-msvideo" && mimetypefiles !=="video/quicktime"  && mimetypefiles !== "video/x-ms-wmv") && <FilePreviewer
            hideControls={true}
            file={{
              data: previewPath,
              name: previewfileName,
              mimeType: mimetypefiles
            }}
          />}
         {(mimetypefiles == "video/mp4" || mimetypefiles == "video/x-msvideo" || mimetypefiles =="video/quicktime"  || mimetypefiles == "video/x-ms-wmv") && 
        <video controls width="100%" height="100%" preload="auto">
        <source src={`data:${mimetypefiles=="video/quicktime" ? "video/mp4" : mimetypefiles};base64,${previewPath}`} type={mimetypefiles=="video/quicktime" ? "video/mp4" : mimetypefiles}></source>
         </video>
          }
          </Spin>
      </Modal>

      
    </>
  );
};
export default DocumentPreview;
