import React, { Component } from "react";
import { Typography, Row, Col, Button, Alert } from "antd";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";
import { getTransfer } from './api';
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import CopyToClipboard from "react-copy-to-clipboard";
import Info from "../info/Info";
import { Link } from "react-router-dom";
import config from "../../config";
const { Text, Title } = Typography;

class TransferDetailView extends Component {
    constructor (props) {
        super(props);
        this.state = {
            transferDetails: {},
            loader: false,
            errorMsg: null,
        };
    }

    componentDidMount() {
        this.fetchTransferData();
    }
    fetchTransferData = async () => {
        this.setState({ loader: true });
        let response = await getTransfer(this.props.match.params.id);
        if (response.ok) {
            this.setState({ transferDetails: response.data, loader: false, errorMsg: null });
        } else {
            this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
        }
    };

    backToTransfer = async () => {
        this.props.history.push("/internalWalletTransfer");
    };

    renderViewData = (titleData, value) => {
        if (titleData == "From Address" || titleData == "To Address") {
            return <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md wallet" />
                <div>
                    <label className="kpi-label">
                        {titleData}
                    </label>
                    <div className="kpi-val fw-700 mb-0">
                        <CopyToClipboard text={value} options={{ format: 'text/plain' }}>
                            <Text
                                copyable
                                className="fs-22 text-black-30 text-center custom-display"
                            >
                                {value ? value : "-"}
                            </Text>
                        </CopyToClipboard>
                    </div>
                </div>
            </Col>
        }
        else {
            return <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md wallet" />
                <div>
                    <label className="kpi-label">
                        {titleData}
                    </label>
                    <p className="kpi-val fw-700 mb-24">
                        {value || "-"}
                    </p>
                </div>
            </Col>
        }
    }

    render() {

        return (
            <>
                {this.state.errorMsg && <div style={{ width: '100%' }}>
                    <Alert className="mb-16 w-100" type="warning"
                        description={this.state.errorMsg} showIcon />
                </div>}
                {this.state.loader && <Loader />}
                <Title className="page-title">Transfer Detail View</Title>
                <Row gutter={24}>
                    <Col xl={24} xxl={24} className="bank-view">
                        <Row className="kpi-List">
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <>
                                    <span className="icon md date" />
                                    <div>
                                        <label className="kpi-label">Date</label>
                                        <div className="kpi-val fw-700 mb-24">
                                            <Moment format={config?.dateFormates?.dateTimeFormate}>{this.state.transferDetails?.createdDate ? apiCalls.convertUTCToLocalTime(this.state.transferDetails?.createdDate) : this.state.transferDetails?.createdDate}</Moment>
                                        </div>
                                    </div>
                                </>
                            </Col>


                            {this.renderViewData("Network", this.state.transferDetails.toNetWork)}
                            {this.renderViewData("Coin", this.state.transferDetails.coin)}
                            {this.renderViewData("From Wallet", this.state.transferDetails.fromWallet)}
                            {this.renderViewData("From Address", this.state.transferDetails.fromAddress)}
                            {this.renderViewData("From Vault", this.state.transferDetails.fromVault)}
                            {this.renderViewData("To Wallet", this.state.transferDetails.toWallet)}
                            {this.renderViewData("To Vault", this.state.transferDetails.toVault)}


                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md cash" />
                                <div>
                                    <label className="kpi-label">
                                        Amount
                                    </label>
                                    <div className="kpi-val fw-700">
                                        <NumberFormat
                                            value={
                                                this.state.transferDetails.amount
                                                    ? this.state.transferDetails.amount
                                                    : "--"
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                            {this.renderViewData("State", this.state.transferDetails.state)}


                            {this.state.transferDetails?.state === "Approved" && <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md routing" />
                                <div>
                                    <label className="kpi-label">Transaction ID</label>
                                    <div className="kpi-val">
                                        {this.state.transferDetails?.transactionId &&
                                            <Link
                                                onClick={() => window.open(`${this.state.transferDetails?.explorer}${this.state.transferDetails?.transactionId}`, '_blank')}
                                            >{this.state.transferDetails?.transactionId}
                                            </Link>}

                                    </div>
                                </div>
                            </Col>}

                            {this.renderViewData("Remarks", this.state.transferDetails.remarks)}

                            {(this.state.transferDetails.state === "Approved" || this.state.transferDetails.state === "Rejected") &&
                                (
                                    this.renderViewData(" State Remarks", this.state.transferDetails.stateRemarks)
                                )}
                        </Row>
                    </Col>
                </Row>

                <Info Id={this.state.transferDetails?.id} Status={this.state.transferDetails?.state} Createdby={this.state.transferDetails?.createdBy} CreatedDate={this.state.transferDetails?.createdDate} ModifiedDate={this.state.transferDetails?.modifiedDate} Modifiedby={this.state.transferDetails?.modifiedBy} />

                <div className="text-right mt-24">
                    <Button
                        type="primary"
                        className="primary-btn cancel-btn"
                        style={{ margin: "0 8px" }}
                        onClick={() => this.backToTransfer()}>
                        Cancel
                    </Button>
                </div>
            </>
        );
    }
}

export default TransferDetailView;
