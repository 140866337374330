import React, { Component } from "react";
import {
  Form,
  Select,
  Tooltip,
  message,
  Modal,
  Button,
  Row,
  Col,
  Input,
  Alert
} from "antd";
import List from "../grid.component";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import Loader from "../loader.component";
import Moment from "react-moment";
import { validateContentRule } from '../../utils/custom.validator';
import {BankReceiveUserNameLuSearch, GridColorsType, getBankCurrencyLookup, getBankStatusLookup, saveBankDeposit,saveEvolveBankDeposit} from './api'
import apiCalls from "../../api/apiCalls";
import config from "../../config";
import { Option } from "antd/lib/mentions";

const { TextArea } = Input;
class BankPyrrosDeposit extends Component {
  constructor(props) {    
    super(props);
    this.state = {
      check: false,
      selection: [],
      selectedObj: {},
      gridUrl:null,
      stateLoading: false,
      isSubmitting: false,
      stateChange: {},
      error: null,
      obj: {
        id: "",
        state: "",
        ReasonofCancellation: ""
      },
      warningMsg: null,
      isModal: false,
      statusLoading: false,
      errorMsg: null,
      statuserrorMsg:null,
      stateLu:[],
      selectedState:null,
      pkeyValue:null,
      customerData: [],
      isLoading: false,
      searchObj: {
        currencyType:"All",
        statusType:'All',
				memberName: "00000000-0000-0000-0000-000000000000",
        
			},
      bankCurrencyTypesData: [],
      bankStatusTypeData: [],
      colorsCode:[]
    };
    this.gridDepositRef = React.createRef();
    this.formref = React.createRef();
  }
  componentDidMount() {
    let gridUrlData;
    let peyKeyData;
    const { pathname } = this.props?.location || {};
    if (pathname === '/pyrrosdeposit') {
      gridUrlData = process.env.REACT_APP_GRID_API + "Bank/GetPyrrosDeposits";
      peyKeyData = "Pyrros Deposit";
    } else if (pathname === '/openpayddeposit') {
      gridUrlData = process.env.REACT_APP_GRID_API + `Bank/GetOpenpaydDeposits`;
      peyKeyData = "OpenPayd Deposit";
    } else {
      gridUrlData = process.env.REACT_APP_GRID_API + "Bank/GetEvolveDeposits";
      peyKeyData = "Evolve Deposit";
    }
    this.setState(prevState => ({ ...prevState, gridUrl: gridUrlData,pkeyValue:peyKeyData }));
     this.BankStatusSearch();
     this.BankCurrencySearch();
     this.gridColorTypes();
  }
  openpaydGridColumns = [
    {
      field: "date",
      title: "Date",
      filter: true,
      width: 220,
      isShowTime: true,
      locked: true,
      filterType: "date",
      customCell: (props) => (
          <div
            className="gridLink"
            onClick={() => this.updateDetailsView(props)}
          >
            {props.dataItem?.date ? (
              <Moment format={config?.dateFormates?.dateTimeFormate}>
                {apiCalls.convertUTCToLocalTime(props.dataItem?.date)}
              </Moment>
            ) : (
              props.dataItem?.date
            )}
          </div>

      )
    },
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      width: 250
    },
    {
      field: "referenceId",
      title: "Customer/Contract ID",
      filter: true,
      width: 250
    },
    {
      field: "name",
      title: "Business Name/Personal Name",
      filter: true,
      width: 300,
      customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width">	{props.dataItem.name}</div>
					{(props.dataItem?.isDigitaltransfer || props?.dataItem?.accountType) && (
						<div className="file-labels ml-8 fs-12 address-label address-label-width">
							{(props.dataItem.isDigitaltransfer || props?.dataItem?.accountType) && "Personal"}
						</div>
					)}</div>
				</td>
			),
    },

    { field: "sendersName", title: "Sender's Name", filter: true, width: 200 },
    {
      field: "sendersBankName",
      title: "Sender's Bank Name ",
      filter: true,
      width: 210
    },
    {
      field: "sendersBankAccount",
      title: "Sender's Bank Account Number/IBAN",
      filter: true,
      width: 310
    },
    {
      field: "currency",
      title: "Currency",
      filter: true,
      width: 120,
      customCell: (props) => {
        const currencyColorConfig = this.state?.colorsCode?.find(colorConfig => colorConfig.currency === props.dataItem.currency);
        const currencyColor = currencyColorConfig?.color.toLowerCase() || 'black';
        const textColor = (currencyColor === 'blue' || currencyColor === 'darkgreen'||currencyColor === 'green') ? 'white' : 'black';
    
        return (
          <td className="whitelist-name">
            <div className={`white-address ibancolor ${currencyColor}`} style={{ color: textColor }}>
              {props.dataItem.currency}
            </div>
          </td>
        );
      },
      
    },

    {
      field: "grossAmount",
      title: "Gross Amount",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },

    {
      field: "commission",
      title: "Commission",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "providerCommission", title: "Provider Commission", filter: true, width: 220, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComission", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationComission", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    {
      field: "netAmount",
      title: "Net Amount",
      filter: true,
      width: 160,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "profit", title: "Profit", filter: true, width: 120, dataType: "number", filterType: "numeric" },
    { field: "profitInBase", title: "Profit In Base", filter: true, width: 140, dataType: "number", filterType: "numeric" },
    { field: "bankTransactionStatus", title: "Bank Status", filter: true, width: 140 },

    { field: "state", title: "Status", filter: true, width: 140 }
  ];
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (

          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleSelectionChange(props, e)}
            />
            <span></span>{" "}
          </label>
      )
    },
    {
      field: "date",
      title: "Date",
      filter: true,
      width: 220,
      isShowTime: true,
      locked: true,
      filterType: "date",
      customCell: (props) => (
          <div
            className="gridLink"
            onClick={() => this.updateDetailsView(props)}
          >
            {props.dataItem?.date ? (
              <Moment format={config?.dateFormates?.dateTimeFormate}>
                {apiCalls.convertUTCToLocalTime(props.dataItem?.date)}
              </Moment>
            ) : (
              props.dataItem?.date
            )}
          </div>

      )
    },
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      width: 250
    },
    {
      field: "referenceId",
      title: "Customer/Contract ID",
      filter: true,
      width: 250
    },
    {
      field: "name",
      title: "Business Name/Personal Name",
      filter: true,
      width: 300,
      customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width">	{props.dataItem.name}</div>
					{(props.dataItem?.isDigitaltransfer || props?.dataItem?.accountType) && (
						<div className="file-labels ml-8 fs-12 address-label address-label-width">
							{(props.dataItem.isDigitaltransfer || props?.dataItem?.accountType) && "Personal"}
						</div>
					)}</div>
				</td>
			),
    },

    { field: "sendersName", title: "Sender's Name", filter: true, width: 200 },
    {
      field: "sendersBankName",
      title: "Sender's Bank Name ",
      filter: true,
      width: 210
    },
    {
      field: "sendersBankAccount",
      title: "Sender's Bank Account Number/IBAN",
      filter: true,
      width: 310
    },
    {
      field: "grossAmount",
      title: "Gross Amount",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "commission",
      title: "Commission",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "providerCommission", title: "Provider Commission", filter: true, width: 220, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComission", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationComission", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    {
      field: "netAmount",
      title: "Net Amount",
      filter: true,
      width: 160,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "profit", title: "Profit", filter: true, width: 120, dataType: "number", filterType: "numeric" },
    { field: "bankTransactionStatus", title: "Bank Status", filter: true, width: 140 },
    { field: "state", title: "Status", filter: true, width: 140 }
  ];
  updateDetailsView = (e) => {
    const items = e.dataItem;
    const val = items.id;
    if (this.props?.location?.pathname === "/pyrrosdeposit") {
      this.props.history.push("/pyrrosdeposit/details/" + val + "/view");
    } else if(this.props?.location?.pathname === "/evolvedeposit") {
      this.props.history.push("/evolvedeposit/details/" + val + "/view");
    }else{
      this.props.history.push("/openpayddeposit/details/" + val + "/view");
    }
    let depositPath;
    const { pathname } = this.props?.location || {};
    if (pathname === "/pyrrosdeposit") {
      depositPath = "/pyrrosdeposit";
    } else if (pathname === "/evolvedeposit") {
      depositPath = "/evolvedeposit";
    } else {
      depositPath = "/openpayddeposit";
    }
    this.props.dispatch(
      setBreadcrumb({
        key:
        depositPath +
          "/details/" +
          val +
          "/view",
        val: `${(e.dataItem.userName || e.dataItem.name)+" " + "/" + " " + e.dataItem.state}`
      })
    );
  };

  statusChange = () => {
    if (!this.state.check) {
      this.setState({ ...this.state, warningMsg: "Please select the one record",
        isSubmitting: this.state.stateChange.state !== "Submitted"});
      setTimeout(() => this.setState({ ...this.state,alert: false, isSubmitting:this.state.stateChange.state !== "Submitted"}),1000);
    } else {
      this.getStatesLu();
      this.setState(
        {
          ...this.state,
          isModal: true,
          stateChange: {
            status: this.state.selectedObj.state,
            customerId: this.state.selectedObj.customerId,
            ReasonofCancellation: this.state.selectedObj?.state==="Approved" ? this.state.selectedObj?.remarks:this.state.selectedObj?.reasonOfCancellation,

          }
        },
        () => {
          this.setState({
            ...this.state,
            stateLoading: true,
            isSubmitting:
              this.state.stateChange.state !== "Submitted"
          });
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                stateLoading: false,
                isSubmitting:
                  this.state.stateChange.state !== "Submitted"
              }),
            1000
          );

          setTimeout(
            () =>
              this.formref.current.setFieldsValue({
                ...this.state,
                status: this.state.selectedObj.state,
                customerId: this.state.selectedObj.customerId,
                ReasonofCancellation: this.state.selectedObj?.reasonOfCancellation,
                remarks: this.state.selectedObj?.remarks,

              }),
            1000
          );
        }
      );
    }
  };

  handleSelectionChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      warningMsg: null,
      errorMsg:null,
      statuserrorMsg:null,
    });
  };

  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Reject":
        status = "Rejected";
        break;
      case "Pending":
        status = "Pending";
        break;
        default:
        return null;
    }
    message.success({
      content:
        (status === "transferred" ? "Amount " : "Record ") +
        (status === "Approve" ? "Approved" : status) +
        " successfully",
      className: "custom-msg",
      duration: 4
    });
  };

  error = () => {
    message.error("Please select the one record");
  };

  stateChange = (val, prop) => {
    
    let { stateChange } = this.state;
    stateChange.state = val;
    this.setState({ ...this.state, stateChange,selectedState:val });
  };
  
  handleCancel = (e) => {
    this.setState({
      ...this.state,
      isModal: false,
      selection: [],
      check: false,
      error: null,
      errorMsg:null,
      warningMsg: null,
      statuserrorMsg:null,
      selectedState:null,
    });
  };

  depositStatusChange = async (values) => {
    this.setState({
      ...this.state,
      selection: [],
      check: true,
      stateLoading: false,
      isBtnApprove: true,
      warningMsg: null,
      errorMsg:null,
      statusLoading:true,
      statuserrorMsg:null
    });
    let statusObj = this.state.obj;
    statusObj.id = this.state.selectedObj.id;
    statusObj.customerId = this.state.selectedObj.customerId;
    statusObj.state = values.status 
    statusObj.ModifiedBy = this.props?.userConfig?.userName
    if (values?.status === "Rejected") {
      statusObj.ReasonofCancellation = values.ReasonofCancellation;
      statusObj.remarks = ""
    }
    else if(values?.status === "Approved"){
      statusObj.remarks =  values.remarks;
      statusObj.ReasonofCancellation = ""
    }
    if(!this.isLoading){
      let response;
      if(this.props?.location?.pathname === "/pyrrosdeposit"){
          response = await saveBankDeposit(statusObj);
      }else{
           response = await saveEvolveBankDeposit(statusObj);
      }
        if (response.ok) {
          this.success(statusObj.state);
          this.setState({
            ...this.state,
            isModal: false,
            selection: [],
            check: true,
            stateLoading: false,
            isBtnApprove: false, warningMsg: null,statusLoading:false,errorMsg:null,statuserrorMsg:null
          });
          this.gridDepositRef.current?.refreshGrid();
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
          this.gridDepositRef.current?.refreshGrid();
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            stateLoading: false,
            isBtnApprove: false,statusLoading:false,
            statuserrorMsg: apiCalls.isErrorDispaly(response),
            errorMsg: null,
            warningMsg: null
          });
        }
    }
  };
  getStatesLu=async()=>{
    let res = await apiCalls.getStates("deposit",this.state.selectedObj.state)
    if(res.ok){
      this.setState({...this.state,stateLu:res.data})
    }else{
      this.setState({ ...this.state,stateErrorMsg: apiCalls.isErrorDispaly(res) });
    }
  }
  onActionClick = (key) => {
    const action = {
      "State Change": "statusChange",
    };
    this[action[key]]();
  };
  renderAlertMsg=(alertMsg,typeError)=>{
    return alertMsg !== undefined && alertMsg !== null && (
      <Alert
        className="w-100 mb-16"
        type={typeError}
        description={alertMsg}
        showIcon
      />
    )
  }
  BankReceiveUserSearch = async (email, memberName) => {
		this.setState({ isLoading: true, errorMsg: null });
		
		const { pathname } = this.props?.location || {};
		if (pathname === `/openpayddeposit`) {
			const response = await BankReceiveUserNameLuSearch(email, memberName);
		if (response.ok) {
			const newState = { isLoading: false, errorMsg: null };
			if (memberName) {
				newState.customerData = response.data;
			} else {
				newState.searchObj = { type: "All", memberName: response.data[0].customerId };
				newState.customerData = response.data;
			}
			this.setState(newState);
		} else {
			this.setState({ errorMsg: apiCalls.isErrorDispaly(response) });
		}
	}
}

BankCurrencySearch = async () => {
  let response = await getBankCurrencyLookup();
  if (response.ok) {
    this.setState({
      bankCurrencyTypesData: response.data,
      errorMsg:null
    });
  }else{
    this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
  }
};

BankStatusSearch = async () => {
  let response = await getBankStatusLookup();
  if (response.ok) {
    this.setState({
      bankStatusTypeData: response.data,
      errorMsg:null
    });
  }else{
    this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
  }
};

  handleUserChange = (event, type) => {
		let userVal = event.target.value;
		if (userVal != null && userVal.length > 2) {
			if (type === "memberName") {
				this.BankReceiveUserSearch(null, userVal);
			}
		}
	}
  handleChange = (value, prop) => {
    let val=""
		let { customerData,searchObj,bankCurrencyTypesData,bankStatusTypeData } = this.state;
    if (prop === "currencyType") {
     
        let index = bankCurrencyTypesData.findIndex(function (o) {
          return o.currency === value;
        });
        val = bankCurrencyTypesData[index].currency;
        searchObj[prop] = prop === "currencyType" ? val : value;
        this.setState({ ...this.state, searchObj });
    }
    else if (prop === "statusType") {
     
      let index = bankStatusTypeData.findIndex(function (o) {
        return o.status === value;
      });
      val = bankStatusTypeData[index].status;
      searchObj[prop] = prop === "statusType" ? val : value;
      this.setState({ ...this.state, searchObj });
  }
		else {
			const obj = customerData?.find((item) => item.name === value);
			searchObj[prop] =  obj?.customerId  ? obj?.customerId : null;
			this.setState({ ...this.state, searchObj });
		}

	};

  handleSearch = (values) => {
		let { searchObj } = this.state;
		this.setState({ ...this.state, searchObj }, () => {
			this.gridDepositRef.current.refreshGrid();
		});

	};

  gridColorTypes=async()=>{
    const res= await GridColorsType();
    if(res.ok){
      this.setState({ ...this.state, colorsCode:res?.data?.result });
    }
  }
  render() {
    const { gridUrl, stateChange, warningMsg,errorMsg ,statuserrorMsg,pkeyValue,customerData,bankCurrencyTypesData,bankStatusTypeData,searchObj} = this.state;
		const options = customerData.map((item) => (
			<Option key={item.id} value={item.name}>
				{item.name}
			</Option>
		));
    const options2 = bankCurrencyTypesData.map((item) => (
			<Option key={item.id} value={item.currency}>
				{item.currency}
			</Option>
		));
    const options3 = bankStatusTypeData.map((item) => (
			<Option key={item.id} value={item.status}>
				{item.status}
			</Option>
		));
    const pathName = window.location.pathname.split('/')[1]
    return (
      <>
      {this.renderAlertMsg(warningMsg,"warning")}
      {this.renderAlertMsg(errorMsg,"error")}
     {pathName === 'openpayddeposit' &&  <Row style={{ alignItems: "flex-end" }} className="mb-16">
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="customerId"
									className="input-label mb-0"
									label="User Name">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onKeyUp={(event) =>
											this.handleUserChange(event, "memberName")
										}
										onChange={(e) => this.handleChange(e, "memberName")}
										placeholder="Select Members">
										{options}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="currency"
									className="input-label mb-0"
									label="Currency ">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "currencyType")}
										placeholder="Select Currency">
										{options2}
									</Select>
								</Form.Item>
							</Col>
              <Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="status"
									className="input-label mb-0"
									label="Status">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "statusType")}
										placeholder="Select Status">
										{options3}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} xs={24} md={12} lg={3} className="px-8">
								<Button
									type="primary"
									className="primary-btn px-24 search-btn mt-20"
									htmlType="submit"
                  onClick={()=>this.handleSearch()}>
									Search
								</Button>
							</Col>
						</Row> }         
        <List
            showActionBar={true}
            pKey={pkeyValue}
            onActionClick={(key) => this.onActionClick(key)}
            url={gridUrl}
            ref={this.gridDepositRef}
            key={gridUrl}
            columns={pathName === 'openpayddeposit' ? this.openpaydGridColumns : this.gridColumns}
            showExcelExport={true}
            className="customer-table-header"
            additionalParams={pathName === 'openpayddeposit' && searchObj}

          />

        <Modal
          title="Confirm Approve ?"
          className="custom-width"
          visible={this.state.isModal}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={this.handleCancel}
              />
            </Tooltip>
          }
          footer={null}
        >
          <div>
          {this.renderAlertMsg(statuserrorMsg,"error")}         
          <Form ref={this.formref}   onFinish={this.depositStatusChange}>
            <Row gutter={24} className="mb-24 pb-24 border-bottom">
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="status"
                  label="State"
                  className="input-label"
                  rules={[
                    {
                      required: true,
                      message: "Is required"
                    }
                  ]}
                >
                  <Select
                    className="cust-input mb-0"
                    onChange={(e) => this.stateChange(e)}
                    disabled={
                      this.state.selectedObj.state === "Approved" ||
                      this.state.selectedObj.state === "Rejected"
                    }
                    placeholder="Select State"
                  >
                     {this.state?.stateLu?.map((item, idx) => <Select.Option value={item?.code} key={idx} >{item?.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
              
              {(this.state.selectedObj.state === "Rejected" || stateChange.state === "Rejected") &&(
              <Col xs={24}>
                  <Form.Item
                    name="ReasonofCancellation"
                    className="input-label"
                    label= "Reason Of Cancellation"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      },
                      {
                        validator:validateContentRule
                      }
                    ]}
                    >
                    <TextArea
                      placeholder= "Reason Of Cancellation"
                      maxLength={100}
                      rows={4}
                      disabled={
                        this.state.selectedObj.state === "Rejected" ||
                        this.state.selectedObj.state === "Approved"
                          ? true
                          : false
                      }
                      showCount
                    />
                  </Form.Item>
              </Col>)}

            </Row>
            <div>
              {this.state.stateLoading && <Loader />}
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  {(this.state.selectedObj.state !== "Approved" && this.state.selectedObj.state !== "Rejected") && 
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    loading={this.state.statusLoading}
                    disabled={!this.state?.selectedState}

                  >
                    Save
                  </Button>}
                </div>
              </Form.Item>
            </div>
          </Form>
          </div>
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig, permissions }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    oidc,
    trackAuditLogData: userConfig.trackAuditLogData,
    permissions
  };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(BankPyrrosDeposit);
