import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import Alert from "antd/lib/alert";

const CompanyCheck = (props) => {
  const [companyCheckDetails,setIsCompanyCheckDetails]=useState(props?.companyCheckData?.checks)
  const [errorMsg,setErrorMsg]=useState(null)
  const { Panel } = Collapse;

  useEffect(()=>{
    setIsCompanyCheckDetails(props?.companyCheckData?.checks)
  },[props?.companyCheckData?.checks])

  return (
    <div className='poa-accordian'>
          {errorMsg && (
          <div className="mt20">
            <Alert
              closable
              type="error"
              description={errorMsg}
              onClose={() => setErrorMsg(null)}
              showIcon
            />
          </div>
        )}
          <Collapse  className='trans-collapse'>
          { <Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Company Check</span></span>} key="1">
          { <>
            <div className='d-flex justify-content wrap-auto'>      
                  </div>
                  <table border="1" className='poa-table'>
                    <thead>
                        <tr>
                            <th className='text-left'>Check</th>
                            <th className='text-left'>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Company Name</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails?.[0]?.companyCheckInfo?.companyName}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Alternative Names</span> </div></td>
                    <td> {companyCheckDetails && companyCheckDetails[0] && companyCheckDetails[0].companyCheckInfo && (
                    <p className='kpi-val fw-700'>
                        {`${companyCheckDetails[0].companyCheckInfo.alternativeNames?.[0] || ""} ${
                        companyCheckDetails[0].companyCheckInfo.alternativeNames?.[1] || ""
                        }`}
                    </p>
                    )}</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Licence Number ? EIN</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails?.[0]?.companyCheckInfo?.licenseInfo?.licenseNumber}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Expiry Date</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails?.[0]?.companyCheckInfo?.validUntil}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Registration Number</span> </div></td>
                    <td> </td>
                    
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Status</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails?.[0]?.companyCheckInfo?.status}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>VAT Number</span> </div></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Tax Number</span> </div></td>
                    <td></td>
                    </tr>                                  
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Type</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails?.[0]?.companyCheckInfo?.type}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Nature of Business (SIC)</span> </div></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Incorporated On</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails[0]?.companyCheckInfo?.incorporatedOn}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Legal Form</span> </div></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Start Date</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails[0]?.createdAt}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Registered On</span> </div></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Registered Office Address</span> </div></td>
                   <td> {companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails[0]?.companyCheckInfo?.officeAddress}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Web Page</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails[0]?.companyCheckInfo?.webPage}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Telephone</span> </div></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Source</span> </div></td>
                    <td>{companyCheckDetails && <p className='kpi-val fw-700'>{companyCheckDetails[0]?.companyCheckInfo?.source}</p> }</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Last Updated</span> </div></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'><div className='d-flex justify-content'><span>Expiry Date</span> </div></td>
                    <td></td>
                    </tr>
                    
                    </tbody>
                  </table></> }
                  
              </Panel>}
              
          </Collapse>
    </div>
  );
};

export default CompanyCheck;
