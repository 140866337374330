import React, { useState, useEffect } from "react";
import { Typography, Button, Form, Input, Alert, Tooltip, Modal} from "antd";
import { getCode, getVerification, sendEmail, verifyEmailCode, getAuthenticator,adminVerifications } from "./api";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Loader from '../loader.component';
const VerificationsModal = (props) => {
    const [email, setEmail] = useState({ showRuleMsg: '', errorMsg: '', btnName: 'get_otp', requestType: 'Send', code: '', verified: false,btnLoader:false });
    const [phone, setPhone] = useState({ showRuleMsg: '', errorMsg: '', btnName: 'get_otp', requestType: 'Send', verified: false,btnLoader:false,code:'' });
    const [authenticator, setAuthenticator] = useState({ showRuleMsg: '', errorMsg: '', btnName: 'verifyOtpBtn', verified: false,btnLoader:false });
    const [phoneSeconds, setPhoneSeconds] = useState(120);
    const [emailSeconds, setEmailSeconds] = useState(120);
    const [errorMsg, setErrorMsg] = useState(false);
    const [form] = Form.useForm();
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
    const useOtpRef = React.useRef(null);
    const { Text} = Typography;
    const fullNumber = props.userConfig.userProfileInfo.phoneNo;
    const last4Digits = fullNumber?.slice(-4);
    const maskedNumber = last4Digits?.padStart(fullNumber.length, "*");
    const history = useHistory()
    const verificationsCancelButton = React.useRef(null);
    const [verificationStatus, setVerificationStatus] = useState({});
    const [verificationFields,setVerificationFields]=useState(null);
    const [errorMessage,setErrorMessage]=useState(null);
    const [showDisabledRedBorder, setShowDisabledRedBorder] = useState(false);
    const [showEmailRedBorder,setShowEmailRedBorder]=useState(false);
    const [showtwfaRedBorder,setShowTWfaRedBorder]=useState(false);
    const [loader,setLoader]=useState(false);
    useEffect(() => {
        if(phoneSeconds===0 && (phone.btnName==='code_Sent' || phone.btnName=== 'verifyOtpBtn')){
            setPhone({ ...phone, btnName: 'resendotp', code: '' });
        }
    }, [phoneSeconds]);//eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if(emailSeconds===0 && (email.btnName==='code_Sent' || email.btnName=== 'verifyOtpBtn')){
            setEmail({ ...email, btnName: 'resendotp', code: '' });
        }
    }, [emailSeconds]);//eslint-disable-line react-hooks/exhaustive-deps
    useEffect(()=>{
        adminVerification();
    },[])
    useEffect(() => {
        addBankSave();
      }, [phone.verified, authenticator.verified,email.verified]);

    const addBankSave =()=>{
        if (verificationFields) {
            if ((verificationFields.email && email.verified)||(verificationFields?.twoFa&&authenticator?.verified )||(verificationFields?.phone && phone.verified )||((verificationFields?.phone && phone.verified) && (verificationFields.email && email.verified))||((verificationFields?.phone && phone.verified) && (verificationFields.twoFa && authenticator.verified))||((verificationFields?.email && email.verified) && (verificationFields.twoFa && authenticator.verified))||((verificationFields?.email && email.verified) && (verificationFields.twoFa && authenticator.verified) &&(verificationFields?.phone && phone.verified))){
              props.verificationsComplete()
            }
          }
    }
        const adminVerification=async()=>{
            setLoader(true);
        let res=await adminVerifications();
        if(res.ok){
            setLoader(false);
            setErrorMessage(null);
            setVerificationFields(res.data)
        }else{
            setLoader(false);
            setErrorMessage(isErrorDispaly(res));
        }
        }
    let timeInterval;
    let count = 120;
    const startphoneTimer = () => {
        let timer = count - 1;
        let seconds;
        timeInterval = setInterval(function () {
            seconds = parseInt(timer % 120);
            setPhoneSeconds(seconds);
            if (--timer < 0) {
                timer = count;
                clearInterval(timeInterval);
            }
        }, 1000);
    };
    let timeIntervalemail;
    let countemail = 120;
    const startemailTimer = () => {
        let timer = countemail - 1;
        let seconds;
        timeIntervalemail = setInterval(function () {
            seconds = parseInt(timer % 120);
            setEmailSeconds(seconds);
            if (--timer < 0) {
                timer = countemail;
                clearInterval(timeIntervalemail);
            }
        }, 1000);
    };

    const handleVerificationsCancelButtonFocus= () =>{
        verificationsCancelButton.current.blur()
    }

    const sendEmailOTP = async () => {
        setEmail({ ...email, errorMsg: '', showRuleMsg: '',btnLoader:true })
        let response = await sendEmail( email.requestType);
        if (response.ok) {
        let emailData = { ...email, errorMsg: '', btnName: 'code_Sent', requestType: 'Resend', showRuleMsg: `Enter 6 digit code sent to your Email Id`,btnLoader:false }
        setEmail(emailData)
        
        startemailTimer()
        } else {
            setEmail({ ...email, errorMsg: isErrorDispaly(response), showRuleMsg: '',btnLoader:false })
         
        }
    };

    const verifyEmailOtp = async () => {
        if(!email.code){
            setErrorMsg('Please enter email verification code')
            setEmail({ ...email, errorMsg: 'Please enter email verification code', verified:false});
        }else if(!Number(email.code)){
            setErrorMsg('Invalid email verification code')
            setEmail({ ...email,  verified: false, btnLoader:false });
        }
        else if(email.code && email.code.length > 5){
        setEmail({ ...email, errorMsg: '', showRuleMsg: '',btnLoader:true })
        let response = await verifyEmailCode(email.code);
        if (response.ok) {
            setErrorMsg(null)
            setShowEmailRedBorder(false)
        setEmail({ ...email, errorMsg: '', verified: true, btnName: 'verified', btnLoader:false });
        setVerificationStatus({...verificationStatus, current: verificationStatus.current+1})
        } else if (response.data == null) {
            setErrorMsg('Invalid email verification code')
            setEmail({ ...email, verified: false, btnLoader:false });
        } else {
            setErrorMsg('Invalid email verification code')
            setEmail({ ...email,  btnLoader:false });
        }
    }else{
        setErrorMsg('Invalid email verification code')
        setEmail({ ...email, verified:false});
    }
    };
    const handleEmailinputChange = (e) => {
        setShowEmailRedBorder(false)
        if (e.target.value) {
            setEmail({ ...email, btnName: 'verifyOtpBtn', code: e.target.value })
        } else if(emailSeconds===0 && email.btnName==='code_Sent'){
            setEmail({ ...email, btnName: 'resendotp', code: '' })
        } else if(emailSeconds===0 && (email.btnName==='verifyOtpBtn' || email.btnName==='resendotp') && e.target.value == "") {
            setEmail({ ...email, btnName: 'resendotp', code: '' })
        } else {
            setEmail({ ...email, btnName: 'code_Sent', code: '' })
        }
    };
    const getphoneOTP = async () => {
        setPhone({ ...phone, errorMsg: '', showRuleMsg: '', btnLoader:true })
        let response = await getCode(phone.requestType);
        if (response.ok) {
        let phoneData = { ...phone, errorMsg: '', btnName: 'code_Sent', requestType: 'Resend', showRuleMsg: `Enter 6 digit code sent to ${maskedNumber}`, btnLoader:false }
        setPhone(phoneData)
        startphoneTimer()
        } else {
            setPhone({ ...phone, errorMsg: isErrorDispaly(response), showRuleMsg: '', btnLoader:false })
        }
    };
    const handlephoneinputChange = (e) => {
        setShowDisabledRedBorder(false)
        if (e.target.value) {
            setPhone({ ...phone, btnName: 'verifyOtpBtn', code: e.target.value })
        } else if(phoneSeconds===0 && phone.btnName==='code_Sent') {
            setPhone({ ...phone, btnName: 'resendotp', code: '' })
        }
        else if(phoneSeconds===0 && (phone.btnName==='resendotp' || phone.btnName==='verifyOtpBtn') && e.target.value == "") {
            setPhone({ ...phone, btnName: 'resendotp', code: '' })
        }else {
            setPhone({ ...phone, btnName: 'code_Sent', code: '' })
        }       
    };
    const verifyPhoneOtp = async () => {
        if(!phone.code){
            setPhone({ ...phone, errorMsg: 'Please enter phone verification code', verified: false, btnLoader: false });
        }
        if (phone.code && phone.code.length > 5) {
            setPhone({ ...phone, errorMsg: '', showRuleMsg: '', btnLoader: true })
            let response = await getVerification(phone.code);
            if (response.ok && response.data !== null) {
                setPhone({ ...phone, errorMsg: '', verified: true, btnName: 'verified', btnLoader: false });
                setErrorMsg(null)
            } else {
                setErrorMsg('Invalid phone verification code')
                setPhone({ ...phone, verified: false, btnLoader: false });
            }
        } else {
            setErrorMsg('Invalid phone verification code')
            setPhone({ ...phone,  verified: false, btnLoader: false });
        }
    };

    const verifyAuthenticatorOTP = async () => {
        if(!authenticator.code){
            setErrorMsg('Please enter authenticator code')
            return setAuthenticator({ ...authenticator,verified: false, btnLoader:false });
        }
        if(authenticator.code && authenticator.code.length>5){
            setErrorMsg(null)
            setAuthenticator({ ...authenticator, errorMsg: '', verified: false, btnLoader:true });
        let response = await getAuthenticator(authenticator.code);
        if (response.ok) {
            setAuthenticator({ ...authenticator, errorMsg: '', verified: true, btnName: 'verified', btnLoader:false });
            setErrorMsg(null)
            setShowTWfaRedBorder(false)
        } else if (response.data == null) {
            setErrorMsg('Invalid authenticator verification code')
            setAuthenticator({ ...authenticator, verified: false, btnLoader:false });
        } else {
            setErrorMsg('Invalid authenticator verification code')
            setAuthenticator({ ...authenticator, btnLoader:false });
        }
    }else{
        setErrorMsg('Invalid authenticator verification code')
        setAuthenticator({ ...authenticator, verified: false, btnLoader:false });
    }
    };
    const handleAuthenticatorinputChange = (e) => {
        setShowTWfaRedBorder(false)
        if (e.target.value) {
            setAuthenticator({ ...authenticator, code: e.target.value })
        } else {
            setAuthenticator({ ...authenticator, code: '' })
        }
    };

    const isErrorDispaly = (objValue) => {
        if (objValue.data && typeof objValue.data === "string") {
            return objValue.data;
        } else if (
            objValue.originalError &&
            typeof objValue.originalError.message === "string"
        ) {
            return objValue.originalError.message;
        } else {
            return "Something went wrong please try again!";
        }
    };


    const verificationSave=()=>{
        (
            
            ((verificationFields?.email && email?.verified) && (verificationFields?.twoFa && authenticator?.verified) && (verificationFields?.phone && phone?.verified))||
            ((verificationFields?.phone && phone?.verified) && (verificationFields?.email && email?.verified))||
            (verificationFields?.phone && phone?.verified && verificationFields?.twoFa && authenticator?.verified)||
                            (verificationFields?.email && email?.verified && verificationFields?.twoFa && authenticator?.verified)||
           (verificationFields?.email && email?.verified)||
            (verificationFields?.twoFa && authenticator?.verified )||
            (verificationFields?.phone && phone?.verified )
           ) && props.onConfirmButton()
    }
    const phone_btnList = {
        get_otp: (
            <Button
                type="text"
                style={{ color: "red", margin: "0 auto" }}
                loading={phone.btnLoader}
                onClick={() => getphoneOTP()}><Text className="text-yellow getcode" >Click here to get code</Text></Button>
        ),
        resendotp: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                loading={phone.btnLoader}
                onClick={() => getphoneOTP()}
            ><Text className="text-yellow" >Click here to resend code</Text></Button>
        ),
        code_Sent: (<div style={{ margin: "0 auto"}} className="code-sent-tool-tip">
            <Button
                type="text"
                style={{ color: "black",margin: "0 auto" }}
            ><Text
                className={`pl-0 ml-0 mr-0 text-white-50
        ${"c-notallowed"}`} >Verification code sent</Text></Button>
            <Tooltip
                placement="topRight"
                title={`Haven't received code? Request new code in ${phoneSeconds} seconds. The code will expire after 2 Min.`}>
                <span className="icon md info mr-8" />
            </Tooltip>
        </div>
        ),
        verified: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                disabled={true}
            ><Text className="text-yellow pr-8"> Verified </Text>
                <span className="icon md greenCheck " />
            </Button>
        ),
        verifyOtpBtn: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                onClick={() => verifyPhoneOtp()}
                loading={phone.btnLoader}
            ><Text className={` text-yellow`} >Click here to verify</Text></Button>
        ),
    };
    
    const email_btnList = {
        get_otp: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                loading={email.btnLoader}
                onClick={() => sendEmailOTP()}><Text className="text-yellow getcode" >Click here to get code</Text></Button>
        ),
        resendotp: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                loading={email.btnLoader}
                onClick={() => sendEmailOTP()}><Text className="text-yellow" >Click here to resend code</Text></Button>
        ),
        code_Sent: (<div style={{ margin: "0 auto"}} className="code-sent-tool-tip">
        <Button
            type="text"
            style={{ color: "black",margin: "0 auto" }}
        ><Text
            className={`pl-0 ml-0 mr-0 text-white-50
    ${"c-notallowed"}`} >Verification code sent</Text></Button>
        <Tooltip
            placement="topRight"
            title={`Haven't received code? Request new code in ${emailSeconds} seconds. The code will expire after 2 Min.`}>
            <span className="icon md info mr-8" />
        </Tooltip>
    </div>
    ),
        verified: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                disabled={true}
            ><Text className="text-yellow pr-8"> Verified </Text>
                <span className="icon md greenCheck " />
            </Button>

        ),
        verifyOtpBtn: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                onClick={() => verifyEmailOtp()}
                loading={email.btnLoader}
            ><Text className={` text-yellow`} >Click here to verify</Text></Button>
        ),
    };

    const authenticator_btnList = {
        verified: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                disabled={true}
            ><Text className="text-yellow pr-8"> Verified </Text>
                <span className="icon md greenCheck " />
            </Button>

        ),
        verifyOtpBtn: (
            <Button
                type="text"
                style={{ color: "black", margin: "0 auto" }}
                onClick={() => verifyAuthenticatorOTP()}
                loading={authenticator.btnLoader}
            ><Text className={` text-yellow`} >Click here to verify</Text></Button>
        ),
    };    
    
    return (
        <>
        <Modal
        title={'Verifications'}
        visible={props.visible}
        closeIcon={
            <Tooltip title="Close">
                <span className="icon md x c-pointer" onClick={()=>props.closeModal()} />
            </Tooltip>
        }
        destroyOnClose={true}
        footer={(props.userConfig.twoFA.isEnabled === 'true' || props.userConfig.twoFA.isEnabled === true) &&
            <div className="text-right mt-24">
                 <Button
                    type="primary"
                    className="primary-btn cancel-btn"
                    ref={verificationsCancelButton}
                    onClick={()=>{
                        setCancelModalIsOpen(true)
                        handleVerificationsCancelButtonFocus()
                    }}
                    >
                    Cancel
                </Button>
                <Button type="primary" className="primary-btn" 
                    onClick={()=>{
                        ((phone.btnName != 'verified' && setShowDisabledRedBorder(true))||(authenticator.btnName != 'verified' &&  setShowTWfaRedBorder(true)) || (email.btnName != 'verified' && setShowEmailRedBorder(true)))
                          if(verificationFields.phone &&  !phone.code){
                            setErrorMsg("Please enter phone verification code")
                          }
                          else if(verificationFields.phone && !phone.verified){
                               setErrorMsg('Please verify phone verification code')
                            }
                            else if(verificationFields.email && !email.code){
                                setErrorMsg('Please enter email verification code')
                            }
                            else if(verificationFields.email && !email.verified )
                            {
                                setErrorMsg('Please verify email verification code')
                            }
                           else if(verificationFields.twoFa && !authenticator.code){
                            setErrorMsg('Please enter authenticator code')
                           }
                            else if(verificationFields.twoFa && !authenticator.verified)
                            {
                                setErrorMsg('Please verify authenticator code')
                            }else{
                                setErrorMsg(null)
                                form.submit()
                            }
                        
                    }}
                    loading={props.loadingBtn}
                    >Confirm
                </Button>
               
            </div>
        }>
    
        <div className="mt-16">
            <div ref={useOtpRef}></div>
            {errorMsg && (
                    <Alert
                    className="mb-12"
                    showIcon
                    onClose={() => setErrorMsg(null)}
                    description={errorMsg}
                    closable={false}
                    type="error"
                />
                )}
                 {errorMessage && (
                    <Alert
                    className="mb-12"
                    showIcon
                    onClose={() => setErrorMessage(null)}
                    description={errorMessage}
                    closable={false}
                    type="error"
                />
                )}
                {loader &&<Loader/>}
            {(props.userConfig.twoFA.isEnabled === 'true' || props.userConfig.twoFA.isEnabled === true) ?
            (
                <>
                    <Form
                        className="mt-36"
                        name="advanced_search"
                        form={form}
                        onFinish={verificationSave}
                        autoComplete="off">
                        <>
                        {verificationFields?.phone &&(<>
                            <Text className="mb-8 px-4 fw-500 pt-16">
                                Phone Verification Code <span style={{color: 'rgb(255,78,79)'}}>*</span>
                            </Text>
                            <Form.Item
                                name="phoneCode"
                                className="input-label otp-verify"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Is required'
                                    }
                                ]}
                                extra={
                                    <div>
                                        <Text className="fs-12 text-white-30 fw-200">
                                            {phone.showRuleMsg}
                                        </Text>
                                        <Text
                                            className="fs-12 text-red fw-200"
                                            style={{ float: "right", color: "var(--textRed)" }}>
                                        </Text>
                                    </div>
                                }
                                
                            >
                                <div className="p-relative d-flex align-center">
                                    <Input
                                        type="text"
                                        className="cust-input custom-add-select mb-0"
                                        placeholder={"Enter code"}
                                        maxLength={6}
                                        style={{ width: "100%", textAlign: 'center', height: '50px', borderColor: showDisabledRedBorder && 'red'}}
                                        disabled={phone.btnName === 'get_otp' || phone.btnName === 'verified'}
                                        onChange={(e) => handlephoneinputChange(e)}
                                    />     
                                    <div className="new-add c-pointer get-code text-yellow hy-align">
                                        {phone_btnList[phone.btnName]}
                                    </div>
                                </div>
                            </Form.Item>
                        </>
                        )}

                        {verificationFields?.email  && (<>
                            <Text className="mb-8 px-4 fw-500 pt-16">
                                Email Verification Code <span style={{color: 'rgb(255,78,79)'}}>*</span>
                            </Text>
                            <Form.Item
                                name="emailCode"
                                className="input-label otp-verify"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Is required'
                                    }
                                ]}
                                extra={
                                    <div>
                                        <Text className="fs-12 text-white-30 fw-200">
                                            {email.showRuleMsg}
                                        </Text>
                                        <Text
                                            className="fs-12 text-red fw-200"
                                            style={{ float: "right", color: "var(--textRed)" }}>
                                        </Text>
                                    </div>
                                }
                            >
                                <div className="p-relative d-flex align-center">
                                <Input
                                            type="text"

                                            className="cust-input custom-add-select mb-0"
                                            placeholder={"Enter code"}
                                            maxLength={6}
                                            style={{ width: "100%", textAlign: 'center', height: '50px', borderColor: showEmailRedBorder && 'red' }}
                                            disabled={email.btnName === 'get_otp' || email.btnName === 'verified'}
                                            onChange={(e) => handleEmailinputChange(e)}
                                        />         
                                    <div className="new-add c-pointer get-code text-yellow hy-align">
                                        {email_btnList[email.btnName]}
                                    </div>
                                </div>
                            </Form.Item>
                        </>
                        )}

                        {verificationFields?.twoFa &&(<>
                            <Text className="mb-8 px-4 fw-500 pt-16">
                                Authenticator Code <span style={{color: 'rgb(255,78,79)'}}>*</span>
                            </Text>
                            <Form.Item
                                name="authenticatorCode"
                                className="input-label otp-verify"
                                extra={
                                    <div>
                                        <Text className="fs-12 text-white-30 fw-200">
                                            {authenticator.showRuleMsg}
                                        </Text>
                                        <Text
                                            className="fs-12 text-red fw-200"
                                            style={{ float: "right", color: "var(--textRed)" }}>
                                        </Text>
                                    </div>
                                    }
                                rules={[
                                    {
                                        required: true,
                                        message: "Is required",
                                    },
                                        ]}
                                >
                                    <div className="p-relative d-flex align-center">
                                        <Input
                                                type="text"
                                                className="cust-input custom-add-select mb-0"
                                                placeholder={"Enter code"}
                                                maxLength={6}
                                                style={{ width: "100%", textAlign: 'center', height: '50px', borderColor: showtwfaRedBorder && 'red'}}
                                                disabled={authenticator.btnName === 'get_otp' || authenticator.btnName === 'verified'}
                                                onChange={(e) => handleAuthenticatorinputChange(e)}
                                        />                                    
                                    
                                        <div className="new-add c-pointer get-code text-yellow hy-align">
                                            {authenticator_btnList[authenticator.btnName]}
                                        </div>
                                    </div>
                                </Form.Item>
                            </>
                        )}
                        </>
                    </Form>
                </>
                
            )
            :
            (<Alert showIcon
                type="error"
                message={"Information"}
                description={
                <p>
                    You are unable to proceed without enabling 2FA.
                    Please enable 2FA from <a 
                    onClick={()=>{
                    history.push("/userprofile/2") 
                    window.scrollTo(0,0)}}>
                        Security Section
                    </a>.
                </p>}
                closable={false}
            />)
            }

        </div>
        </Modal>
        <Modal
            visible={cancelModalIsOpen}
            closable={false}
            title={'Confirm'}
            centered={true}
            footer={
                <>
                    <Button type="primary" className="primary-btn cancel-btn"
                        onClick={()=> {
                            setCancelModalIsOpen(false)
                            } }>
                        No
                    </Button>
                    <Button type="primary" className="primary-btn"
                        onClick={ () => {
                            setCancelModalIsOpen(false)
                            props.closeModal()
                        }}
                        >
                        Yes
                    </Button>
                </>
            }
        >
             <p className="fs-16 mb-0">Are you sure you want to cancel?</p>
        </Modal>
        </>       
    );
};

const connectStateToProps = ({ oidc, userConfig }) => {
    return {
        auth: oidc.profile,
        userConfig
    };
};

export default connect(connectStateToProps)(VerificationsModal)