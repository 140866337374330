import React, { useState } from 'react';
import { Image, Modal,Empty } from 'antd';
import FilePreviewer from "react-file-previewer";
const IdentityDetails = ({ identityDetails=[] }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewPath,setPreviewPath]=useState(null);

  const handleClosePreview = () => {
    setPreviewVisible(false);
  };
  const openPDFFile=(url)=>{
    setPreviewVisible(true);
    setPreviewPath(url)
  }
  const filePreviewPath = () => {
		return previewPath;
	};
  return (
    <div className='identity-image d-flex gap-16 wrap-auto'>
              {identityDetails?.map(({ documentType, url }, index) => (
          <div key={index}>
            {documentType?.includes('image') && (
              <Image
                src={url}
                alt={`Image ${index + 1}`}
                style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }}
              />
            ) || documentType === 'pdf' && (
              <div className='pdf-document'>
                <div>
                  <div className='text-center'>
                    <span onClick={() => openPDFFile(url)} className='icon pdfdocument c-pointer'></span>
                  </div>
                  <div className='text-center mt10'>
                    <a onClick={() => openPDFFile(url)} rel="noopener noreferrer" className='text-center'>
                      View PDF
                    </a>
                  </div>
                </div>
              </div>
            ) || null}
          </div>
        ))}

                      {identityDetails?.length ==0 && 
                      <Empty
                    style={{width:"100%"}}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                ></Empty>}
     <Modal
        visible={previewVisible}
        onCancel={handleClosePreview}
        style={{
          top: 20,
        }}
        footer={null}
        width={1000}
      >
        <h1>File Previewer</h1>
        <FilePreviewer
				hideControls={true}
				file={{
          url: previewPath ? filePreviewPath() : null,
					mimeType:  "application/pdf",
				}}
			/>
      </Modal>  
    </div>
  );
};

export default IdentityDetails;
