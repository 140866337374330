import apiCalls from "../api/apiCalls";

const USEER_INFO = "userInfo";
const CLEAR_USER_INFO = "clearUserInfo";
const FETCH_TRACK_AUDITLOGS = "fetchtrackauditlogs";
const UPDATE_TWOFACTOR = "updatetwofactor";

const updatetwofactor = (payload) => {
    return {
        type: UPDATE_TWOFACTOR,
        payload
    }
};
const userInfo = (payload) => {
    return {
        type: USEER_INFO,
        payload
    }
};
const clearUserInfo = () => {
    return { type: CLEAR_USER_INFO, payload: null }
}
const fetchtrackauditlogs = (payload) => {
    return {
        type: FETCH_TRACK_AUDITLOGS,
        payload
    }
};

const getmemeberInfo = () => {
    return async (dispatch) => {
        apiCalls.getMember().then((res) => {
            if (res.ok) {
                dispatch(userInfo(res.data))
                dispatch(updatetwofactor({ loading: false, isEnabled: res.data.twofactorVerified }));
            }
        });
    }
}


const getIpRegisteryData = () => {
    return async (dispatch) => {
        await apiCalls.getIpRegistery().then((res) => {
            var userAgent = window.navigator.userAgent;
            var Browser = userAgent.match(/(chrome|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)[1];
            var version = userAgent.match(/(chrome|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)[2];
            var osRegex = /(Windows NT|Mac OS X|Linux|iPhone|iPad|iPod|Android)[^\s;)]*/i;
            var osMatch = userAgent.match(osRegex);    
            var operatingSystem = osMatch ? osMatch[0] : "Unknown";
            const start = userAgent.indexOf('(') + 1;
            const end = userAgent.indexOf(')');
            const deviceInfo = userAgent.substring(start, end);
            const deviceName = deviceInfo.split(';')[0].trim();
            if (res.ok) {
                let ipInfo = {
                    "Ip": res.data.ip,
                    "Location": {
                        "countryName": res.data?.country_name,
                        "state": res.data.region_name,
                        "city": res.data?.city,
                        "postal": res.data?.zip,
                        "latitude": res.data?.latitude,
                        "longitude": res.data?.longitude
                    },
                    "Browser": Browser,
                    "DeviceType": {
                        "name": deviceName,
                        "type": res.data?.type,
                        "version":operatingSystem + " " + version
                    }
                }
                dispatch(fetchtrackauditlogs(ipInfo));
            }
        });
    }
}
let initialState = {
    userProfileInfo: null,
    trackAuditLogData: {},
    twoFA: { loading: true, isEnabled: false }
};
const UserConfig = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case USEER_INFO:
            state = { ...state, userProfileInfo: action.payload }
            return state;
            /* falls through */
        case CLEAR_USER_INFO:
            state = { userProfileInfo: null, trackAuditLogData: {} };
            /* falls through */
        case FETCH_TRACK_AUDITLOGS:
            state = { ...state, trackAuditLogData: action.payload }
            return state;
            /* falls through */
        case UPDATE_TWOFACTOR:
            if (typeof action.payload == "boolean")
                state = { ...state, userProfileInfo: { ...state.userProfileInfo, twofactorVerified: action.payload } }
            else
                state = { ...state, twoFA: { loading: action.payload.loading, isEnabled: action.payload.isEnabled } }
            return state;
            /* falls through */
        default:
            return state;
    }
}

export default UserConfig;
export { userInfo, getmemeberInfo, getIpRegisteryData, fetchtrackauditlogs, clearUserInfo, updatetwofactor };
