import React, { Component } from "react";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import {
  Button,
  Modal,
  Tooltip,
  Form,
  Select,
  message,
  Row,
  Col,
  Alert
} from "antd";
import Loader from "../loader.component";
import {
  setBreadcrumb,
} from "../../reducers/breadcrumbReducer";
import { validateContentRule } from "../../utils/custom.validator";
import { connect } from "react-redux";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { statusAlert,getStates } from "./api";
import Moment from "react-moment";
import CreateCase from "../../utils/createCase";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import {CaseRediractions} from '../../utils/caseRediractions';
import config from "../../config";
const { Option } = Select;
class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      check: false,
      selection: [],
      selectedObj: {},
      type: "",
      caseModal: false,
      stateLoading: true,
      error: null,
      obj: {
        id: "",
        screenName: "alerts",
        state: ""
      },
      alert: false,
      stateChange: {},
      modal: false,
      btnDisabled: false,
      stateHistory: false,
      modalVisible: false,
      loading: false,
      warningMsg:null,
      errorMessage:null,
      isApprove: false,
      statesLst:[]
    };
    this.formref = React.createRef();
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.gridRef = React.createRef();
  }

  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked:true,
      customCell: (props) => (
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
              className="grid_check_box"
            />
            <span></span>
          </label>
      )
    },
    {
      field: "createdDate",
      title: "Date",
      width: 160,
      filter: true,
      locked:true,
      filterType: "date",
      customCell: (props) => (
        <td>
          <div className="gridLink" onClick={() => this.caseView(props)}>
          <Moment format={config?.dateFormates?.dateFormate}>
                {apiCalls.convertUTCToLocalTime(props.dataItem?.createdDate)}
              </Moment>
          </div>
        </td>
      )
    },
    { field: "alertNumber", title: "Alert Number", width: 150, filter: true },
    { field: "alert", title: "Alert Title", width: 200, filter: true },
    { field: "name", title: "Customer Name", width: 180, filter: true },
    {
      field: "caseids",
      title: "Case Number",
      width: 160,
     

      customCell: (props) => (
        <td>
           <div className="gridLink" >
            {props.dataItem.caseIds?.map(item=><div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
          </div>
  
        </td>
      )
    },
    { field: "email", title: "Customer Email", width: 250, filter: true },
    { field: "feature", title: "Feature", width: 180, filter: true },
    { field: "type", title: "Type", width: 120, filter: true },
    { field: "alertMode", title: "Alert Mode", width: 150, filter: true },
    { field: "createdBy", title: "Created By", width: 200, filter: true },
    { field: "modifiedBy", title: "Modified By", width: 200, filter: true },
    { field: "state", title: "Status", width: 120, filter: true }
  ];

  componentDidMount = () => {
    setCurrentAction(null);
  };
  hideCaseModal = () => {
    this.setState({ ...this.state, caseModal: false, selection: []}, ()=>  this.gridRef.current.refreshGrid());
  }

  caseView = (e) => {
    const items = e.dataItem;
    this.props.dispatch(setCurrentAction(null));
    const val = items.id;
    this.props.history.push("/alerts/" + val + "/alertview");
    this.props.dispatch(
      setBreadcrumb({
        key: "/alerts/" + val + "/alertview",
        val: e.dataItem.alert
      })
    );
   
  };
  
  handleInputChange = (prop, e) => {
    const rowChecked = prop.dataItem;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection,error: null,
      warningMsg:null,
      errorMessage:null
    });
  };
  
  createCaseView = (CaseData) => {
    let propsData=this.props
    this.props.dispatch(updateCurrentScreen("cases"));
    CaseRediractions(CaseData,propsData)
  };
  createCase = () => {
    if(!this.state.check){
      this.setState({ ...this.state,errorMessage:null,warningMsg:"Please select the one record",error: null, });
    }
     else {
    this.setState({ ...this.state, caseModal: true,check: false });
    }
  }
  add = () => {
    this.setState({...this.state,loading:true})
    this.props.history.push({
      pathname: "/alerts/00000000-0000-0000-0000-000000000000/alertdetails/add",
      state: { pKey: "alerts", action: "add" }
    });
    this.props.dispatch(
      setBreadcrumb({
        key: "/alerts/00000000-0000-0000-0000-000000000000/alertdetails/add",
        val: "Add Alert"
      })
    );

  };

  

  edit = () => {
    this.setState({errorMessage:null,selection:[],warningMsg:null,error: null,})
    if (!this.state.check) {
      this.setState({errorMessage:null, warningMsg:"Please select the one record" ,error: null,});
    }
   else {
      const obj = this.state.selectedObj;
      const val = obj.id;
      const caseNumber=obj.caseNumber;
      if(caseNumber === "" || caseNumber === null){
         if (obj.state === "Completed" || obj.state === "Dismissed") {
          this.props.history.push("/alerts/" + val + "/alertdetails/disabled");
          this.props.dispatch(
            setBreadcrumb({
              key: "/alerts/" + val + "/alertdetails/disabled",
              val: obj.alert
            })
          );
        }else {
          this.props.history.push("/alerts/" + val + "/alertdetails/edit");
          this.props.dispatch(
            setBreadcrumb({
              key: "/alerts/" + val + "/alertdetails/edit",
              val: obj.alert
            })
          );
        }
      }
      else{

        this.setState({selection: [],error: null,warningMsg:null,errorMessage:"Already case created with this alert, So can't edit"})
      }
      
    }

  };

  stateChange = (val) => {
    let { stateChange } = this.state;
    stateChange.status = val;
    this.setState({ ...this.state, stateChange,error: null,errorMessage:null,warningMsg:null });
  };

  handleCancel = (e) => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      check: false,
      error: null,
      errorMessage:null,warningMsg:null
    });
    this.formref.current.resetFields();
  };
  success = (status) => {
    message.success({
      content:
        "Record " +
        (status === "Completed" || status === "Complete"
          ? "Completed"
          : "Dismissed") +
        " successfully",
      className: "custom-msg",
      duration: 1
    });
  };
  statusChange = () => {
    this.setState({
     ...this.state,
     modal: false,
     selection: [],
     check: false,
     error: null,
     errorMessage:null,warningMsg:null
   });
   if (!this.state.check) {
     this.setState({...this.state, warningMsg:"Please select the one record"});
   } else {
     getStates(this.state.selectedObj.state).then(response => {
       if (response.ok) {
           this.setState({ ...this.state, statesLst: response.data, stateLoading: false });
       }else{
         this.setState({ ...this.state,  error: apiCalls.isErrorDispaly(response) });
       }
   })
     this.setState({...this.state,modal: true, errorMessage:null,
         stateChange: {
           status: this.state.selectedObj.state,
           type: this.state.selectedObj.type,
         }
       },
       () => {
         this.setState({...this.state,stateLoading: true,errorMessage:null});
         setTimeout(
           () =>
             this.setState({...this.state,stateLoading: false}),
           1000
         );
         setTimeout(
           () =>
             this.formref.current.setFieldsValue({
               ...this.state,
               status: this.state.selectedObj.state
             }),
           1000
         );
       }
     );
   }
 };
  success = (state) => {
    switch (state) {
      case "Complete":
        state = "completed";
        break;
      case "Dismissed":
        state = "Dismissed";
        break;
      case "Submitted":
        state = "Submitted";
        break;
    }
    message.success({
      content: "Alert " + state + " successfully",
      className: "custom-msg",
      duration: 3
    });
  };
  error = () => {
    this.setState({...this.state,warningMsg:"Please select the one record"})
  };
  handleOk = async (values) => {
    if (!this.isLoading) {
      this.setState({ ...this.state, error: null,isApprove: false,errorMessage:null, btnDisabled: true });
      this.isLoading = true;
      let statusObj = {};
      statusObj.id = this.state.selectedObj.id;
      statusObj.status = values.status || this.state.selectedObj.state;
      statusObj.customerId = this.props.userConfig?.id;
      statusObj.info = JSON.stringify(this.props.trackAuditLogData);
      statusObj.modifiedBy=this.props.userConfig.userName;
      let response = await statusAlert(statusObj);
      if (response.ok) {
        this.props.dispatch(setCurrentAction(null));
        this.props.history.push("/alerts");
        this.setState({ stateLoading: true, btnDisabled: false });
        this.success(statusObj.status);
        this.setState({
          ...this.state,
          modal: false,
          selection: [],
          check: false,
          isApprove: false,errorMessage:null
        });
        setTimeout(() => {
          this.isLoading = false;
        }, 2000);
        this.gridRef.current.refreshGrid();
      } else {
        this.setState({
          ...this.state,
          isApprove: false,btnDisabled: false,
          error: apiCalls.isErrorDispaly(response),errorMessage:null
        });
      }
    }

  
  };
  onActionClick = (key) => {
    const actions = {
      add: "add",
      edit: "edit",
      "State Change": "statusChange",
      "Create Case": "createCase"
    };
    this[actions[key]]();
  };
  render() {
    const { loading, selectedObj,caseModal,warningMsg,errorMessage,error } = this.state;

    return (
      <>
        {loading && <Loader />}
        {warningMsg !== undefined && warningMsg !== null && warningMsg !== "" && (
            <div style={{ width: '100%' }}>
              <Alert
                className="w-100 mb-16 align-center"
                type="warning"
                description={warningMsg}
                showIcon
              />
            </div>
          )}
          {errorMessage !== undefined && errorMessage !==null && errorMessage !=="" && (
                <div style={{ width: '100%' }}>
                <Alert className="w-100 mb-16 align-center" type="error" showIcon description={errorMessage} />
                </div>
              )}
        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"alerts"}
          ref={this.gridRef}
          url={process.env.REACT_APP_GRID_API + "Alert/GetAlertK"}
          columns={this.gridColumns}
        />
        <Modal
          title="Confirm Complete/Dismiss?"
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {this.state.stateLoading && <Loader />}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.handleOk}
            >
              {error !== undefined && error!==null &&error!=="" && (
                <div style={{ width: '100%' }}>
                <Alert className="w-100 mb-16 align-center" type="error" showIcon message={error} />
                </div>
              )}
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="status"
                    label="State"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required",
                        whitespace: true
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Select
                      className="cust-input mb-0"
                      onChange={(e) => this.stateChange(e)}
                      disabled={this.state.selectedObj.state !== "Submitted" && this.state.selectedObj.state !=="Pending"}
                      placeholder="Select State"
                    >
                        {this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                     
                    </Select>
                  </Form.Item>
                </Col>

              </Row>
              <Form.Item className="mb-0">
               
               <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  {selectedObj.state === "Submitted" &&
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    disabled={
                      this.state.stateChange.status === "Submitted" ||
                      this.btnDisabled
                    }

                    loading={this.state.btnDisabled}
                  >
                    Save
                  </Button>}
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Create Case"
          visible={caseModal}
          width={1000}
          style={{top: 16}}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          destroyOnClose
          footer={null}
        >
         <CreateCase FromScreen='Alerts' ScreenId={this.state.selectedObj.id} CreateCaseId={this.state.selectedCaseId} ScreenName="alerts" closeModal={this.hideCaseModal} />
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return {
    breadCrumb,
    userConfig: userConfig.userProfileInfo,
    oidc,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(Alerts);