import React, { useState } from "react";
import Moment from "react-moment";
import {Tooltip,message,Modal,Button,Alert} from "antd";
import apiCalls from "../api/apiCalls";
import config from "../config";
const LockUnlock=(props)=>{
const [isBtnLoader,setIsBtnLoader]=useState(false);
const [lockError,setIsLockError]=useState(null);
   const isSaveLock=async()=>{
    setIsLockError(null)
    setIsBtnLoader(true)
     let lockunlock;
     if (props.selectedObj?.lockUnlock === "Unlocked") {
       lockunlock = "Locked";
     } else if (props.selectedObj?.lockUnlock === null) {
       lockunlock = "Locked";
     } else {
       lockunlock = "Unlocked";
     }
        let obj = {
            "Id": props?.selectedObj?.id,
            "lockunlock": lockunlock,
            "Modifiedby": props?.ModifiedBy,
            "ModifiedDate": new Date(),
        }
        let res = await  apiCalls.lockUnlock(props.screen,obj);
        if(res.ok){   
          let lockUnlockMessage;
          if (props.selectedObj?.lockUnlock === "Unlocked") {
            lockUnlockMessage = "Locked";
          } else if (props.selectedObj?.lockUnlock === null) {
            lockUnlockMessage = "Locked";
          } else {
            lockUnlockMessage = "Unlocked";
          }        
            message.success({
              content: `Record ${lockUnlockMessage} successfully`,
                className: "custom-msg",
                duration: 1
              });
              props.isGridRefresh()
              props.LockedPopClose()
              setIsBtnLoader(false)
        }else{
            setIsBtnLoader(false);
            setIsLockError(apiCalls.isErrorDispaly(res));
        }
        }
  let action;
  if (props?.selectedObj?.lockUnlock === "Unlocked") {
    action = "lock";
  } else if (props?.selectedObj?.lockUnlock === null) {
    action = "lock";
  } else {
    action = "unlock";
  }
    return(<>
              <Modal
          title="History"
          visible={props?.isModal}
          className="crypto-list"
          destroyOnClose
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={() =>
                    props.LockedPopClose(setIsLockError(null))
                }
              />
            </Tooltip>
          }
		  
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() =>
                props.LockedPopClose(setIsLockError(null))
              }>
              Close
            </Button>
          }>
			   <table className="commision-table edit-commition-table table-responsive">
            <tr> 
              <th>Action</th>              
              <th>Name</th>
			  <th>Date</th>
            </tr>
            {props.getLockandUnLockDetails?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.lockunlock}</td>
                  <td>{item?.modifiedby}</td> 
                  <td>
                  {item?.modifiedDate ? <Moment format={config?.dateFormates?.dateTimeFormate} >{item?.modifiedDate && apiCalls.convertUTCToLocalTime(item?.modifiedDate) || item?.modifiedDate}</Moment> : item?.modifiedDate}
                    </td>
                </tr>
              );
            })}
          </table>
        </Modal>

        <Modal
          title="Confirm Lock/Unlock?"
          visible={props.isCheckLock}
          className="crypto-list"
          destroyOnClose
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={() =>
                    props.LockedPopClose(setIsLockError(null))
                }
              />
            </Tooltip>
          }
          footer={<>
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() =>
                props.LockedPopClose(setIsLockError(null))
              }>
              No
            </Button>
            <Button
			type="submit"
			className="primary-btn"
			onClick={() =>
			isSaveLock()
			}
            loading={isBtnLoader}>
			Yes
		  </Button></>
          }>
            {lockError !== undefined && lockError !== null && (
					<Alert
						className="w-100 mb-16"
						type="warning"
						description={lockError}
						showIcon
					/>
				)}
				<p>Do you really want to {action}?</p>
        </Modal>
    </>)
}
export default LockUnlock;