
import React, { Component } from 'react';
import { Row, Col, Typography, Alert } from 'antd';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Loader from '../loader.component';
import { handleTransaction } from '../../reducers/transactionReducer'
import { setCurrentAction } from '../../reducers/actionsReducer';
import Moment from 'react-moment';
import apiCalls from '../../api/apiCalls';
import { publishShowActions } from '../grid.component/subscribir';
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { Link } from 'react-router-dom';
import config from "../../config";

const { Title } = Typography

class TransactionSummary extends Component {
  state = {
    transactionData: {},
  }
  componentDidMount() {
    this.props.setAction(null)
    this.props.fetchtransactionData(this.props.match.params.id)
    publishShowActions(false);
  }

  redirectCustomerView = () => {
    const items = this.props.transactionStore.transactionData;
    const val = items.data.customerId;
    this.props.dispatch(
      setBreadcrumb({
        key: "/customers/details/" + val,
        val: items.data.customerName
      })
    );
    localStorage.setItem("MyURL","/customers/details/" + val)
    window.open("/customers/details/" + val);

  };
  renderTransactionCommission = (commissionIcon,commissionLable,CommissionValue) => (
    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
      <span className={`icon md ${commissionIcon}`} />
      <div>
        <label className="kpi-label">{commissionLable}</label>
        <div className=" kpi-val">
          {CommissionValue ? (
            <NumberFormat
              value={CommissionValue}
              decimalSeparator="."
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={8}
            />
          ) : (
            "0"
          )}
        </div>
      </div>
    </Col>
  )
  render() {
    const { data: transactionData, loading, error } = this.props.transactionStore.transactionData;
    return (
      <>
        {loading && <Loader />}
        <Title className="page-title">Transaction Detail View</Title>
        {error !== undefined && error !== null && (
          <Alert type="error" className="mb-16" showIcon message={error} />
        )}

        {transactionData && (
          <Row gutter={8}>
            <Col xl={24} xxl={24} className="bank-view">
              <Row className="kpi-List">
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md date" />
                  <div>
                    <label className="kpi-label">Submission Date</label>
                    <div className="kpi-val">
                      <Moment format={config?.dateFormates?.dateTimeFormate}>
                        {transactionData?.date
                          ? apiCalls.convertUTCToLocalTime(
                            transactionData?.date
                          )
                          : transactionData?.date}
                      </Moment>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md user" />
                  <div>
                    <label className="kpi-label">Customer/Contract ID</label>
                    <div className="kpi-val">{transactionData?.referenceId || "-"}</div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md user" />
                  <div>

                    <div>

                      <label className="kpi-label">{transactionData?.isBusiness ? <><b>Business Name</b>/<>Personal Name</> </> : <><>Business Name</>/<b>Personal Name</b></>}</label>
                      <br />
                      <span className="kpi-val c-pointer">
                        <Link onClick={() => this.redirectCustomerView()}>
                          {transactionData?.customerName}
                        </Link>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md user" />
                  <div>
                    <label className="kpi-label">User Name</label>
                    <div className="kpi-val">{transactionData?.userName || "-"}</div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md email" />
                  <div>
                    <label className="kpi-label">Email</label>
                    <div className=" kpi-val">
                      {transactionData?.customerEmail || "-"}
                    </div>
                  </div>
                </Col>


                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md acnum" />
                  <div>
                    <label className="kpi-label">Transaction</label>
                    <div className=" kpi-val">{transactionData?.docType || "-"}</div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md wallet" />
                  <div>
                    <label className="kpi-label">From Wallet Code</label>
                    <div className="kpi-val">
                      {transactionData?.fromwalletCode
                        ? transactionData?.fromwalletCode
                        : "-"}
                    </div>
                  </div>
                </Col>
                {this.renderTransactionCommission("file","From Value",transactionData?.fromvalue)}
               
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md wallet" />
                  <div>
                    <label className="kpi-label">To Wallet Code</label>
                    <div className="kpi-val">
                      {transactionData?.towalletCode
                        ? transactionData?.towalletCode
                        : "-"}
                    </div>
                  </div>
                </Col>
                {this.renderTransactionCommission("file","To Value",transactionData?.tovalue)}
                {this.renderTransactionCommission("file","From Before Value",transactionData?.fromvaluebefore)}
                {this.renderTransactionCommission("file","From After Value",transactionData?.fromvalueafter)}
                {this.renderTransactionCommission("file","To Before Value",transactionData?.tovaluebefore)}
                {this.renderTransactionCommission("file","To After Value",transactionData?.tovalueafter)}
                {this.renderTransactionCommission("commission","Fees",transactionData?.effectiveFee)}
                {this.renderTransactionCommission("commission","Commission",transactionData?.comission)}
                {this.renderTransactionCommission("commission","Commission in Base",transactionData?.comissioninUSD)}
                {this.renderTransactionCommission("commission","Provider Commission",transactionData?.providerComission)}          
                {this.renderTransactionCommission("commission","Provider Commission in Base",transactionData?.providerComissioninUSD)}    
                {this.renderTransactionCommission("commission","Sweep Source Commission",transactionData?.sweepsourceComissionValue)}
                {this.renderTransactionCommission("commission","Sweep Source in Base",transactionData?.sweepSourceValueInBase)}
                {this.renderTransactionCommission("commission","Sweep Destination Commission",transactionData?.sweepDestinationComissionValue)}
                {this.renderTransactionCommission("commission","Sweep Destination in Base",transactionData?.sweepDestinationValueInBase)}
                {this.renderTransactionCommission("cash","Total cost",transactionData?.totalAmount)}
                {this.renderTransactionCommission("commission","Profit",transactionData?.profit)}
                {this.renderTransactionCommission("commission","Profit in Base",transactionData?.profitInUSD)}               
                
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                  <span className="icon md file" />
                  <div>
                    <label className="kpi-label">Provider</label>
                    <div className="kpi-val">
                      {transactionData?.provider
                        ? transactionData?.provider
                        : "-"}
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md commission" />
                  <div>
                    <label className="kpi-label">Wallet Score</label>
                    <div className="kpi-val">
                      {transactionData?.walletScore
                        ? transactionData?.walletScore
                        : "-"}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const connectStateToProps = ({ transactionStore }) => {
  return { transactionStore }
}
const connectDispatchToProps = dispatch => {
  return {
    fetchtransactionData: (transId) => {
      dispatch(handleTransaction(transId))
    },
    setAction: (val) => {
      dispatch(setCurrentAction(val))
    },
    dispatch
  }
}

export default connect(connectStateToProps, connectDispatchToProps)(TransactionSummary);
