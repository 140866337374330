import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Alert } from "antd";
import Loader from "../loader.component";
import { publishShowActions } from "../grid.component/subscribir";
import { getSelfHostedData } from "./api";
import { connect } from 'react-redux';
import { setCurrentAction } from '../../reducers/actionsReducer';
import apiCalls from "../../api/apiCalls";
import Moment from 'react-moment';
import config from "../../config";

const { Title, Text } = Typography;

const SelfHostedDetailedView = (props) => {
    const [loading, setLoading] = useState(false);
    const [fiatData, setFiatData] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        publishShowActions(false);
        getLoadData();
    }, []);//eslint-disable-line react-hooks/exhaustive-deps
    const getLoadData = async () => {
        setLoading(true);
        let response = await getSelfHostedData(props.match.params.id);
        if (response.ok) {
            setError(null)
            setFiatData(response.data);
        } else {
            setError(apiCalls.isErrorDispaly(response));
        }
        setLoading(false);
    };

    const backToAddressBook = () => {
        props.history.push("/selfhostedwallets");
    };

    return (
        <>
            {loading && <Loader />}
            {error !== undefined && error !== null && <Alert type="error" className="mb-16" showIcon message={error} />}
            <Title className="page-title"> SELF-HOSTED DETAILS</Title>
            {fiatData && (
                <>
                    <Row gutter={8}>
                        <Col xl={24} xxl={24} className="bank-view">

                            <Row className="kpi-List" style={{ paddinTop: '10' }}>

                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <div>
                                        <label className="kpi-label">Created Date</label>
                                        <div className=" kpi-val">
                                            <Moment format={config?.dateFormates?.dateTimeFormate}>
                                                {fiatData?.createdDate
                                                    ? apiCalls.convertUTCToLocalTime(
                                                        fiatData?.createdDate
                                                    )
                                                    : fiatData?.createdDate}
                                            </Moment>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <div>
                                        <label className="kpi-label">Proof Type</label>
                                        <div className="kpi-val">{fiatData?.proofType || "-"}</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <div>
                                        <label className="kpi-label">Asset</label>
                                        <div className="kpi-val">{fiatData?.asset || "-"}</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <div>
                                        <label className="kpi-label">Wallet Address</label>
                                        <div className="kpi-val">{fiatData?.fromAddress || "-"}</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <div>
                                        <label className="kpi-label">Amount</label>
                                        <div className=" kpi-val">{fiatData?.amount || "-"}</div>
                                    </div>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                    <div>
                                        <label className="kpi-label">Status</label>
                                        <div className="kpi-val">{fiatData?.status || "-"}</div>
                                    </div>
                                </Col>
                               
                                
                               
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
            <div className="text-right mt-24">
                <Button
                    type="primary"
                    className="primary-btn cancel-btn"
                    style={{ margin: "0 8px" }}
                    onClick={backToAddressBook}>
                    Cancel
                </Button>
            </div>
        </>

    );
};
const connectStateToProps = ({ userConfig }) => {
    return { userConfig: userConfig.userProfileInfo }
}
const connectDispatchToProps = dispatch => {
    return {
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(SelfHostedDetailedView);
