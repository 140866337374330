import React, { Component } from "react";
import { Typography, Button, Upload, message, Spin, Alert } from "antd";
import { connect } from "react-redux";
import { uploadClient } from "../../api";
import { getmemeberInfo, getIpRegisteryData } from "../../reducers/configReduser";
import DefaultUser from "../../assets/images/defaultuser.jpg";
import apiCalls from "../../api/apiCalls";

class ProfileInfo extends Component {
  state = { Image: null, Loader: false, fileLoader: false, errorMessage: null };
  uploadProps = {
    name: "file",
    multiple: false,
    fileList: [],
    customRequest: ({ file }) => {

      let formData = new FormData();
      this.setState({ ...this.state, Loader: true });
      formData.append("file", file, file.name);
      uploadClient.post("UploadFile", formData).then((res) => {
        if (res.ok) {
          this.setState({ ...this.state, Loader: false });
          let Obj = {
            ImageURL: res.data[0],
            UserId: this.props.userConfig?.userId
          };

          this.saveImage(Obj);
        } else {
          this.setState({ ...this.state, Loader: false ,errorMessage:apiCalls.isErrorDispaly(res) });
        }
      });
    },
    beforeUpload: (file) => {
      let fileType = {
        "image/png": true,
        "image/jpg": true,
        "image/jpeg": true,
        "image/PNG": true,
        "image/JPG": true,
        "image/JPEG": true
      };
      let isFileName = file.name.split(".").length === 2;
      if (fileType[file.type] && isFileName) {
        return true;
      } else {
        this.setState({
          ...this.state, Loader: false, errorMessage: isFileName
            ? `File is not allowed. You can upload jpg, png, jpeg files`
            : "File don't allow double extension"
        });
        return Upload.LIST_IGNORE;
      }
    }
  };
  componentDidMount() {

    this.props.getmemeberInfoa(this.props.userConfig.userId, this.props.userConfig.id);
  }

  saveImage = async (Obj) => {
    this.setState({ ...this.state, Loader: true });
    Obj.info = JSON.stringify(this.props.trackAuditLogData);
    let res1 = await apiCalls.ProfileImageSave(Obj);
    if (res1.ok) {
      this.props.getmemeberInfoa(this.props.userConfig.userId);
      message.success({
        content: "Profile uploaded successfully",
        className: "custom-msg",
        duration: 3
      });
      setTimeout(()=>{this.setState({ ...this.state, Loader: false,errorMessage:null })},3000);
       } else {
      this.setState({ ...this.state, Loader: false,errorMessage:apiCalls.isErrorDispaly(res1)});
    }
  };

  fileDownload = async () => {
      this.setState({ ...this.state, fileLoader: true });
      let res = await apiCalls.downloadKyc(this.props.userConfig?.id);
      if (res.ok) {
        window.open(res.data);
        message.destroy();
        message.success({
          content: "Document downloaded successfully",
          className: "custom-msg",
          duration: 3,
        });
        this.setState({ ...this.state, fileLoader: false });
      }
  }

  render() {
    const { Title, Text } = Typography;
    return (
      <>
        {this.state.errorMessage !== null && (
          <Alert
            className="mb-16"
            type="error"
            description={this.state.errorMessage}
            showIcon
          />
        )}
        <div className="profile-info text-center">
            <>
              <Spin spinning={this.state.Loader}>
                <img src={this.props.userConfig.imageURL || DefaultUser }
                  className="user-profile"
                  alt={"image"}
                />
              <Upload
                {...this.uploadProps}
                accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG"
              >
                <Button
                  shape="circle"
                  type="primary"
                  className="img-upld"
                  size="large"
                  icon={<span className="icon xl camera" />}
                />
              </Upload>
              </Spin>
            </>
        </div>
      
        <div className="box basic-info basic-details">
          <Title className="basicinfo mb-16">
            {" "}
            <Text className="basicinfo mb-8">Personal Details</Text>
          </Title>
          <ul className="user-list pl-0">
            {this.props.userConfig.isBusiness && <li className="profileinfo">
              <div className="profile-block">
                <label className="mb-0 profile-label">
                  <Text className="mb-0 profile-label" >Business</Text>
                </label>
                <p className="mb-0 profile-value" style={{ flexGrow: 12 }}>
                  {this.props.userConfig.businessName || "--"}
                </p>
                <div></div>
              </div>
            </li>}
            <li className="profileinfo">
              <div className="profile-block">
                <label className="mb-0 profile-label">
                  <Text className="mb-0 profile-label">User Name</Text>
                </label>
                <p className="mb-0 profile-value" style={{ flexGrow: 12 }}>
                  {this.props.userConfig.userName || "--"}
                </p>
                <div></div>
              </div>
            </li>
            {this.props.userConfig.isBusiness !== true && <>
              <li className="profileinfo">
                <div className="profile-block ">
                  <label className="mb-0 profile-label">
                    <Text className="mb-0 profile-label">First Name</Text>
                  </label>
                  <p className="mb-0 profile-value" style={{ flexGrow: 12 }}>
                    {this.props.userConfig.firstName || "--"}
                  </p>
                  <div></div>
                </div>
              </li>

              <li className="profileinfo">
                <div className="profile-block ">
                  <label className="mb-0 profile-label">
                    <Text className="mb-0 profile-label">Last Name</Text>
                  </label>
                  <p className="mb-0 profile-value" style={{ flexGrow: 12 }}>
                    {this.props.userConfig.lastName || "--"}
                  </p>
                  <div></div>
                </div>
              </li></>}
          </ul>
        </div>
        <div className="box contact-info basic-details">
          <Title className="basicinfo">
            <Text className="basicinfo">Contact Info</Text>
          </Title>
          <ul className="user-list pl-0">
          
            <li className="profileinfo">
              <div className="profile-block">
                <label className="mb-0 profile-label">
                  <Text
                    className="mb-0 profile-label"
                  >Email Address</Text>
                </label>
                <p className="mb-0 profile-value" style={{ flexGrow: 12 }}>
                  {this.props.userConfig.email || "--"}
                </p>
                <div></div>
              </div>
            </li>
            <li className="profileinfo">
              <div className="profile-block ">
                <label className="mb-0 profile-label">
                  <Text
                    className="mb-0 profile-label"
                  >Phone Number</Text>
                </label>
                <div style={{ flexGrow: 12 }}>
                  <p className="mb-0 profile-value">
                    {this.props.userConfig.phoneNo || "--"}
                  </p>
                </div>
                <div></div>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    getmemeberInfoa: (useremail, id) => {
      dispatch(getmemeberInfo(useremail, id));
    },
    trackauditlogs: () => {
      dispatch(getIpRegisteryData());
    }
  };

};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(ProfileInfo);
