import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip,Alert ,message,Form,Input,Row,Col} from "antd";
import { saveNotes} from "../components/deposit.component/api";
import apiCalls from "../api/apiCalls";
import { PlusCircleOutlined,EditOutlined } from '@ant-design/icons';
import { validateContentRule } from "./custom.validator";
import  {connect} from "react-redux";
const {TextArea}=Input
const Notes=(props)=>{
	const [noteform] = Form.useForm();
	const [notesModal,setNotesModal]=useState(false)
	const [notesError,setnotesError]=useState(null)
	const [notesLoader,setnotesLoader]=useState(false)
	const [notes,setnotes]=useState()
	const [savednote,setsavedNote]=useState()
    const [state,setstate]=useState()
    useEffect(()=>{
        setsavedNote(props.createdNote)
    },[props.createdNote])
    const addNotes = () => {
        setnotes(savednote)
        setNotesModal(true); setnotesError(null); setnotesLoader(false)
        noteform.setFieldsValue({ notes: savednote });setstate('Added')
    }
    const EditNotes = () => {noteform.resetFields();
        setnotes(savednote); setNotesModal(true); noteform.setFieldsValue({ notes: savednote });setstate('Edited')
    }
    const saveNote = async () => {
        setnotesLoader(true)
        let obj = { "Id": props.screenId, "ScreenName":props.screenName, "Notes": notes,state:state+" Message",
        modifiedBy:props.userConfig.userName,modifiedDate:new Date()
    }
        let response = await saveNotes(obj);
        if (response.ok) {
            message.destroy();
            message.success({
                content: "Notes saved successfully",
                className: "custom-msg",
                duration: 4
            }
            );
            setnotesError(null); setnotesLoader(false); setsavedNote(notes);
            setNotesModal(false)
        } else {
            setnotesError(apiCalls.isErrorDispaly(response))
            setnotesLoader(false); setNotesModal(false)
        }
    }
    return (
        <>
        <b style={{marginLeft: 11}}>Notes</b> {!savednote && <PlusCircleOutlined onClick={() => addNotes()} />}{savednote && <EditOutlined onClick={() => EditNotes()} />}

        <TextArea
            placeholder="Notes"
            maxLength={100}
            rows={3}
            disabled
            value={savednote} showCount/>
           
        <Modal
            visible={notesModal}
            title='Notes'
            closeIcon={
                <Tooltip title="Close">
                    <span
                        className="icon md x c-pointer risk-close"
                        onClick={() => {setNotesModal(false);setnotes(savednote); noteform.resetFields();}}
                    />
                </Tooltip>
            }
            footer={null}
        >
             <Form
                onFinish={() => saveNote()}
                className="risk-popup"
                form={noteform}>
            {notesError !== undefined && notesError !== null && (
                <Alert className="w-100 mb-16" type="error" showIcon description={notesError} />
            )}<Row gutter={24} className="mb-24 pb-24 border-bottom">


                <Col xs={24} sm={24} md={24}>
                    <Form.Item
                        name="notes"
                        className="input-label"
                        label="Notes"
                        rules={[
                            {
                                required: true,
                                message: "Is required"
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                            {
                                validator: validateContentRule
                            }
                        ]}
                    >
                        <TextArea
                            placeholder="Notes"
                            maxLength={100}
                            rows={3}

                            onChange={(e) => setnotes(e.target.value)} showCount/>
                    </Form.Item></Col>
                    </Row><div>
                    <Form.Item className="mb-0">
            <div className="text-right">
                    <Button
                        type="primary"
                        className="primary-btn cancel-btn mr-8"
                        onClick={() => {setNotesModal(false);setnotes(savednote); noteform.resetFields();}}
                    >
                        {" "}
                        Cancel{" "}
                    </Button>
                <Button
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    loading={notesLoader}
                >
                    Save{" "}
                </Button>
            </div>
        </Form.Item>
                </div>
                </Form>
        </Modal>
        </>
    )
}
const connectStateToProps = ({ userConfig }) => {
    return { userConfig: userConfig.userProfileInfo }
  }
export default  connect(connectStateToProps)(Notes);