import React, { Component } from "react";
import List from "../grid.component";
import {  Tooltip, Modal, message, Button, Alert } from "antd";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import {activeInactive} from "./api"


class Notices extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isCheck: false,
      selectedObj: {},
      selection: [],
      modal: false,
      gridUrl: process.env.REACT_APP_GRID_API + "Notices",
      obj: {
        id: [],
        tableName: "Master.Notices",
        //modifiedBy: "",
        status: []
      },
      alert: false,
      errorMessage: "",
      btnDisabled: false,
      warningMsg: null,
      isnoticeLoading: false
    };
    this.gridRef = React.createRef();
  }

  gridColumns = [
    {
      field: "",
      title: "",
      locked: true,
      width: 50,
      customCell: (props) => (
        <td>
          {" "}
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="isCheck"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.checkChange(props, e)}
            />
            <span></span>{" "}
          </label>
        </td>
      )
    },
    {
      field: "title",
      title: "Title",
      width: 140,
      filter: true,
      locked: true,
      customCell: (props) => (
          <div className="gridLink" onClick={() => this.noticeView(props)}>
            {" "}
            {props.dataItem.title}
          </div>
        )
    },
    { field: "noticeFor", title: "Type", filter: true, width: 150 },
    {
      field: "fromDate",
      title: "From Date",
      filter: true,
      filterType: "date",
      width: 150
    },
    {
      field: "todate",
      title: "To Date",
      filter: true,
      filterType: "date",
      width: 150
    },
    { field: "createdBy", title: "Created By", filter: true },
    {
      field: "createdDate",
      title: "Created Date",
      filter: true,
      filterType: "date"
    },
    { field: "status", title: "Status", filter: true, width: 150 }
  ];


  noticeView = (e) => {
    const rowId = e.dataItem.id;
    this.props.history.push("/notices/" + rowId + "/disabled");
    this.props.dispatch(
      setBreadcrumb({ key: "/notices/" + rowId, val: e.dataItem.title })
    );
  
  };
  statusUpdate = () => {
    if (!this.state.isCheck) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record' });
    } else {
      this.setState({ modal: true });
    }
  };
  checkChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.currentTarget.type === "checkbox"
        ? e.currentTarget.checked
        : e.currentTarget.value;
    const name = e.currentTarget.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      warningMsg: null
    });
  };
  success = () => {
    message.success({
      content:
        "Record " +
        (this.state.selectedObj.status === "Active"
          ? "deactivated"
          : "activated") +
        " successfully",
      className: "custom-msg",
      duration: 4
    });
  };
  handleSatatuSave = async () => {
    this.setState({ ...this.state, isLoading: true, btnDisabled: true, isnoticeLoading: true });
    let statusObj = this.state.obj;
    statusObj.id.push(this.state.selectedObj.id);
    statusObj.modifiedBy = this.props.userConfig?.userName;
    statusObj.status.push(this.state.selectedObj.status);
    statusObj.info = JSON.stringify(this.props.trackAuditLogData);
    statusObj.customerId = this.props.userConfig?.id;

    let response = await activeInactive(statusObj);
    if (response.ok) {
      this.props.dispatch(setCurrentAction(null));
      this.success();
      this.setState({
        ...this.state,
        modal: false,
        selection: [],
        isCheck: false,
        isLoading: false,
        btnDisabled: false,
        isnoticeLoading: false
      });
      this.gridRef.current.refreshGrid();
    } else {
      this.setState({
        ...this.state,
        isLoading: false,
        selection: [],
        isCheck: false,
        isnoticeLoading: false,
        error: response.status === 401 ? response.data.message : response.data
      });
    }
   
  };
  handleCancel = (e) => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      isCheck: false,
      error: null,
      warningMsg: null
    });
  };
  addNotice = () => {
    this.props.history.push("/notices/00000000-0000-0000-0000-000000000000");
    this.props.dispatch(
      setBreadcrumb({
        key: "/notices/00000000-0000-0000-0000-000000000000",
        val: "Add Notice"
      })
    );

  };
  editNotice = () => {
    if (!this.state.isCheck) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record' });
    } else {
      const obj = this.state.selectedObj;
      let val = obj.id;
      if (this.state.selectedObj.status === "Inactive") {
        this.props.history.push("/notices/" + val + "/disabled");
        this.props.dispatch(
          setBreadcrumb({ key: "/notices/" + val, val: obj.title })
        );
      } else {
        this.props.history.push("/notices/" + val);
        this.props.dispatch(
          setBreadcrumb({ key: "/notices/" + val, val: obj.title })
        );
      }
    }

  };
  onActionClick = (key) => {
    const actions = {
      add: "addNotice",
      edit: "editNotice",
      disable: "statusUpdate"
    };
    this[actions[key]]();
  };
  render() {
    const { gridUrl, error, warningMsg } = this.state;
    return (
      <>
        {warningMsg !== undefined && warningMsg !== null && (
          <Alert
            className="w-100 mb-16"
            type="warning"
            description={warningMsg}
            showIcon
          />
        )}

        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"notices"}
          url={gridUrl}
          ref={this.gridRef}
          columns={this.gridColumns}
        />
        <Modal
          title={
            this.state.selectedObj.status === "Active"
              ? "Confirm Deactivate?"
              : "Confirm Activate?"
          }
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.handleCancel}
              >
                No
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                onClick={this.handleSatatuSave}
                loading={this.state.isnoticeLoading}
              >
                Yes
              </Button>
            </>
          }
        >
          {error !== undefined && error !== null && (
            <Alert type="error" className="mb-16" showIcon description={error} />
          )}
          <p className="fs-16 mb-0">
            Do you really want to{" "}
            {this.state.selectedObj.status === "Active"
              ? "deactivate?"
              : "activate?"}
          </p>
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return { breadCrumb, oidc, userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData };
};

export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(Notices);
