import React, { Component } from 'react';
import { connect } from "react-redux";
import { publishShowActions } from "../grid.component/subscribir";
import {Typography, Tabs,Button,Table } from 'antd';
import { Link } from "react-router-dom";
const { Title,  } = Typography;
const { TabPane } = Tabs;

const columns = [
	{
		title: "Employee",
		dataIndex: "firstname",
	},
	{
		title: "Topup",
		dataIndex: "lastname",
	},
	{
		title: "Date",
		dataIndex: "email",
	},
	{
		title: "Currency",
		dataIndex: "countryofresidence",
	},
	{
		title: "Status",
		dataIndex: "kyc",
	},
];
const data = [
	{
		key: "1",
		firstname: (
			<Link className="textpure-yellow" to="/#">
				John Doe
			</Link>
		),
		lastname: "500",
		email: "16-09-2022",
		countryofresidence: "USD",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Pending
				</Button>
			</div>
		),
	},
	{
		key: "2",
		firstname: (
			<Link className="textpure-yellow" to="/#">
				Smith Joy
			</Link>
		),
        lastname: "500",
		email: "16-09-2022",
		countryofresidence: "USD",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Completed
				</Button>
			</div>
		),
	},
	{
		key: "3",
		firstname: (
			<Link  className="textpure-yellow" to="/#">
				Jessica
			</Link>
		),
        lastname: "500",
		email: "16-09-2022",
		countryofresidence: "USD",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Suspended
				</Button>
			</div>
		),
	},
	{
		key: "4",
		firstname: (
			<Link  className="textpure-yellow" to="/#">
				Veeru
			</Link>
		),
		lastname: "500",
		email: "16-09-2022",
		countryofresidence: "USD",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Cancelled
				</Button>
			</div>
		),
	},
    {
		key: "4",
		firstname: (
			<Link className="textpure-yellow" to="/#">
				Mike
			</Link>
		),
		lastname: "500",
		email: "16-09-2022",
		countryofresidence: "USD",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Completed
				</Button>
			</div>
		),
	},
];
const columnsEmp = [
	{
		title: "First Name",
		dataIndex: "firstname",
	},
	{
		title: "Last Name",
		dataIndex: "lastname",
	},
	{
		title: "email",
		dataIndex: "email",
	},
	{
		title: "Country Of Residence",
		dataIndex: "countryofresidence",
	},
	{
		title: "Status",
		dataIndex: "kyc",
	},
];
const dataEmp = [
	{
		key: "1",
		firstname: (
			<Link  className="textpure-yellow" to="/#">
				John 
			</Link>
		),
		lastname: "Doe",
		email: "john@yopmail.com",
		countryofresidence: "India",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Pending
				</Button>
			</div>
		),
	},
	{
		key: "2",
		firstname: (
			<Link  className="textpure-yellow" to="/#">
				Smith 
			</Link>
		),
        lastname: "Joy",
		email: "smith@yopmail.com",
		countryofresidence: "India",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Completed
				</Button>
			</div>
		),
	},
	{
		key: "3",
		firstname: (
			<Link  className="textpure-yellow" to="/#">
				Jessica
			</Link>
		),
        lastname: "Smith",
		email: "jessica@yopmail.com",
		countryofresidence: "India",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Suspended
				</Button>
			</div>
		),
	},
	{
		key: "4",
		firstname: (
			<Link  className="textpure-yellow" to="/#">
				Veeru
			</Link>
		),
		lastname: "Joy",
		email: "veeru@yopmail.com",
		countryofresidence: "India",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Cancelled
				</Button>
			</div>
		),
	},
    {
		key: "4",
		firstname: (
			<Link  className="textpure-yellow" to="/#">
				Mike
			</Link>
		),
		lastname: "Tison",
		email: "mike@yopmail.com",
		countryofresidence: "India",
		kyc: (
			<div>
				{" "}
				<Button className="card-tblbtn success" type="primary">
					Completed
				</Button>
			</div>
		),
	},
];
class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "topup",
            employee: true,
        }
    }

    changeTab = (activeKey) => {
		
        this.setState({ ...this.state, activeTab: activeKey }, () => {
            if (activeKey === 'employe') {
                this.props.history.push("/cardsmodule/employee")
            } else if (activeKey === 'Cards') {
                this.props.history.push("/cardsmodule/cards")
            }
            else {
                this.props.history.push("/cardsmodule/topup")
            }
        });
    }

componentDidMount=()=>{
    publishShowActions(false);
}
    render() {
       
        return (
            <>
            
               <div>
                    <div >
                    <div className="fs-26 text-left text-white mb-8 fw-400">
                    <div className="d-flex justify-content">
                    <Title className="fs-26 text-left mb-8 fw-400">
                            Topups
                        </Title>
                    </div>
                        <div className="text-right">
							<button
								className="primary-btn px-23 custom-btn prime mb-8"
								type="button">
								Approved
							</button>
						</div>
				<Tabs defaultActiveKey={1} className='card-list mt-24'>
                        <TabPane tab={"Employee"} key="1" >
                        <Table columns={columnsEmp} dataSource={dataEmp}  />
                        </TabPane>
                        <TabPane tab={"Topups"} key="2" >
                        <Table columns={columns} dataSource={data}  />
                        </TabPane>
                    </Tabs>
                    </div>
                    
                    </div>
                    </div>
    
  
            </>
        )
    }
}

const connectStateToProps = ({ oidc, userConfig }) => {
    return { userConfig: userConfig.userProfileInfo, oidc, trackAuditLogData: userConfig.trackAuditLogData };
};
export default connect(connectStateToProps, (dispatch) => {return { dispatch };})(Cards);